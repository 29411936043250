import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuth } from '../../redux/authSlice';

import { FaChevronRight, FaSearch } from 'react-icons/fa';
import { LeadMessageSection } from './LeadMessageSection.js/leadMessageSection';
import { LeadUserMessageList } from './userList'


export const MessagesPageInCommon = (leadData) => {
  const dispatch = useDispatch();
  const [selectedLeadUser, setSelectedLeadUser] = useState([])

  useEffect(() => {
    console.log(leadData, 'selecting first one')
  }, [leadData]);

  const handleUserClick = (leadId) => {
    let filteredUserDetails = leadData.leadsData.filter(i => i.id === leadId);
    setSelectedLeadUser(filteredUserDetails);
  };
  return (
    <div className="flex">
      <div className="bg-white w-1/4 rounded h-screen">
        <div className="flex justify-between p-2">
          {/* <button className="bg-blue-500 text-white w-full py-2 px-4 rounded flex items-center justify-between">
            <div>Select Listing</div>
            <FaChevronRight className="ml-2" />
          </button> */}
        </div>
        <>
          <div className="flex justify-between px-2">
            <div className="relative w-full">
              <FaSearch className="absolute left-3 top-3 text-gray-400" />
              <input
                type="text"
                placeholder="Search..."
                // value={searchTerm}
                // onChange={handleSearchChange}
                className="w-full pl-10 border border-blue-500 rounded py-2 px-4 bg-gray-100"
              />
            </div>
            {/* <button className="text-black-500 truncate text-sm font-semibold underline py-2 px-4 rounded flex items-center ml-2">
              Sort By
            </button> */}
          </div>
          <div className='flex flex-col gap-2 pt-2'>
            <LeadUserMessageList leadData={leadData} handleUserClick={handleUserClick} />
          </div>
        </>
      </div>
      {selectedLeadUser.length > 0 ?
        <LeadMessageSection leadData={selectedLeadUser} /> : <div className='w-3/4 ml-2 bg-white rounded-lg overflow-y-auto max-w-[95%] sm:max-w-[90%] text-center'>Please select a user </div>
      }
    </div>
  );
};