import React, { useState, useEffect } from "react";
import { SubHeaderLeads } from "./subheaderLeads";
import { AllLeadsMain } from "./allLeads/AllLeads";
import { TrackSystemForProperties } from "./TrackSystemForProperties";
import { AddNewLead } from "../../../components/common/models/addNewLead";
import { createLeads } from "../../../redux/leadSlice";
import { useDispatch } from "react-redux";
import { MessagesPageInCommon } from "../../../components/Messages/messages";

export const PropertiesLead = ({ leadsData, properties1, listingData,leadsUserData }) => {

  const [properties, setProperties] = useState([]);
  const [leads, setLeads] = useState([]);
  const [listing, setListing] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("[properties_name]");
  const [isOrderByDropdownOpen, setIsOrderByDropdownOpen] = useState(false);
  const [showActive, setShowActive] = useState(true);
  const [showInactive, setShowInactive] = useState(true);
  const [isLeadModalOpen, setIsLeadModalOpen] = useState(false);
  const [activeSubHeader, setActiveSubHeader] = useState("All Leads");
  const [selectedListingId, setSelectedListingId] = useState(null);
  const [selectedListingMatterMostId, SetSelectedListingMatterMostId] =
    useState("");
  const dispatch = useDispatch();  

  useEffect(() => {
    setProperties(properties1?.data || []);
    setListing(listingData?.data || []);
    let filterForPropertyLead = leadsData.data.filter(
      (leads) => leads.attributes.crm_listings.data.length > 0
    );
    console.log(
      filterForPropertyLead,
      "lead that comming from property listing",
      leadsData
    );
    setLeads(filterForPropertyLead || []);
  }, [properties1, leadsData, listingData]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectedList = (data) => {
    setSelectedListingId(data.id);
    SetSelectedListingMatterMostId(data.attributes.mattermost_id);

    // SetSelectedListingMatterMostId(id?id:"");
  };

  const handleOrderChange = (value) => {
    setOrderBy(value);
    setIsOrderByDropdownOpen(false);
  };

  const toggleOrderByDropdown = () => {
    setIsOrderByDropdownOpen(!isOrderByDropdownOpen);
  };

  const toggleActive = () => {
    setShowActive(!showActive);
  };

  const toggleInactive = () => {
    setShowInactive(!showInactive);
  };

  const openModal = () => {
    setIsLeadModalOpen(true);
  };

  const closeModal = () => {
    setIsLeadModalOpen(false);
  };

  const handleAddLeadFormSubmit = (formData) => {
    console.log(
      formData,
      "formdataaa for Lead",
      leadsData,
      selectedListingMatterMostId
    );

    formData.data.crm_listings = {
      disconnect: [],
      connect: [{ id: selectedListingId, position: { end: true } }],
    };

    dispatch(
      createLeads({ leadsData: formData, selectedListingMatterMostId })
    ).then(() => {
      closeModal();
      window.location.reload();
    });
  };

  const handleSubHeaderClick = (title) => {
    setActiveSubHeader(title);
  };

  return (
    <div>
      <SubHeaderLeads
        searchTerm={searchTerm}
        handleSearchChange={handleSearchChange}
        orderBy={orderBy}
        isOrderByDropdownOpen={isOrderByDropdownOpen}
        toggleOrderByDropdown={toggleOrderByDropdown}
        handleOrderChange={handleOrderChange}
        showActive={showActive}
        toggleActive={toggleActive}
        showInactive={showInactive}
        toggleInactive={toggleInactive}
        openModal={openModal}
        activeSubHeader={activeSubHeader}
        handleSubHeaderClick={handleSubHeaderClick}
        selectedPropertyId={selectedListingId} 
      />
      {activeSubHeader === "All Leads" && (
        <AllLeadsMain
          properties1={properties1}
          listingData={properties1 ? properties1 : []}
          leadsData={leads}
          handleSelectedListingId={handleSelectedList}
        />
      )}
      {activeSubHeader === "Lead Tracking System" && (
        <TrackSystemForProperties
          properties1={properties1?.data || []}
          listingData={properties1 ? properties1 : []}
          leadsData={leads}
          handleSelectedListingId={handleSelectedList}
        />
      )}
      {activeSubHeader === "Messages" && (
        <MessagesPageInCommon leadsData={leadsUserData.data}/>
      )}
      {isLeadModalOpen && (
        <AddNewLead
          isOpen={isLeadModalOpen}
          onClose={closeModal}
          onSubmit={handleAddLeadFormSubmit}
        />
      )}
    </div>
  );
};
