import React, { useState } from "react";
import { SubHeader } from "./Subheader";
import { Post } from "../../components/Post/post";

export const Feed = () => {

  const [activeItem, setActiveItem] = useState('Post');


  return (
    <div className="Projects-page bg-gray-100 min-h-screen p-1">
      <SubHeader activeItem={activeItem} onNavigate={setActiveItem} />
      {activeItem === "Post" && <Post section="communication" />}
      {/* {activeItem === 'Lead' && <AllLeadsMain projectsData={projectsData.data} properties1={properties1} />}
    {activeItem === 'LeadsTrackingSystem' && <TrackSystemForProperties projectsData={projectsData.data} properties1={properties1}/>} */}
    </div>
  );
};
