import { createSlice, createAsyncThunk } from  '@reduxjs/toolkit';
import { fetchServicesListings,fetchServicesUserListings } from '../services/api';
import axios from 'axios';
const initialState = {
    servicesListingData: [],
    servicesUserListingData: [],
    servicesListingStatus: 'idle',
    servicesListingError: null,
    servicesListingCurrentPage: 1,
    servicesListingTotalPages: 1,
    servicesUserListingStatus: 'idle',
    servicesUserListingError: null,
    servicesUserListingCurrentPage: 1,
    servicesUserListingTotalPages: 1,
  };  
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  
  export const getServicesListings = createAsyncThunk(
    'servicesListings/getServicesListings',
    async ({ page, pageSize }) => {
      const servicesListings = await fetchServicesListings(page, pageSize);
      return servicesListings;
    }
  );
  export const getServicesUserListings = createAsyncThunk(
    'servicesListings/getServicesUserListings',
    async ({ page, pageSize,userId }) => {
      const servicesListings = await fetchServicesUserListings(page, pageSize,userId);
      console.log(servicesListings,'-- user service listing data'  )
      return servicesListings;
    }
  );

export const createServicesListing = createAsyncThunk(
  'ServicesListings/createServicesListing',
  async (listingData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-service-listings`, listingData,{
        headers: {  
          "Content-Type": "application/json",
        },
      });
      let myStrapiData = response.data.data;
      console.log(myStrapiData,'my strapi data of ServicesListing')
      const servicesListingName = myStrapiData.attributes.name.split(' ').join('').toLowerCase();
      let mattermostTeamId ='ix1bdn6bwpbd9gak17r9eydurc' 
      const matterMostData = {
        team_id:mattermostTeamId,
        name: `${myStrapiData.id}${servicesListingName}`,
        display_name: `ser_${myStrapiData.id}_${myStrapiData.attributes.name}`,
        type: "P",
        user_id: loggedInUser.mattermost_user_id
      };
     console.log(myStrapiData,"myStrapiDatamyStrapiData")

    //// calling the api of mattermost //////////////
    const matterMostResponse = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}api/listing-api-mattermost`, matterMostData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log(matterMostResponse.data, 'mattermost_property_listing')
    
    listingData.data.mattermost_services_listing_id = matterMostResponse.data.mattermostChannelId;

      const responseWithMatterMostId = await axios.put(`${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-service-listings/${myStrapiData.id}`, listingData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return responseWithMatterMostId;

  } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateServicesListing = createAsyncThunk(
  'servicesListings/updateServicesListing',
  async ({ id, listingData }, { rejectWithValue }) => {
    try {
  const response = await axios.put(`${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-service-listings/${id}`, listingData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteServicesListing = createAsyncThunk(
  'servicesListings/deleteProperty',
  async (id, { rejectWithValue }) => {
    try {
  const response = await axios.delete(`${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-ServicesListings/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const servicesListingsSlice = createSlice({
  name: 'servicesListings',
  initialState,
  reducers: {
    setPage(state, action) {  
      state.servicesListingCurrentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServicesListings.pending, (state) => {
        state.servicesListingStatus = 'loading';
      })
      .addCase(getServicesListings.fulfilled, (state, action) => {
        state.servicesListingStatus = 'succeeded';
        state.servicesListingData = action.payload;
        state.servicesListingTotalPages = Math.ceil(action.payload.meta.pagination.total / action.payload.meta.pagination.pageSize);
      })
      .addCase(getServicesUserListings.fulfilled, (state, action) => {
        state.servicesUserListingStatus = 'succeeded';
        state.servicesUserListingData = action.payload;
        state.servicesUserListingTotalPages = Math.ceil(action.payload.meta.pagination.total / action.payload.meta.pagination.pageSize);
      })
      .addCase(getServicesListings.rejected, (state, action) => {
        state.servicesListingStatus = 'failed';
        state.servicesListingError = action.error.message;
      });
  },
});
export const { setPage } = servicesListingsSlice.actions;
export default servicesListingsSlice.reducer;