import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchProperties , fetchUserProperties } from '../services/api';
import axios from 'axios';
const initialState = {
  data: [],
  userData: [],
  status: 'idle',
  userStatus: 'idle',
  error: null,
  userError: null,
  currentPage: 1,
  userCurrentPage: 1,
  totalPages: 1,
  userTotalPages: 1,
};

const loggedInUser = JSON.parse(localStorage.getItem("user"));

export const getProperties = createAsyncThunk(
  'properties/getProperties',
  async ({ page, pageSize }) => {
    const properties = await fetchProperties(page, pageSize);
    return properties;
  }
);

export const getUserProperties = createAsyncThunk(
  'properties/getUserProperties',
  async ({ page, pageSize, userId }) => {
    const properties = await fetchUserProperties(page, pageSize, userId);
    return properties;
  }
);

  export const createProperty = createAsyncThunk(
    'property/createProperty',
    async (propertyData, { rejectWithValue }) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-properties`, propertyData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        return response;
      } catch (error) {
        console.error('Error creating property:', error);
        return rejectWithValue(error.response.data);
      }
    }
  );
  export const createPropertyListingFromProjects = createAsyncThunk(
    'property/createProperty',
    async (propertyData, { rejectWithValue }) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-listings`, propertyData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      let myStrapiData = response.data.data;
        const propertyName = myStrapiData.attributes.property_name.split(' ').join('').toLowerCase();
        let mattermostTeamId ='ix1bdn6bwpbd9gak17r9eydurc' 
        
        const matterMostData = {
          team_id:mattermostTeamId,
          name: `${myStrapiData.id}${propertyName}`,
          display_name: `proj_${myStrapiData.id}_${myStrapiData.attributes.property_name}`,
          type: "P",
          user_id: loggedInUser.mattermost_user_id
        };

        const matterMostResponse = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}api/listing-api-mattermost`, matterMostData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log(matterMostResponse.data, 'mattermost_project_listing')

        response.data.mattermost_id = matterMostResponse.data.mattermostChannelId;
        const responseWithMatterMostId = await axios.put(`${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-listings/${myStrapiData.id}`, response, {
          headers: {
            "Content-Type": "application/json",
          },
        }); 

      } catch (error) {
        console.error('Error creating property:', error);
        return rejectWithValue(error.response.data);
      }
    }
  );

export const updateProperty = createAsyncThunk(
  'property/updateProperty',
  async ({ id, propertyData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-listings/${id}`,
        propertyData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteProperty = createAsyncThunk(
  'property/deleteProperty',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-listings/${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteService = createAsyncThunk(
  'services/deleteService',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-service-listings/${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const propertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    setPage(state, action) {  
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProperties.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getProperties.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
        state.totalPages = Math.ceil(action.payload.meta.pagination.total / action.payload.meta.pagination.pageSize);
      })
      .addCase(getUserProperties.fulfilled, (state, action) => {
        state.userStatus = 'succeeded';
        state.userData = action.payload;
        state.userTotalPages = Math.ceil(action.payload.meta.pagination.total / action.payload.meta.pagination.pageSize);
      })
      .addCase(getProperties.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});
export const { setPage } = propertiesSlice.actions;
export default propertiesSlice.reducer;