import { FaParking, FaTree, FaLock, FaBed, FaWifi, FaChair, FaWater, FaEdit, FaTrash, FaSwimmingPool, FaDumbbell } from 'react-icons/fa';
import React, { useState, useEffect } from 'react';
import { IoLocationSharp } from "react-icons/io5";
import { BiCloset } from "react-icons/bi";
import { BsTextarea } from "react-icons/bs";
import { MdOutlineGasMeter } from 'react-icons/md';
// import { DetailsCard } from '../cards/DetailsCard';

export const MyServicesListing = ({ services = [], onDelete, onEdit, containerWidth, borderOfContainer }) => {
  const [clicks, setClicks] = useState({});
  const [expandedAmenities, setExpandedAmenities] = useState({});
  useEffect(() => {
    setClicks({});
    setExpandedAmenities({});
  }, [services]);

  const handleClick = (id) => {
    setClicks((prevClicks) => ({
      ...prevClicks,
      [id]: (prevClicks[id] || 0) + 1,
    }));
  };

  const handleExpandAmenities = (id) => {
    setExpandedAmenities((prevExpanded) => ({
      ...prevExpanded,
      [id]: !prevExpanded[id],
    }));
  };

  const handleDelete = (id) => {
    onDelete(id);
  };

  const handleEdit = (id) => {
    const property = services.find((prop) => prop.id === id);
    onEdit(property);
  };

  const renderAmenityIcon = (amenityKey) => {
    switch (amenityKey) {
      case 'Bed':
        return <FaBed className="text-black-700 mr-1" />;
      case 'garden':
        return <FaTree className="text-black-500 mr-1" />;
      case 'security':
        return <FaLock className="text-black-500 mr-1" />;
      case 'gas':
        return <MdOutlineGasMeter className="text-black-500 mr-1" />;
      case 'Wifi':
        return <FaWifi className="text-black-500 mr-1" />;
      case 'chair':
        return <FaChair className="text-black-500 mr-1" />;
      case 'water':
        return <FaWater className="text-black-500 mr-1" />;
      case 'wardrobe':
        return <BiCloset className="text-black-500 mr-1" />;
      case 'parking':
        return <FaParking className="text-black-500 mr-1" />;
      default:
        return null;
    }
  };

  if (!services.length) {
    return <div>No services available.</div>;
  }

  return (
    <>
      {services.map((service) => (
        <div key={service.id} className={`w-full md:w-${containerWidth} px-4 mb-4`}>
          <div className={`${borderOfContainer} bg-white h-full flex flex-col`}>
            <div className="flex flex-col md:flex-row flex-grow">
              <div className="relative w-full md:w-48 h-48 mr-4 mb-4 md:mb-0">
                {service.attributes?.thumbnail_image?.data?.attributes?.url ? (
                  <img
                    // src={service.attributes.image.data.attributes.url}
                    src={service?.attributes?.thumbnail_image?.data?.attributes?.url || null}
                    alt={service.attributes.name}
                    className="w-full h-full object-cover rounded-md"
                  />
                ) : (
                  <div className="w-full h-full bg-gray-200 flex items-center justify-center rounded-md">
                    No Image
                  </div>
                )}
                <div className="absolute top-2 left-2 flex space-x-2">
                  <button onClick={() => handleEdit(service.id)} className="bg-white p-1 rounded-full shadow">
                    <FaEdit className="text-blue-500" />
                  </button>
                  <button onClick={() => handleDelete(service.id)} className="bg-white p-1 rounded-full shadow">
                    <FaTrash className="text-red-500" />
                  </button>
                </div>
              </div>
              <div className="flex flex-col justify-between flex-grow">
                <div>
                  <div className="flex justify-between items-center">
                    <h2 className="text-2xl font-semibold">{service.attributes?.name || 'No Name'}</h2>
                    <span className="text-blue-600">
                      <p>ID: {service.id}</p>
                      <button onClick={() => handleClick(service.id)} className="text-blue-600">
                        Clicks: {clicks[service.id] || 0}
                      </button>
                    </span>
                  </div>
                  <p className="text-lg text-gray-700">AED {service.attributes?.price || 'N/A'}</p>
                  <div className="space-y-1">
                    <div className="flex items-center space-x-2">
                      <IoLocationSharp className="text-gray-700" />
                      <p>{service.attributes?.services_id.data.attributes.location || 'No Address'}</p>
                    </div>
                    <div className="flex flex-wrap items-center">
                      {/* {(expandedAmenities[property.id] ? Object.keys(property.attributes?.amenities || {}) : Object.keys(property.attributes?.amenities || {}).slice(0, 6)).map((amenityKey, index) => (
                        <div key={index} className="flex items-center space-x-2 w-1/2">
                          {renderAmenityIcon(amenityKey)}
                          <span>{amenityKey.charAt(0).toUpperCase() + amenityKey.slice(1)}</span>
                        </div>
                      ))} */}
                    </div>
                    {/* {Object.keys(property.attributes?.amenities || {}).length > 6 && (
                      <p
                        className="text-gray-700 cursor-pointer"
                        onClick={() => handleExpandAmenities(property.id)}
                      >
                        {expandedAmenities[property.id] ? 'Show Less' : `+${Object.keys(property.attributes?.amenities || {}).length - 6} More`}
                      </p>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <p className="text-gray-700">{service.attributes?.description || 'No Description'}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
