import React, { useState } from "react";
export const AllLeadPropertiesListings = ({
  data,
  handleListingClick,
  listingCountMap,
}) => {
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  console.log(data, "data");
  const handlePropertySelection = (propertyId) => {
    setSelectedProjectId(propertyId);
    handleListingClick(propertyId);
  };
  return (
    <div className="divide-y divide-gray-200">
      {data.map((listing, index) => {
        if (
          !listing ||
          !listing.attributes ||
          !listing.attributes.thumbnail_image ||
          !listing.attributes.thumbnail_image.data
        ) {
          return null;
        }
        const isSelected = listing.id === selectedProjectId;
        return (
          <div
            key={index}
            onClick={() => handlePropertySelection(listing.id)}
            className={`flex items-center justify-between py-4 px-2 cursor-pointer ${isSelected ? "bg-blue-100" : ""
              }`}
          >
            <div className="flex items-center">
              <div className="flex-shrink-0 h-16 w-16 rounded-lg overflow-hidden border border-gray-300">
                <img
                  className="h-full w-full object-cover"
                  src={listing.attributes.thumbnail_image.data.attributes.url}
                  alt="properties"
                />
              </div>
              <div className="ml-2 flex-grow flex flex-col">
                <div className="font-semibold">{listing.attributes.property_name || listing.attributes.name}</div>
                {/* <IoLocationSharp className="text-gray-700 text-3xl mr-2" />                     */}
                <div className="text-gray-500 flex items-center w-40 truncate">
                  {listing.attributes.property_address}
                </div>
                {/* <div className="text-blue-500">{listing.attributes.property_price}</div> */}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
