import React from "react";

export const SubHeaderLeads = ({
  searchTerm,
  handleSearchChange,
  orderBy,
  isOrderByDropdownOpen,
  toggleOrderByDropdown,
  handleOrderChange,
  showActive,
  toggleActive,
  showInactive,
  toggleInactive,
  openModal,
  activeSubHeader,
  handleSubHeaderClick,
}) => {
  return (
    <div className="bg-white py-3 px-5 mb-6 shadow-md rounded-lg">
      <div className="flex items-center justify-between">
        <div className="flex space-x-4 flex-grow">
          <SubHeaderItem
            title="All Leads"
            isActive={activeSubHeader === "All Leads"}
            onClick={() => handleSubHeaderClick("All Leads")}
          />
          <SubHeaderItem
            title="Lead Tracking System"
            isActive={activeSubHeader === "Lead Tracking System"}
            onClick={() => handleSubHeaderClick("Lead Tracking System")}
          />
          <SubHeaderItem
            title="Messages"
            isActive={activeSubHeader === "Messages"}
            onClick={() => handleSubHeaderClick("Messages")}
          />
          {/* <button
            className={`ml-2 px-4 h-8 rounded ${showActive ? 'bg-green-500 text-white' : 'bg-gray-100 border-2 border-gray-300 text-gray-300'}`}
            onClick={toggleActive}
          >
            Active
          </button>
          <button
            className={`px-4 rounded h-8 ${!showActive ? 'bg-gray-500 text-white' : 'bg-gray-100 border-2 border-gray-300 text-gray-300'}`}
            onClick={toggleActive}
          >
            Draft
          </button>  */}
          <button
            className="border h-8 border-blue-500 text-blue-500  px-4 rounded"
            onClick={openModal}
          >
            Add New Lead
          </button>
        </div>
      </div>
    </div>
  );
};

const SubHeaderItem = ({ title, isActive, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`cursor-pointer text-black hover:text-gray-900 px-4 ${
        isActive ? "text-blue-500 border-b-2 border-blue-500" : ""
      }`}
    >
      {title}
    </div>
  );
};
