import React, { useEffect, useState } from 'react';
import { ChevronRight } from 'lucide-react/dist/cjs/lucide-react.js';
import userLogoImage from '../../services/images/profileLogo.jpg';


export const LeadUserMessageList = ({ leadData, handleUserClick }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [orderBy, setOrderBy] = useState('client_name');
  const [isOrderByDropdownOpen, setIsOrderByDropdownOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [data,setData]=useState([])
  useEffect(()=>{
    console.log(leadData.leadsData ,'inlist');
    setData(leadData.leadsData);
  },[leadData])

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleOrderChange = (value) => {
    setOrderBy(value);
    setIsOrderByDropdownOpen(false);
  };

  const toggleOrderByDropdown = () => {
    setIsOrderByDropdownOpen(!isOrderByDropdownOpen);
  };

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  const handleProjectSelection = (userId) => {
    setSelectedProjectId(userId);
    handleUserClick(userId);
  };

  const filteredUsers = data
  .filter(user =>
    user.attributes.client_name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
    user.attributes.lead_message?.toLowerCase().includes(searchTerm?.toLowerCase())
  )
  .sort((a, b) => {
    if (orderBy === 'client_name') {
      return a.attributes.client_name.localeCompare(b.attributes.client_name);
    } else if (orderBy === 'lead_message') {
      return a.attributes.lead_message.localeCompare(b.attributes.lead_message);
    } else if (orderBy === 'id') {
      return a.id - b.id;
    }
    return 0;
  });
  
  return (
    <div style={{ backgroundColor: "white" }} className="border-r border-gray-300 h-[450px] overflow-y-scroll">
      {filteredUsers && filteredUsers.map(user => {
        const isSelected = user.id === selectedProjectId;
        return(
        <div key={user.id} onClick={() => handleProjectSelection(user.id)} className={`flex items-center p-4 cursor-pointer hover:bg-gray-100 ${
          isSelected ? "bg-blue-100" : ""
        }`}>
          <img src={userLogoImage} className="w-10 h-10 rounded-full" alt={user.client_name} />
          <div className='pl-5'>
            <strong>{user.attributes.client_name}</strong>
            <p>{user.attributes.lead_message}</p>
          </div>
        </div>
      )})}
    </div>
  );
};
