import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

export const EditPropertyInventoryListingModel = ({
  isOpen,
  onClose,
  onSubmit,
  formAction,
  setFormAction,
  listing = {},
  properySelectedId,
  propertiesData,
}) => {
  
  
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();


  const [formData, setFormData] = useState({
    name: "",
    status: "",
    price: "",
    address: "",
    description: "",
    number_of_beds: "",
    property_id: properySelectedId || "",
    listing_type: "",
    size: "",
    furniture: false,
    amenities: ["Parking", "Air-Conditioned", "Balcony", "Gym"],
    floor_number: "",
    flat_number: "",
    rent_type: "",
    thumbnail_image: null,
  });

  useEffect(() => {
    console.log(listing);
    if (listing && Object.keys(listing).length > 0 && listing[0]?.id) {
      setFormAction("edit");
      setFormData({
        name: listing[0].attributes.name || "",
        address: listing[0].attributes.address || "",
        listing_type: listing[0].attributes.listing_type || "",
        price: listing[0].attributes.price || "",
        status: listing[0].attributes.status || "",
        description: listing[0].attributes.description || "",
        property_id: properySelectedId || "",
        number_of_beds: listing[0].attributes.number_of_beds || "",
        size: listing[0].attributes.size || "",
        furniture: listing[0].attributes.furniture || false,
        floor_number: listing[0].attributes.floor_number || "",
        flat_number: listing[0].attributes.flat_number || "",
        amenities: listing[0].attributes.amenities || [],
        rent_type: listing[0].attributes.rent_type || "",
        thumbnail_image: null,
      });
    } else {
      setFormAction("create");
      setFormData({
        name: "",
        status: "",
        price: "",
        address: "",
        description: "",
        number_of_beds: "",
        listing_type: "",
        size: "",
        property_id: properySelectedId || "",
        furniture: false,
        amenities: ["Parking", "Air-Conditioned", "Windows", "Balcony", "Gym"],
        floor_number: "",
        flat_number: "",
        rent_type: "",
        thumbnail_image: null,
      });
    }
  }, [listing, setFormAction]);

  const handleAddAmenity = () => {
    if (formData.newAmenity.trim()) {
      setFormData({
        ...formData,
        amenities: [...formData.amenities, formData.newAmenity.trim()],
        newAmenity: "",
      });
    }
  };

  const handleRemoveAmenity = (index) => {
    const newAmenities = formData.amenities.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      amenities: newAmenities,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    const typeOfImage =
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/svg+xml" ||
      file.type === "image/gif" ||
      file.type === "image/bmp" ||
      file.type === "image/webp";

    if (!typeOfImage) {
      return;
    }

    const imageUrl = URL.createObjectURL(file);
    const imageData = new FormData();
    imageData.append("files", file);

    try {
      const response = await axios.post(
        "https://admin.gulfin.ai/api/upload",
        imageData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("Image upload failed");
      }
      const result = response.data;
      const imageId = result[0].id;
      updateFormDataWithImageId(imageId);
    } catch (error) {
      console.error("Image upload error:", error);
    }
  };

  const updateFormDataWithImageId = (imageId) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      thumbnail_image: imageId,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (
      formData.thumbnail_image &&
      typeof formData.thumbnail_image === "object"
    ) {
      await handleImageChange({
        target: { files: [formData.thumbnail_image] },
      });
    }
    const payload = {
      property_id: {
        disconnect: [],
        connect: [
          {
            id: formData.property_id,
            position: {
              end: true,
            },
          },
        ],
      },
      user: {
        disconnect: [],
        connect: [
          {
            id: loggedInUser.id,
            position: {
              end: true,
            },
          },
        ],
      },
      name: formData.name,
      status: formData.status,
      price: formData.price,
      address: formData.address,
      description: formData.description,
      number_of_beds: formData.number_of_beds,
      listing_type: formData.type,
      size: formData.size,
      furniture: formData.furniture,
      thumbnail_image: formData.thumbnail_image,
      amenities: formData.amenities,
      floor_number: formData.floor_number,
      flat_number: formData.flat_number,
      rent_type: formData.rent_type,
    };
    onSubmit({ data: payload });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div
        className="bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl overflow-y-auto"
        style={{ height: "80%" }}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">{`${
            location.pathname === "/properties"
              ? listing && Object.keys(listing).length > 0
                ? "Edit Your Listing"
                : "Add a New Listing"
              : "Add a New Listing"
          }`}</h2>
          <button
            onClick={onClose}
            className="text-2xl text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
        </div>
        <form onSubmit={handleFormSubmit}>
          <div className="mb-4 flex space-x-4">
            <div className="w-1/2">
              <label className="block text-sm font-medium mb-1">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="w-1/2">
              <label className="block text-sm font-medium mb-1">Status</label>
              <select
                name="status"
                value={formData.status}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              >
                <option value="">Select Status</option>
                <option value="Rent">Rent</option>
                <option value="Buy">Buy</option>
                <option value="Shared">Shared</option>
              </select>
            </div>
          </div>
          {formData.status === "Rent" && (
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">
                Rent Type
              </label>
              <select
                name="rent_type"
                value={formData.rent_type}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              >
                <option value="">Select Rent Type</option>
                <option value="yearly">Yearly</option>
                <option value="monthly">Monthly</option>
              </select>
            </div>
          )}
          <div className="mb-4 flex space-x-4">
            <div className="w-1/2">
              <label className="block text-sm font-medium mb-1">Price</label>
              <input
                type="text"
                name="price"
                value={formData.price}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="w-1/2">
              <label className="block text-sm font-medium mb-1">Address</label>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Description
            </label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              className="w-full p-2 border rounded h-32"
              required
            />
          </div>
          <div className="mb-4 flex space-x-4">
            <div className="w-1/3">
              <label className="block text-sm font-medium mb-1">
                Number of Beds
              </label>
              <input
                type="number"
                name="number_of_beds"
                value={formData.number_of_beds}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="w-1/3">
              <label className="block text-sm font-medium mb-1">Type</label>
              {/* <input
                type="text"
                name="listing_type"
                value={formData.listing_type}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              /> */}
              <select
                name="listing_type"
                value={formData.listing_type}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              >
                <option value="">Select Listing Type</option>
                <option value="ready">Ready</option>
                <option value="off-plan">Off plan</option>
              </select>
            </div>
            <div className="w-1/3">
              <label className="block text-sm font-medium mb-1">
                Size (sqft)
              </label>
              <input
                type="number"
                name="size"
                value={formData.size}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Amenities</label>
            <div className="flex space-x-2 mb-2">
              <input
                type="text"
                name="newAmenity"
                value={formData.newAmenity || ""}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                placeholder="Add new amenity"
              />
              <button
                type="button"
                onClick={handleAddAmenity}
                className="bg-blue-500 text-white p-2 rounded"
              >
                Add
              </button>
            </div>
            <div className="flex flex-wrap">
              {formData.amenities.map((amenity, index) => (
                <div
                  key={index}
                  className="bg-gray-200 p-2 rounded m-1 flex items-center"
                >
                  <span>{amenity}</span>
                  <button
                    type="button"
                    onClick={() => handleRemoveAmenity(index)}
                    className="ml-2 text-red-500"
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className="mb-4 flex space-x-4">
            <div className="w-1/3">
              <label className="block text-sm font-medium mb-1">
                Floor Number
              </label>
              <input
                type="text"
                name="floor_number"
                value={formData.floor_number}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            {!listing && (
              <div className="w-1/3">
                <label className="block text-sm font-medium mb-1">
                  Select Property
                </label>
                <select
                  name="property_id"
                  value={formData.property_id}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                  required
                >
                  {propertiesData.data.map((i) => (
                    <option key={i.id} value={i.id}>
                      {i.attributes.property_name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className="w-1/3">
              <label className="block text-sm font-medium mb-1">
                Flat Number
              </label>
              <input
                type="text"
                name="flat_number"
                value={formData.flat_number}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Furniture</label>
            <input
              type="checkbox"
              name="furniture"
              checked={formData.furniture}
              onChange={(e) =>
                setFormData({ ...formData, furniture: e.target.checked })
              }
              className="mr-2"
            />
            <span>{formData.furniture ? "Yes" : "No"}</span>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Image</label>
            <input
              type="file"
              name="thumbnail_image"
              onChange={handleImageChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Submit
            </button>
            <button
              type="button"
              onClick={onClose}
              className="ml-4 bg-gray-300 text-gray-700 px-4 py-2 rounded"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
