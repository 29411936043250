import React from "react";
import { Routes, Route, Naviage } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import { HomePage } from "../home";
import { Login } from "../Login/login";
import { Register } from "../Register/register";
import { DashboardPage } from "../Dashboard/dashboard";
import { ProjectsPage } from "../Projects/projects";
import { PropertiesPage } from "../Properties/properties";
import { ServicesPage } from "../Services/services";
import { SettingsPage } from "../Settings/settings";
import { InVoicePage } from "../invoice/invoice";
import { CommunicationPage } from "../Communication/MainCommunication";
import PersonalLayout from "../../layouts/PersonalLayout";
import EnquiryPage from "../Enquiry/Enquiry";
import { PersonalDashboardPage } from "../PersonalDashboard/DashboardPage";
import PersonalInvoice from "../PersonalInvoice/PersonalInvoice";
import { Feed } from "../Feed/Feed";

export const RoutesComponent = ({ isAuthenticated }) => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <MainLayout>
            <HomePage />
          </MainLayout>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Register />} />
      <Route
        path="/dashboard"
        element={
          <MainLayout>
            <DashboardPage />
          </MainLayout>
        }
      />
      <Route
        path="/feed"
        element={
          <MainLayout>
            <Feed />
          </MainLayout>
        }
      />
      <Route
        path="/communication"
        element={
          <MainLayout>
            <CommunicationPage />
          </MainLayout>
        }
      />
      <Route
        path="/projects"
        element={
          <MainLayout>
            <ProjectsPage />
          </MainLayout>
        }
      />
      <Route
        path="/properties"
        element={
          <MainLayout>
            <PropertiesPage />
          </MainLayout>
        }
      />
      <Route
        path="/services"
        element={
          <MainLayout>
            <ServicesPage />
          </MainLayout>
        }
      />
      <Route
        path="/invoice"
        element={
          <MainLayout>
            <InVoicePage />
          </MainLayout>
        }
      />
      <Route
        path="/settings"
        element={
          <MainLayout>
            <SettingsPage />
          </MainLayout>
        }
      />
      <Route
        path="/personal-dashboard"
        element={
          <PersonalLayout>
            <PersonalDashboardPage />
          </PersonalLayout>
        }
      />
      <Route
        path="/personal-enquiries"
        element={
          <PersonalLayout>
            <EnquiryPage />
          </PersonalLayout>
        }
      />
      <Route
        path="/personal-invoice"
        element={
          <PersonalLayout>
            <PersonalInvoice />
          </PersonalLayout>
        }
      />
      <Route
        path="/personal-settings"
        element={
          <PersonalLayout>
            <SettingsPage />
          </PersonalLayout>
        }
      />
      {!isAuthenticated && <Route path="/login" element={<Login />} />}
      {!isAuthenticated && <Route path="/signup" element={<Login />} />}
      {!isAuthenticated && <Route path="*" element={<Login />} />}
    </Routes>
  );
};
