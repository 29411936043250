import React, { useState } from 'react';
import { FaAngleDown, FaSearch } from 'react-icons/fa';

export const SelectLeadListing = ({ selectedProperties, selectedPropertyListings, setShowListingsSelectingList }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedListings, setSelectedListings] = useState(selectedPropertyListings.map(listings => ({ ...listings, isSelected: true })));

  const handleClose = () => {
    setShowListingsSelectingList(false);
  };

  const handleListingToggle = (propertyId) => {
    const updatedProperties = selectedProperties.map(property => {
      if (property.id === propertyId) {
        return { ...property, isSelected: !property.isSelected };
      }
      return property;
    });
    setSelectedListings(updatedProperties);
  };

  const filteredProperties = selectedListings.filter(property => property.isSelected);
  return (
    <div className="bg-white p-4 rounded-lg w-[400px] ml-auto">
      <div className="bg-blue-500 text-white w-full py-2 mb-2 px-4 rounded flex items-center justify-between">
        <div>Select Listing</div>
        <FaAngleDown className="ml-2" />
      </div>
      <div className="flex justify-between mb-4 bt-2">
        <div className="relative w-full">
          <FaSearch className="absolute left-3 top-3 text-gray-400" />
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-1.2 pl-10 border border-blue-500 rounded py-2 px-4 bg-gray-100"
          />
        </div>
        <div className="ml-2 w-1.2 py-2 px-3 text-center">
          <input
            type="checkbox"
            className="form-checkbox h-5  text-blue-600"
          />
          <div className="text-black-500 text-sm font-semibold underline rounded flex">
            Select All
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-semibold">
          {selectedListings?.attributes?.name} listings
        </h2>
      </div>
      <div className="mt-4">
        {filteredProperties.map((listing, index) => (
          <div
            key={index}
            className="flex items-center justify-between py-4 px-2 cursor-pointer"
          >
            <div className="flex items-center">
              <div className="flex-shrink-0 h-16 w-16 rounded-lg overflow-hidden border border-gray-300">
                <img
                  className="h-full w-full object-cover"
                  src={listing.attributes.thumbnail_image.data.attributes.url}
                  alt="Project"
                />
              </div>
              <div className="ml-2 flex-grow">
                <div className="font-semibold">
                  {listing.attributes.name}
                </div>
                <div className="text-gray-500 flex items-center">
                  {listing.attributes.address}
                </div>
              </div>
            </div>
            {/* <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-600"
            /> */}
            <input
              type="checkbox"
              checked={listing.isSelected}
              onChange={() => handleListingToggle(listing.id)}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
          </div>
        ))}
      </div>
      <button
        className="text-white p-2 bg-blue-500 rounded mt-4 w-full"
        onClick={handleClose}
      >
        Apply
      </button>
    </div>
  );
};
