import React from 'react';

export const SubHeader = ({ activeItem, onNavigate }) => {
  return (
    <div className="bg-white py-3 px-5 mb-2.5 shadow-md rounded-lg">
      <div className="flex items-center justify-between">
        <div className="flex space-x-4 flex-grow font-medium">
          {/* <SubHeaderItem
            title="My Feed"
            isActive={activeItem === 'Post'}
            onClick={() => onNavigate('Post')}
          /> */}
          {/* <SubHeaderItem
            title="All Leads"
            isActive={activeItem === 'Lead'}
            onClick={() => onNavigate('Lead')}
          />
          <SubHeaderItem
            title="Leads Tracking System"
            isActive={activeItem === 'LeadsTrackingSystem'}
            onClick={() => onNavigate('LeadsTrackingSystem')}
          /> */}
         <SubHeaderItem
            title="Message"
            isActive={activeItem === 'Message'}
            onClick={() => onNavigate('Message')}
          />
        </div>
      </div>
    </div>
  );
};

const SubHeaderItem = ({ title, isActive, onClick, extraClass = '' }) => {
  return (
    <div
      onClick={onClick}
      className={`cursor-pointer text-black hover:text-gray-900 px-4 ${extraClass} ${isActive ? 'text-blue-500 border-b-2 border-blue-500' : ''}`}
    >
      {title}
    </div>
  );
};
