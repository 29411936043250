import React from "react";
import { InvoiceTable } from "./InvoiceTable";

const PersonalInvoice = () => {
  const inVoiceData = [
    {
      leadId: "1234567",
      orderDetails: "Click to view",
      type: "Property",
      status: "Pending",
      invoice: "#987876",
    },
    {
      leadId: "2345678",
      orderDetails: "Click to view",
      type: "Services",
      status: "Completed",
      invoice: "#987876",
    },
    {
      leadId: "3456789",
      orderDetails: "Click to view",
      type: "Property",
      status: "In Progress",
      invoice: "#987876",
    },
    {
      leadId: "4567890",
      orderDetails: "Click to view",
      type: "Services",
      status: "Cancelled",
      invoice: "#987876",
    },
    {
      leadId: "5678901",
      orderDetails: "Click to view",
      type: "Property",
      status: "Pending",
      invoice: "#987876",
    },
    {
      leadId: "6789012",
      orderDetails: "Click to view",
      type: "Property",
      status: "Completed",
      invoice: "#987876",
    },
    {
      leadId: "7890123",
      orderDetails: "Click to view",
      type: "Services",
      status: "In Progress",
      invoice: "#987876",
    },
    {
      leadId: "8901234",
      orderDetails: "Click to view",
      type: "Property",
      status: "Cancelled",
      invoice: "#987876",
    },
    {
      leadId: "9012345",
      orderDetails: "Click to view",
      type: "Property",
      status: "Pending",
      invoice: "#987876",
    },
  ];

  return (
    <div className="Projects-page bg-gray-100 min-h-screen p-1">
      <InvoiceTable data={inVoiceData} />
    </div>
  );
};

export default PersonalInvoice;
