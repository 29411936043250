import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaHubspot } from "react-icons/fa6";
import {
  HiOutlineCurrencyYen,
  HiOutlineBuildingOffice2,
} from "react-icons/hi2";
import { MdOutlineCurrencyYen } from "react-icons/md";
import { setPropertiesData } from "../../redux/propertiesSlice";
import { setServicesData } from "../../redux/servicesSlice";
import {
  calculateDashboardData,
  fetchUserDashboard,
  fetchUserDashboardLeads,
  getDashboardData,
} from "./dashboardData";
import dummyData from "../../services/dummy_data.json";
import { DashboardCharts } from "../../components/charts/DashboardChart";

export const DashboardPage = () => {
  const dispatch = useDispatch();
  const dashboardData = useSelector((state) => state.dashboard);
  const [data, setData] = useState(null);
  const [leadData, setLeadData] = useState(null);
  const [converted, setConverted] = useState(null);
  const [sales, setSales] = useState(null);
  const [profitByMonth, setProfitByMonth] = useState({
    totalSales: [],
    totalConversions: [],
  });
  const [incomeBreakdown, setIncomeBreakdown] = useState({
    serviceIncome: 0,
    rentalIncome: 0,
    propertyIncome: 0,
  });

  const [loading, setLoading] = useState(true);
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const id = loggedInUser.id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await fetchUserDashboard(id);
        const userLeads = await fetchUserDashboardLeads(id);
        const conversionData = await fetch(
          `https://admin.gulfin.ai/api/crm-leads?filters[crm_listings][property_user][$eq]=${id}&filters[status][$eq]=converted&populate=crm_listings`
        );

        const salesData = await fetch(
          `https://admin.gulfin.ai/api/crm-leads?filters[crm_listings][property_user][$eq]=${id}&filters[status][$eq]=paid&populate=crm_listings`
        );

        const conversionResponse = await conversionData.json();
        const salesResponse = await salesData.json();
        console.log(salesResponse);

        const totalPaidRentInUSD = salesResponse.data.reduce((sum, lead) => {
          const rentInUSD =
            lead.attributes.crm_listings.data[0].attributes.rent_in_usd;
          return sum + rentInUSD;
        }, 0);

        const salesByMonth = aggregateByMonth(salesResponse.data);

        const conversionsByMonth = aggregateByMonth(conversionResponse.data);

        setData(userData);
        setLeadData(userLeads);
        setConverted(conversionResponse.meta.pagination.total);
        setSales(totalPaidRentInUSD);
        setProfitByMonth({
          totalSales: salesByMonth,
          totalConversions: conversionsByMonth,
        });
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  const aggregateByMonth = (data) => {
    const months = Array(12).fill(0); 
    data.forEach((lead) => {
      const createdAt = new Date(lead.attributes.createdAt);
      const monthIndex = createdAt.getMonth();
      const rentInUSD =
        lead.attributes.crm_listings.data[0].attributes.rent_in_usd;
      months[monthIndex] += rentInUSD;
    });
    return months;
  };

  const getCurrentMonthNames = () => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const currentMonth = new Date().getMonth();
    return monthNames.slice(0, currentMonth + 1);
  };
  
  const currentMonthNames = getCurrentMonthNames();


  if (!dashboardData || !data || !leadData) return <div>Loading...</div>;


  return (
    <div className="dashboard-page">
      <div
        className="flex justify-center rounded-lg shadow-md mb-8"
        style={{ margin: "auto", backgroundColor: "white" }}
      >
        {/* Box 1 */}
        <div className="w-4/5 flex items-center p-4">
          <FaHubspot className="text-4xl text-blue-500 mr-4" />
          <div>
            <p className="text-2xl font-bold text-gray-900">
              {leadData.meta.pagination.total}
            </p>
            <h3 className="text-lg font-semibold text-gray-800">Total Leads</h3>
          </div>
        </div>
        <div className="border-r border-gray-300 h-16 my-auto mx-2"></div>
        {/* Box 2 */}
        <div className="w-4/5 flex items-center p-2">
          <HiOutlineCurrencyYen className="text-4xl text-blue-500 mr-4" />
          <div>
            <p className="text-2xl font-bold text-gray-900">
              {converted || "0"}
            </p>
            <h3 className="text-lg font-semibold text-gray-800">
              Total Conversion
            </h3>
          </div>
        </div>
        <div className="border-r border-gray-300 h-16 my-auto mx-2"></div>
        {/* Box 3 */}
        <div className="w-4/5 flex items-center p-2">
          <MdOutlineCurrencyYen className="text-4xl text-blue-500 mr-4" />
          <div>
            <p className="text-2xl font-bold text-gray-900">
              ${parseInt(sales, 10)}
            </p>
            <h3 className="text-lg font-semibold text-gray-800">Total Sales</h3>
          </div>
        </div>
        <div className="border-r border-gray-300 h-16 my-auto mx-2"></div>
        {/* Box 4 */}
        <div className="w-4/5 flex items-center p-4">
          <HiOutlineBuildingOffice2 className="text-4xl text-blue-500 mr-4" />
          <div>
            <p className="text-2xl font-bold text-gray-900">
              {data.meta.pagination.total}
            </p>
            <h3 className="text-lg font-semibold text-gray-800">
              Total Listing
            </h3>
          </div>
        </div>
      </div>
      {/* <div className='rounded-lg shadow-md mb-8'> */}
      <DashboardCharts
        profitByMonth={profitByMonth}
        incomeBreakdown={incomeBreakdown}
        currentMonthNames={currentMonthNames}
      />
      {/* </div> */}
    </div>
  );
};
