import React, { useEffect, useState } from 'react';
import { FaTrashAlt, FaPhoneAlt, FaCheckCircle, FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { FiSave } from "react-icons/fi";
import { ImCross } from "react-icons/im";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { AllLeadMessageSection } from '../../AllLead/allLeadUserMessage';
import { MessageSection } from '../../Messages/messageSection/messageSection';
import { useDispatch, useSelector } from 'react-redux';
import { Messages } from '../message/messages';
import { deleteLead, updateLeads } from '../../../redux/leadSlice';
import { Notes } from '../Notes/notes'; // Import the Notes component
import { MessagesPageInCommon } from '../../Messages/messages';
import { LeadMessageSection } from '../../Messages/LeadMessageSection.js/leadMessageSection';

export const UserViewDetails = ({ lead, onClose }) => {
  // const { users, selectedUser, loading, error } = useSelector((state) => state.messages);
  const [activeTab, setActiveTab] = useState('profile');
  const [notes, setNotes] = useState([]); // State to manage notes
  const [statusHistory, setStatusHistory] = useState([]); // State to manage status history
  const dispatch = useDispatch();

  useEffect(() => {
    // Initialize status history from lead data if available
    if (lead.attributes.status_history) {
      setStatusHistory(lead.attributes.status_history);
    }
  }, [lead]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleAcceptLead = () => {
    const updatedLead = {
      ...lead,
      attributes: {
        ...lead.attributes,
        status: 'accepted',
      },
    };

    let updatedLeadData = { data: updatedLead.attributes };
    dispatch(updateLeads({ id: lead.id, updatedLeadData })).then(() => {
      // Update status history
      const newStatusEntry = { status: 'accepted', timestamp: new Date().toISOString() };
      setStatusHistory([...statusHistory, newStatusEntry]);

      // Reload the page to reflect changes
      window.location.reload();
    });
  };

  const handleDeleteLead = () => {
    dispatch(deleteLead(lead.id)).then(() => {
      window.location.reload();
    });
  };

  const addNote = (noteText) => {
    setNotes((prevNotes) => [...prevNotes, noteText]);
  };

  return (
    <div className="fixed top-0 left-0 w-full h-screen bg-gray-900 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-lg w-3/4 flex" style={{ height: "592px" }}>
        <div className="w-1/3 pr-8">
          <div className="mb-4">
            {/* <img src={lead.attributes.leads_users.data[0].image.data.attributes.url} alt="Profile" className="h-20 w-20 rounded-full mb-2" /> */}
            <h3 className="text-lg font-semibold">{lead.attributes.client_name}</h3>
            <p className="text-gray-600">{lead.attributes.email}</p>
          </div>
          <div className="flex justify-between">
            {lead.attributes.status === null ? (
              <>
                <button onClick={handleDeleteLead} className="text-red-900 bg-red-300 border px-4 py-2 rounded-lg flex items-center">
                  <ImCross className="mr-2" /> Reject
                </button>
                <button onClick={handleAcceptLead} className="text-green-900 bg-green-300 border px-4 py-2 rounded-lg flex items-center">
                  <FaCheckCircle className="mr-2" /> Accept
                </button>
              </>
            ) : (
              <p className="text-green-500 mb-4">{`Status ${lead.attributes.status}`}</p>
            )}
          </div>
        </div>
        <div className="w-2/3 pl-8 border-l">
          <div className="flex justify-between items-center mb-4">
            <div className="flex space-x-4">
              <button
                className={`text-blue-500 ${activeTab === 'profile' ? 'border-b-2 border-blue-500' : ''}`}
                onClick={() => handleTabChange('profile')}
              >
                Profile
              </button>
              <button
                className={`text-blue-500 ${activeTab === 'message' ? 'border-b-2 border-blue-500' : ''}`}
                onClick={() => handleTabChange('message')}
              >
                Message
              </button>
              <button
                className={`text-blue-500 ${activeTab === 'notes' ? 'border-b-2 border-blue-500' : ''}`}
                onClick={() => handleTabChange('notes')}
              >
                Notes
              </button>
            </div>
            <button onClick={onClose} className="text-gray-500 text-3xl hover:text-gray-700">&times;</button>
          </div>
          {activeTab === 'profile' && (
            <div>
              <div className="flex items-center mb-4">
                <div>
                  <h3 className="text-xl font-semibold text-gray-800">{lead.attributes.client_name}</h3>
                </div>
              </div>
              <div className="space-y-2">
                <div className="flex items-center">
                  <MdEmail className='m-2' />
                  <p className="text-gray-500">{lead.attributes.email}</p>
                </div>
                <div className="flex items-center">
                  <FaPhoneAlt className="m-2" />
                  <p className="text-gray-600">{lead.attributes.client_number}</p>
                </div>
                <div className="flex items-center">
                  <FaLocationDot className='m-2' />
                  <p className="text-gray-600">{lead.attributes.location}</p>
                </div>
              </div>
            </div>
          )}
          {activeTab === 'message' && (
            <div>
              {/* <MessagesPageInCommon leadsData={lead}/> */}
              <LeadMessageSection leadData={lead} /> : <div className='w-full align-end'>Please select a user </div>
            </div>
          )}
          {activeTab === 'notes' && (
            <div className="flex flex-col h-full">
              <Notes notes={notes} addNote={addNote} statusHistory={statusHistory} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
