import React, { useState, useMemo, useEffect } from "react";
import axios from "axios";

export const InvoiceDataTable = ({ data }) => {
  const [filters, setFilters] = useState({
    project: "",
    priceRange: "",
    sortBy: "",
    listing: "",
  });

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const formatDate = (inputDateString) => {
    const date = new Date(inputDateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };

  useEffect(() => {
    console.log(data, "listing data--->>");
  }, []);

  const filteredData = useMemo(() => {
    if (!data.data) return [];

    let filtered = [...data.data];

    if (filters.listing) {
      filtered = filtered?.filter(
        (invoice) =>
          invoice?.attributes?.crm_listing?.data?.id === Number(filters.listing)
      );
    }
    if (filters.priceRange) {
      console.log(filtered, "filter listing", filters.priceRange);
      const [minPrice, maxPrice] = filters.priceRange.split("-").map(Number);
      filtered = filtered.filter((invoice) => {
        const price = Number(
          invoice?.attributes?.crm_listing?.data?.attributes?.price
        );
        return price >= Number(minPrice) && price <= Number(maxPrice);
      });
    }

    if (filters.sortBy === "date") {
      filtered.sort(
        (a, b) =>
          new Date(a.attributes.createdAt) - new Date(b.attributes.createdAt)
      );
    } else if (filters.sortBy === "invoiceNumber") {
      filtered.sort((a, b) =>
        a.attributes.invoice_id.localeCompare(b.attributes.invoice_id)
      );
    }

    return filtered;
  }, [data.data, filters.listing, filters.priceRange, filters.sortBy]);

  useEffect(() => {
    console.log(filteredData, "filteredData------>>");
  }, []);

  const downloadPDF = async (url, fileName) => {
    try {
      const response = await fetch(url, {
        mode: "no-cors",
      });
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const resendInvoiceEmail = async (invoice) => {
    try {
      const emailResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}api/send-email`,
        {
          to: invoice.attributes.email,
          subject: "Your Invoice is Ready",
          text: `Your invoice is ready. Here are the details: 
               Invoice Number: ${invoice.attributes.invoice_number}
               Invoice URL: ${invoice.attributes.invoice_pdf.data.attributes.url}`,
          html: `<p>Your invoice is ready.</p>
               <p>Invoice Number: ${invoice.attributes.invoice_number}</p>
               <p>Invoice URL: <a href="${invoice.attributes.invoice_pdf.data.attributes.url}">Download Invoice</a></p>`,
        }
      );

      if (emailResponse.status !== 200) {
        throw new Error("Failed to send email to user");
      }

      alert("Invoice email resent successfully!");
    } catch (error) {
      console.error("Error resending invoice email:", error);
      alert("Failed to resend invoice email. Please try again.");
    }
  };

  return (
    <div className="w-full md:w-full">
      <div className="bg-white border border-gray-200 rounded-lg p-4">
        <div className="flex justify-between items-center mb-4">
          <div className="flex space-x-4">
            <select
              name="project"
              value={filters.project}
              onChange={handleFilterChange}
              className="p-2 border rounded"
            >
              <option value="">Select Project</option>
              <option value="Project1">Project 1</option>
              <option value="Project2">Project 2</option>
            </select>
            <select
              name="listing"
              value={filters.listing}
              onChange={handleFilterChange}
              className="p-2 border rounded"
            >
              <option value="">Select Listing</option>
              {data.data &&
                data.data.map((invoice) => (
                  <option
                    key={`${invoice.attributes?.crm_listing.data?.id} `}
                    value={invoice.attributes?.crm_listing.data?.id}
                    className="text-black"
                  >
                    {
                      invoice.attributes?.crm_listing?.data?.attributes
                        ?.property_name
                    }
                  </option>
                ))}
            </select>
            <select
              name="priceRange"
              value={filters.priceRange}
              onChange={handleFilterChange}
              className="p-2 border rounded"
            >
              <option value="">Select Price Range</option>
              <option value="30000-50000">30000-50000</option>
              <option value="50000-100000">50000-100000</option>
              <option value="100000-500000">100000-500000</option>
            </select>
          </div>
          <select
            name="sortBy"
            value={filters.sortBy}
            onChange={handleFilterChange}
            className="p-2 border rounded"
          >
            <option value="">Sort By</option>
            <option value="date">Date</option>
            <option value="invoiceNumber">Invoice Number</option>
          </select>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full table-auto">
            <thead className="bg-blue-500 text-white">
              <tr>
                <th className="px-4 py-2 text-left">Date</th>
                <th className="px-4 py-2 text-left">Listing Id</th>
                <th className="px-4 py-2 text-left">Listing Name</th>
                <th className="px-4 py-2 text-left">Invoice Number</th>
                <th className="px-4 py-2 text-left">Invoice PDF</th>
                <th className="px-4 py-2 text-left">Resend Invoice</th>
              </tr>
            </thead>
            <tbody>
              {filteredData &&
                filteredData.map((invoice, index) => (
                  <tr
                    key={invoice.id}
                    className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
                  >
                    <td className="px-4 py-2">
                      {formatDate(invoice.attributes.publishedAt)}
                    </td>
                    <td className="px-4 py-2">
                      {invoice.attributes?.crm_listing?.data?.id}
                    </td>
                    <td className="px-4 py-2">
                      {
                        invoice.attributes.crm_listing.data.attributes
                          .property_name
                      }
                    </td>
                    <td className="px-4 py-2">
                      {invoice.attributes.invoice_number}
                    </td>
                    <td className="px-4 py-2">
                      <a
                        href={
                          invoice.attributes.invoice_pdf.data.attributes.url
                        }
                        target="_blank"
                      >
                        <button className="px-4 py-2 bg-[#00D756] text-white">
                          Download
                        </button>
                      </a>
                    </td>
                    <td className="px-4 py-2">
                      <button
                        onClick={() => resendInvoiceEmail(invoice)}
                        className="px-4 py-2 bg-blue-500 text-white"
                      >
                        Resend Invoice
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
