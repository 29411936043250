import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useLoadScript } from "@react-google-maps/api";

export const AddNewService = ({
  isOpen,
  onClose,
  onSubmit,
  service = {},
  setFormAction,
  servicesData,
}) => {
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const [formData, setFormData] = useState({
    name: "",
    provider_name: "",
    description: "",
    price: "",
    location: "",
    image: null,
  });

  useEffect(() => {
    if (service && Object.keys(service).length > 0) {
      setFormAction("edit");
      setFormData({
        name: service.name || "",
        provider_name: service.provider_name || "",
        description: service.description || "",
        price: service.price || "",
        location: service.location || "",
        image: service.image?.data?.attributes?.url || null,
        // crm_service_listing: {
        //     disconnect: [],
        //     connect: service.attributes.crm_service_listing?.connect || []
        // }
      });
    } else {
      setFormAction("create");
    }
  }, [service, setFormAction]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    const validImageTypes = [
      "image/png",
      "image/jpeg",
      "image/svg+xml",
      "image/gif",
      "image/bmp",
      "image/webp",
    ];

    if (!validImageTypes.includes(file.type)) {
      alert("Invalid image type");
      return;
    }

    const imageData = new FormData();
    imageData.append("files", file);

    try {
      const response = await axios.post(
        "https://admin.gulfin.ai/api/upload",
        imageData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("Image upload failed");
      }

      const result = response.data;
      const imageUrl = result[0].id;
      setFormData((prevFormData) => ({
        ...prevFormData,
        image: imageUrl,
      }));
    } catch (error) {
      console.error("Image upload error:", error);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const payload = {
      name: formData.name,
      provider_name: formData.provider_name,
      description: formData.description,
      price: formData.price,
      location: formData.location,
      image: formData.image,
      user: {
        disconnect: [],
        connect: [
          {
            id: loggedInUser.id,
            position: {
              end: true,
            },
          },
        ],
      },
      // crm_service_listing: formData.crm_service_listing
    };
    onSubmit({ data: payload });
    onClose();
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
    libraries: ["places"],
  });

  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
  };

  const onDragEnd = (event) => {
    const position = event.latLng.toJSON();
  };

  const inputRef = useRef(null);

  useEffect(() => {
    const initAutocomplete = async () => {
      console.log("here");

      if (window.google) {
        console.log("here");

        const autocomplete = new window.google.maps.places.Autocomplete(
          inputRef.current
        );

        autocomplete.addListener("place_changed", async () => {
          const place = autocomplete.getPlace();
          if (place && place.formatted_address) {
            const { location } = place.geometry;

            setFormData((prevData) => ({
              ...prevData,
              location: place.formatted_address,
            }));

            const map = new window.google.maps.Map(
              document.getElementById("map"),
              {
                center: { lat: location.lat(), lng: location.lng() },
                zoom: 14,
                options: mapOptions,
              }
            );

            const marker = new window.google.maps.Marker({
              map,
              position: { lat: location.lat(), lng: location.lng() },
              draggable: true,
            });

            marker.addListener("dragend", onDragEnd);
          }
        });
      }
    };

    if (isOpen) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
      script.async = true;
      script.onload = initAutocomplete;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [isOpen]);


  const mapHeight = formData.location ? "300px" : "0";

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-2xl overflow-y-auto overscrollBehavior-contain h-[500px]">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Add Service Listing</h2>
          <button
            onClick={onClose}
            className="text-3xl text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
        </div>
        <form onSubmit={handleFormSubmit}>
          <div className="mb-4 flex space-x-4">
            <div className="w-1/2">
              <label className="block text-sm font-medium mb-1">
                Service Name
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="w-1/2">
              <label className="block text-sm font-medium mb-1">
                Select Provider
              </label>
              <input
                type="text"
                name="provider_name"
                value={formData.provider_name}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
          </div>
          <div className="mb-4 flex space-x-4">
            <div className="w-1/2">
              <label className="block text-sm font-medium mb-1">Location</label>
              <input
                type="text"
                name="location"
                ref={inputRef}
                value={formData.location}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="w-1/2">
              <label className="block text-sm font-medium mb-1">Price</label>
              <input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
          </div>
          <div className="mb-4">
            <label
              className={`${
                formData.latitude && formData.longitude ? "block" : "hidden"
              }  text-sm font-medium mb-1`}
            >
              Property Location
            </label>
            <div
              id="map"
              style={{ height: mapHeight, transition: "height 0.3s ease" }}
            ></div>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Description
            </label>
            <textarea
              type="textarea"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Image</label>
            <input
              type="file"
              name="image"
              onChange={handleImageChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="flex justify-end space-x-2">
            <button
              onClick={onClose}
              className="bg-gray-300 text-black-700 py-2 px-4 rounded"
            >
              Close
            </button>
            <button
              type="submit"
              className="bg-[#407BFF] text-white py-2 px-4 rounded"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
