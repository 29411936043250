import React from "react";
import { GlobalAnalyticsChart } from "../../../components/charts/reuseChart";
import { RecentTransactions } from "../../../components/common/RecentTransactions/recentTransactions";
// import {TopProjects} from './topProjects';
// import {RecentTransactions} from './recentTransactions';

export const AnalystProjectPage = ({ leadsData, properties1, projectData }) => {
  console.log(leadsData);

  const filterAndCountLeads = (data) => {
    console.log(data, "filtered");

    const getDateParts = (dateString) => {
      try {
        const date = new Date(dateString);
        return { month: date.getMonth() + 1, year: date.getFullYear() };
      } catch (error) {
        console.error("Error parsing date:", error);
        return { month: 0, year: 0 }; // Return default values on error
      }
    };

    const currentMonth = new Date().getMonth() + 1;
    
    let countsArray = Array(currentMonth).fill(0);

    
    
    if (data && Array.isArray(data)) {

      console.log(data);
      
      data.forEach((lead) => {
        try {
          // Check if crm_listings exists and has data
          if (
            lead.attributes &&
            lead.attributes.crm_listings &&
            lead.attributes.crm_listings.data.length > 0
          ) {
            const { month } = getDateParts(lead.attributes.createdAt);
            if (month > 0 && month <= currentMonth) {
              countsArray[month - 1]++;
            }
          }
        } catch (error) {
          console.error("Error processing lead:", error);
        }
      });
    } else {
      console.warn("Invalid data format: Expected an array");
    }

    console.log(countsArray);
    
    return countsArray;
  };

  const totalLeadsData = filterAndCountLeads(leadsData.data);

  const countPropertiesUpToCurrentMonth = (properties) => {
    // Helper function to get month from createdAt
    const getMonth = (dateString) => {
      const date = new Date(dateString);
      return date.getMonth(); // Returns month index starting from 0 for January
    };

    // Determine the current month index (0-based)
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();

    // Initialize an array with zeros for each month up to the current month
    let monthlyCounts = Array(currentMonthIndex + 1).fill(0); // +1 because we want to include the current month

    // Count properties by month, up to the current month
    properties?.forEach((property) => {
      const propertyMonthIndex = getMonth(property.attributes.createdAt);
      if (propertyMonthIndex <= currentMonthIndex) {
        // Only count properties up to the current month
        monthlyCounts[propertyMonthIndex]++;
      }
    });

    return monthlyCounts;
  };

  const totalData = countPropertiesUpToCurrentMonth(properties1.data);

  const filterAndCountConversions = (data) => {
    const getDateParts = (dateString) => {
      const date = new Date(dateString);
      return { month: date.getMonth() + 1, year: date.getFullYear() };
    };

    const currentMonth = new Date().getMonth() + 1;
    let countsArray = Array(currentMonth).fill(0);

    data.data?.forEach((lead) => {
      if (lead.attributes.status === "converted") {
        const { month } = getDateParts(lead.attributes.createdAt);
        if (month <= currentMonth) {
          countsArray[month - 1]++;
        }
      }
    });
    
    return countsArray;
  };

  const totalConversions = filterAndCountConversions(leadsData);

  const totalSales = [0, 0, 0, 0, 0, 0, 0, 0, 0];

  return (
    <div className="m-1 h-[100vh]">
      <div className="flex flex-wrap pr-2 h-[600px] overflow-y-auto">
        <GlobalAnalyticsChart title="Total Leads" data={totalLeadsData} />
        <GlobalAnalyticsChart title="Total Listing" data={totalData} />
        <GlobalAnalyticsChart
          title="Total Conversions "
          data={totalConversions}
        />
        {/* <RecentTransactions transactions={transactions}  width="md:w-1/2"/> */}
        <GlobalAnalyticsChart title="Total Sales" data={totalSales} />
      </div>
    </div>
  );
};
