import React, { useState, useEffect } from 'react';
import { SubHeaderLeads } from './subheaderLeads';
import { AllLeadsMain } from './allLeads/AllLeads';
import { AddNewLead } from '../../../components/common/models/addNewLead';
import { createLeads } from '../../../redux/leadSlice';
import { useDispatch } from 'react-redux';
import { MessagesPageInCommon } from '../../../components/Messages/messages';
import { TrackSystemForServices } from './trackingSystemForServices';
// import { TrackSystemForProject } from './TrackSystemForProject';

export const ServicesLead = ({ leadsData, servicesListing,serviceData,leadsUserData }) => {
  const [listing, setListing] = useState([]);
  const [leads, setLeads] = useState([]);
  const [services, setServices] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [orderBy, setOrderBy] = useState('[properties_name]');
  const [isOrderByDropdownOpen, setIsOrderByDropdownOpen] = useState(false);
  const [showActive, setShowActive] = useState(true);
  const [showInactive, setShowInactive] = useState(true);
  const [isLeadModalOpen, setIsLeadModalOpen] = useState(false);
  const[selectedPropertyId,setSelectedPropertyId]=useState(null);
  const [activeSubHeader, setActiveSubHeader] = useState('All Leads');
  const[selectedListingMatterMostId,SetSelectedListingMatterMostId]=useState("")
  const dispatch = useDispatch();
  console.log(leadsUserData);
  useEffect(() => {
    setServices(serviceData?.data || []);
    setLeads(leadsData?.data || []);
    setListing(servicesListing);
  }, [serviceData, leadsData,servicesListing]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

 const handleSelectedList=(data)=>{
  setSelectedPropertyId(data.id)
  SetSelectedListingMatterMostId(data.attributes.mattermost_services_listing_id);
 }
   
  const handleOrderChange = (value) => {
    setOrderBy(value);
    setIsOrderByDropdownOpen(false);
  };

  const toggleOrderByDropdown = () => {
    setIsOrderByDropdownOpen(!isOrderByDropdownOpen);
  };

  const toggleActive = () => {
    setShowActive(!showActive);
  };

  const toggleInactive = () => {
    setShowInactive(!showInactive);
  };

  const openModal = () => {
    setIsLeadModalOpen(true);
  };

  const closeModal = () => {
    setIsLeadModalOpen(false);
  };

  const handleAddLeadFormSubmit = (formData) => {
    formData.data.crm_service_listings = {
      disconnect: [],
      connect: [{ id: selectedPropertyId, position: { end: true } }]
    }
    dispatch(createLeads({ leadsData: formData,selectedListingMatterMostId })).then(() => {
      window.location.reload();
      closeModal();
  });
  }

const handleSubHeaderClick = (title) => {
  setActiveSubHeader(title);
};

return (
  <div>
    <SubHeaderLeads
      searchTerm={searchTerm}
      handleSearchChange={handleSearchChange}
      orderBy={orderBy}
      isOrderByDropdownOpen={isOrderByDropdownOpen}
      toggleOrderByDropdown={toggleOrderByDropdown}
      handleOrderChange={handleOrderChange}
      showActive={showActive}
      toggleActive={toggleActive}
      showInactive={showInactive}
      toggleInactive={toggleInactive}
      openModal={openModal}
      activeSubHeader={activeSubHeader}
      handleSubHeaderClick={handleSubHeaderClick}
    />
    {activeSubHeader === 'All Leads' && <AllLeadsMain services={services} listings={listing?listing:[]} leadsData={leadsData.data} handleSelectedListingId={handleSelectedList}/>}
    {activeSubHeader === 'Lead Tracking System' && <TrackSystemForServices projectsData={services?.data || []} listingData={listing?listing:[]} leadsData={leadsData.data} handleSelectedListingId={handleSelectedList} />}
    {activeSubHeader === 'Messages' && <MessagesPageInCommon leadsData={leadsUserData.data} />}

    {isLeadModalOpen && <AddNewLead
     isOpen={isLeadModalOpen}
     onClose={closeModal}
     onSubmit={handleAddLeadFormSubmit}
     selectedPropertyId={selectedPropertyId}
     />
     }
  </div>
);
};





