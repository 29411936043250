import { setDashboardData } from "../../redux/dashboardSlice";

export const calculateDashboardData = (properties, services) => (dispatch) => {
  const totalLeads = properties.totalLeads + services.totalLeads;
  const totalListing = properties.totalListing + services.totalListing;
  const totalConversions =
    properties.totalConversions + services.totalConversions;
  const totalSales = properties.totalSales + services.totalSales;

  const profitByMonth = properties.profitByMonth.map(
    (profit, index) => profit + services.profitByMonth[index]
  );

  const incomeBreakdown = {
    serviceIncome:
      properties.incomeBreakdown.serviceIncome +
      services.incomeBreakdown.serviceIncome,
    rentalIncome:
      properties.incomeBreakdown.rentalIncome +
      services.incomeBreakdown.rentalIncome,
    propertyIncome:
      properties.incomeBreakdown.propertyIncome +
      services.incomeBreakdown.propertyIncome,
  };

  const dashboardData = {
    totalLeads,
    totalListing,
    totalConversions,
    totalSales,
    profitByMonth,
    incomeBreakdown,
  };

  dispatch(setDashboardData(dashboardData));
};

export const fetchUserDashboard = async (userId) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-listings?filters[property_user][$eq]=${userId}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    console.log(data);
    
    return data;
  } catch (error) {
    console.error("Error fetching properties:", error);
    throw error;
  }
};

export const fetchUserDashboardLeads = async (userId) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-leads?filters[crm_listings][property_user][$eq]=${userId}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching properties:", error);
    throw error;
  }
};
