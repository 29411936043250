import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchServices, fetchUserServices } from "../services/api";
import axios from "axios";
const initialState = {
  serviceData: [],
  userServiceData: [],
  serviceStatus: "idle",
  userServiceStatus: "idle",
  serviceError: null,
  userServiceError: null,
  serviceCurrentPage: 1,
  userServiceCurrentPage: 1,
  serviceTotalPages: 1,
  userServiceTotalPages: 1,
};

export const getServices = createAsyncThunk(
  "services/getServices",
  async ({ page, pageSize }) => {
    const services = await fetchServices(page, pageSize);
    console.log(
      page,
      pageSize,
      "---------------",
      services,
      "service slice code"
    );
    return services;
  }
);

export const getUserServices = createAsyncThunk(
  "services/getUserServices",
  async ({ page, pageSize, userId }) => {
    const services = await fetchUserServices(page, pageSize, userId);
    // console.log(
    //   page,
    //   pageSize,
    //   "---------------",
    //   services,
    //   "service slice code"
    // );
    return services;
  }
);

export const createService = createAsyncThunk(
  "services/createService",
  async (serviceData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-services`,
        serviceData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateService = createAsyncThunk(
  "services/updateService",
  async ({ id, serviceData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-services/${id}`,
        serviceData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteServices = createAsyncThunk(
  "property/deleteService",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-services/${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    setPage(state, action) {
      state.serviceCurrentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServices.pending, (state) => {
        state.serviceStatus = "loading";
      })
      .addCase(getServices.fulfilled, (state, action) => {
        state.serviceStatus = "succeeded";
        state.serviceData = action.payload;
        state.serviceTotalPages = Math.ceil(
          action.payload.meta.pagination.total /
            action.payload.meta.pagination.pageSize
        );
      })
      .addCase(getUserServices.fulfilled, (state, action) => {
        state.userServiceStatus = "succeeded";
        state.userServiceData = action.payload;
        state.userServiceTotalPages = Math.ceil(
          action.payload.meta.pagination.total /
            action.payload.meta.pagination.pageSize
        );
      })
      .addCase(getServices.rejected, (state, action) => {
        state.serviceStatus = "failed";
        state.serviceError = action.error.message;
      });
  },
});
export const { setPage } = servicesSlice.actions;
export default servicesSlice.reducer;
