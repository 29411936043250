import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaBars, FaEnvelope, FaBuilding, FaCog } from "react-icons/fa";
import { MdDashboard, MdOutlineMessage } from "react-icons/md";
import { FaUserGear } from "react-icons/fa6";
import { GoProject } from "react-icons/go";
import { LiaEnvelopeSquareSolid, LiaFileInvoiceSolid } from "react-icons/lia";
import styles from "./sidebar.module.css";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../redux/sidebarSlice";
import { logout } from "../../redux/authSlice";
import { useNavigate } from "react-router-dom";
import { RiLogoutCircleLine } from "react-icons/ri";
import { BiComment } from "react-icons/bi";
import { FiMessageSquare } from "react-icons/fi";
const PersonalSidebar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isCollapsed = useSelector((state) => state.sidebar.isCollapsed);
  const handleToggleSidebar = () => {
    setIsOpen(!isOpen);
    dispatch(toggleSidebar());
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <nav
      className={`${styles.sidebar} ${
        isOpen ? styles.expanded : styles.collapsed
      } `}
    >
      <div className="flex justify-end items-center mb-6">
        <FaBars
          className={`${styles.hamburger} ${
            isOpen ? styles.expanded : styles.collapsed
          }`}
          onClick={handleToggleSidebar}
        />
      </div>
      <ul className="flex flex-col font-medium">
        <li className="mb-4">
          <NavLink
            to="/personal-dashboard"
            className={({ isActive }) =>
              isActive
                ? `${styles["nav-link"]} ${styles.active}`
                : styles["nav-link"]
            }
          >
            <MdDashboard className={styles.icon} />
            <span className={isOpen ? "block" : "hidden"}>Dashboard</span>
          </NavLink>
        </li>
        
        <li className="mb-4">
          <NavLink
            to="/personal-enquiries"
            className={({ isActive }) =>
              isActive
                ? `${styles["nav-link"]} ${styles.active}`
                : styles["nav-link"]
            }
          >
            <MdOutlineMessage className={styles.icon} />
            <span className={isOpen ? "block" : "hidden"}>My Enquiries</span>
          </NavLink>
        </li>


        <li className="mb-4">
          <NavLink
            to="/personal-invoice"
            className={({ isActive }) =>
              isActive
                ? `${styles["nav-link"]} ${styles.active}`
                : styles["nav-link"]
            }
          >
            <LiaFileInvoiceSolid className={styles.icon} />
            <span className={isOpen ? "block" : "hidden"}>My Invoices</span>
          </NavLink>
        </li>
        <li className="mb-4">
          <NavLink
            to="/personal-settings"
            className={({ isActive }) =>
              isActive
                ? `${styles["nav-link"]} ${styles.active}`
                : styles["nav-link"]
            }
          >
            <FaCog className={styles.icon} />
            <span className={isOpen ? "block" : "hidden"}>Settings</span>
          </NavLink>
        </li>
      </ul>

      <div className="fixed bottom-20 left-9 flex justify-center items-center">
        {!isOpen ? (
          <RiLogoutCircleLine
            onClick={handleLogout}
            className="text-xl" 
            style={{ cursor: "pointer" }}
          />
        ) : (
          <button
            onClick={handleLogout}
            className="ml-4 w-32 px-4 py-2 border border-black text-sm font-medium rounded-md text-black"
          >
            Logout
          </button>
        )}
      </div>
    </nav>
  );
};

export default PersonalSidebar;
