import React, { useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";

export const SettingsPage = () => {
  const [settingsData, setSettingsData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [uploadButtonText, setUploadButtonText] = useState("Update Image");
  const [imageUrl, setImageUrl] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const countries = [
    { value: "USA", label: "USA" },
    { value: "Canada", label: "Canada" },
    { value: "UK", label: "UK" },
  ];
  const languages = [
    { value: "Hindi", label: "Hindi" },
    { value: "English", label: "English" },
  ];
  const units = [
    { value: "Square Feet", label: "Square Feet" },
    { value: "Meter", label: "Meter" },
  ];

  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const id = loggedInUser.id;

  useEffect(() => {
    const loadSettingsData = async () => {
      try {
        const fetchData = async () => {
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_BACKEND_DOMAIN}api/users/${id}?populate=profile_picture`
            );

            setSettingsData(response.data);
          } catch (err) {
            console.error(err);
          }
        };

        fetchData();

        setSelectedCountries([
          { value: "USA", label: "USA" },
          { value: "UK", label: "UK" },
        ]); // Example initial values
        setSelectedLanguages([{ value: "English", label: "English" }]); // Example initial values
        setSelectedUnits([{ value: "Square Feet", label: "Square Feet" }]); // Example initial values
      } catch (error) {
        console.error("Error loading Settings data:", error);
      }
    };

    loadSettingsData();
  }, []);

  console.log(settingsData);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);
    console.log({
      settingsData,
      selectedCountries,
      selectedLanguages,
      selectedUnits,
    });
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const validImageTypes = [
      "image/png",
      "image/jpeg",
      "image/svg+xml",
      "image/gif",
      "image/bmp",
      "image/webp",
    ];

    if (!validImageTypes.includes(file.type)) {
      alert("Invalid image type");
      return;
    }

    const preview = URL.createObjectURL(file);
    setImagePreview(preview);

    const imageData = new FormData();
    imageData.append("files", file);

    try {
      const response = await axios.post(
        "https://admin.gulfin.ai/api/upload",
        imageData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("Image upload failed");
      }

      const result = response.data;
      const imageId = result[0].id;
      setImageUrl(imageId);
      setUploadButtonText("Select Another");
    } catch (error) {
      console.error("Image upload error:", error);
    }
  };

  console.log(imageUrl);

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleRemoveSelection = (item, setSelected) => {
    setSelected((prev) => prev.filter((i) => i.value !== item.value));
  };

  const handleUpdateProfilePicture = async () => {
    try {
      const response = await axios.put(
        `https://admin.gulfin.ai/api/users/${id}`,
        { profile_picture: imageUrl },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to update profile picture");
      }
      console.log("Profile picture updated successfully");
      window.location.reload();
    } catch (error) {
      console.error("Error updating profile picture:", error);
    }
  };

  if (!settingsData) return <div>Loading...</div>;

  return (
    <div className="flex flex-col items-center p-6 h-full bg-white overflow-scroll">
      <div className="flex justify-between items-center w-full mb-6">
        <div className="text-xl font-bold">Account</div>
        <button
          className="bg-[#407BFF] text-white px-4 py-2 rounded-md"
          onClick={handleEdit}
        >
          Edit
        </button>
      </div>

      <div className="flex flex-col items-center mb-6">
        {imagePreview ? (
          <img
            src={imagePreview}
            alt="Profile Preview"
            className="rounded-full w-24 h-24 object-cover"
          />
        ) : settingsData.profile_picture ? (
          <img
            src={settingsData.profile_picture.url}
            alt="Profile"
            className="rounded-full w-24 h-24 object-cover"
          />
        ) : (
          <img
            src="https://via.placeholder.com/150"
            alt="Profile"
            className="rounded-full w-24 h-24 object-cover"
          />
        )}

        <div className="flex justify-center items-center gap-x-2">
          <button
            type="button"
            onClick={() => document.getElementById("image-upload").click()}
            className="mt-5 bg-blue-500 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded"
          >
            {uploadButtonText}
          </button>
          <input
            id="image-upload"
            type="file"
            onChange={handleImageUpload}
            className="hidden"
          />

          {imageUrl && (
            <div
              type="button"
              onClick={handleUpdateProfilePicture}
              className="mt-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Confirm
            </div>
          )}
        </div>
      </div>

      <div className="w-full max-w-md-24">
        {/* <div className="grid grid-cols-12 gap-24">
          <div className="form-group">
            <label className="block font-bold mb-1">Agent/Owner Name</label>
            {isEditing ? (
              <input
                type="text"
                value={settingsData.name}
                onChange={(e) => setSettingsData({ ...settingsData, name: e.target.value })}
                className="border rounded-md px-3 py-2 w-full"
              />
            ) : (
              <div>{settingsData.name}</div>
            )}
          </div>
          <div className="form-group">
            <label className="block font-bold mb-1">Email ID</label>
            {isEditing ? (
              <input
                type="email"
                value={settingsData.email}
                onChange={(e) => setSettingsData({ ...settingsData, email: e.target.value })}
                className="border rounded-md px-3 py-2 w-full"
              />
            ) : (
              <div>{settingsData.email}</div>
            )}
          </div>
          <div className="form-group">
            <label className="block font-bold mb-1">Contact Number</label>
            {isEditing ? (
              <input
                type="tel"
                value={settingsData.contact}
                onChange={(e) => setSettingsData({ ...settingsData, contact: e.target.value })}
                className="border rounded-md px-3 py-2 w-full"
              />
            ) : (
              <div>{settingsData.contact}</div>
            )}
          </div>
        </div> */}
        <div className="grid grid-cols-3 gap-8">
          <div className="form-group col-span-1">
            <label className="block font-bold mb-1">Agent/Owner Name</label>
            {isEditing ? (
              <input
                type="text"
                value={settingsData.name}
                onChange={(e) =>
                  setSettingsData({ ...settingsData, name: e.target.value })
                }
                className="border rounded-md px-3 py-2 w-full"
              />
            ) : (
              <div>{settingsData.username}</div>
            )}
          </div>
          <div className="form-group col-span-1">
            <label className="block font-bold mb-1">Email ID</label>
            {isEditing ? (
              <input
                type="email"
                value={settingsData.email}
                onChange={(e) =>
                  setSettingsData({ ...settingsData, email: e.target.value })
                }
                className="border rounded-md px-3 py-2 w-full"
              />
            ) : (
              <div>{settingsData.email}</div>
            )}
          </div>
          <div className="form-group col-span-1">
            <label className="block font-bold mb-1">Contact Number</label>
            {isEditing ? (
              <input
                type="tel"
                value={settingsData.contact}
                onChange={(e) =>
                  setSettingsData({ ...settingsData, contact: e.target.value })
                }
                className="border rounded-md px-3 py-2 w-full"
              />
            ) : (
              <div>{settingsData.phone_number}</div>
            )}
          </div>
        </div>

        <div className="grid grid-cols-3 gap-8 mt-5">
          <div className="form-group">
            <label className="block font-bold mb-1">Address</label>
            {isEditing ? (
              <textarea
                value={settingsData.address}
                onChange={(e) =>
                  setSettingsData({ ...settingsData, address: e.target.value })
                }
                className="border rounded-md px-3 py-2 w-full"
              />
            ) : (
              <div>{settingsData.address}</div>
            )}
          </div>
          <div className="form-group">
            <label className="block font-bold mb-1">Credits</label>
            {isEditing ? (
              <textarea
                value={"12/36"}
                onChange={(e) =>
                  setSettingsData({ ...settingsData, address: e.target.value })
                }
                className="border rounded-md px-3 py-2 w-full"
              />
            ) : (
              <div>{"12/36"}</div>
            )}
          </div>
          <div className="form-group">
            <label className="block font-bold mb-1">Currency</label>
            {isEditing ? (
              <select
                value={settingsData.currency}
                onChange={(e) =>
                  setSettingsData({ ...settingsData, currency: e.target.value })
                }
                className="border rounded-md px-3 py-2 w-full"
              >
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="GBP">GBP</option>
              </select>
            ) : (
              <div>{settingsData.currency}</div>
            )}
          </div>
        </div>
        {/* <div className="grid grid-cols-1 gap-6 mt-6">
          
        </div> */}

        {/* Country Section */}
        <div className="mt-6">
          <h3 className="font-bold mb-1">Country</h3>
          {isEditing ? (
            <Select
              options={countries}
              isMulti
              value={selectedCountries}
              onChange={(selectedOptions) =>
                setSelectedCountries(selectedOptions)
              }
              className="border rounded-md px-3 py-2 w-full mb-2"
            />
          ) : null}
          <div className="flex flex-wrap">
            {selectedCountries.map((country) => (
              <div
                key={country.value}
                className="relative bg-blue-200 text-blue-800 rounded-full px-4 py-2 mr-2 mb-2 flex items-center"
              >
                {country.label}
                {isEditing && (
                  <button
                    className="ml-2 text-red-500"
                    onClick={() =>
                      handleRemoveSelection(country, setSelectedCountries)
                    }
                  >
                    &times;
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Language Section */}
        <div className="mt-6">
          <h3 className="font-bold mb-1">Language</h3>
          {isEditing ? (
            <Select
              options={languages}
              isMulti
              value={selectedLanguages}
              onChange={(selectedOptions) =>
                setSelectedLanguages(selectedOptions)
              }
              className="border rounded-md px-3 py-2 w-full mb-2"
            />
          ) : null}
          <div className="flex flex-wrap">
            {selectedLanguages.map((language) => (
              <div
                key={language.value}
                className="relative bg-green-200 text-green-800 rounded-full px-4 py-2 mr-2 mb-2 flex items-center"
              >
                {language.label}
                {isEditing && (
                  <button
                    className="ml-2 text-red-500"
                    onClick={() =>
                      handleRemoveSelection(language, setSelectedLanguages)
                    }
                  >
                    &times;
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Unit Section */}
        <div className="mt-6">
          <h3 className="font-bold mb-1">Unit</h3>
          {isEditing ? (
            <Select
              options={units}
              isMulti
              value={selectedUnits}
              onChange={(selectedOptions) => setSelectedUnits(selectedOptions)}
              className="border rounded-md px-3 py-2 w-full mb-2"
            />
          ) : null}
          <div className="flex flex-wrap">
            {selectedUnits.map((unit) => (
              <div
                key={unit.value}
                className="relative bg-purple-200 text-purple-800 rounded-full px-4 py-2 mr-2 mb-2 flex items-center"
              >
                {unit.label}
                {isEditing && (
                  <button
                    className="ml-2 text-red-500"
                    onClick={() =>
                      handleRemoveSelection(unit, setSelectedUnits)
                    }
                  >
                    &times;
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-end mt-6">
          {isEditing && (
            <>
              <button
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md mr-4"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className="bg-[#407BFF] text-white px-4 py-2 rounded-md"
                onClick={handleSave}
              >
                Save
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
