import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SubHeader } from "./subHeader";
import { Post } from "../../components/Post/post";
import { PropertiesListings } from "./Listing/propertiesListings";
import { AnalystPropertiesPage } from "./propertiesAnalyst/propertiesAnalyst";
import { PropertiesLead } from "./Leads/lead";
import {
  getProperties,
  getUserProperties,
  setPage,
} from "../../redux/propertiesSlice";
import { getProjects, getUserProjects } from "../../redux/proejctsSlice";
import { PropertyExplore } from "./Explore/explore";
import { InventoryLatest } from "./inventory/inventory";
import { getListings, getUserListings } from "../../redux/listingSlice";
import { getLeads, getUserLeads } from "../../redux/leadSlice";

const LoadingComponent = () => (
  <div
    className="animate-spin inline-block size-8 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
    role="status"
    aria-label="loading"
  >
    <span class="sr-only">Loading...</span>
  </div>
);

export const PropertiesPage = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const id = loggedInUser.id;

  const [activeItem, setActiveItem] = useState(() => {
    const savedItem = sessionStorage.getItem("activePropertiesItem");
    return savedItem || "propertiesAnalytics";
  });

  const updateActiveItem = (item) => {
    setActiveItem(item);
    sessionStorage.setItem("activePropertiesItem", item);
  };

  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);
  const [listings, setListings] = useState([]);
  const [allListings, setAllListings] = useState([]);
  const [listingsMeta, setListingsMeta] = useState(null);
  const [allListingsMeta, setAllListingsMeta] = useState(null);

  const dispatch = useDispatch();

  const {
    data: properties1,
    status,
    error,
    currentPage,
    totalPages,
  } = useSelector((state) => state.properties);

  const {
    userData: properties2,
    userStatus,
    userError,
    userCurrentPage,
    userTotalPages,
  } = useSelector((state) => state.properties);

  const {
    projectsData,
    projectStatus,
    projectError,
    projectCurrentPage,
    projectTotalPages,
  } = useSelector((state) => state.projects);

  const {
    leadsUserData,
    leadsUserStatus,
    leadsUserError,
    leadsUserCurrentPage,
    leadsUserTotalPages,
  } = useSelector((state) => state.leads);

  const {
    listingData,
    listingStatus,
    listingError,
    listingCurrentPage,
    listingTotalPages,
  } = useSelector((state) => state.listings);
  
  
  const {
    userProjectsData,
    userProjectStatus,
    userProjectError,
    userProjectCurrentPage,
    userProjectTotalPages,
  } = useSelector((state) => state.projects);

  const {
    listingUserData,
    listingUserStatus,
    listingUserError,
    listingUserCurrentPage,
    listingUserTotalPages,
  } = useSelector((state) => state.listings);

  const {
    leadsData,
    leadsStatus,
    leadsError,
    leadsCurrentPage,
    leadsTotalPages,
  } = useSelector((state) => state.leads);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://admin.gulfin.ai/api/crm-listings/?populate=*&filters[property_user][$eq]=${id}&filters[rent_status][$notNull]=true`
        );

        const response2 = await fetch(
          `https://admin.gulfin.ai/api/crm-listings/?populate=*&filters[rent_status][$notNull]=true`
        );
        if (!response.ok) {
          throw new Error("Network response for listings was not ok");
        }

        if (!response2.ok) {
          throw new Error("Network response for listings was not ok");
        }

        const listingsResult = await response.json();
        const allListings = await response2.json();
        setListings(listingsResult.data);
        setAllListings(allListings.data);
        setListingsMeta(listingsResult.meta);
        setAllListingsMeta(allListings.meta);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        dispatch(getProperties({ page: currentPage, pageSize: 30 })),
        dispatch(
          getUserProperties({ page: userCurrentPage, pageSize: 30, userId: id })
        ),
        dispatch(getProjects({ page: projectCurrentPage, pageSize: 30 })),
        dispatch(
          getUserProjects({
            page: userProjectCurrentPage,
            pageSize: 100,
            userId: id,
          })
        ),
        dispatch(getListings({ page: listingCurrentPage, pageSize: 30 })),
        dispatch(
          getUserListings({
            page: listingUserCurrentPage,
            pageSize: 30,
            userId: id,
          })
        ),
        dispatch(
          getLeads({ page: leadsCurrentPage, pageSize: 30, userId: id })
        ),
      ]);
      setIsInitialLoadComplete(true);
    };

    fetchData();
  }, [dispatch, id]);
  useEffect(() => {
    dispatch(
      getUserLeads({ page: leadsUserCurrentPage, pageSize: 100, userId: id,pageName:"properties" })
    );
  }, [dispatch, leadsUserCurrentPage, id]);

  const handlePageChange = (newPage) => {
    dispatch(setPage(newPage));
  };

  const isConfirm = loggedInUser.confirmed;
  const role = loggedInUser.user_role;

  const renderComponent = () => {
    if (!isInitialLoadComplete) {
      return (
        <div className="flex justify-center items-center h-[500px]">
          <LoadingComponent />;
        </div>
      );
    }

    switch (activeItem) {
      case "Inventory":
        if (role === "owner" || isConfirm) {
          return (
            <InventoryLatest
              projectsData={userProjectsData}
              properties1={properties2}
              listingData={listingData}
            />
          );
        }
        return (
          <p>
            Your profile is not verified yet. Please wait till an Admin approves
            your profile.
          </p>
        );
      case "Listing":
        if (role === "owner" || isConfirm) {
          return (
            <PropertiesListings
              properties1={listings}
              listings={listings}
              pageCount={listingsMeta}
            />
          );
        }
        return (
          <p>
            Your profile is not verified yet. Please wait till an Admin approves
            your profile.
          </p>
        );
      case "Explore":
        return (
          <PropertyExplore properties1={properties1} listings={allListings} pageCount={allListingsMeta} />
        );
      case "propertiesAnalytics":
        if (role === "owner" || isConfirm) {
          return (
            <AnalystPropertiesPage
              leadsData={leadsUserData}
              listingData={listings}
            />
          );
        }
        return (
          <p>Your analytics will appear here once your profile is approved</p>
        );
      case "Lead":
        if (role === "owner" || isConfirm) {
          return (
            <PropertiesLead
              leadsData={leadsData}
              properties1={listings}
              listingData={listingUserData}
              leadsUserData={leadsUserData}
            />
          );
        }
        return (
          <p>
            Your property leads will appear here once your profile is approved
          </p>
        );
      default:
        return null;
    }
  };

  return (
    <div className="Projects-page bg-gray-100 min-h-screen p-1 ">
      <SubHeader activeItem={activeItem} onNavigate={updateActiveItem} />
      {renderComponent()}
    </div>
  );
};
