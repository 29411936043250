import { configureStore } from '@reduxjs/toolkit';
import propertiesReducer from './propertiesSlice';
import listingsReducer from './listingSlice';
import leadReducer from './leadSlice';
import projectsReducer from './proejctsSlice';
import servicesReducer from './servicesSlice';
import servicesListingsReducer from './servicesListingSlice';
import dashboardReducer from './dashboardSlice';
import sidebarReducer from './sidebarSlice';
import authReducer from './authSlice';
const store = configureStore({
  reducer: {
    auth: authReducer,
    properties: propertiesReducer,
    listings: listingsReducer,
    servicesListings: servicesListingsReducer,
    leads: leadReducer,
    projects: projectsReducer,
    services: servicesReducer,
    dashboard: dashboardReducer,
    sidebar:sidebarReducer

  },
});

export default store;
