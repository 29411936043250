import {
  FaParking,
  FaTree,
  FaLock,
  FaBed,
  FaWifi,
  FaChair,
  FaWater,
  FaEdit,
  FaTrash,
  FaSwimmingPool,
  FaDumbbell,
} from "react-icons/fa";
import { IoIosShareAlt } from "react-icons/io";
import React, { useState, useEffect } from "react";
import { IoLocationSharp, IoShareSocialSharp } from "react-icons/io5";
import { BiCloset } from "react-icons/bi";
import { BsTextarea } from "react-icons/bs";
import { MdOutlineGasMeter } from "react-icons/md";
import { Star } from "lucide-react";
// import { DetailsCard } from '../cards/DetailsCard';

export const MyListingPage = ({
  listing = [],
  onDelete,
  onEdit,
  containerWidth,
  borderOfContainer,
  refer,
}) => {
  const [clicks, setClicks] = useState({});
  const [expandedAmenities, setExpandedAmenities] = useState({});

  useEffect(() => {
    setClicks({});
    console.log("listing in listing list", listing);
    setExpandedAmenities({});
  }, [listing]);

  const handleClick = (id) => {
    setClicks((prevClicks) => ({
      ...prevClicks,
      [id]: (prevClicks[id] || 0) + 1,
    }));
  };

  const handleExpandAmenities = (id) => {
    setExpandedAmenities((prevExpanded) => ({
      ...prevExpanded,
      [id]: !prevExpanded[id],
    }));
  };

  const handleDelete = (id) => {
    onDelete(id);
  };

  const handleEdit = (id) => {
    const property = listing.find((prop) => prop.id === id);
    onEdit(property);
  };

  const handleShare = (id) => {
    const propertyLink = `https://www.gulfin.ai/details?id=${id}&status=draft`;
    navigator.clipboard
      .writeText(propertyLink)
      .then(() => alert("Property link copied to clipboard"))
      .catch(() => alert("Failed to copy property link"));
  };

  const renderAmenityIcon = (amenityKey) => {
    switch (amenityKey) {
      case "Bed":
        return <FaBed className="text-black-700 mr-1" />;
      case "garden":
        return <FaTree className="text-black-500 mr-1" />;
      case "security":
        return <FaLock className="text-black-500 mr-1" />;
      case "gas":
        return <MdOutlineGasMeter className="text-black-500 mr-1" />;
      case "Wifi":
        return <FaWifi className="text-black-500 mr-1" />;
      case "chair":
        return <FaChair className="text-black-500 mr-1" />;
      case "water":
        return <FaWater className="text-black-500 mr-1" />;
      case "wardrobe":
        return <BiCloset className="text-black-500 mr-1" />;
      case "parking":
        return <FaParking className="text-black-500 mr-1" />;
      default:
        return null;
    }
  };

  if (!listing.length) {
    return <div>No listing available.</div>;
  }

  return (
    <>
      {listing &&
        listing.map((property) => (
          <div
            key={property.id}
            className={`w-full md:w-${containerWidth} px-4 mb-4`}
          >
            <div
              className={`${borderOfContainer} bg-white h-full flex flex-col`}
            >
              <div className="flex flex-col">
                <div className="flex gap-x-2">
                  <div className="relative w-48 h-48 mr-4 mb-4 md:mb-0">
                    {property.attributes?.thumbnail_image?.data?.attributes
                      ?.url ? (
                      <img
                        src={
                          property.attributes.thumbnail_image.data.attributes
                            .url
                        }
                        alt={property.attributes.name}
                        className="w-full h-full object-cover rounded-md"
                      />
                    ) : (
                      <div className="w-full h-full bg-gray-200 flex items-center justify-center rounded-md">
                        No Image
                      </div>
                    )}

                    {refer !== "explore" && (
                      <div className="absolute top-2 left-2 flex space-x-2">
                        <button
                          onClick={() => handleEdit(property.id)}
                          className="bg-white p-1 rounded-full shadow"
                        >
                          <FaEdit className="text-blue-500" />
                        </button>
                        <button
                          onClick={() => handleDelete(property.id)}
                          className="bg-white p-1 rounded-full shadow"
                        >
                          <FaTrash className="text-red-500" />
                        </button>
                        <button
                          onClick={() => handleShare(property.id)}
                          className="bg-white p-1 rounded-full shadow"
                        >
                          <IoShareSocialSharp size={20} />
                          {/* Share Link */}
                        </button>
                        <button
                          onClick={() =>
                            window.open(
                              `https://www.gulfin.ai/details?id=${property.id}&status=draft`,
                              "_blank",
                              "noopener,noreferrer"
                            )
                          }
                          className="bg-white p-1 rounded-full shadow"
                        >
                          <IoIosShareAlt size={20} />
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col">
                    <div className="w-full flex justify-between items-center gap-x-2">
                      <h2 className="text-2xl font-semibold">
                        {property.attributes.property_name || "No Name"}
                      </h2>
                      <span className="text-blue-600">
                        <p>ID: {property.id}</p>
                        <button
                          onClick={() => handleClick(property.id)}
                          className="text-blue-600"
                        >
                          Clicks: {clicks[property.id] || 0}
                        </button>
                      </span>
                    </div>
                    <p className="text-lg text-gray-700 flex gap-x-2">
                      <span>{property.attributes?.currency || "AED"}</span>

                      {property.attributes?.rent || "N/A"}
                    </p>
                    <div className="space-y-1">
                      <div className="flex items-center space-x-2">
                        <IoLocationSharp className="text-gray-700" />
                        <p className="">
                          {property.attributes?.property_address
                            ? `${property.attributes.property_address.slice(
                                0,
                                35
                              )}${
                                property.attributes.property_address.length > 35
                                  ? "..."
                                  : ""
                              }`
                            : "No Address"}
                        </p>
                      </div>
                      <div className="flex flex-wrap items-center">
                        {property.attributes?.property_size && (
                          <div className="flex items-center space-x-2 w-1/2">
                            <BsTextarea className="text-gray-700" />
                            <p>
                              {property.attributes.property_size}{" "}
                              {property.attributes.listing_size_unit}{" "}
                            </p>
                          </div>
                        )}
                      </div>

                      <div className="grid grid-cols-2 items-center gap-x-10">
                        <div>
                          Listing Status :{" "}
                          <span className="capitalize">
                            {" "}
                            {property.attributes.rent_status}{" "}
                          </span>
                        </div>
                        <div>
                          {property.attributes.rent_status === "rent "
                            ? "Rent"
                            : "Price"}
                          : {property.attributes?.currency || "AED"}{" "}
                          {property.attributes.rent}
                        </div>
                        <div>Type : {property.attributes.property_type}</div>
                        <div className="flex gap-x-1">
                          Rating : {property.attributes.rating}{" "}
                          <Star color="yellow" fill="yellow" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <p className="text-gray-700">
                  {property.attributes?.description &&
                  property.attributes.description.length > 250
                    ? `${property.attributes.description.substring(0, 260)}...`
                    : property.attributes?.description || "No Description"}
                </p>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};
