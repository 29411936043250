import React, { useEffect, useRef, useState } from 'react';
import { LeadReceiverDetails } from './leadReceiverDetails';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { AiOutlineFile, AiOutlineGif, AiOutlinePicture } from 'react-icons/ai';
import { BsEmojiSmile } from 'react-icons/bs';
import { FiSend } from 'react-icons/fi';
import { SendMessageForLeads } from '../../../redux/leadSlice';
import axios from 'axios';

export const LeadMessageSection = ({ leadData }) => {

  const isSidebarCollapsed = useSelector((state) => state.sidebar.isCollapsed);
  const [adminMessage, setAdminMessage] = useState('');
  const [messages, setMessages] = useState([]);
  // const [newMessage, setNewMessage] = useState('')
  const [webSocket, setWebSocket] = useState(null)
  const dispatch = useDispatch();
  const dummy = useRef()

  const selectedLead = leadData[0];
  const loggedInUser = JSON.parse(localStorage.getItem('user'));
  const sessionToken = loggedInUser.mattermost_session_token_id;

  useEffect(() => {
    console.log('messages', messages)
    const fetchMessages = async () => {
      const rootId = selectedLead.attributes.mattermost_lead_id;
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN}api/get-mattermost-messages?postId=${rootId}`);
        console.log(response);


        const sortedMessages = Object.values(response.data.mattermostResponse.posts).sort((a, b) => b.create_at - a.create_at);
        setMessages(sortedMessages);
        // console.log(messages, 'initial state message')
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };
    // dummy.current.scrollIntoView({behavior: 'smooth'})
    fetchMessages();
  }, [leadData]);

  useEffect(() => {
    console.log(messages, 'state message')
  },)

  useEffect(() => {
    const rootId = selectedLead.attributes.mattermost_lead_id;
    const ws = new WebSocket('wss://messaging.gulfin.ai/api/v4/websocket');

    ws.onopen = () => {
      console.log('WebSocket connected');

      // Send authentication challenge
      const authData = {
        "seq": 1,
        "action": "authentication_challenge",
        "data": {
          "token": sessionToken
        }
      };
      ws.send(JSON.stringify(authData));
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log(rootId, 'root_ID')
      // console.log(JSON.parse(event.data));
      if (data.event === 'posted' && JSON.parse(data.data.post).root_id === rootId) {
        console.log(JSON.parse(data.data.post), 'data.post')
        setMessages((prevMessages) => {
          if (prevMessages[0].id !== JSON.parse(data.data.post).id) {
            return [JSON.parse(data.data.post), ...prevMessages]
          }
          return prevMessages
        });
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    setWebSocket(ws);
  }, []);

  useEffect(() => {
    messages.filter(i => i.user_id);
  }, [messages]);

  const sendMessage = async () => {
    if (!adminMessage) {
      console.error('Message cannot be empty');
      return;
    }
    console.log(selectedLead, 'selected lead');
    const channelId = selectedLead.attributes.mattermost_channel_id;
    const rootId = selectedLead.attributes.mattermost_lead_id;

    const payload = {
      channel_id: channelId,
      message: adminMessage,
      root_id: rootId,
      sessionToken: sessionToken
    };
    try {
      await dispatch(SendMessageForLeads(payload));
      setAdminMessage('');
      // dummy.current.scrollIntoView({behavior: 'smooth'})
      // const getMessages = await axios.get(
      //   `${process.env.REACT_APP_BACKEND_DOMAIN}api/get-mattermost-messages?postId=${rootId}`
      // );
      // const mess = Object.values(getMessages.data.mattermostResponse.posts);

      // const sortedData = mess.sort((a, b) => b.create_at - a.create_at);

      // setMessages(sortedData);
    } catch (error) {
      console.error('Error dispatching message:', error);
    }
  };

  const formatTimestamp = (timestamp) => {
    return format(new Date(timestamp), 'hh:mm a');
  };

  const filteredAdminMessages = messages.filter(
    (message) => message.user_id === loggedInUser.mattermost_user_id
  ).sort((a, b) => a.create_at - b.create_at);
  const filteredUserMessages = messages.filter(
    (message) => message.user_id !== loggedInUser.mattermost_user_id
  ).sort((a, b) => a.create_at - b.create_at);;

  // const messageSectionWidth = isSidebarCollapsed ? 'w-2/3' : 'w-4/5';
  const receiverDetailsWidth = isSidebarCollapsed ? 'w-1/3' : 'w-2/5';

  const mergedMessages = [
    ...filteredUserMessages.map((message) => ({
      ...message,
      sender: 'user',
    })),
    ...filteredAdminMessages.map((message) => ({
      ...message,
      sender: 'admin',
    })),
  ].sort((a, b) => new Date(a.create_at) - new Date(b.create_at));


  return (
    <div className="flex justify-between p-4 bg-white ml-2 w-3/4 rounded-lg border" >
      <div
        className={`h-[540px] p-4 border-r border-gray-300 flex flex-col w-full`}
      >
        <div className="flex-grow overflow-auto px-4">
          {leadData && (
            <div>
              <div className="mt-4">

                {mergedMessages.map((message, index) => (
                  <div
                    key={index}
                    className={`flex items-center mb-2 ${message.sender === 'user' ? 'justify-start' : 'justify-end'}`}
                  >
                    {/* <div ref={dummy}></div> */}
                    {message.sender === 'user' ? (
                      <>
                        {/* <img
              src={selectedLead.profilePicture}
              alt={selectedLead.client_name}
              className="w-10 h-10 rounded-full mr-2 object-cover"
            /> */}
                        <div className="p-2 rounded-lg text-black shadow-lg bg-blue-100">
                          <strong>{selectedLead.attributes.client_name}</strong>
                          <span className="text-gray-500 ml-2">
                            {formatTimestamp(message.create_at)}
                          </span>
                          <p>{message.message}</p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="p-2 rounded-lg text-black shadow-lg bg-gray-100">
                          <strong>{loggedInUser.username}</strong>
                          <span className="text-gray-500 ml-2">
                            {formatTimestamp(message.create_at)}
                          </span>
                          <p>{message.message}</p>
                        </div>
                        {/* <img
              src={loggedInUser.profilePicture}
              alt={loggedInUser.username}
              className="w-10 h-10 rounded-full ml-2 object-cover"
            /> */}
                      </>
                    )}
                  </div>
                ))}

              </div>
            </div>
          )}
        </div>
        <div className="border shadow-lg rounded-md mt-4">
          <input
            value={adminMessage || ''}
            onChange={(e) => setAdminMessage(e.target.value)}
            type="text"
            placeholder="Type a message..."
            className="w-full p-2 border-b rounded-t-md focus-visible:outline-none focus-visible:ring-0 border-gray-300"
          />
          <div className="flex items-center">
            <button className="p-2">
              <AiOutlinePicture size={24} />
            </button>
            <button className="p-2">
              <AiOutlineFile size={24} />
            </button>
            <button className="p-2">
              <AiOutlineGif size={24} />
            </button>
            <button className="p-2">
              <BsEmojiSmile size={24} />
            </button>
            <button className="ml-auto p-2 border-none bg-transparent">
              <FiSend size={24} onClick={sendMessage} />
            </button>
          </div>
        </div>
      </div>

      {/* <LeadReceiverDetails
        // selectedUser={selectedUser}
        leadData={leadData}
        collapsedwidth={receiverDetailsWidth}
      /> */}
    </div>
  );
};







