import React, { useState, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import { AddEditListingModel } from '../../../components/common/models/addEditListingModel';
import { DeleteConfirmantionModel } from '../../../components/common/models/deleteConfirmantionModel';
import { deleteProperty, updateProperty } from '../../../redux/propertiesSlice';
import { useDispatch } from 'react-redux';
import { Pagination } from '../../../components/common/pagination/pagination';
import { MyPropertiesListing } from '../../../components/Properties/MyPropertiesListing';
import { AddEditPropertyModel } from '../../../components/common/models/addNewProperty';
import { MyListingPage } from '../../../components/Properties/myListingPage';
import { EditPropertyInventoryListingModel } from '../../../components/common/models/EditPropertyInventoryListing';
import { createListing, updateListing } from '../../../redux/listingSlice';

export const PropertyExplore = ({properties1,listings, pageCount }) => {


  console.log(listings);

  const [listing, setListing] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [orderBy, setOrderBy] = useState('title');
  const [isOrderByDropdownOpen, setIsOrderByDropdownOpen] = useState(false);
  const [showActive, setShowActive] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPropertyModelOpen, setIsPropertyModelOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [propertyToDelete, setPropertyToDelete] = useState(null);
  const [formAction, setFormAction] = useState('edit');
  const [currentPage, setCurrentPage] = useState(1);
  const [priceRange, setPriceRange] = useState('Any Price');
  const [bedsCount, setBedsCount] = useState('Any');
  const [propertyType, setPropertyType] = useState('All Types');
  const propertiesPerPage = 10;
  const [editListingId, setEditListingId] = useState(null)
  const dispatch = useDispatch();

  // useEffect(() => {
  //   setListing(listings.data);
  //   setSelectedProperty(listings[0]);
  // }, [listings]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleOrderChange = (value) => {
    setOrderBy(value);
    setIsOrderByDropdownOpen(false);
  };

  const toggleOrderByDropdown = () => {
    setIsOrderByDropdownOpen(!isOrderByDropdownOpen);
  };

  const toggleActive = () => {
    setShowActive(!showActive);
  };

  const handleDelete = (id) => {
    setPropertyToDelete(listings.find((prop) => prop.id === id));
    setShowDeleteModal(true);
  };

  const handleDeleteConfirmation = () => {
    dispatch(deleteProperty(propertyToDelete.id)).then(() => {
      window.location.reload();
      setShowDeleteModal(false);
  });
  };
  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
  };

  const handleEdit = (listing) => {
    console.log(listing)
    setSelectedProperty([listing]);
    setEditListingId(listing.id)
    setIsPropertyModelOpen(true);
  };

  const propertyModelClose = () => {
    setIsPropertyModelOpen(false);
    // setSelectedProperty(null);
  };

  // const handleAddEditFormSubmit = (formData) => {
  //   let updatedName = formData.data.property_name.split(' ').join('');
  //   formData.data.name = updatedName?.toLowerCase();
  //   formData.data.display_name = formData.data.property_name;
  //   formData.data.type = "O";
  //   if (formAction === 'edit') {
  //     dispatch(updateProperty({ id: editPropertyId, propertyData: formData })).then(() => {
  //       window.location.reload();
  //       propertyModelClose();
  //   });
  //   } 
  // };

  const handleAddListingSubmit = (formData) => {
    formData.data.display_name = formData.data.name;
    formData.data.type = "O";
    if (formAction === "edit") {
      dispatch(
        updateListing({ id: editListingId, listingData: formData })
      ).then(() => {
        propertyModelClose();
        window.location.reload();
      });
    } else {
      dispatch(createListing(formData)).then(() => {
        propertyModelClose();
        window.location.reload();
      });
    }
  };
  const handlePriceRangeChange = (e) => {
    setPriceRange(e.target.value);
  };

  const handleBedsCountChange = (e) => {
    setBedsCount(e.target.value);
  };

  const handlePropertyTypeChange = (e) => {
    setPropertyType(e.target.value);
  };

  const applyFilters = (properties) => {
    return properties
      .filter(property => {
        const matchesSearch = property.attributes?.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          property.attributes.address?.toLowerCase().includes(searchTerm?.toLowerCase());

        const matchesPriceRange = priceRange === 'Any Price' || (
          priceRange === '$0 - $500' && property.attributes.price >= 0 && property.attributes.price <= 500 ||
          priceRange === '$500 - $1000' && property.attributes.price > 500 && property.attributes.price <= 1000 ||
          priceRange === '$1000 - $1500' && property.attributes.price > 1000 && property.attributes.price <= 1500 ||
          priceRange === '$1500 - $2000' && property.attributes.price > 1500 && property.attributes.price <= 2000
        );

        const matchesBedsCount = bedsCount === 'Any' || property.attributes.beds_count === parseInt(bedsCount);

        const matchesPropertyType = propertyType === 'All Types' || property.attributes.listing_type === propertyType;

        return matchesSearch && matchesPriceRange && matchesBedsCount && matchesPropertyType;
      })
      .sort((a, b) => {
        if (orderBy === 'name') {
          return a.attributes.name.localeCompare(b.attributes.name);
        } else if (orderBy === 'price') {
          return parseInt(a.attributes.price.replace(/[^0-9]/g, '')) - parseInt(b.attributes.price.replace(/[^0-9]/g, ''));
        }
        return 0;
      });
  };

  // const filteredProperties = applyFilters(listing);
  // const indexOfLastProperty = currentPage * propertiesPerPage;
  // const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
  // const currentListing = filteredProperties.slice(indexOfFirstProperty, indexOfLastProperty);
  // const totalPages = Math.ceil(filteredProperties.length / propertiesPerPage);

  return (
    <div className="bg-white border rounded-md">
      <div className="flex items-center p-4 space-x-9">
        <div className="relative flex-grow">
          <FaSearch className="absolute top-1/2 transform -translate-y-1/2 left-3 text-black-700" />
          <input
            type="text"
            placeholder="Search by location.."
            value={searchTerm}
            onChange={handleSearchChange}
            className="pl-10 pr-4 py-2 border placeholder-black-700 rounded-md w-2/3"
          />
        </div>
        <select className="py-2 px-2 text-gray-700 border rounded-md" value={priceRange} onChange={handlePriceRangeChange}>
          <option>Any Price</option>
          <option>$0 - $500</option>
          <option>$500 - $1000</option>
          <option>$1000 - $1500</option>
          <option>$1500 - $2000</option>
        </select>
        <select className="py-2 px-2 border rounded-md text-white bg-blue-500 h-10 w-32" value={bedsCount} onChange={handleBedsCountChange}>
          <option>Any</option>
          <option>1-3 Beds</option>
          <option>3-5 Beds</option>
          <option>5-7 Beds</option>
        </select>
        <select className="py-2 px-2 border-2 rounded-md border-grey-700" value={propertyType} onChange={handlePropertyTypeChange}>
          <option>All Types</option>
          <option>House</option>
          <option>Apartment</option>
          <option>Condo</option>
          <option>Townhouse</option>
        </select>
        <button className="py-2 pr-4 px-4 text-900 underline ml-8">
          More Filter
        </button>
      </div>

      <div className="w-full h-[450px] overflow-y-auto">
        {listings.length ? (
          <>
        <div className="flex flex-wrap ">
          <MyListingPage
            listing={listings}
            onDelete={handleDelete}
            onEdit={handleEdit}
            containerWidth={"1/2"}
            borderOfContainer={"p-4 border rounded-lg"}
            refer={"explore"}
          />
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={pageCount.pagination.pageCount}
          onPageChange={setCurrentPage}
        />
        
      </>
      ) : (
        <div className="text-2xl text-center p-4 ml-4">
          Listing not found for this property
        </div>
      )}
      </div>

      {showDeleteModal && (
        <DeleteConfirmantionModel
          isOpen={showDeleteModal}
          onCancel={handleDeleteCancel}
          onDelete={handleDeleteConfirmation}
          property={propertyToDelete}
        />
      )}

{isPropertyModelOpen && (
        <EditPropertyInventoryListingModel
          isOpen={isPropertyModelOpen}
          onClose={propertyModelClose}
          onSubmit={handleAddListingSubmit}
          setFormAction={setFormAction}
          listing={selectedProperty}
          propertiesData={properties1}
        />
      )}
    </div>
  );
};
