import React, { useState, useEffect } from "react";
import { AllLeadsPropertiesUserDetails } from "../../../components/Properties/Lead/AllLeadsPropertiesUserDetails.js";
import { useDispatch } from "react-redux";
import {
  FaChevronRight,
  FaSearch,
  FaAngleRight,
  FaAngleDown,
} from "react-icons/fa";
import { Pagination } from "../../../components/common/pagination/pagination.js";
import {
  SelectProjectsLead,
  SelectPropertiesLead,
} from "../../../components/Properties/Lead/SelectPropertiesLead.js";
import { AllLeadPropertiesListings } from "../../../components/Properties/Lead/AllLeadsPropertiesListing.js";
import { SelectLeadListing } from "../../../components/Properties/Lead/SelectLeadListing.js";
import { AllLeadProjectsListings } from "../../../components/Projects/Lead/AllLeadsProjectsListing.js";
import { AllLeadsProjectsUserDetails } from "../../../components/Projects/Lead/AllLeadsProjectsUserDetails.js";
import { LeadTrackingSystem } from "../../../components/leadTrackingSystem/leadTrackingSystem.js";
import { LeadTrackingSystemDetails } from "../../../components/leadTrackingSystem/leadTrackingSystemDetails.js";
import axios from "axios";
import Loader from "../../../components/Loader.js";
export const TrackSystemForProject = ({
  projectsData,
  properties,
  leadsData,
  handleSelectedListingId,
}) => {
  const [projects, setProjects] = useState([]);
  const [property, setProperty] = useState([]);
  const [selectedListing, setSelectedListing] = useState(null);
  const [selectedProperties, setSelectedProperty] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("name");
  const [filteredListing, setFilteredListing] = useState([]);
  const [isOrderByDropdownOpen, setIsOrderByDropdownOpen] = useState(false);
  const [showActive, setShowActive] = useState(true);
  const [currentPage, setCurrentPage] = useState(3);
  const [selectedLeads, setSelectedLeads] = useState();
  const [isSelecting, setIsSelecting] = useState(false);
  const [showListingSelectingList, setShowListingsSelectingList] =
    useState(false);
  const [selectedProjectsListings, setSelectedProjecListings] = useState([]);
  const [selectedProject, setSelectedProject] = useState([]);
  const [userLeadsToShow, setUserLeadsToShow] = useState([]);
  const [loading, setLoading] = useState(true);

  const listingsPerPage = 10;
  const dispatch = useDispatch();

  useEffect(() => {
    setProperty(properties);
    setSelectedProperty(properties[0]?.id || null);
    setFilteredListing(property);
  }, [properties, property, leadsData]);

  console.log(filteredListing, 'properties--->>');

  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const id = loggedInUser.id;

  useEffect(() => {
    const fetchLeads = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://admin.gulfin.ai/api/crm-leads?filters[user][$eq]=${id}&filters[crm_listings][$eq]=${selectedListing}&populate=crm_listings`
        );

        setUserLeadsToShow(response.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchLeads();
  }, [selectedListing, id]);

  const handlePropertyClick = (listingId) => {
    setSelectedListing(listingId);

    const filtered = property.filter((listings) => listings.id === listingId);
    setFilteredListing(filtered);
    let getLead = leadsData.filter(
      (lead) =>
        lead.attributes.crm_listings &&
        lead.attributes.crm_listings.data.some(
          (listing) => listing.id === listingId
        )
    );
    setSelectedLeads(getLead);
    handleSelectedListingId(filtered[0]);
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    console.log(e.target.value);

    filterListings(e.target.value, selectedProject);
  };

  const handleOrderChange = (value) => {
    setOrderBy(value);
    setIsOrderByDropdownOpen(false);
  };

  const toggleOrderByDropdown = () => {
    setIsOrderByDropdownOpen(!isOrderByDropdownOpen);
  };

  const toggleActive = () => {
    setShowActive(!showActive);
  };

  const handleSelectListingClick = () => {
    setIsSelecting(!isSelecting);
  };

  const handlePropertySelect = (projectId) => {
    console.log(projectId);

    let updatedselectedProject;
    if (selectedProject.includes(projectId)) {
      updatedselectedProject = selectedProject.filter((id) => id !== projectId);
    } else {
      updatedselectedProject = [...selectedProject, projectId];
    }
    setSelectedProject(updatedselectedProject);
    filterListings(searchTerm, updatedselectedProject);
  };

  const handleSelectAll = () => {
    const allPropertyIds = projectsData.map((peroperty) => peroperty.id);
    setSelectedProject(allPropertyIds);

    filterListings(searchTerm, allPropertyIds);
  };

  const filterListings = (searchTerm, selectedProperties) => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();

    const filtered = properties.filter((listing) => {
      const matchesSearchTerm = listing.attributes.name
        .toLowerCase()
        .includes(lowercasedSearchTerm);

      const matchesProperty =
        selectedProperties.length === 0 ||
        selectedProperties.includes(listing.attributes.property_id.data.id);
      return matchesSearchTerm && matchesProperty;
    });
    setFilteredListing(filtered);
    setProperty(filtered);
  };

  const indexOfLastListing = currentPage * listingsPerPage;
  const indexOfFirstListings = indexOfLastListing - listingsPerPage;
  const currentListing = filteredListing.slice(
    indexOfFirstListings,
    indexOfLastListing
  );
  const totalPages = Math.ceil(filteredListing.length / listingsPerPage);

  const listingCountMap = property.reduce((acc, property) => {
    const propertuId = property.attributes.property_id?.data?.id;
    if (propertuId) {
      acc[propertuId] = (acc[propertuId] || 0) + 1;
    }
    return acc;
  }, {});

  const handleProjectClick = (projectId) => {
    const myProjects = projectsData.find(
      (project) => property.id === projectId
    );

    if (myProjects) {
      const projectListings = properties.filter(
        (listing) => listing.attributes.property_id.data.id === projectId
      );
      setSelectedProjecListings(projectListings);
      setSelectedProject(myProjects);
      setShowListingsSelectingList(true); // Show the properties selecting list
    } else {
      setSelectedProjecListings([]);
      setSelectedProject(null);
      setShowListingsSelectingList(false); // Hide the properties selecting list
    }
  };

  return (
    <div className="w-full">
      <div className="flex w-full flex-grow">
        <div className="w-1/4 bg-white border rounded">
          <div className="p-2">
            <div className="flex justify-between mb-2">
              {/* <button className=" bg-blue-500 text-white w-full py-2 px-4 rounded flex items-center justify-between" onClick={handleSelectListingClick}>
                {!isSelecting ? <>
                  <div>Select Listing</div>
                  <FaChevronRight className="ml-2" />
                </> : <>
                  <div>Select Projects</div>
                  <FaAngleDown className="ml-2" />
                </>}
              </button> */}
            </div>
            {isSelecting && (
              <SelectPropertiesLead
                properties={projectsData}
                handlePropertySelect={handlePropertySelect}
                listings={property}
                handleSelectAll={handleSelectAll}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                selectedProperties={selectedProject}
                onProjectClick={handleProjectClick}
              />
            )}
            {!isSelecting && (
              <>
                <div className="flex justify-between h-full">
                  <div className="relative w-full">
                    <FaSearch className="absolute left-3 top-3 text-gray-400" />
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                      className="w-full pl-10 border border-blue-500 rounded py-2 px-4 bg-gray-100"
                    />
                  </div>
                  {/* <button className="text-black-500 text-sm truncate font-semibold underline py-2 px-4 rounded flex items-center ml-2">
                    Sort By
                  </button> */}
                </div>
                <div className="h-[450px] overflow-y-auto w-full mt-2">
                  <AllLeadProjectsListings
                    data={property}
                    handlePropertyClick={handlePropertyClick}
                    listingCountMap={listingCountMap}
                  />
                </div>
              </>
            )}

            <div className="w-full absolute ">
              <div className="fixed flex w-full justify-start items-center">
                {showListingSelectingList && (
                  <SelectLeadListing
                    selectedProperties={selectedProject}
                    selectedProjectsListings={selectedProjectsListings}
                    setShowListingsSelectingList={setShowListingsSelectingList}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="ml-2 w-3/4 border bg-white rounded-lg overflow-y-auto">
          <div>
            {selectedListing ? (
              <div className="p-2">
                <LeadTrackingSystemDetails leadsData={userLeadsToShow} listing={filteredListing}/>
              </div>
            ) : (
              <div className="text-md text-center">Please select a listing</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
