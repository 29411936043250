import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/authSlice";
import { selectAuth } from "../redux/authSlice";
import Header from "./Header/header";
import Sidebar from "./Sidebar/sidebar";
import PersonalSidebar from "./Sidebar/PersonalSidebar";

const PersonalLayout = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isUserLoading } = useSelector(selectAuth);
  console.log("isUserLoading:", isUserLoading);

  if (isUserLoading) {
    return <div>Loading...</div>;
  }

  const dummyUser = JSON.parse(localStorage.getItem("user"));

  if (!dummyUser) {
    navigate("/login");
  }

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };
  return (
    <div className="flex flex-col h-screen p-4 font-poppins">
      <Header user={dummyUser} onLogout={handleLogout} />
      <div className="flex flex-1 pt-3 overflow-hidden">
        <PersonalSidebar />
        <main className="bg-gray-100 flex-1">{children}</main>
      </div>
    </div>
  );
};

export default PersonalLayout;
