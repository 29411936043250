import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  FaChevronRight,
  FaSearch,
  FaAngleRight,
  FaAngleDown,
} from "react-icons/fa";
import { Pagination } from "../../../components/common/pagination/pagination";
import {
  SelectProjectsLead,
  SelectPropertiesLead,
} from "../../../components/Properties/Lead/SelectPropertiesLead.js";
import { AllLeadPropertiesListings } from "../../../components/Properties/Lead/AllLeadsPropertiesListing.js";
import { SelectLeadListing } from "../../../components/Properties/Lead/SelectLeadListing.js";
import { PropertyLeadTrackingSystemDetails } from "../../../components/Properties/Lead/PropertyLeadTrackingDetails.js";
import { LeadTrackingSystemDetails } from "../../../components/leadTrackingSystem/leadTrackingSystemDetails.js";
export const TrackSystemForServices = ({
  properties1,
  listingData,
  leadsData,
  handleSelectedListingId,
}) => {
  const [properties, setProperties] = useState([]);
  const [listings, setlistings] = useState([]);
  const [selectedListings, setSelectedListing] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("name");
  const [filteredListing, setFilteredListing] = useState([]);
  const [isOrderByDropdownOpen, setIsOrderByDropdownOpen] = useState(false);
  const [showActive, setShowActive] = useState(true);
  const [currentPage, setCurrentPage] = useState(3);
  const [selectedLeads, setSelectedLeads] = useState();
  const [isSelecting, setIsSelecting] = useState(false);
  const [showListingSelectingList, setShowListingsSelectingList] =
    useState(false);
  const [selectedPropertyListings, setSelectedPropertyListings] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState([]);
  const [loading, setLoading] = useState([])

  const listingsPerPage = 10;
  const dispatch = useDispatch();

  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const id = loggedInUser.id;

  console.log(selectedListings, 'service listind id ')

  useEffect(() => {
    const fetchLeads = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://admin.gulfin.ai/api/crm-leads?populate=crm_service_listings&filters[user][$eq]=${id}&filters[crm_service_listings][$eq]=${selectedListings}`
        );

        setSelectedLeads(response.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchLeads();
  }, [selectedListings, id]);

  useEffect(() => {
    setlistings(listingData);
    // setSelectedListing(listingData[0]?.id || null);
    setFilteredListing(listings);
  }, [listingData, listings, leadsData]);

  const handleListingClick = (listingId) => {
    setSelectedListing(listingId)
    const filtered = listings.filter(listing => listing.id === listingId);
    setFilteredListing(filtered);
    let getLead = leadsData.filter(lead =>
      lead.attributes.crm_service_listings && lead.attributes.crm_service_listings.data.some(listing => listing.id === listingId)
    );
    setSelectedLeads(getLead);
    // handleSelectedListingId(filtered[0].attributes.mattermost_listing_id);
    handleSelectedListingId(filtered[0])
    setCurrentPage(1); // Reset to the first page when a new project is selected
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    filterListings(e.target.value, selectedProperty);
  };

  const handleOrderChange = (value) => {
    setOrderBy(value);
    setIsOrderByDropdownOpen(false);
  };

  const toggleOrderByDropdown = () => {
    setIsOrderByDropdownOpen(!isOrderByDropdownOpen);
  };

  const toggleActive = () => {
    setShowActive(!showActive);
  };

  const handleSelectListingClick = () => {
    setIsSelecting(!isSelecting);
  };

  const handlePropertySelect = (projectId) => {
    let updatedSelectedProperty;
    if (selectedProperty.includes(projectId)) {
      updatedSelectedProperty = selectedProperty.filter(
        (id) => id !== projectId
      );
    } else {
      updatedSelectedProperty = [...selectedProperty, projectId];
    }
    setSelectedProperty(updatedSelectedProperty);
    filterListings(searchTerm, updatedSelectedProperty);
  };

  const handleSelectAll = () => {
    const allPropertyIds = properties1.map((peroperty) => peroperty.id);
    setSelectedProperty(allPropertyIds);

    filterListings(searchTerm, allPropertyIds);
  };

  const filterListings = (searchTerm, selectedProperties) => {
    console.log(selectedProperties, "------------", listingData);
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const filtered = listingData.filter((listing) => {
      const matchesSearchTerm = listing.attributes.name
        .toLowerCase()
        .includes(lowercasedSearchTerm);
      const matchesProperty =
        selectedProperties.length === 0 ||
        selectedProperties.includes(listing.attributes.property_id.data.id);
      return matchesSearchTerm && matchesProperty;
    });
    setFilteredListing(filtered);
    setlistings(filtered);
  };

  const indexOfLastListing = currentPage * listingsPerPage;
  const indexOfFirstListings = indexOfLastListing - listingsPerPage;
  const currentListing = filteredListing.slice(
    indexOfFirstListings,
    indexOfLastListing
  );
  const totalPages = Math.ceil(filteredListing.length / listingsPerPage);
  // console.log(currentListing,'currentlisting')
  const listingCountMap = listings.reduce((acc, property) => {
    const propertuId = property.attributes.property_id?.data?.id;
    if (propertuId) {
      acc[propertuId] = (acc[propertuId] || 0) + 1;
    }
    return acc;
  }, {});

  // const proertyLeadMap = currentListing.reduce((acc, listing) => {
  //   const property =
  //     listing.attributes.property_id.data.attributes.property_name;
  //   if (!acc[property]) {
  //     acc[property] = [];
  //   }
  //   acc[property].push(listing);
  //   return acc;
  // }, {});

  const handlePropertyClick = (propertyId) => {
    const myProperty = properties1.find(
      (property) => property.id === propertyId
    );

    if (myProperty) {
      const PropertyListings = listingData.filter(
        (listing) => listing.attributes.property_id.data.id === propertyId
      );
      setSelectedPropertyListings(PropertyListings);
      setSelectedProperty(myProperty);
      setShowListingsSelectingList(true); // Show the properties selecting list
    } else {
      setSelectedPropertyListings([]);
      setSelectedProperty(null);
      setShowListingsSelectingList(false); // Hide the properties selecting list
    }
  };

  return (
    <div className="w-full">
      <div className="flex w-full flex-grow ">
        <div className="w-1/4 bg-white border rounded md:w-5/12">
          <div className="p-2">
            <div className="flex mb-2">
              {/* <button
                className="bg-blue-500 text-white w-full py-2 px-4 rounded flex items-center justify-between"
                onClick={handleSelectListingClick}
              >
                {!isSelecting ? (
                  <>
                    <div>Select Listing</div>
                    <FaChevronRight className="ml-2" />
                  </>
                ) : (
                  <>
                    <div>Select Projects</div>
                    <FaAngleDown className="ml-2" />
                  </>
                )}
              </button> */}
            </div>
            {isSelecting && (
              <SelectPropertiesLead
                properties={properties1}
                handlePropertySelect={handlePropertySelect}
                listings={listings}
                handleSelectAll={handleSelectAll}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                selectedProperties={selectedProperty}
                onProjectClick={handlePropertyClick}
              />
            )}
            {!isSelecting && (
              <>
                <div className="flex justify-between h-full">
                  <div className="relative w-full">
                    <FaSearch className="absolute left-3 top-3 text-gray-400" />
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                      className="w-full pl-10 border border-blue-500 rounded py-2 px-4 bg-gray-100"
                    />
                  </div>
                  {/* <button className="text-black-500 font-semibold underline py-2 px-4 rounded flex items-center ml-2">
                    Filters
                  </button> */}
                </div>
                <div className="h-[450px] overflow-y-auto">
                  <AllLeadPropertiesListings
                    data={listings}
                    handleListingClick={handleListingClick}
                    listingCountMap={listingCountMap}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <>
          <div className="w-3/4 absolute">
            <div className="fixed flex justify-start items-center">
              {showListingSelectingList && (
                <SelectLeadListing
                  selectedProperties={selectedProperty}
                  selectedPropertyListings={selectedPropertyListings}
                  setShowListingsSelectingList={setShowListingsSelectingList}
                />
              )}
            </div>
          </div>
          <div className="ml-2 w-full border rounded-lg bg-white overflow-y-auto mx-auto">
            <div className="w-full">
              {selectedLeads ?
                <LeadTrackingSystemDetails refer={"services"} leadsData={selectedLeads} />
                : <div className='text-md text-center'>Please select a listing</div>}
            </div>
          </div>
        </>
      </div>
    </div>
  );
};
