import React, { useEffect, useState } from "react";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import profilePicture from "../../services/images/profileLogo1.jpg";
import { useDispatch } from "react-redux";
import { updateLeads } from "../../redux/leadSlice";
import { UserViewDetails } from "../common/models/userViewDetails";
import InvoicePopup from "./leadInvoice";
import axios from "axios";

const API = process.env.REACT_APP_BACKEND_DOMAIN;

const ItemType = {
  LEAD: "lead",
};

const LeadItem = ({ lead, moveLead }) => {
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [{ isDragging }, drag] = useDrag({
    type: ItemType.LEAD,
    item: { id: lead.id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const handleLeadClick = () => {
    setSelectedLead(lead);
    setShowUserModal(true);
  };

  return (
    <>
      <div
        ref={drag}
        className={`border rounded-lg shadow-lg p-1 mb-2 py-3 border-gray-300 flex items-center ${
          isDragging ? "opacity-50" : "opacity-100"
        }`}
      >
        <div onClick={handleLeadClick} className="flex cursor-pointer">
          <img
            src={profilePicture}
            alt={lead.attributes.client_name}
            className="w-10 h-10 rounded-full p-1"
          />
          <div className="truncate">
            <div className="font-semibold w-auto truncate text-sm">
              {lead.attributes.client_name}
            </div>
            <div className="text-xs">{lead.attributes.client_number}</div>
          </div>
        </div>
      </div>
      {showUserModal && selectedLead && (
        <UserViewDetails
          lead={selectedLead}
          onClose={() => setShowUserModal(false)}
        />
      )}
    </>
  );
};

const LeadSection = ({ title, leads, status, moveLead }) => {
  const [, drop] = useDrop({
    accept: ItemType.LEAD,
    drop: (item) => moveLead(item.id, status),
  });

  return (
    <div ref={drop} className="w-40 overflow-x-auto p-2 flex flex-col border">
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      {/* <div className="flex w-max h-[450px] flex-col overflow-x-auto"> */}
      <div className="flex h-[450px] flex-col overflow-x-auto">
        {leads.length ? (
          leads.map((lead) => (
            <LeadItem key={lead.id} lead={lead} moveLead={moveLead} />
          ))
        ) : (
          <div className="text-sm">No leads for this property</div>
        )}
      </div>
    </div>
  );
};

export const LeadTrackingSystemDetails = ({ refer, leadsData, listing }) => {
  const [leads, setLeads] = useState([]);
  const [showInvoicePopup, setShowInvoicePopup] = useState(false);
  const [selectedLeadForInvoice, setSelectedLeadForInvoice] = useState(null);
  const [previousStatus, setPreviousStatus] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Array.isArray(leadsData.data)) {
      setLeads(leadsData.data);
    } else {
      console.error("leadsData is not an array:", leadsData);
    }
  }, [leadsData]);

  console.log(leads);

  const getLeadsByStatus = (status) => {
    if (!leads || leads.length === 0) return [];
    return leads?.filter((lead) => lead.attributes.status === status);
  };
  const statuses = [
    "accepted",
    "contacted",
    "converted",
    "paid",
    "closed",
    "failed",
    "archive",
  ];

  const moveLead = async (id, newStatus) => {
    const leadToUpdate = leads.find((lead) => lead.id === id);
    if (!leadToUpdate) return;

    setPreviousStatus(leadToUpdate.attributes.status);

    const updatedLeads = leads.map((lead) =>
      lead.id === id
        ? { ...lead, attributes: { ...lead.attributes, status: newStatus } }
        : lead
    );
    setLeads(updatedLeads);

    if (newStatus === "converted") {
      setSelectedLeadForInvoice(leadToUpdate);
      setShowInvoicePopup(true);

      try {
        const emailResponse = await axios.post(`${API}api/send-email`, {
          to: leadToUpdate.attributes.email,
          subject: "Your Lead Has Been Converted",
          text: `Congratulations! Your lead has been converted.`,
          html: `<p>Congratulations! Your lead has been converted.</p>`,
        });

        if (emailResponse.status !== 200) {
          throw new Error("Failed to send email to user");
        }
      } catch (error) {
        console.error("Error sending email for converted lead:", error);
      }
    } else {
      const updatedLeadData = {
        data: { ...leadToUpdate.attributes, status: newStatus },
      };

      try {
        const response = await dispatch(
          updateLeads({ id: leadToUpdate.id, updatedLeadData })
        );

        console.log("Status updated:", response.data);

        const emailResponse = await axios.post(`${API}api/send-email`, {
          to: leadToUpdate.attributes.email,
          subject: "Lead Status Update",
          text: `The status of your lead has been updated to: ${newStatus}`,
          html: `<p>The status of your lead has been updated to: <strong>${newStatus}</strong></p>`,
        });

        if (emailResponse.status !== 200) {
          throw new Error("Failed to send email to user");
        }
      } catch (error) {
        console.error("Error updating status or sending email:", error);
      }
    }
  };

  const handleInvoiceSubmit = async (invoiceData) => {
    const updatedLeadData = {
      data: { ...selectedLeadForInvoice.attributes, status: "converted" },
    };
    dispatch(updateLeads({ id: selectedLeadForInvoice.id, updatedLeadData }))
      .then((response) => {
        console.log("Status updated after invoice submission:", response.data);
      })
      .catch((error) => {
        console.error("Error updating status after invoice submission:", error);
      });

    setShowInvoicePopup(false);
  };

  const handleInvoiceCancel = () => {
    const revertedLeads = leads.map((lead) =>
      lead.id === selectedLeadForInvoice.id
        ? {
            ...lead,
            attributes: { ...lead.attributes, status: previousStatus },
          }
        : lead
    );

    setLeads(revertedLeads);
    setShowInvoicePopup(false);
  };

  if (!leadsData) return <div>No lead selected</div>;

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="w-full">
        <div className="flex overflow-x-auto">
          {statuses.map((status) => (
            <LeadSection
              key={status}
              title={status.charAt(0).toUpperCase() + status.slice(1)}
              leads={getLeadsByStatus(status)}
              status={status}
              moveLead={moveLead}
            />
          ))}
        </div>
      </div>
      {showInvoicePopup && selectedLeadForInvoice && (
        <InvoicePopup
          lead={selectedLeadForInvoice}
          onClose={handleInvoiceCancel}
          onSubmit={handleInvoiceSubmit}
          refer={refer}
        />
      )}
    </DndProvider>
  );
};
