import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SubHeader } from "./subHeader";
import { AnalystProjectPage } from "./Analytics/analytics";
import { ProjectLead } from "./Leads/lead";
import { ProjectsListings } from "./Listing/projectListing";
import { ProjectsInventoryLatest } from "./inventory/myProjectsInventory";
import { ProjectsExplore } from "./Explore/explore";
import {
  getProperties,
  getUserProperties,
  setPage,
} from "../../redux/propertiesSlice";
import {
  getAllListings,
  getProjects,
  getUserProjects,
} from "../../redux/proejctsSlice";
import { getLeads, getUserLeads } from "../../redux/leadSlice";
import axios from "axios";
const LoadingComponent = () => (
  <div
    className="animate-spin inline-block size-8 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
    role="status"
    aria-label="loading"
  >
    <span class="sr-only">Loading...</span>
  </div>
);

export const ProjectsPage = () => {
  const [selectedListingId, SetSelectedistingId] = useState(0);
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const id = loggedInUser.id;
  const dispatch = useDispatch();

  const [activeItem, setActiveItem] = useState(() => {
    const savedItem = sessionStorage.getItem("activeProjectsItem");
    return savedItem || "Analytics";
  });

  const updateActiveItem = (item) => {
    setActiveItem(item);
    sessionStorage.setItem("activeProjectsItem", item);
  };

  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);
  const [properties, setProperties] = useState(null);
  const [selectedProjectListings, setSelectedProjectListings] = useState([]);
  const [allListings, setAllListings] = useState([]);

  const {
    data: properties1,
    status,
    error,
    currentPage,
    totalPages,
  } = useSelector((state) => state.properties);
  const {
    userData: properties2,
    userStatus,
    userError,
    userCurrentPage,
    userTotalPages,
  } = useSelector((state) => state.properties);
  const {
    projectsData,
    projectStatus,
    projectError,
    projectCurrentPage,
    projectTotalPages,
  } = useSelector((state) => state.projects);
  const { userData } = useSelector((state) => state.auth);

  const {
    userProjectsData,
    userProjectStatus,
    userProjectError,
    userProjectCurrentPage,
    userProjectTotalPages,
  } = useSelector((state) => state.projects);
  const {
    leadsUserData,
    leadsUserStatus,
    leadsUserError,
    leadsUserCurrentPage,
    leadsUserTotalPages,
  } = useSelector((state) => state.leads);
  const {
    leadsData,
    leadsStatus,
    leadsError,
    leadsCurrentPage,
    leadsTotalPages,
  } = useSelector((state) => state.leads);

  useEffect(() => {
    dispatch(getLeads({ page: leadsCurrentPage, pageSize: 100 }));
  }, [dispatch, leadsCurrentPage, id]);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        dispatch(getProperties({ page: currentPage, pageSize: 100 })),
        dispatch(
          getUserProperties({ page: userCurrentPage, pageSize: 30, userId: id })
        ),
        dispatch(getProjects({ page: projectCurrentPage, pageSize: 100 })),
        dispatch(
          getUserProjects({
            page: userProjectCurrentPage,
            pageSize: 100,
            userId: id,
          })
        ),
        // dispatch(
        //   getLeads({ page: leadsCurrentPage, pageSize: 100, userId: id })
        // ),
      ]);
      setIsInitialLoadComplete(true);
    };

    fetchData();
  }, [
    dispatch,
    id,
    userProjectCurrentPage,
    projectCurrentPage,
    leadsCurrentPage,
  ]);

  useEffect(() => {
    dispatch(
      getUserLeads({
        page: leadsUserCurrentPage,
        pageSize: 100,
        userId: id,
        pageName: "projects",
      })
    );
  }, [dispatch, leadsUserCurrentPage, id]);

  useEffect(() => {
    dispatch(
      getLeads({
        page: leadsCurrentPage,
        pageSize: 30,
        crm_properties: selectedListingId,
      })
    );
  }, [leadsCurrentPage, selectedListingId, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://admin.gulfin.ai/api/crm-listings?populate=*&filters[property_user][$eq]=${id}&filters[rent_status][$null]=true`
        );
        const response2 = await axios.get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-listings?populate=thumbnail_image&filters[rent_status][$null]=true`
        );

        console.log(response.data);

        console.log(response2.data);


        setSelectedProjectListings(response.data);
        setAllListings(response2.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const handlePageChange = (newPage) => {
    dispatch(setPage(newPage));
  };

  const isConfirm = loggedInUser.confirmed;
  const role = loggedInUser.user_role;

  const renderComponent = () => {
    if (!isInitialLoadComplete) {
      return (
        <div className="flex justify-center items-center h-[500px]">
          <LoadingComponent />;
        </div>
      );
    }

    switch (activeItem) {
      // case "Post":
      //   return <Post section="projects" />;
      case "Analytics":
        return role === "owner" || isConfirm ? (
          <AnalystProjectPage
            leadsData={leadsUserData}
            properties1={selectedProjectListings}
          />
        ) : (
          <p>Your analytics will appear here once your profile is approved</p>
        );
      case "Explore":
        return (
          <ProjectsExplore
            projectsData={allListings}
            properties1={properties1}
          />
        );
      case "Inventory":
        return role === "owner" || isConfirm ? (
          <ProjectsInventoryLatest
            projectsData={userProjectsData}
            properties1={properties}
          />
        ) : (
          <p>
            Your profile is not verified yet. Please wait till an Admin approves
            your profile.
          </p>
        );
      case "Listing":
        return role === "owner" || isConfirm ? (
          <ProjectsListings
            projectsData={userProjectsData}
            properties1={selectedProjectListings}
          />
        ) : (
          <p>
            Your profile is not verified yet. Please wait till an Admin approves
            your profile.
          </p>
        );
      case "Lead":
        return role === "owner" || isConfirm ? (
          <ProjectLead
            leadsData={leadsData}
            listings={selectedProjectListings.data}
            leadsUserData={leadsUserData}
          />
        ) : (
          <p>
            Your projects leads will appear here once your profile is approved
          </p>
        );
      default:
        return null;
    }
  };

  return (
    <div className="Projects-page bg-gray-100 min-h-screen p-1">
      <SubHeader activeItem={activeItem} onNavigate={updateActiveItem} />
      {renderComponent()}
    </div>
  );
};
