import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { ListingList } from "../../../components/common/listing/listingList";
import { AddEditListingModel } from "../../../components/common/models/addEditListingModel";
import { DeleteConfirmantionModel } from "../../../components/common/models/deleteConfirmantionModel";
import { updateProperty } from "../../../redux/propertiesSlice";
import { useDispatch } from "react-redux";
import { Pagination } from "../../../components/common/pagination/pagination";
import { MyServicesListing } from "../../../components/services/myServicesListing";
import { deleteServices, updateService } from "../../../redux/servicesSlice";
import { AddNewService } from "../../../components/common/models/addNewService";
import { AddNewServiceListing } from "../../../components/common/models/addNewServiceListing";
import { createServicesListing, updateServicesListing } from "../../../redux/servicesListingSlice";

export const ServicesExplore = ({ servicesListings,servicesData }) => {

  const [services, setServices] = useState([]);
  // const [selectedProperty, setSelectedProperty] = useState(null);
  const [isServiceModelOpen, setIsServiceModelOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("title");
  const [isOrderByDropdownOpen, setIsOrderByDropdownOpen] = useState(false);
  const [showActive, setShowActive] = useState(true);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState(null);
  const [formAction, setFormAction] = useState("edit");
  const [currentPage, setCurrentPage] = useState(1);
  const [priceRange, setPriceRange] = useState("Any Price");
  const [serviceSelectId, setServiceSelectId] = useState(null);
  const [bedsCount, setBedsCount] = useState("Any");
  const [propertyType, setPropertyType] = useState("All Types");
  const [editListingId, setEditListingId] = useState(null);
  const propertiesPerPage = 10;
  const dispatch = useDispatch();

  useEffect(() => {
    setServices(servicesListings);
    // setIsServiceModelOpen(servicesListings[0]);
  }, [servicesListings]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleOrderChange = (value) => {
    setOrderBy(value);
    setIsOrderByDropdownOpen(false);
  };

  const toggleOrderByDropdown = () => {
    setIsOrderByDropdownOpen(!isOrderByDropdownOpen);
  };

  const toggleActive = () => {
    setShowActive(!showActive);
  };

  const handleDelete = (id) => {
    setServiceToDelete(services.find((prop) => prop.id === id));
    setShowDeleteModal(true);
  };

  const handleDeleteConfirmation = () => {
    setServices(services.filter((prop) => prop.id !== serviceToDelete.id));
    dispatch(deleteServices(serviceToDelete.id)).then(() => {
      window.location.reload();
      handleDeleteCancel();
    });
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
  };

  const handleEdit = (property) => {
    setSelectedProperty(property.attributes);
    setEditListingId(property.id);
    setIsServiceModelOpen(true);
  };

  // const openModal = () => {
  //   setIsModalOpen(true);
  // };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  //   setIsServiceModelOpen(null);
  // };
  const servicesModelClose = () => {
    setIsServiceModelOpen(false);
    setSelectedProperty(null);
  };


  // const handleAddEditFormSubmit = (formData) => {
  //   window.location.reload();
  //   dispatch(updateService({ id: editPropertyId, serviceData: formData })).then(
  //     () => {
  //       servicesModelClose();
  //       window.location.reload();
  //     }
  //   );
  // };


  const handleAddServiceListingSubmit = (formData) => {
    formData.data.display_name = formData.data.name;
    formData.data.type = "O";
    if (formAction === "edit") {
      console.log("edit", formData);
      dispatch(
        updateServicesListing({ id: editListingId, listingData: formData })
      ).then(() => {
        window.location.reload();
        servicesModelClose();
      });
    } else {
      console.log("create", formData);
      dispatch(createServicesListing(formData)).then(() => {
        window.location.reload();
        servicesModelClose();
      });
    }
  };

  const handlePriceRangeChange = (e) => {
    setPriceRange(e.target.value);
  };

  const handleBedsCountChange = (e) => {
    setBedsCount(e.target.value);
  };

  const handlePropertyTypeChange = (e) => {
    setPropertyType(e.target.value);
  };

  const applyFilters = (properties) => {
    return properties
      .filter((property) => {
        const matchesSearch =
          property.attributes?.name
            ?.toLowerCase()
            .includes(searchTerm?.toLowerCase()) ||
          property.attributes?.location
            ?.toLowerCase()
            .includes(searchTerm?.toLowerCase());

        const matchesPriceRange =
          priceRange === "Any Price" ||
          (priceRange === "$0 - $500" &&
            property.attributes.price >= 0 &&
            property.attributes.price <= 500) ||
          (priceRange === "$500 - $1000" &&
            property.attributes.price > 500 &&
            property.attributes.price <= 1000) ||
          (priceRange === "$1000 - $1500" &&
            property.attributes.price > 1000 &&
            property.attributes.price <= 1500) ||
          (priceRange === "$1500 - $2000" &&
            property.attributes.price > 1500 &&
            property.attributes.price <= 2000);

        const matchesBedsCount =
          bedsCount === "Any" ||
          property.attributes.beds_count === parseInt(bedsCount);

        // const matchesPropertyType = propertyType === 'All Types' || property.attributes.property_type === propertyType;

        return matchesSearch && matchesPriceRange && matchesBedsCount;
      })
      .sort((a, b) => {
        if (orderBy === "name") {
          return a.attributes.name.localeCompare(b.attributes.name);
        } else if (orderBy === "price") {
          return (
            parseInt(a.attributes.price.replace(/[^0-9]/g, "")) -
            parseInt(b.attributes.price.replace(/[^0-9]/g, ""))
          );
        }
        return 0;
      });
  };

  const filteredServices = applyFilters(services);
  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
  const currentServices = filteredServices.slice(indexOfFirstProperty, indexOfLastProperty);
  const totalPages = Math.ceil(filteredServices.length / propertiesPerPage);
  

  return (
    <div className="bg-white border rounded-md">
      <div className="flex items-center p-4 space-x-9">
        <div className="relative flex-grow">
          <FaSearch className="absolute top-1/2 transform -translate-y-1/2 left-3 text-black-700" />
          <input
            type="text"
            placeholder="Search by location.."
            value={searchTerm}
            onChange={handleSearchChange}
            className="pl-10 pr-4 py-2 border placeholder-black-700 rounded-md w-2/3"
          />
        </div>
        <select
          className="py-2 px-2 text-gray-700 border rounded-md"
          value={priceRange}
          onChange={handlePriceRangeChange}
        >
          <option>Any Price</option>
          <option>$0 - $500</option>
          <option>$500 - $1000</option>
          <option>$1000 - $1500</option>
          <option>$1500 - $2000</option>
        </select>
        <select
          className="py-2 px-2 border rounded-md text-white bg-blue-500 h-10 w-32"
          value={bedsCount}
          onChange={handleBedsCountChange}
        >
          <option>Any</option>
          <option>1-3 Beds</option>
          <option>3-5 Beds</option>
          <option>5-7 Beds</option>
        </select>
        <select
          className="py-2 px-2 border-2 rounded-md border-grey-700"
          value={propertyType}
          onChange={handlePropertyTypeChange}
        >
          <option>All Types</option>
          <option>House</option>
          <option>Apartment</option>
          <option>Condo</option>
          <option>Townhouse</option>
        </select>
        <button className="py-2 pr-4 px-4 text-900 underline ml-8">
          More Filter
        </button>
      </div>

      <div className="w-full h-[470px] overflow-y-auto">
        <div className="flex flex-wrap ">
          <MyServicesListing
            services={currentServices}
            onDelete={handleDelete}
            onEdit={handleEdit}
            containerWidth={"1/2"}
            borderOfContainer={"p-4 border rounded-lg"}
          />
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      </div>

      {showDeleteModal && (
        <DeleteConfirmantionModel
          isOpen={showDeleteModal}
          onCancel={handleDeleteCancel}
          onDelete={handleDeleteConfirmation}
          property={serviceToDelete}
        />
      )}

      {isServiceModelOpen && (
        <AddNewServiceListing
        isOpen={isServiceModelOpen}
        onClose={servicesModelClose}
        onSubmit={handleAddServiceListingSubmit}
        service={selectedProperty}
        setFormAction={setFormAction}
        serviceSelectId={serviceSelectId}
        servicesData={servicesData}
      />
      )}
    </div>
  );
};
