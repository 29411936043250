import React, { useState } from "react";
import axios from "axios";
import { jsPDF } from "jspdf";

const InvoicePopup = ({ lead, onClose, onSubmit, refer }) => {
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const id = loggedInUser.id;

  console.log(lead);

  const generateInvoiceNumber = () => {
    return Math.floor(100000 + Math.random() * 900000).toString();
  };

  const [invoiceData, setInvoiceData] = useState({
    name: lead.attributes.client_name,
    email: lead.attributes.email,
    phone_number: lead.attributes.client_number,
    amount:
      refer === "services"
        ? lead.attributes.crm_service_listings.data[0].attributes.price
        : lead.attributes.crm_listings.data[0].attributes.rent,
    property_name:
      refer === "services"
        ? lead.attributes.crm_service_listings.data[0].attributes.name
        : lead.attributes.crm_listings.data[0].attributes.property_name,
    bank_details: "",
    invoice_number: generateInvoiceNumber(),
    crm_listing:
      refer === "services"
        ? {
            disconnect: [],
            connect: [
              // {
              //   id: lead.attributes.crm_service_listings.data[0].id,
              //   position: {
              //     end: true,
              //   },
              // },
            ],
          }
        : {
            disconnect: [],
            connect: [
              {
                id: lead.attributes.crm_listings.data[0].id,
                position: {
                  end: true,
                },
              },
            ],
          },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInvoiceData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const payload = { data: invoiceData };


  
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}api/invoices`,
        payload
      );

      console.log("Invoice updated:", response.data);

      const invoiceUniqueId = response.data.data.id;

      const pdfBlob = await generatePDF(invoiceData);
      const pdfUploadResponse = await uploadPDF(pdfBlob);

      const updatePayload = {
        data: {
          invoice_pdf: pdfUploadResponse.data[0].id,
        },
      };

      const updateInvoicePDF = await axios.put(
        `${process.env.REACT_APP_BACKEND_DOMAIN}api/invoices/${invoiceUniqueId}`,
        updatePayload
      );

      alert("Your invoice in generated! It will be sent to your email soon.");

      console.log("Invoice updated with PDF:", updateInvoicePDF.data);

      onSubmit(invoiceData);
    } catch (error) {
      console.error("Error updating invoice:", error);
    }
  };

  const generatePDF = (data) => {
    return new Promise((resolve, reject) => {
      console.log("Generating PDF...");

      const doc = new jsPDF();

      // Set up the header
      doc.setFontSize(24);
      doc.setTextColor(0, 0, 0);
      doc.text("Gulfin", 10, 20);
      doc.setFontSize(14);
      doc.setTextColor(100, 100, 100);
      doc.text("Find the Best Property in Your Country", 10, 30);

      // Horizontal line separator
      doc.setLineWidth(0.5);
      doc.line(10, 40, 200, 40);

      // Set up the main content
      doc.setFontSize(18);
      doc.setTextColor(0, 0, 0);
      doc.text(`Invoice for Property: ${data.property_name}`, 10, 50);

      // Table-like styling for details
      const tableData = [
        { label: "Name", value: data.name },
        { label: "Email", value: data.email },
        { label: "Phone Number", value: data.phone_number },
        { label: "Amount", value: `$${data.amount}` },
      ];

      let yOffset = 60;
      doc.setFontSize(14);
      tableData.forEach((item) => {
        doc.text(`${item.label}:`, 10, yOffset);
        doc.setTextColor(100, 100, 100);
        doc.text(item.value, 60, yOffset);
        doc.setTextColor(0, 0, 0);
        yOffset += 10;
      });

      // Horizontal line separator
      doc.setLineWidth(0.5);
      doc.line(10, yOffset + 5, 200, yOffset + 5);
      yOffset += 15;

      // Bank details
      doc.setFontSize(16);
      doc.text("Bank Details", 10, yOffset);
      doc.setFontSize(14);
      const bankDetailsLines = doc.splitTextToSize(data.bank_details, 180);
      doc.text(bankDetailsLines, 10, yOffset + 10);

      // Footer
      doc.setFontSize(12);
      doc.setTextColor(100, 100, 100);
      doc.text("Thank you for your business!", 10, 290);

      // Convert PDF to Blob
      const pdfBlob = doc.output("blob");
      resolve(pdfBlob);
    });
  };

  const uploadPDF = async (pdfBlob) => {
    const pdfData = new FormData();
    pdfData.append("files", pdfBlob, "invoice.pdf");

    try {
      const response = await axios.post(
        "https://admin.gulfin.ai/api/upload",
        pdfData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("PDF upload failed");
      }

      return response;
    } catch (error) {
      console.error("PDF upload error:", error);
      throw error;
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-4 rounded shadow-lg w-96">
        <h2 className="text-xl font-semibold mb-4">Create Invoice</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-2">
            <label className="block text-gray-700">Name</label>
            <input
              type="text"
              name="name"
              value={invoiceData.name}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div className="mb-2">
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              value={invoiceData.email}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div className="mb-2">
            <label className="block text-gray-700">Phone Number</label>
            <input
              type="text"
              name="phone_number"
              value={invoiceData.phone_number}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div className="mb-2">
            <label className="block text-gray-700">Amount</label>
            <input
              type="text"
              name="amount"
              value={invoiceData.amount}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div className="mb-2">
            <label className="block text-gray-700">Property Name</label>
            <input
              type="text"
              name="property_name"
              value={invoiceData.property_name}
              onChange={handleChange}
              readOnly
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div className="mb-2">
            <label className="block text-gray-700">Payment Details</label>
            <textarea
              name="bank_details"
              value={invoiceData.bank_details}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded"
            />
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="button"
              onClick={onClose}
              className="mr-2 px-4 py-2 bg-gray-500 text-white rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 mr-2 bg-blue-500 text-white rounded"
            >
              Send Invoice and Convert
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded"
            >
              Convert
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InvoicePopup;
