import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchLeads,
  fetchMyUserLeads,
  fetchUserLeads,
  fetchUserLeadsPersonal,
} from "../services/api";
import axios from "axios";
const initialState = {
  leadsData: [],
  leadsUserPersonalData: [],
  leadsStatus: "idle",
  leadsUserStatuspersonal: "idle",
  leadsError: null,
  leadsCurrentPage: 1,
  leadsTotalPages: 1,
  leadsUserData: [],
  leadsUserStatus: "idle",
  leadsUserError: null,
  leadsUserCurrentPage: 1,
  leadsUserTotalPages: 1,
  leadsUserTotalPagespersonal: 1,
};
const API = process.env.REACT_APP_BACKEND_DOMAIN;

const generateRandomDigits = () => {
  return Math.floor(100 + Math.random() * 900);
};

const loggedInUser = JSON.parse(localStorage.getItem("user"));
const LogedInUserid = loggedInUser && loggedInUser.id;
export const getLeads = createAsyncThunk(
  "leads/getLeads",
  async ({ page, pageSize }) => {
    const leads = await fetchLeads(page, pageSize);
    return leads;
  }
);

export const getUserLeads = createAsyncThunk(
  "leads/getUserLeads",
  async ({ page, pageSize, userId, pageName }) => {
    const leads = await fetchMyUserLeads(page, pageSize, userId, pageName);
    return leads;
  }
);

export const getLeadUserpersonal = createAsyncThunk(
  "leads/getUserLeadsPersonal",
  async ({ page, pageSize, userenquires }) => {
    const leads = await fetchUserLeadsPersonal(page, pageSize, userenquires);
    return leads;
  }
);

// create a new lead
export const createLeads = createAsyncThunk(
  "leads/createLeads",
  async ({ leadsData, selectedListingMatterMostId }, { rejectWithValue }) => {
    try {
      // create mattermost user
      const mattermostPayload = {
        email: leadsData.data.email,
        username: leadsData.data.username,
        password: `${leadsData.data.client_name.split(" ").join("")}@123`,
        team_id: "ix1bdn6bwpbd9gak17r9eydurc",
        channel_id: selectedListingMatterMostId,
        message: `${leadsData.data.client_name} took interest in your listing`,
        root_id: null,
      };

      const createLoginTeamChannelPostMattermost = await axios.post(
        `${API}api/lead-api-mattermost
        `,
        mattermostPayload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(createLoginTeamChannelPostMattermost.data);

      leadsData.data.mattermost_lead_id =
        createLoginTeamChannelPostMattermost.data.mattermostPostId;
      leadsData.data.mattermost_channel_id = selectedListingMatterMostId;

      // lead user

      const existingUserResponse = await axios.get(
        `${API}api/users?filters[email][$eq]=${leadsData.data.email}`
      );


      let newUserid;

      console.log(newUserid);
      
      if (existingUserResponse.data.length !== 0) {
        newUserid = existingUserResponse.data[0].id;
        console.log("User already exists, using existing user ID:", newUserid);
      } else {
        const userResponse = await axios.post(`${API}api/auth/local/register`, {
          username: `${leadsData.data.client_name
            .split(" ")
            .join("")}${generateRandomDigits()}`,
          email: leadsData.data.email,
          password: `${leadsData.data.client_name.split(" ").join("")}@123`,
          confirmed: false,
          mattermost_user_id:
            createLoginTeamChannelPostMattermost.data.mattermostUserId,
          mattermost_session_token_id:
            createLoginTeamChannelPostMattermost.data.mattermostSessionToken,
        });

        console.log(userResponse);

        const data = userResponse.data;
        console.log(data);
        newUserid = data.user.id;
      }


      leadsData.data.user = {
        disconnect: [],
        connect: [
          {
            id: LogedInUserid,
            position: {
              end: true,
            },
          },
        ],
      };
      leadsData.data.userenquires = {
        disconnect: [],
        connect: [
          {
            id: newUserid,
            position: {
              end: true,
            },
          },
        ],
      };
      // return response.data;
      const crm_lead_response = await axios.post(
        `${API}api/crm-leads`,
        leadsData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const updateLeads = createAsyncThunk(
  "leads/updateLeads",
  async ({ id, updatedLeadData }, { rejectWithValue }) => {
    try {
      console.log(updatedLeadData);
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-leads/${id}`,
        updatedLeadData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteLead = createAsyncThunk(
  "leads/deleteLeads",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-leads/${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const SendMessageForLeads = createAsyncThunk(
  "leads/sendMessageForLeads",
  async (payload, { rejectWithValue }) => {
    try {
      const SentMessages = await axios.post(
        `${API}api/crm-lead-mattermost`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const leadsSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {
    setPage(state, action) {
      state.leadsCurrentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLeads.pending, (state) => {
        state.leadsStatus = "loading";
      })
      .addCase(getLeads.fulfilled, (state, action) => {
        state.leadsStatus = "succeeded";
        state.leadsData = action.payload;
        state.leadsTotalPages = Math.ceil(
          action.payload.meta.pagination.total /
            action.payload.meta.pagination.pageSize
        );
      })
      .addCase(getUserLeads.fulfilled, (state, action) => {
        state.leadsUserStatus = "succeeded";
        state.leadsUserData = action.payload;
        state.leadsUserTotalPages = Math.ceil(
          action.payload.meta.pagination.total /
            action.payload.meta.pagination.pageSize
        );
      })
      .addCase(getLeadUserpersonal.fulfilled, (state, action) => {
        state.leadsUserStatuspersonal = "succeeded";
        state.leadsUserPersonalData = action.payload;
        state.leadsUserTotalPagespersonal = Math.ceil(
          action.payload.meta.pagination.total /
            action.payload.meta.pagination.pageSize
        );
      })
      .addCase(getLeads.rejected, (state, action) => {
        state.leadsStatus = "failed";
        state.leadsError = action.error.message;
      });
  },
});
export const { setPage } = leadsSlice.actions;
export default leadsSlice.reducer;
