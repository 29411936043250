import React, { useEffect, useState } from "react";
import { FaSave } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { UserViewDetails } from "../../common/models/userViewDetails";

export const AllLeadsPropertiesUserDetails = ({ leadsData }) => {
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);

  const handleViewDetails = (lead) => {
    setSelectedLead(lead);
    setShowUserModal(true);
  };

  if (!leadsData || leadsData.length === 0) {
    return <div>No leads data available.</div>;
  }

  return (
    <>
      <div className="flex flex-wrap">
        {leadsData.map((userleaddata, index) => {
          const listing = userleaddata.attributes.crm_listing?.data?.attributes;
          const users = userleaddata.attributes.leads_users?.data || [];
          const leadMessages = userleaddata.attributes.lead_message || "";
          return (
            <div key={index} className="w-full md:w-1/2 p-2">
              <div className="mb-4 p-4 border-2 flex flex-col">
                <div className="flex items-center w-full">
                  <div className="w-full">
                    <div className="flex items-center justify-between">
                      <h3 className="text-lg font-semibold">
                        {userleaddata.attributes.client_name}
                      </h3>
                      <FaSave className="text-2xl" />
                    </div>
                    <p className="text-gray-600">
                      {" "}
                      {userleaddata.attributes.email}
                    </p>
                    <p className="text-gray-600">
                      {" "}
                      {userleaddata.attributes.client_number}
                    </p>
                    <p className="pb-4">Enquiry For </p>
                    {listing ? (
                      <div className="flex items-center mb-4 bg-blue-100">
                        {/* <div className="flex-shrink-0 h-16 w-16 rounded-lg overflow-hidden border border-gray-300 mr-4">
                                        <img className="h-full w-full object-cover" src={properties.thumbnail_image?.data?.attributes?.url || ''} alt="listing" />
                                    </div> */}
                        <div>
                          <h3 className="text-lg font-semibold">
                            {listing.name}
                          </h3>
                          <div className="flex items-center">
                            {/* <IoLocationSharp className="text-gray-700 mr-2" /> */}
                            {/* <p>{properties.address}, {properties.size}</p> */}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="mb-4">No leads are available</div>
                    )}
                    <div className="flex justify-between items-center mb-4">
                      <h3 className="text-lg font-semibold">Message</h3>
                    </div>
                    <p>{userleaddata.attributes.lead_message}</p>
                  </div>
                </div>
                <div className="flex justify-end mt-4">
                  <button
                    className="border border-blue-500 rounded p-2 text-blue-500"
                    onClick={() => handleViewDetails(userleaddata)}
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {showUserModal && selectedLead && (
        <UserViewDetails
          lead={selectedLead}
          onClose={() => setShowUserModal(false)}
        />
      )}
    </>
  );
};

// {userleaddata.attributes.property_users.data.map((user, userIndex) => (
//     <div key={userIndex} className="mb-4 p-4 border-2 flex items-center justify-between mb-2">
//         <div className="flex items-center">
//             <div>
//                 <div className='flex items-center justify-between'>
//                     <h3 className="text-lg font-semibold">{user.attributes.username}</h3>
//                     <FaSave className="text-2xl" />
//                 </div>
//                 <p className="text-gray-600">{user.attributes.email}</p>
//                 <p className='pb-4'>Enquiry For </p>
//                 <div className="flex items-center mb-4 bg-blue-100">
//                     <div className="flex-shrink-0 h-16 w-16 rounded-lg overflow-hidden border border-gray-300 mr-4">
//                         {/* <img className="h-full w-full object-cover" src={filteredProjects[0].attributes.image.data.attributes.url} alt="Project" /> */}
//                     </div>
//                     <div>
//                         {/* <h3 className="text-lg font-semibold">{filteredProjects[0].attributes.project_name}</h3> */}
//                         <div className="flex items-center">
//                             <IoLocationSharp className="text-gray-700 mr-2" />
//                             {/* <p>{filteredProjects[0].attributes.location},{filteredProjects[0].attributes.area}</p> */}
//                         </div>
//                     </div>
//                 </div>
//                 <div className="flex justify-between items-center mb-4">
//                     <h3 className="text-lg font-semibold">Message</h3>
//                     <button className="text-blue-500" onClick={() => handleViewDetails(user)}>View Details</button>
//                 </div>
//             </div>
//         </div>
//     </div>
// ))}
