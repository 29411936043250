import React from "react";
import { useDispatch } from "react-redux";
import { logout } from "../redux/authSlice";
import { useNavigate } from "react-router-dom";

export const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { user } = useSelector(selectAuth);

  const dummyUser = JSON.parse(localStorage.getItem("user"));
  console.log(dummyUser);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div className="px-4 py-6 sm:px-0">
        <h1 className="text-3xl font-bold text-gray-900">
          Welcome to the Home Page
        </h1>
        <p className="mt-2 text-xl text-gray-600">
          Hello, {dummyUser.username}!
        </p>

        <button
          onClick={handleLogout}
          className="mt-4 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          style={{ backgroundColor: "#407BFF" }}
        >
          Logout
        </button>
      </div>
    </div>
  );
};
