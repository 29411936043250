import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAllListings, fetchProjects, fetchUserProjects } from '../services/api';
import axios from 'axios';

const initialState = {
  projectsData: [],
  userProjectsData: [],
  projectStatus: 'idle',
  userProjectStatus: 'idle',
  projectError: null,
  userProjectError: null,
  projectCurrentPage: 1,
  userProjectCurrentPage: 1,
  projectTotalPages: 1,
  userProjectTotalPages: 1,
};

export const getProjects = createAsyncThunk(
  'projects/getProjects',
  async ({ page, pageSize }) => {
    console.log('Thunk getProjects called with:', page, pageSize);
    const projects = await fetchProjects(page, pageSize);
    console.log('projects in slice', projects);
    return projects;
  }
);

export const getAllListings = createAsyncThunk(
  'projects/getAllListings',
  async ({ page, pageSize }) => {
    const projects = await fetchAllListings(page, pageSize);
    return projects;
  }
);



export const getUserProjects = createAsyncThunk(
  'projects/getUserProjects',
  async ({ page, pageSize, userId }) => {
    console.log('Thunk getProjects called with:', page, pageSize, userId);
    const projects = await fetchUserProjects(page, pageSize, userId);
    return projects;
  }
);

export const createProjects = createAsyncThunk(
  'projects/createProjects',
  async (projectsData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-projects`, projectsData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateProjects = createAsyncThunk(
  'projects/updateProjects',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-projects/${id}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteProject = createAsyncThunk(
  'property/deleteProject',
  async (id, { rejectWithValue }) => {
    try {
  const response = await axios.delete(`${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-projects/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProjectPage(state, action) {
      state.projectCurrentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjects.pending, (state) => {
        state.projectStatus = 'loading';
      })
      .addCase(getProjects.fulfilled, (state, action) => {
        state.projectStatus = 'succeeded';
        state.projectsData = action.payload;
        state.projectTotalPages = Math.ceil(action.payload.meta.pagination.total / action.payload.meta.pagination.pageSize);
      })
      .addCase(getUserProjects.fulfilled, (state, action) => {
        state.userProjectStatus = 'succeeded';
        state.userProjectsData = action.payload;
        state.userProjectTotalPages = Math.ceil(action.payload.meta.pagination.total / action.payload.meta.pagination.pageSize);
      })
      .addCase(getProjects.rejected, (state, action) => {
        state.projectStatus = 'failed';
        state.projectError = action.error.message;
      });
  },
});

export const { setProjectPage } = projectsSlice.actions;
export default projectsSlice.reducer;
