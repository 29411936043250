import React from 'react';

export const SubHeader = ({ activeItem, onNavigate }) => {
  return (
    <div className="bg-white py-3 px-5 mb-2.5 shadow-md rounded-lg">
      <div className="flex items-center justify-between">
        <div className="flex space-x-4 flex-grow font-medium">
          {/* <SubHeaderItem
            title="My Feed"
            isActive={activeItem === 'Post'}
            onClick={() => onNavigate('Post')}
          /> */}
          <SubHeaderItem
            title="Analytics"
            isActive={activeItem === 'Analytics'}
            onClick={() => onNavigate('Analytics')}
          />
          <SubHeaderItem
            title="My Inventory"
            isActive={activeItem === 'Inventory'}
            onClick={() => onNavigate('Inventory')}
          />
          <SubHeaderItem
            title="My Listing"
            isActive={activeItem === 'Listing'}
            onClick={() => onNavigate('Listing')}
          />
          <SubHeaderItem
            title="Lead"
            isActive={activeItem === 'Lead'}
            onClick={() => onNavigate('Lead')}
          />
         <SubHeaderItem
            title="Explore"
            isActive={activeItem === 'Explore'}
            onClick={() => onNavigate('Explore')}
          />
        </div>
      </div>
    </div>
  );
};

const SubHeaderItem = ({ title, isActive, onClick, extraClass = '' }) => {
  return (
    <div
      onClick={onClick}
      className={`cursor-pointer text-black hover:text-gray-900 px-4 ${extraClass} ${isActive ? 'text-blue-500 border-b-2 border-blue-500' : ''}`}
    >
      {title}
    </div>
  );
};
