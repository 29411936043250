import React, { useEffect, useState } from 'react';
import { SubHeader } from './subHeader';
import { Post } from '../../components/Post/post';
import { MessagesPage, MessagesPageInCommon } from '../../components/Messages/messages';
import { AllLeadsMain } from '../../pages/Properties/Leads/allLeads/AllLeads';
import { useDispatch, useSelector } from 'react-redux';
import { getProperties, setPage } from '../../redux/propertiesSlice';
import { getProjects } from '../../redux/proejctsSlice';
import { TrackSystemForProperties } from '../Properties/Leads/TrackSystemForProperties';
// import { fetchFeed } from '../../services/api';

export const CommunicationPage = () => {
  const dispatch = useDispatch();
  // const { inventoryData, inventoryStatus, inventoryError } = useSelector((state) => state.inventory);
  // const { exploreData, exploreStatus, exploreError } = useSelector((state) => state.explore);
  const { data: properties1, status, error, currentPage, totalPages } = useSelector((state) => state.properties);
  const { projectsData, projectStatus, projectError, projectCurrentPage, projectTotalPages } = useSelector((state) => state.projects);
  const [activeItem, setActiveItem] = useState('Message');

  // useEffect(() => {
  //   dispatch(getProperties({ page: currentPage, pageSize: 10 }));
  //   // dispatch(getProjects({ page: projectCurrentPage, pageSize: 10 }));
  //   // console.log(projectsData, 'projectsData');
  //   console.log(properties1, 'propertiesData');
  // }, [dispatch, currentPage]);

  useEffect(() => {
    dispatch(getProperties({ page: currentPage, pageSize: 10 }));
    dispatch(getProjects({ page: projectCurrentPage, pageSize: 10 }));
  }, [dispatch, currentPage, projectCurrentPage]);


    

  const handlePageChange = (newPage) => {
    dispatch(setPage(newPage));
  };

  return (
    <div className="Projects-page bg-gray-100 min-h-screen p-1">
      <SubHeader activeItem={activeItem} onNavigate={setActiveItem} />
      {activeItem === 'Message' && <MessagesPageInCommon />}
      {/* {activeItem === 'Lead' && <AllLeadsMain projectsData={projectsData.data} properties1={properties1} />}
      {activeItem === 'LeadsTrackingSystem' && <TrackSystemForProperties projectsData={projectsData.data} properties1={properties1}/>} */}
    </div>
  );
};
