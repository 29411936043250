// src/services/api.js
import axios from "axios";

const API_URL = "https://admin.gulfin.ai/api/";

// login
export const loginUser = async (credentials) => {
  const response = await axios.post(`${API_URL}/login`, credentials);
  return response.data;
};

// register
export const registerUser = async (userData) => {
  const response = await axios.post(`${API_URL}/register`, userData);
  return response.data;
};
export const GetLoggedInUserData = async () => {
  const response = await axios.get(`${API_URL}users?populate[0]=crm_leads`);

  return response.data;
};

// dashboard
export const fetchDashboardData = async () => {
  const response = await axios.get(`${API_URL}/dashboard`);
  return response.data;
};

export const fetchUserDetailsFromId = async (userId) => {
  const response = await axios.get(
    `${API_URL}users/${userId}?populate[0]=name&populate[1]=username&populate[2]=id&populate[3]=profilePicture&populate[4]=mattermost_session_token_id`
  );
  return response.data;
};

// properties api
// export const fetchProperties = async (page, pageSize) => {
//   try {
//     const response = await fetch(`${API_URL}crm-properties?populate=*`);
//     if (!response.ok) {
//       throw new Error('Network response was not ok');
//     }
//     const data = await response.json();
//     return data;
//   } catch (error) {
//     console.error('Error fetching properties:', error);
//     throw error;
//   }
// };
export const fetchFeed = async (page, pageSize) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_DOMAIN}api/feeds?populate[0]=image&populate[1]=user&populate[2]=property&populate[3]=project&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort=publishedAt:desc`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    console.log("get properties 1 from without user", data);
    return data;
  } catch (error) {
    console.error("Error fetching properties:", error);
    throw error;
  }
};

export const fetchProperties = async (page, pageSize) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-properties?&populate[0]=project_id&populate[1]=thumbnail_image&populate[2]=property_users&pagination[page]=${page}&pagination[pageSize]=${pageSize}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    console.log("get propertyes 1 from without useer", data);
    return data;
  } catch (error) {
    console.error("Error fetching properties:", error);
    throw error;
  }
};

export const fetchUserProperties = async (page, pageSize, userId) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-properties?populate[0]=project_id&populate[1]=thumbnail_image&populate[2]=property_users&pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[property_user][$eq]=${userId}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching properties:", error);
    throw error;
  }
};

export const fetchListings = async (page, pageSize) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-listings?populate[0]=property_id&populate[1]=thumbnail_image&populate[2]=property_users&pagination[page]=${page}&pagination[pageSize]=${pageSize}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching listing:", error);
    throw error;
  }
};

export const fetchUserListing = async (page, pageSize, userId) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-listings?populate[0]=property_id&populate[1]=thumbnail_image&populate[2]=user&pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[user][$eq]=${userId}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching properties:", error);
    throw error;
  }
};

export const fetchLeads = async (page, pageSize) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-leads?populate[0]=crm_properties&populate[1]=crm_listings&populate[2]=leads_user&populate[3]=crm_service_listings&pagination[page]=${page}&pagination[pageSize]=${pageSize}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    console.log(data, "leads api function is calling");
    return data;
  } catch (error) {
    console.error("Error fetching listing:", error);
    throw error;
  }
};

export const fetchMyUserLeads = async (page, pageSize, userId) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-leads?populate[0]=user&populate[1]=crm_listings&pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[user][$eq]=${userId}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    console.log(data, "leads api function is calling");
    return data;
  } catch (error) {
    console.error("Error fetching listing:", error);
    throw error;
  }
};

export const fetchUserLeadsPersonal = async (page, pageSize, userenquires) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-leads?populate[0]=user&pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[userenquires][$eq]=${userenquires}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    console.log(data, "leads api function is calling");
    return data;
  } catch (error) {
    console.error("Error fetching listing:", error);
    throw error;
  }
};

export const fetchProjects = async (page, pageSize) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-projects?populate=image&pagination[page]=${page}&pagination[pageSize]=${pageSize}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching properties:", error);
    throw error;
  }
};

export const fetchAllListings = async (page, pageSize) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-listings?populate=thumbnail_image&pagination[page]=${page}&pagination[pageSize]=${pageSize}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching properties:", error);
    throw error;
  }
};

export const fetchUserProjects = async (page, pageSize, userId) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-projects?populate=image,location&pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[user][$eq]=${userId}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching properties:", error);
    throw error;
  }
};

export const fetchServices = async (page, pageSize) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-services?populate[0]=image&pagination[page]=${page}&pagination[pageSize]=${pageSize}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    console.log(data, "services api function is calling");
    return data;
  } catch (error) {
    console.error("Error fetching listing:", error);
    throw error;
  }
};

export const fetchUserServices = async (page, pageSize, userId) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-services?populate[0]=image&pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[user][$eq]=${userId}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    console.log(data, "my logged In user services");
    return data;
  } catch (error) {
    console.error("Error fetching listing:", error);
    throw error;
  }
};

export const fetchServicesListings = async (page, pageSize) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-service-listings?populate[0]=services_id&populate[1]=thumbnail_image&populate[2]=property_users&pagination[page]=${page}&pagination[pageSize]=${pageSize}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    console.log(data, "services listings api function is calling");
    return data;
  } catch (error) {
    console.error("Error fetching listing:", error);
    throw error;
  }
};
export const fetchServicesUserListings = async (page, pageSize, userId) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-service-listings?populate[0]=services_id&populate[1]=thumbnail_image&populate[2]=property_users&pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[user][$eq]=${userId}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    console.log(data, "services listings api function is calling");
    return data;
  } catch (error) {
    console.error("Error fetching listing:", error);
    throw error;
  }
};

//  Services
export const fetchServicesData = async () => {
  const response = await axios.get(`${API_URL}/services`);
  return response.data;
};
