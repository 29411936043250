import React, { useState, useEffect, useRef, useMemo } from "react";
import axios from "axios";
import { useLoadScript } from "@react-google-maps/api";

import Loader from "./Loader";
import MultiColumnDropdown from "./common/models/multiColumnDropdown";
import MultiDeveloperDropdown from "./common/models/multiDeveloperDropdown";
export const AddProjectModal = ({
  isOpen,
  onClose,
  onSubmit,
  setFormAction,
  project = {},
}) => {
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const developer = loggedInUser.username;

  const [uploading, setUploading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCityId, setSelectedCityId] = useState(null);
  const [projectState, setProjectState] = useState("Project");

  useEffect(() => {
    console.log(project, "project");
  }, [project]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
    libraries: ["places"],
  });

  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
  };

  const onDragEnd = (event) => {
    const position = event.latLng.toJSON();
    setFormData((prevData) => ({
      ...prevData,
      latitude: position.lat,
      longitude: position.lng,
    }));
  };

  const inputRef = useRef(null);

  useEffect(() => {
    const initAutocomplete = async () => {
      console.log("here");

      if (window.google) {
        console.log("here");

        const autocomplete = new window.google.maps.places.Autocomplete(
          inputRef.current
        );

        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          if (place && place.formatted_address) {
            const { location } = place.geometry;
            setFormData((prevData) => ({
              ...prevData,
              address: place.formatted_address,
              latitude: location.lat(),
              longitude: location.lng(),
            }));
            const map = new window.google.maps.Map(
              document.getElementById("map"),
              {
                center: { lat: location.lat(), lng: location.lng() },
                zoom: 14,
                options: mapOptions,
              }
            );

            const marker = new window.google.maps.Marker({
              map,
              position: { lat: location.lat(), lng: location.lng() },
              draggable: true,
            });

            marker.addListener("dragend", onDragEnd);
          }
        });
      }
    };

    if (isOpen) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
      script.async = true;
      script.onload = initAutocomplete;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }

    const fetchCities = async () => {
      try {
        const response = await fetch(
          "https://admin.gulfin.ai/api/cities?pagination[page]=1&pagination[pageSize]=1000"
        );
        const data = await response.json();
        setCities(data.data);
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };

    fetchCities();
  }, [isOpen, mapOptions]);

  const handleInputClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleCitySelect = (city, id) => {
    setSelectedCity(city);
    setSelectedCityId(id);
    setShowDropdown(false);
  };

  const [formData, setFormData] = useState({
    project_name: "",
    address: "",
    location: "",
    area_units: "",
    area_length: "",
    area_in_meter: "",
    project_type: "",
    buildings: [{ name: "", floors: "", flats: "" }],
    elevation: "",
    underground_parking: "",
    wall_materials: "",
    userDeveloper: "",
    description: "",
    image: null,
    permit_number: "",
    ded_number: "",
    rera_number: "",
    brn_number: "",
    project_status: "",
    completion_date: "",
    user: {
      disconnect: [],
      connect: [
        {
          id: loggedInUser.id,
          position: {
            end: true,
          },
        },
      ],
    },
  });

  const handleBuildingInputChange = (index, field, value) => {
    setFormData((prevData) => {
      const newBuildings = [...prevData.buildings];
      newBuildings[index][field] = value;
      return { ...prevData, buildings: newBuildings };
    });
  };

  const addNewBuilding = () => {
    setFormData((prevData) => ({
      ...prevData,
      buildings: [...prevData.buildings, { name: "", floors: "", flats: "" }],
    }));
  };

  useEffect(() => {
    if (project && Object.keys(project).length > 0) {
      setFormAction("edit");
      setFormData({
        project_name: project.project_name || "",
        address: project.address || "",
        location: project.location?.attributes?.Name || "",
        area_units: project.area_units || "",
        area_length: project.area_length || "",
        area_in_meter: project.area_in_meter || "",
        project_type: project.project_type || "",
        buildings: project.buildings || [{ name: "", floors: "", flats: "" }],
        elevation: project.elevation || "",
        underground_parking: project.underground_parking || "",
        wall_materials: project.wall_materials || "",
        userDeveloper: project.developer || "",
        description: project.description || "",
        image: project.image?.data?.attributes?.url || null,
        project_status: project.project_status || "",
        permit_number: project.permit_number || "",
        ded_number: project.ded_number || "",
        rera_number: project.rera_number || "",
        brn_number: project.brn_number || "",
        completion_date: project.completion_date || "",
        user: {
          disconnect: [],
          connect: [
            {
              id: loggedInUser.id,
              position: {
                end: true,
              },
            },
          ],
        },
      });
    } else {
      setFormData({
        project_name: "",
        location: "",
        address: "",
        area_units: "",
        area_length: "",
        area_in_meter: "",
        project_type: "",
        buildings: [{ name: "", floors: "", flats: "" }],
        elevation: "",
        underground_parking: "",
        wall_materials: "",
        userDeveloper: "",
        description: "",
        image: null,
        permit_number: "",
        ded_number: "",
        rera_number: "",
        brn_number: "",
        project_status: "",
        completion_date: "",
        user: {
          disconnect: [],
          connect: [
            {
              id: loggedInUser.id,
              position: {
                end: true,
              },
            },
          ],
        },
      });
    }
  }, [project, setFormAction]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      location: selectedCity?.attributes?.Name || "",
    }));
  }, [selectedCity]);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    const validImageTypes = [
      "image/png",
      "image/jpeg",
      "image/svg+xml",
      "image/gif",
      "image/bmp",
      "image/webp",
    ];

    if (!validImageTypes.includes(file.type)) {
      alert("Invalid image type");
      return;
    }

    const imageData = new FormData();
    imageData.append("files", file);

    setUploading(true);

    try {
      const response = await axios.post(
        "https://admin.gulfin.ai/api/upload",
        imageData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("Image upload failed");
      }

      const result = response.data;
      const imageUrl = result[0].id;
      setFormData((prevFormData) => ({
        ...prevFormData,
        image: imageUrl,
      }));

      e.target.value = "";
    } catch (error) {
      console.error("Image upload error:", error);
    } finally {
      setUploading(false);
    }
  };

  const convertToMeters = (value, unit) => {
    return unit === "feet" ? value * 0.3048 : value; // Conversion factor: 1 foot = 0.3048 meters
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const areaLength = parseFloat(formData.area_length);
    let areaInMeter = "";

    if (formData.area_units === "feet") {
      areaInMeter = convertToMeters(areaLength, "feet").toString();
    } else if (formData.area_units === "meter") {
      areaInMeter = areaLength.toString();
    }

    let payload = {
      project_name: formData.project_name,
      location: {
        disconnect: [],
        connect: [
          {
            id: selectedCityId,
            position: {
              end: true,
            },
          },
        ],
      },
      address: formData.address,
      area_unit: formData.area_units,
      area_length: formData.area_length,
      area_in_meter: areaInMeter,
      project_type: formData.project_type,
      buildings: formData.buildings,
      elevation: formData.elevation,
      underground_parking: formData.underground_parking,
      wall_materials: formData.wall_materials,
      developer: formData.userDeveloper,
      description: formData.description,
      image: formData.image,
      project_status: formData.project_status,
      permit_number: formData.permit_number,
      ded_number: formData.ded_number,
      area_number: formData.rera_number,
      brn_number: formData.brn_number,
      completion_date: formData.completion_date,
      user: {
        disconnect: [],
        connect: [
          {
            id: loggedInUser.id,
            position: {
              end: true,
            },
          },
        ],
      },
    };
    onSubmit({ data: payload });
    onClose();
  };
  // const options = {
  //   residential: [
  //     "Villa",
  //     "Townhouse",
  //     "Villa Compound",
  //     "Residential Plot",
  //     "Residential Floor",
  //   ],
  //   commercial: [
  //     "Pent House",
  //     "Hotel Apartment",
  //     "Apartment",
  //     "Other Residential",
  //   ],
  // };

  const developerOptions = [
    { id: 1, name: developer },
    { id: 2, name: "Byut builders" },
    { id: 3, name: "Nilachal builders" },
    { id: 4, name: "Sky Apartment builders" },
    { id: 5, name: "Rainbow Complex builders" },
    { id: 6, name: "Binghatti builders" },
    { id: 7, name: "Kalinga builders" },
  ];

  const handleDropdownChange = (selectedValue) => {
    setFormData((prevData) => ({
      ...prevData,
      userDeveloper: selectedValue,
    }));
  };

  if (!isOpen) return null;
  if (!isLoaded) return <div>Loading...</div>;

  const mapHeight = formData.address ? "300px" : "0";

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-4 rounded-lg shadow-lg w-full max-w-2xl overflow-auto h-5/6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">
            {setFormAction === "edit" ? "Edit Project" : "Add a New Project"}
          </h2>
          <button
            onClick={onClose}
            className="text-3xl text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
        </div>
        <form onSubmit={handleFormSubmit}>
          <div className="mb-4 flex space-x-4">
            <div className="w-full">
              <label className="block text-sm font-medium mb-1">
                Project Name <span className="text-red-500">*</span>{" "}
              </label>
              <input
                type="text"
                name="project_name"
                value={formData.project_name}
                onChange={handleInputChange}
                className="w-full border rounded p-2"
              />
            </div>

            {/* <div className="w-1/2">
              <label className="block text-sm font-medium mb-1">
                Select Type <span className="text-red-500">*</span>
              </label>

              <MultiColumnDropdown
                options={options}
                value={formData.project_type}
                onChange={handleDropdownChange}
                propertyState={projectState}
              />
            </div> */}
          </div>

          <div className="mb-4 w-full flex items-center gap-x-5">
            <div
              className={`${
                formData.project_status === "Offplan" ? "w-1/2" : "w-full"
              }`}
            >
              <label className="block text-sm font-medium mb-1">
                Project Status <span className="text-red-500">*</span>{" "}
              </label>
              <select
                name="project_status"
                value={formData.project_status}
                onChange={handleInputChange}
                className={`p-2 border rounded w-full`}
                required
              >
                <option value="">Select Status</option>
                <option value="Ready">Ready</option>
                <option value="Offplan">Off Plan</option>
              </select>
            </div>
            {formData.project_status === "Offplan" && (
              <div className="w-1/2">
                <label className="block text-sm font-medium mb-1">
                  Completion Date <span className="text-red-500">*</span>{" "}
                </label>
                <input
                  type="completion_date"
                  name="completion_date"
                  value={formData.completion_date}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Developer <span className="text-red-500">*</span>{" "}
            </label>

            <MultiDeveloperDropdown
              options={developerOptions}
              value={formData.userDeveloper}
              onChange={handleDropdownChange}
            />
            {/* <input
              type="text"
              name="userDeveloper"
              value={formData.userDeveloper}
              onChange={handleInputChange}
              className="w-full border rounded p-2"
            /> */}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Buildings</label>
            {formData.buildings.map((building, index) => (
              <div key={index} className="grid grid-cols-3 gap-4 mb-2">
                <input
                  type="text"
                  placeholder="Building Name"
                  value={building.name}
                  onChange={(e) =>
                    handleBuildingInputChange(index, "name", e.target.value)
                  }
                  className="w-full p-2 border rounded"
                />
                <input
                  type="number"
                  placeholder="No. of Floors"
                  value={building.floors}
                  onChange={(e) =>
                    handleBuildingInputChange(index, "floors", e.target.value)
                  }
                  className="w-full p-2 border rounded"
                />
                <input
                  type="number"
                  placeholder="No. of Flats"
                  value={building.flats}
                  onChange={(e) =>
                    handleBuildingInputChange(index, "flats", e.target.value)
                  }
                  className="w-full p-2 border rounded"
                />
              </div>
            ))}
            <button
              type="button"
              onClick={addNewBuilding}
              className="mt-2 bg-blue-500 text-white py-1 px-3 rounded"
            >
              Add Building
            </button>
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Wall Materials <span className="text-red-500">*</span>{" "}
            </label>
            <select
              name="wall_materials"
              value={formData.wall_materials}
              onChange={handleInputChange}
              className="w-full border rounded p-2"
              required
            >
              <option value="">Select Wall Material</option>
              <option value="Monolith">Monolith</option>
              <option value="Concrete">Concrete</option>
              <option value="Brick">Brick</option>
              <option value="Wood">Wood</option>
              <option value="Steel">Steel</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Elevation <span className="text-red-500">*</span>{" "}
            </label>
            <select
              name="elevation"
              value={formData.elevation}
              onChange={handleInputChange}
              className="w-full border rounded p-2"
            >
              <option value="">Select type</option>
              <option value="elevator">Elevator</option>
              <option value="stairs">Stairs</option>
              <option value="both">Both</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Underground Parking <span className="text-red-500">*</span>{" "}
            </label>
            <select
              name="underground_parking"
              value={formData.underground_parking}
              onChange={handleInputChange}
              className="w-full border rounded p-2"
            >
              <option value="">Select</option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Description <span className="text-red-500">*</span>{" "}
            </label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              className="w-full border rounded p-2"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium mb-4">
              Add Image <span className="text-red-500">*</span>{" "}
            </label>
            <input
              type="file"
              onChange={handleImageChange}
              className="hidden"
              id="file-input"
            />
            <label
              htmlFor="file-input"
              className="cursor-pointer text-blue-500 border-blue-300 border-2 p-1.5 px-10 rounded hover:text-white hover:bg-blue-500 hover:border-blue-500 transition-colors ease-linear duration-200"
            >
              Choose File
            </label>
            {uploading && (
              <div className="flex justify-center items-center">
                <Loader />
              </div>
            )}
            {formData.image && <div className="mt-2">Image Uploaded</div>}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Project Location <span className="text-red-500">*</span>{" "}
            </label>
            <input
              type="text"
              name="address"
              ref={inputRef}
              value={formData.address}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>

          <div className="mb-4">
            <label
              className={`${
                formData.address ? "block" : "hidden"
              }  text-sm font-medium mb-1`}
            >
              Project Location
            </label>
            <div
              id="map"
              style={{ height: mapHeight, transition: "height 0.3s ease" }}
            ></div>
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Permit Number and Document
            </label>
            <input
              type="text"
              name="permit_number"
              value={formData.permit_number}
              onChange={handleInputChange}
              className="w-full border rounded p-2"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">DED Number</label>
            <input
              type="text"
              name="ded_number"
              value={formData.ded_number}
              onChange={handleInputChange}
              className="w-full border rounded p-2"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              RERA Number
            </label>
            <input
              type="text"
              name="rera_number"
              value={formData.rera_number}
              onChange={handleInputChange}
              className="w-full border rounded p-2"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">BRN Number</label>
            <input
              type="text"
              name="brn_number"
              value={formData.brn_number}
              onChange={handleInputChange}
              className="w-full border rounded p-2"
            />
          </div>

          <div className="flex justify-end">
            <button
              type="reset"
              onClick={onClose}
              className="bg-gray-200 text-gray-700 py-2 px-4 rounded mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`bg-blue-500 text-white py-2 px-4 rounded ${
                formData.address
                  ? "bg-blue-500"
                  : "bg-gray-200 cursor-not-allowed"
              }`}
              disabled={!formData.address}
            >
              {setFormAction === "edit" ? "Update" : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
