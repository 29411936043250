import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import styles from "./charts.module.css";
import { useSelector } from "react-redux";
export const DashboardCharts = ({ profitByMonth, incomeBreakdown, currentMonthNames }) => {

  const { serviceIncome, rentalIncome, propertyIncome } = incomeBreakdown;
  const isSidebarCollapsed = useSelector((state) => state.sidebar.isCollapsed);
  // let lineChartWidth = isSidebarCollapsed ? 770 : 650;
  let donutChartWidth = isSidebarCollapsed ? 405 : 350;
  // Line Chart Data
  const lineChartOptions = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false, // Hide toolbar if not needed
      },
    },
    colors: ["#40FF6A", "#2163f6"], // Change line color to #40FF6A
    stroke: {
      curve: "smooth", // Make the line a soft curve
    },
    xaxis: {
      categories: currentMonthNames, // Adjust as per the months available
    },
    yaxis: {
      labels: {
        formatter: (value) => `$${parseInt(value,10)}`,
      },
    },
  };

  const lineChartSeries = [
    {
      name: "Total Sales",
      data: profitByMonth.totalSales,
    },
    {
      name: "Total Conversions",
      data: profitByMonth.totalConversions,
    },
  ];

  // Donut Chart Data
  const donutChartOptions = {
    chart: {
      type: "donut",
    },
    colors: ["#4e85ff", "#2163f6", "#003cc1"],
    labels: ["Services", "Rental", "Properties"],
    dataLabels: {
      enabled: false, // Disable the data labels
    },
    legend: {
      show: true,
      position: "bottom", // Move the legend to the bottom
      horizontalAlign: "left", // Align the legend to the left
      fontSize: "14px",
      fontWeight: "bold",
      formatter: function (val, opts) {
        const total = opts.w.config.series.reduce((a, b) => a + b, 0);
        const percentage = (
          (opts.w.config.series[opts.seriesIndex] / total) *
          100
        ).toFixed(2);
        return `${val}: ${percentage}%`;
      },
    },
  };

  const donutChartSeries = [serviceIncome, rentalIncome, propertyIncome];

  return (
    <div className="w-[200%] mt-5">
      <div className={styles.inlineCharts}>
        <div className={styles.chartBox}>
          <div className={styles.chartTitle}>Profit by Months</div>
          <ReactApexChart
            options={lineChartOptions}
            series={lineChartSeries}
            type="line"
            height={300}
            width={1200}
          />
        </div>
        {/* <div className={styles.chartBox}>
          <div className={styles.chartTitle}>Total Income</div>
          <ReactApexChart
            options={donutChartOptions}
            series={donutChartSeries}
            type="donut"
            height={240}
            width={donutChartWidth}
          />
        </div> */}
      </div>
    </div>
  );
};
