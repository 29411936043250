import {
  FaParking,
  FaTree,
  FaLock,
  FaBed,
  FaWifi,
  FaChair,
  FaWater,
  FaEdit,
  FaTrash,
  FaSwimmingPool,
  FaDumbbell,
  FaShare,
} from "react-icons/fa";
import { IoIosShareAlt } from "react-icons/io";
import React, { useState, useEffect } from "react";
import { IoLocationSharp, IoShareSocialSharp } from "react-icons/io5";
import { BiCloset } from "react-icons/bi";
import { BsTextarea } from "react-icons/bs";
import { MdOutlineGasMeter } from "react-icons/md";
import { X } from "lucide-react";
// import { DetailsCard } from '../cards/DetailsCard';

export const MyPropertiesListing = ({
  properties = [],
  onDelete,
  onEdit,
  containerWidth,
  borderOfContainer,
  refer,
}) => {
  const [clicks, setClicks] = useState({});
  const [expandedAmenities, setExpandedAmenities] = useState({});
  const [helpFormOpen, setHelpFormOpen] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://admin.gulfin.ai/api/enquiries", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: formData,
        }),
      });

      if (response.ok) {
        alert("We have recieved your query! You will be reached out soon.");
        setHelpFormOpen(false);
        setFormData({ name: "", email: "", message: "" });
      } else {
        alert("Failed to send message. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handleClick = (id) => {
    setClicks((prevClicks) => ({
      ...prevClicks,
      [id]: (prevClicks[id] || 0) + 1,
    }));
  };

  const handleExpandAmenities = (id) => {
    setExpandedAmenities((prevExpanded) => ({
      ...prevExpanded,
      [id]: !prevExpanded[id],
    }));
  };

  const handleHelpButtonClick = () => {
    setHelpFormOpen(true);
  };

  const handleDelete = (id) => {
    onDelete(id);
  };

  const handleEdit = (id) => {
    const property = properties.find((prop) => prop.id === id);
    onEdit(property);
  };

  const handleShare = (id) => {
    const propertyLink = `https://www.gulfin.ai/details?id=${id}&status=draft`;
    navigator.clipboard
      .writeText(propertyLink)
      .then(() => alert("Property link copied to clipboard"))
      .catch(() => alert("Failed to copy property link"));
  };

  const renderAmenityIcon = (amenityKey) => {
    switch (amenityKey) {
      case "Bed":
        return <FaBed className="text-black-700 mr-1" />;
      case "garden":
        return <FaTree className="text-black-500 mr-1" />;
      case "security":
        return <FaLock className="text-black-500 mr-1" />;
      case "gas":
        return <MdOutlineGasMeter className="text-black-500 mr-1" />;
      case "Wifi":
        return <FaWifi className="text-black-500 mr-1" />;
      case "chair":
        return <FaChair className="text-black-500 mr-1" />;
      case "water":
        return <FaWater className="text-black-500 mr-1" />;
      case "wardrobe":
        return <BiCloset className="text-black-500 mr-1" />;
      case "parking":
        return <FaParking className="text-black-500 mr-1" />;
      default:
        return null;
    }
  };

  if (!properties.length) {
    return <div>No properties available.</div>;
  }

  console.log(properties);

  return (
    <>
      {properties.map((property) => (
        <div
          key={property.id}
          className={`w-full md:w-${containerWidth} px-4 mb-4`}
        >
          <div className={`${borderOfContainer} bg-white h-full flex flex-col`}>
            <div className="flex flex-col">
              <div className="flex gap-x-2">
                <div className="relative w-48 h-48 mr-4 mb-4 md:mb-0">
                  {property.attributes?.thumbnail_image?.data?.attributes
                    ?.url ? (
                    <img
                      src={
                        property.attributes.thumbnail_image.data.attributes.url
                      }
                      alt={property.attributes.property_name}
                      className="w-full h-full object-cover rounded-md"
                    />
                  ) : (
                    <div className="w-full h-full bg-gray-200 flex items-center justify-center rounded-md">
                      No Image
                    </div>
                  )}
                  {refer !== "explore" && (
                    <div className="absolute top-2  right-2 flex space-x-2">
                      <button
                        onClick={() => handleEdit(property.id)}
                        className="bg-white p-1 rounded-full shadow"
                      >
                        <FaEdit className="text-blue-500" />
                      </button>
                      <button
                        onClick={() => handleDelete(property.id)}
                        className="bg-white p-1 rounded-full shadow"
                      >
                        <FaTrash className="text-red-500" />
                      </button>
                      <button
                        onClick={() => handleShare(property.id)}
                        className="bg-white p-1 rounded-full shadow"
                      >
                        <IoShareSocialSharp size={20} />
                        {/* Share Link */}
                      </button>
                      <button
                        onClick={() =>
                          window.open(
                            `https://www.gulfin.ai/details?id=${property.id}&status=draft`,
                            "_blank",
                            "noopener,noreferrer"
                          )
                        }
                        className="bg-white p-1 rounded-full shadow"
                      >
                        <IoIosShareAlt size={20} />
                      </button>
                    </div>
                  )}
                </div>

                <div className="flex flex-col">
                  <div className="w-full flex justify-between items-center gap-x-2">
                    <div className="text-2xl font-semibold">
                      {property.attributes?.property_name || "No Name"}
                    </div>

                    <div className="flex flex-col items-center text-blue-600">
                      <p>ID: {property.id}</p>
                      <button
                        onClick={() => handleClick(property.id)}
                        className="text-blue-600 text-nowrap"
                      >
                        Clicks: {clicks[property.id] || 0}
                      </button>
                    </div>
                  </div>
                  <p className="text-lg text-gray-700 flex gap-x-2">
                    <span>{property.attributes?.currency || "AED"}</span>

                    {property.attributes?.rent || "N/A"}
                  </p>
                  <div className="space-y-1">
                    <div className="flex items-center space-x-2">
                      <IoLocationSharp className="text-gray-700" />
                      <p className="">
                        {property.attributes?.property_address
                          ? `${property.attributes.property_address.slice(
                              0,
                              35
                            )}${
                              property.attributes.property_address.length > 35
                                ? "..."
                                : ""
                            }`
                          : "No Address"}
                      </p>
                    </div>

                    <div className="flex flex-wrap items-center">
                      {property.attributes?.property_size && (
                        <div className="flex items-center space-x-2 w-1/2">
                          <BsTextarea className="text-gray-700" />
                          <p>
                            {property.attributes.property_size}{" "}
                            {property.attributes.listing_size_unit}{" "}
                          </p>
                        </div>
                      )}
                    </div>

                    <div className="grid grid-cols-2 gap-x-5 h-[70px] pb-2 overflow-y-scroll">
                      {property.attributes.amenities &&
                        property.attributes.amenities.map((amenity) => (
                          <div key={amenity.id} className="flex items-center">
                            <span className="w-40 truncate">
                              {amenity.feature_name}
                            </span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <p className="text-gray-700">
                {property.attributes?.description &&
                property.attributes.description.length > 250
                  ? `${property.attributes.description.substring(0, 260)}...`
                  : property.attributes?.description || "No Description"}
              </p>
            </div>
            <div className="flex justify-center mt-10">
              <button
                onClick={() => handleHelpButtonClick()}
                className="flex items-center text-blue-500 border-blue-300 border-2 p-1.5 px-10 rounded hover:text-white hover:bg-blue-500 hover:border-blue-500 transition-colors ease-linear duration-200 font-medium"
              >
                Seek Customer Support
              </button>
            </div>
          </div>
        </div>
      ))}

      {helpFormOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white px-10 pb-10 rounded-lg w-[700px]">
            <div className="flex justify-end">
              <X
                color="red"
                onClick={() => setHelpFormOpen(false)}
                className="my-5 hover:scale-105 cursor-pointer"
              />
            </div>
            <h2 className="text-xl font-semibold mb-4 text-center">
              Enquiry Form
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                  className="p-2 border rounded w-full"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  className="p-2 border rounded w-full"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">
                  Message
                </label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                  className="p-2 border rounded w-full"
                />
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};
