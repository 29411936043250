import React, { useState, useEffect } from "react";
import { Pagination } from "../../../components/common/pagination/pagination";
import { DeleteConfirmantionModel } from "../../../components/common/models/deleteConfirmantionModel";
import {
  createListing,
  deleteListing,
  updateListing,
} from "../../../redux/listingSlice";
import { useDispatch } from "react-redux";
import { AddEditPropertyModel } from "../../../components/common/models/addNewProperty";
import { InventoryProperties } from "../../../components/Properties/InventoryProperties";
import { InventoryListings } from "../../../components/Properties/InventoryListings";
import { EditPropertyInventoryListingModel } from "../../../components/common/models/EditPropertyInventoryListing";
import { createProperty, updateProperty } from "../../../redux/propertiesSlice";
import { BiChevronDown } from "react-icons/bi";
import { AddPropertyListingModal } from "../../../components/common/models/AddPropertyListingModal";
import { EditPropertyListingModal } from "../../../components/common/models/editPropertyListingModal";

export const InventoryLatest = ({ projectsData, properties1, listingData }) => {
  const [listings, setListing] = useState([]);
  const [filteredListing, setFilteredListing] = useState([]);
  const [propertyListings, setPropertyListings] = useState([]);
  const [propertyMeta, setPropertyMeta] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("property_name");
  const [isOrderByDropdownOpen, setIsOrderByDropdownOpen] = useState(false);
  const [isActive, setIsActive] = useState("all");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [listingToDelete, setListingToDelete] = useState(null);
  const [formAction, setFormAction] = useState("edit");
  const [propertyMatterMostID, setPropertyMatterMostID] = useState("");
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [isPropertyModelOpen, setIsPropertyModelOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [editListingId, setEditListingId] = useState(null);
  const [isPropertySelected, setIsPropertySelected] = useState(false);
  const listingsPerPage = 10;
  const dispatch = useDispatch();

  const [filteredPropertyListings, setFilteredPropertyListings] = useState([]);

  useEffect(() => {
    const filterListings = () => {
      const filtered =
        isActive === "all"
          ? propertyListings
          : propertyListings.filter((listing) =>
              isActive === "active"
                ? !listing.attributes.draft
                : listing.attributes.draft
            );
      setFilteredPropertyListings(filtered);
    };

    filterListings();
  }, [propertyListings, isActive]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const propertyResponse = await fetch(
          `https://admin.gulfin.ai/api/crm-properties/${selectedPropertyId}?populate=*`
        );

        if (!propertyResponse.ok) {
          throw new Error("Network response for property was not ok");
        }
        const propertyResult = await propertyResponse.json();
        setSelectedProperty(propertyResult.data);

        const listingsResponse = await fetch(
          `https://admin.gulfin.ai/api/crm-listings/?populate=*&filters[thumbnail_image][$eq]=${propertyResult.data.attributes.thumbnail_image.data.id}`
        );
        if (!listingsResponse.ok) {
          throw new Error("Network response for listings was not ok");
        }

        const listingsResult = await listingsResponse.json();
        setPropertyListings(listingsResult.data);
        setPropertyMeta(listingsResult.meta);
        setIsPropertySelected(true);
      } catch (err) {
        console.error(err);
      }
    };

    if (selectedPropertyId) {
      fetchData();
    }
  }, [selectedPropertyId]);

  const handlePropertyClick = (listingId) => {
    setSelectedPropertyId(listingId);
    const filtered = listings.filter(
      (listing) =>
        listing.attributes.property_id &&
        listing.attributes.property_id.data &&
        listing.attributes.property_id.data.id === listingId
    );
    setFilteredListing(filtered);
    const propertyFiltering = properties1.data.filter(
      (i) => i.id === listingId
    );
    setPropertyMatterMostID(propertyFiltering[0].attributes.mattermost_id);
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleOrderChange = (value) => {
    setOrderBy(value);
    setIsOrderByDropdownOpen(false);
  };

  const toggleOrderByDropdown = () => {
    setIsOrderByDropdownOpen(!isOrderByDropdownOpen);
  };

  // const toggleActive = () => {
  //   setShowActive(!showActive);
  //   setCurrentPage(1);
  // };

  const handleDelete = (id) => {
    setListingToDelete(listings.find((prop) => prop.id === id));
    setShowDeleteModal(true);
  };

  const handleEdit = (listing) => {
    setSelectedProperty(listing);
    setEditListingId(listing.id);
    setIsEditModalOpen(true);
  };

  const handleDeleteConfirmation = () => {
    dispatch(deleteListing(listingToDelete.id)).then(() => {
      setShowDeleteModal(false);
      window.location.reload();
    });
    setListing(listings.filter((prop) => prop.id !== listingToDelete.id));
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
    // setSelectedProperty({});
  };

  const closeModal = () => {
    setIsModalOpen(false);
    // setSelectedProperty(null);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    // setSelectedProperty(null);
  };

  const propertyModelOpen = () => {
    setIsPropertyModelOpen(true);
    setSelectedProperty({});
  };

  const propertyModelClose = () => {
    setIsPropertyModelOpen(false);
    setSelectedProperty(null);
  };

  const handleAddEditFormSubmit = (formData) => {
    dispatch(createProperty(formData)).then(() => {
      propertyModelClose();
      window.location.reload();
    });
  };

  const handleAddListingSubmit = (formData) => {
    formData.data.display_name = formData.data.name;
    dispatch(createListing(formData)).then(() => {
      alert("Listing created successfully!");
      closeModal();
      window.location.reload();
    });
  };

  const handleEditListingSubmit = (formData) => {
    dispatch(updateListing({ id: editListingId, listingData: formData })).then(
      () => {
        alert("Listing updated successfully!");
        closeEditModal();
        window.location.reload();
      }
    );
  };

  const propertyCountMap = listings.reduce((acc, listing) => {
    const propertyId = listing.attributes.property_id?.data?.id;
    if (propertyId) {
      acc[propertyId] = (acc[propertyId] || 0) + 1;
    }
    return acc;
  }, {});

  console.log(propertyListings);

  return (
    <div className="bg-white rounded">
      <div className="flex items-center justify-between w-full p-4">
        <div className="flex font-medium gap-x-5">
          {/* <button className="flex items-center border-black border-2 p-1.5 px-10 rounded hover:text-white hover:bg-black transition-colors ease-linear duration-200">
            Filter
          </button> */}
          <button
            className={`flex items-center text-blue-500 border-blue-300 border-2 p-1.5 px-10 rounded hover:text-white hover:bg-blue-500 hover:border-blue-500 transition-colors ease-linear duration-200`}
            onClick={propertyModelOpen}
          >
            Create a Property +
          </button>
        </div>
        <div className="relative">
          <p className="font-semibold flex flex-col items-center">
            <p className="text-2xl">12/20</p>
            <p className="">Available Credits</p>
          </p>

          {isOrderByDropdownOpen && (
            <div className="absolute right-0 mt-1 bg-white border border-gray-300 rounded shadow-lg">
              <div
                onClick={() => handleOrderChange("property_name")}
                className={`px-4 py-2 cursor-pointer ${
                  orderBy === "property_name"
                    ? "bg-gray-100"
                    : "hover:bg-gray-100"
                }`}
              >
                Title
              </div>
              <div
                onClick={() => handleOrderChange("property_price")}
                className={`px-4 py-2 cursor-pointer ${
                  orderBy === "property_price"
                    ? "bg-gray-100"
                    : "hover:bg-gray-100"
                }`}
              >
                Price
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="w-full">
        <div className="flex w-full h-screen flex-grow pl-4 pr-4">
          <div className="w-full md:w-3/12">
            <div className="bg-[#407BFF] p-2 text-center text-white text-lg font-semibold">
              My Inventory
            </div>
            {properties1.data.length !== 0 ? (
              <InventoryProperties
                data={properties1.data}
                handlePropertyClick={handlePropertyClick}
                propertyCountMap={propertyCountMap}
              />
            ) : (
              <div className="flex flex-col justify-center items-center mt-5">
                <div>No Available Properties.</div>
                <div>Add Properties.</div>
              </div>
            )}
          </div>
          <div className="w-full md:w-3/7 mx-2 h-screen">
            <div className="w-full border-2 border-grey-200 ml-2">
              <div className="flex justify-between font-medium">
                <div className="flex gap-4 font-medium items-center">
                  <button
                    className={`flex items-center text-blue-500 border-blue-300 border-2 p-1.5 px-10 m-2 ml-4 rounded hover:text-white hover:bg-blue-500 hover:border-blue-500 transition-colors ease-linear duration-200`}
                    onClick={() => {
                      if (selectedProperty) {
                        openModal();
                      } else {
                        alert("Select a property first");
                      }
                    }}
                  >
                    Create Listing +
                  </button>

                  <div className="flex w-32">
                    <button
                      className={`px-4 rounded-l-md border-r-0 w-32
    ${
      isActive === "all"
        ? "bg-green-500 text-white"
        : "bg-gray-100 h-10 border-2 border-gray-300 text-gray-300"
    }`}
                      onClick={() => setIsActive("all")}
                    >
                      All
                    </button>
                    <button
                      className={`px-4 rounded-l-r-md border-l-0 h-10 w-32
    ${
      isActive === "active"
        ? "bg-green-500 text-white"
        : "bg-gray-100 border-2 border-gray-300 text-gray-300"
    }`}
                      onClick={() => setIsActive("active")}
                    >
                      Active
                    </button>
                    <button
                      className={`px-4 rounded-r-md border-l-0 h-10 w-32 
    ${
      isActive === "draft"
        ? "bg-gray-500 text-white"
        : "bg-gray-100 border-2 border-gray-300 text-gray-300"
    }`}
                      onClick={() => setIsActive("draft")}
                    >
                      Draft
                    </button>
                  </div>
                </div>
                <button
                  onClick={toggleOrderByDropdown}
                  className="border-2 border-black text-black p-2 mb-2 mt-2 mr-4 px-4 rounded flex items-center"
                >
                  Sort By{" "}
                  <span className="ml-2">
                    <BiChevronDown className="text-xl" />
                  </span>
                </button>
              </div>
              <div className="h-96 overflow-y-auto">
                {isPropertySelected ? (
                  propertyListings.length ? (
                    <>
                      <InventoryListings
                        listings={filteredPropertyListings}
                        onDelete={handleDelete}
                        onEdit={handleEdit}
                        containerWidth={"3/7"}
                        borderOfContainer={"border-b lg"}
                      />
                      <Pagination
                        currentPage={currentPage}
                        totalPages={Math.ceil(
                          filteredPropertyListings.length / listingsPerPage
                        )}
                        onPageChange={setCurrentPage}
                      />
                    </>
                  ) : (
                    <div className="text-2xl text-center p-4 ml-4">
                      No listing available for selected property.
                    </div>
                  )
                ) : (
                  <div className="text-2xl text-center p-4 ml-4">
                    Select a property to view associated listings.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDeleteModal && (
        <DeleteConfirmantionModel
          isOpen={showDeleteModal}
          onCancel={handleDeleteCancel}
          onDelete={handleDeleteConfirmation}
          property={listingToDelete}
        />
      )}

      {isPropertyModelOpen && (
        <AddEditPropertyModel
          isOpen={isPropertyModelOpen}
          onClose={propertyModelClose}
          onSubmit={handleAddEditFormSubmit}
          setFormAction={setFormAction}
          property={selectedProperty}
          projectData={projectsData}
        />
      )}
      {isModalOpen && (
        <AddPropertyListingModal
          isOpen={isModalOpen}
          onClose={closeModal}
          onSubmit={handleAddListingSubmit}
          formAction={formAction}
          setFormAction={setFormAction}
          property={selectedProperty}
          propertiesData={properties1}
        />
      )}

      {isEditModalOpen && (
        <EditPropertyListingModal
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          onSubmit={handleEditListingSubmit}
          property={selectedProperty}
        />
      )}
    </div>
  );
};
