import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";

export const SelectPropertiesLead = ({
  properties,
  handlePropertySelect,
  listings,
  handleSelectAll,
  searchTerm,
  setSearchTerm,
  selectedProperties,
  onProjectClick,
}) => {
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    handleSelectAll();
  };

  const handleSelectOne = (id) => {
    onProjectClick(id);
    handlePropertySelect(id);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="relative flex">
      <div className="w-full bg-white border rounded p-4 mr-4">
        <div className="flex justify-between mb-4">
          <div className="relative w-auto">
            <FaSearch className="absolute left-3 top-3 text-gray-400" />
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="w-full pl-10 border border-blue-500 rounded py-2 px-4 text-blue-500 bg-gray-100"
            />
          </div>
          <div className="ml-2 py-2 px-3 text-center">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
            <div className="text-black-500 text-sm font-semibold underline rounded flex text-nowrap">
              Select All
            </div>
          </div>
        </div>
        <div className="divide-y divide-gray-200 h-[200px] overflow-y-auto">
          <div className="space-y-4">
            {properties.map((project) => (
              <div
                key={project.id}
                className="flex items-center justify-between py-4 px-2 cursor-pointer"
              >
                <div className="flex items-center">
                  <div className="flex-shrink-0 h-16 w-16 rounded-lg overflow-hidden border border-gray-300">
                    <img
                      className="h-full w-full object-cover"
                      src={
                        project.attributes?.thumbnail_image?.data?.attributes
                          ?.url || ""
                      }
                      alt="Project"
                    />
                  </div>
                  <div className="ml-2 flex-grow flex flex-col">
                    <div className="font-semibold">
                      {project.attributes.property_name}
                    </div>
                    <div className="text-gray-500 flex items-center">
                      <IoLocationSharp className="text-gray-700 text-xl mr-1" />
                      <div className="w-40 truncate">
                        {project.attributes.property_address}
                      </div>
                    </div>
                  </div>
                </div>
                <input
                  type="checkbox"
                  checked={selectedProperties.includes(project.id)}
                  onChange={() => handleSelectOne(project.id)}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
