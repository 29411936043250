import React, { useState, useEffect } from "react";
import { DeleteConfirmantionModel } from "../../../components/common/models/deleteConfirmantionModel";
import {
  createProperty,
  createPropertyListingFromProjects,
  deleteProperty,
  updateProperty,
} from "../../../redux/propertiesSlice";
import { Pagination } from "../../../components/common/pagination/pagination";
import { useDispatch } from "react-redux";
import { AddEditPropertyModel } from "../../../components/common/models/addNewProperty";
import { MyPropertiesListing } from "../../../components/Properties/MyPropertiesListing";

export const ProjectsListings = ({ projectsData, properties1 }) => {
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("property_name");
  const [isOrderByDropdownOpen, setIsOrderByDropdownOpen] = useState(false);
  const [showActive, setShowActive] = useState(true);
  const [isPropertyModelOpen, setIsPropertyModelOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [propertyToDelete, setPropertyToDelete] = useState(null);
  const [formAction, setFormAction] = useState("create");
  const [currentPage, setCurrentPage] = useState(1);
  const [editPropertyId, setEditPropertyId] = useState(null);
  const [isActive, setIsActive] = useState("all");

  const propertiesPerPage = 10;
  const dispatch = useDispatch();

  useEffect(() => {
    const filterListings = () => {
      const filtered =
        isActive === "all"
          ? properties1.data
          : properties1.data.filter((listing) =>
              isActive === "active"
                ? !listing.attributes.draft
                : listing.attributes.draft
            );
      setFilteredProperties(filtered);
    };

    filterListings();
  }, [properties1, isActive]);

  const handleOrderChange = (value) => {
    setOrderBy(value);
    setIsOrderByDropdownOpen(false);
  };

  const toggleOrderByDropdown = () => {
    setIsOrderByDropdownOpen(!isOrderByDropdownOpen);
  };

  const toggleActive = () => {
    setShowActive(!showActive);
  };

  const handleDelete = (id) => {
    setPropertyToDelete(id);
    setShowDeleteModal(true);
  };

  const handleEdit = (property) => {
    setSelectedProperty(property.attributes);
    setEditPropertyId(property.id);
    setIsPropertyModelOpen(true);
  };

  const handleDeleteConfirmation = () => {
    dispatch(deleteProperty(propertyToDelete)).then(() => {
      window.location.reload();
      setShowDeleteModal(false);
    });
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
  };

  const propertyModelOpen = () => {
    setIsPropertyModelOpen(true);
    setSelectedProperty({});
  };

  const propertyModelClose = () => {
    setIsPropertyModelOpen(false);
    setSelectedProperty(null);
  };

  const handleAddEditFormSubmit = (formData) => {
    let updatedName = formData.data.property_name.split(" ").join("");
    formData.data.name = updatedName;
    formData.data.display_name = formData.data.property_name;
    if (formAction === "edit") {
      dispatch(
        updateProperty({ id: editPropertyId, propertyData: formData })
      ).then(() => {
        window.location.reload();
        propertyModelClose();
      });
    } else {
      dispatch(createPropertyListingFromProjects(formData)).then(() => {
        window.location.reload();
        propertyModelClose();
      });
    }
  };

  return (
    <div className="bg-white h-[100vh]">
      <div className="flex items-center justify-between w-full p-4">
        <div className="flex font-medium">
          <button
            className={`flex items-center text-blue-500 border-blue-300 border-2 p-1.5 px-10 rounded hover:text-white hover:bg-blue-500 hover:border-blue-500 transition-colors ease-linear duration-200`}
            onClick={() => {
              if (projectsData.data.length !== 0) {
                propertyModelOpen();
              } else {
                alert(
                  "You don't have any projects yet, try adding a project first."
                );
              }
            }}
          >
            Create Property Listing +
          </button>
          <div className="flex w-1/2 ml-10">
            <button
              className={`px-4 rounded-l-md border-r-0 w-32
                        ${
                          isActive === "all"
                            ? "bg-green-500 text-white h-10"
                            : "bg-gray-100 h-10 border-2 border-gray-300 text-gray-300"
                        }`}
              onClick={() => setIsActive("all")}
            >
              All
            </button>
            <button
              className={`px-4 rounded-l--md border-l-0 h-10 w-32
                          ${
                            isActive === "active"
                              ? "bg-green-500 text-white"
                              : "bg-gray-100 border-2 border-gray-300 text-gray-300"
                          }`}
              onClick={() => setIsActive("active")}
            >
              Active
            </button>
            <button
              className={`px-4 rounded-r-md border-l-0 h-10 w-32 
                          ${
                            isActive === "draft"
                              ? "bg-gray-500 text-white"
                              : "bg-gray-100 border-2 border-gray-300 text-gray-300"
                          }`}
              onClick={() => setIsActive("draft")}
            >
              Draft
            </button>
          </div>
        </div>
      </div>
      <div className="w-full h-[450px] overflow-y-auto">
        <div className="flex flex-wrap ">
          <MyPropertiesListing
            properties={filteredProperties}
            onDelete={handleDelete}
            onEdit={handleEdit}
            containerWidth={"1/2"}
            borderOfContainer={"p-4 border rounded-lg"}
          />
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(filteredProperties?.length / propertiesPerPage)}
          onPageChange={setCurrentPage}
        />
      </div>

      {showDeleteModal && (
        <DeleteConfirmantionModel
          isOpen={showDeleteModal}
          onCancel={handleDeleteCancel}
          onDelete={handleDeleteConfirmation}
          property={propertyToDelete}
        />
      )}

      {isPropertyModelOpen && (
        <AddEditPropertyModel
          isOpen={isPropertyModelOpen}
          onClose={propertyModelClose}
          onSubmit={handleAddEditFormSubmit}
          formAction={formAction}
          setFormAction={setFormAction}
          property={selectedProperty}
          projectData={projectsData}
        />
      )}
    </div>
  );
};
