import React, { useEffect, useState } from "react";
import { EnquiryTable } from "./EnquiryTable";
import { useDispatch, useSelector } from "react-redux";
import { getLeads, getLeadUserpersonal, getUserLeads } from "../../redux/leadSlice";
import { DivideSquare } from "lucide-react";

const EnquiryPage = () => {
  const EnquiryData = [
    {
      leadId: "1234567",
      listingId: "L001",
      type: "Property",
      companyName: "Acme Corp",
      companyContact: "+1-555-0198",
      status: "Active",
      paymentLink: "https://example.com/pay/1234567",
      messaging: "Click to view",
    },
    {
      leadId: "2345678",
      listingId: "L002",
      type: "Services",
      companyName: "Globex LLC",
      companyContact: "+1-555-0398",
      status: "Inactive",
      paymentLink: "https://example.com/pay/2345678",
      messaging: "Click to view",
    },
    {
      leadId: "3456789",
      listingId: "L003",
      type: "Property",
      companyName: "Soylent Green Inc.",
      companyContact: "+1-555-0598",
      status: "Pending",
      paymentLink: "https://example.com/pay/3456789",
      messaging: "Click to view",
    },
    {
      leadId: "4567890",
      listingId: "L004",
      type: "Services",
      companyName: "FarmCo",
      companyContact: "+1-555-0798",
      status: "Active",
      paymentLink: "https://example.com/pay/4567890",
      messaging: "Click to view",
    },
    {
      leadId: "5678901",
      listingId: "L005",
      type: "Services",
      companyName: "Home Realty",
      companyContact: "+1-555-0998",
      status: "Inactive",
      paymentLink: "https://example.com/pay/5678901",
      messaging: "Click to view",
    },
    {
      leadId: "6789012",
      listingId: "L006",
      type: "Property",
      companyName: "OfficeSpace Rentals",
      companyContact: "+1-555-1198",
      status: "Pending",
      paymentLink: "https://example.com/pay/6789012",
      messaging: "Click to view",
    },
    {
      leadId: "7890123",
      listingId: "L007",
      type: "Property",
      companyName: "Manufacturing Solutions",
      companyContact: "+1-555-1398",
      status: "Active",
      paymentLink: "https://example.com/pay/7890123",
      messaging: "Click to view",
    },
    {
      leadId: "8901234",
      listingId: "L008",
      type: "Services",
      companyName: "AgriProduce",
      companyContact: "+1-555-1598",
      status: "Inactive",
      paymentLink: "https://example.com/pay/8901234",
      messaging: "Click to view",
    },
    // {
    //   leadId: "9012345",
    //   listingId: "L009",
    //   type: "Residential",
    //   companyName: "DreamHomes",
    //   companyContact: "+1-555-1798",
    //   status: "Pending",
    //   paymentLink: "https://example.com/pay/9012345",
    //   messaging: "Click to view",
    // },
  ];
   const loggedInUser = JSON.parse(localStorage.getItem("user"));
   const id = loggedInUser.id;
   const dispatch = useDispatch();
   const {
    leadsUserPersonalData,
    leadsUserStatus,
    leadsUserError,
    leadsUserCurrentPage,
    leadsUserTotalPages,
  } = useSelector((state) => state.leads);


   useEffect(()=>{
    dispatch(getLeadUserpersonal({ page: leadsUserCurrentPage, pageSize: 100,userenquires: id }))
   },[dispatch,leadsUserCurrentPage,id])

  return (
    <div className="Projects-page bg-gray-100 min-h-screen p-1">
      {leadsUserPersonalData.data ?<EnquiryTable data={leadsUserPersonalData.data} />:<div>LeadData is not avalable</div>}
    </div>
  );
};

export default EnquiryPage;
