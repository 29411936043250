import React, { useEffect, useState } from "react";

import Inventorydata from "../../services/dummy_properties.json";
import { InvoiceDataTable } from "./InvoiceDataTable";
export const InVoicePage = () => {
  const [invoiceData, setInvoiceData] = useState([]);

  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const id = loggedInUser.id;
  const isConfirm = loggedInUser.confirmed;
  const role = loggedInUser.user_role;

  useEffect(() => {
    const loadProjectsData = async () => {
      try {
        const url = `https://admin.gulfin.ai/api/invoices?populate=crm_listing,invoice_pdf&filters[crm_listing][property_user][$eq]=${id}`;
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setInvoiceData(data);
      } catch (error) {
        console.error("Error loading Projects data:", error);
      }
    };

    loadProjectsData();
  }, []);

  if (!invoiceData) return <div>Loading...</div>;

  return (
    <div className="Projects-page bg-gray-100 min-h-screen p-1">
      {role === "owner" || isConfirm ? (
        <InvoiceDataTable data={invoiceData} />
      ) : (
        <p> Your invoices will appear here once your profile is approved</p>
      )}
    </div>
  );
};
