import React, { useState, useEffect } from "react";
import { Pagination } from "../../../components/common/pagination/pagination";
import { DeleteConfirmantionModel } from "../../../components/common/models/deleteConfirmantionModel";
import { useDispatch } from "react-redux";
import { ServicesInventoryServices } from "../../../components/services/servicesInventoryServices";
import { AddNewService } from "../../../components/common/models/addNewService";
import { InventoryListingForServices } from "../../../components/services/inventoryListingForServices";
import { createService } from "../../../redux/servicesSlice";
import { AddNewServiceListing } from "../../../components/common/models/addNewServiceListing";
import {
  createServicesListing,
  updateServicesListing,
} from "../../../redux/servicesListingSlice";
import { BiChevronDown } from "react-icons/bi";
import axios from "axios";
import { deleteService } from "../../../redux/propertiesSlice";

export const ServicesInventoryLatest = ({
  servicesData,
  servicesListingData,
}) => {
  const [serviceListings, setServiceListing] = useState([]);
  const [serviceMeta, setServiceMeta] = useState([]);
  const [filteredListings, setFilteredListings] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("name");
  const [isOrderByDropdownOpen, setIsOrderByDropdownOpen] = useState(false);
  const [showActive, setShowActive] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [serviceSelectId, setServiceSelectId] = useState(null);
  const [propertyToDelete, setPropertyToDelete] = useState(null);
  const [selectedListingId, setServicesSelectedid] = useState(null);
  const [formAction, setFormAction] = useState("edit");
  const [isServiceModelOpen, setIsServiceModelOpen] = useState(false);
  const [serivceMatterMostID, setSerivceMatterMostID] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [editListingId, setEditListingId] = useState(null);
  const [isServiceSelected, setIsServiceSelected] = useState(false);
  const serviceListingPerPage = 4;
  const dispatch = useDispatch();

  const [isActive, setIsActive] = useState("all");
  const [filteredServiceListings, setFilteredServiceListings] = useState([]);

  useEffect(() => {
    const filterListings = () => {
      const filtered =
        isActive === "all"
          ? serviceListings
          : serviceListings.filter((listing) =>
              isActive === "active"
                ? !listing.attributes.draft
                : listing.attributes.draft
            );
      setFilteredServiceListings(filtered);
    };

    filterListings();
  }, [serviceListings, isActive]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const serviceResponse = await fetch(
          `https://admin.gulfin.ai/api/crm-services/${serviceSelectId}?populate=*`
        );
        if (!serviceResponse.ok) {
          throw new Error("Network response for property was not ok");
        }
        const serviceResult = await serviceResponse.json();
        setSelectedProperty(serviceResponse.data);

        const listingsResponse = await fetch(
          `https://admin.gulfin.ai/api/crm-service-listings/?populate=*&filters[services_id][$eq]=${serviceSelectId}`
        );
        if (!listingsResponse.ok) {
          throw new Error("Network response for listings was not ok");
        }
        const listingsResult = await listingsResponse.json();
        setServiceListing(listingsResult.data);
        setServiceMeta(listingsResult.meta);
        setIsServiceSelected(true);
      } catch (err) {
        console.error(err);
      }
    };
    if (serviceSelectId) {
      fetchData();
    }
  }, [serviceSelectId]);

  const handlePropertyClick = (serviceId) => {
    console.log("serviceListings :  ", serviceListings, serviceId);
    setServiceSelectId(serviceId);
    const filtered = serviceListings.filter(
      (serviceListing) =>
        serviceListing.attributes.services_id &&
        serviceListing.attributes.services_id.data &&
        serviceListing.attributes.services_id.data.id === serviceId
    );
    const servicesFiltering = servicesData.data.filter(
      (i) => i.id === serviceId
    );
    setSerivceMatterMostID(
      servicesFiltering[0].attributes.mattermost_services_id
    );
    setFilteredListings(filtered);
    console.log(filtered);
    setCurrentPage(1); // Reset to the first page when a new service is selected
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleOrderChange = (value) => {
    setOrderBy(value);
    setIsOrderByDropdownOpen(false);
  };

  const toggleOrderByDropdown = () => {
    setIsOrderByDropdownOpen(!isOrderByDropdownOpen);
  };

  const toggleActive = () => {
    setIsActive((prevState) => !prevState);
  };

  const handleDelete = (id) => {
    setPropertyToDelete(id);
    setShowDeleteModal(true);
  };

  const handleEdit = (listing) => {
    setEditListingId(listing.id);
    setSelectedProperty(listing.attributes);
    setIsModalOpen(true);
  };

  console.log(propertyToDelete);
  const handleDeleteConfirmation = () => {
    console.log("here");
    dispatch(deleteService(propertyToDelete)).then(() => {
      window.location.reload();
      setShowDeleteModal(false);
    });
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
    setSelectedProperty({});
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProperty(null);
  };

  const servieModelOpen = () => {
    setIsServiceModelOpen(true);
    // setSelectedProperty({});
  };

  const serviceModelClose = () => {
    setIsServiceModelOpen(false);
    // setSelectedProperty(null);
  };

  const handleAddEditFormSubmit = (formData) => {
    console.log(formData);
    dispatch(createService(formData)).then(() => {
      alert("Service created successfully.");
      window.location.reload();
      closeModal();
    });
  };

  const handleAddServiceListingSubmit = (formData) => {
    formData.data.display_name = formData.data.name;
    formData.data.type = "O";
    if (formAction === "edit") {
      console.log("edit", formData);
      dispatch(
        updateServicesListing({ id: editListingId, listingData: formData })
      ).then(() => {
        window.location.reload();
        closeModal();
      });
    } else {
      console.log("create", formData);
      dispatch(createServicesListing(formData)).then(() => {
        alert("listing created successfully!");
        window.location.reload();
        closeModal();
      });
    }
  };

  const indexOfLastServiceListing = currentPage * serviceListingPerPage;
  const indexOfFirstProperty =
    indexOfLastServiceListing - serviceListingPerPage;
  const currentServiceListings = filteredListings.slice(
    indexOfFirstProperty,
    indexOfLastServiceListing
  );
  const totalPages = Math.ceil(filteredListings.length / serviceListingPerPage);
  const propertyCountMap = serviceListings.reduce((acc, property) => {
    const projectId = property.attributes.project_id?.data?.id;
    if (projectId) {
      acc[projectId] = (acc[projectId] || 0) + 1;
    }
    return acc;
  }, {});

  console.log(serviceListings);

  return (
    <div className="bg-white rounded">
      <div className="flex items-center justify-between w-full p-4">
        <div className="flex font-medium gap-x-5">
          <button
            className={`flex items-center text-blue-500 border-blue-300 border-2 p-1.5 px-10 rounded hover:text-white hover:bg-blue-500 hover:border-blue-500 transition-colors ease-linear duration-200`}
            onClick={servieModelOpen}
          >
            Add Service +
          </button>
        </div>
        <div className="relative">
          <p className="font-semibold flex flex-col items-center">
            <p className="text-2xl">12/20</p>
            <p className="">Available Credits</p>
          </p>

          {isOrderByDropdownOpen && (
            <div className="absolute right-0 mt-1 bg-white border border-gray-300 rounded shadow-lg">
              <div
                onClick={() => handleOrderChange("property_name")}
                className={`px-4 py-2 cursor-pointer ${
                  orderBy === "property_name"
                    ? "bg-gray-100"
                    : "hover:bg-gray-100"
                }`}
              >
                Title
              </div>
              <div
                onClick={() => handleOrderChange("property_price")}
                className={`px-4 py-2 cursor-pointer ${
                  orderBy === "property_price"
                    ? "bg-gray-100"
                    : "hover:bg-gray-100"
                }`}
              >
                Price
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="w-full">
        <div className="flex w-full h-screen flex-grow pl-4 pr-4">
          <div className="w-1/4">
            <div className="bg-[#407BFF] p-2 text-center text-white text-lg font-semibold mb-4">
              My Inventory
            </div>

            {servicesData.data.length !== 0 ? (
              <ServicesInventoryServices
                data={servicesData.data}
                handlePropertyClick={handlePropertyClick}
                propertyCountMap={propertyCountMap}
              />
            ) : (
              <div className="flex flex-col justify-center items-center mt-5">
                <div>No Available Services.</div>
                <div>Add Services.</div>
              </div>
            )}
          </div>

          {servicesData.data.length !== 0 && (
            <div className="w-3/4 mr-2 ml-2 h-screen">
              <div className="w-full border-2 border-grey-200 ml-2">
                <div className="flex justify-between font-medium">
                  <div className="flex items-center">
                    <button
                      className={`flex items-center text-blue-500 border-blue-300 border-2 p-1.5 px-10 m-2 ml-4 rounded hover:text-white hover:bg-blue-500 hover:border-blue-500 transition-colors ease-linear duration-200`}
                      onClick={openModal}
                    >
                      Create Listing +
                    </button>
                    <div className="flex font-medium px-2">
                      <button
                        className={`px-4 rounded-l-md border-r-0 w-32
                        ${
                          isActive === "all"
                            ? "bg-green-500 text-white"
                            : "bg-gray-100 h-10 border-2 border-gray-300 text-gray-300"
                        }`}
                        onClick={() => setIsActive("all")}
                      >
                        All
                      </button>
                      <button
                        className={`px-4 rounded-l-r-md border-l-0 h-10 w-32
                      ${
                        isActive === "active"
                          ? "bg-green-500 text-white"
                          : "bg-gray-100 border-2 border-gray-300 text-gray-300"
                      }`}
                        onClick={() => setIsActive("active")}
                      >
                        Active
                      </button>
                      <button
                        className={`px-4 rounded-r-md border-l-0 h-10 w-32 
                                ${
                                  isActive === "draft"
                                    ? "bg-gray-500 text-white"
                                    : "bg-gray-100 border-2 border-gray-300 text-gray-300"
                                }`}
                        onClick={() => setIsActive("draft")}
                      >
                        Draft
                      </button>
                    </div>
                  </div>
                  <button
                    onClick={toggleOrderByDropdown}
                    className="border-2 border-black text-black p-2 mb-2 mt-2 mr-4 px-4 rounded flex items-center"
                  >
                    Sort By{" "}
                    <span className="ml-2">
                      <BiChevronDown className="text-xl" />
                    </span>
                  </button>
                </div>
                <div className="h-[60vh] overflow-y-auto">
                  {isServiceSelected ? (
                    filteredServiceListings.length ? (
                      <InventoryListingForServices
                        listings={filteredServiceListings}
                        onDelete={handleDelete}
                        onEdit={handleEdit}
                        containerWidth={"3/7"}
                        borderOfContainer={"border-b lg"}
                      />
                    ) : (
                      <div className="text-2xl text-center p-4 ml-4">
                        No {isActive ? "active" : "draft"} listings for this
                        service
                      </div>
                    )
                  ) : (
                    <div className="text-2xl text-center p-4 ml-4">
                      Select a service to view associated listings.
                    </div>
                  )}
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={setCurrentPage}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showDeleteModal && (
        <DeleteConfirmantionModel
          isOpen={showDeleteModal}
          onCancel={handleDeleteCancel}
          onDelete={handleDeleteConfirmation}
          property={propertyToDelete}
        />
      )}

      {isModalOpen && (
        <AddNewServiceListing
          isOpen={isModalOpen}
          onClose={closeModal}
          onSubmit={handleAddServiceListingSubmit}
          service={selectedProperty}
          setFormAction={setFormAction}
          serviceSelectId={serviceSelectId}
          servicesData={servicesData}
        />
      )}
      {isServiceModelOpen && (
        <AddNewService
          isOpen={isServiceModelOpen}
          onClose={serviceModelClose}
          onSubmit={handleAddEditFormSubmit}
          service={selectedProperty}
          setFormAction={setFormAction}
          servicesData={servicesData}
        />
      )}
    </div>
  );
};
