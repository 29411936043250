import React from 'react';
import ReactApexChart from 'react-apexcharts';

export const GlobalAnalyticsChart = ({ title, data }) => {  
  const lineChartOptions = {
    chart: {
      height: 350,
      type: 'line',
      toolbar: {
        show: false // Hide toolbar if not needed
      }
    },
    colors: ['#40FF6A'], // Change line color to #40FF6A
    stroke: {
      curve: 'smooth' // Make the line a soft curve
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'], // Adjust as per the months available
    },
    yaxis: {
      labels: {
        formatter: (value) => `${value}`,
      },
    },
  };

  const lineChartSeries = [
    {
      name: title,
      data: data,
    }
  ];

  return (
    <div className="w-full md:w-1/2 pl-4 mb-4 pr-0">
      <div className="bg-white border border-gray-200 rounded-lg p-4">
        <div className="text-lg font-semibold mb-4">{title}</div>
        <ReactApexChart
          options={lineChartOptions}
          series={lineChartSeries}
          type="line"
          height={300}
        />
      </div>
    </div>
  );
};
