import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchListings, fetchUserListing } from '../services/api';
import axios from 'axios';
const initialState = {
    listingData: [],
    listingUserData: [],
    listingStatus: 'idle',
    listingUserStatus: 'idle',
    listingUserError: null,
    listingError: null,
    listingUserCurrentPage: 1,
    listingCurrentPage: 1,
    listingUserTotalPages: 1,
    listingTotalPages: 1,

  };  
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  
  export const getListings = createAsyncThunk(
    'listings/getListings',
    async ({ page, pageSize }) => {
      const listings = await fetchListings(page, pageSize);
      return listings;
    }
  );
  
  export const getUserListings = createAsyncThunk(
    'listings/getUserListings',
    async ({ page, pageSize, userId }) => {
      const listings = await fetchUserListing(page, pageSize, userId);
      console.log(page, pageSize, "---------------", listings, 'listing slice code');
      return listings;
    }
  );

export const createListing = createAsyncThunk(
  'listings/createListing',
  async (listingData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-listings`, listingData,{
        headers: {  
          "Content-Type": "application/json",
        },
      });
      let myStrapiData = response.data.data;

      console.log(myStrapiData,'my strapi data of listing')

      const ListingName = myStrapiData.attributes.property_name.split(' ').join('').toLowerCase();
      let mattermostTeamId ='ix1bdn6bwpbd9gak17r9eydurc' 

      const matterMostData = {
        team_id:mattermostTeamId,
        name: `${myStrapiData.id}${ListingName}`,
        display_name: `prop_${myStrapiData.id}_${myStrapiData.attributes.property_name}`,
        type: "P",
        user_id: loggedInUser.mattermost_user_id
      };
    //// calling the api of mattermost //////////////
    const matterMostResponse = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}api/listing-api-mattermost`, matterMostData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log(matterMostResponse.data, 'mattermost_property_listing')
    
    response.data.mattermost_id = matterMostResponse.data.mattermostChannelId;

      const responseWithMatterMostId = await axios.put(`${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-listings/${myStrapiData.id}`, response, {
        headers: {
          "Content-Type": "application/json",
        },
      });

  } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateListing = createAsyncThunk(
  'listings/updateListing',
  async ({ id, listingData }, { rejectWithValue }) => {
    try {
  const response = await axios.put(`${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-listings/${id}`, listingData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteListing = createAsyncThunk(
  'property/deleteProperty',
  async (id, { rejectWithValue }) => {
    try {
  const response = await axios.delete(`${process.env.REACT_APP_BACKEND_DOMAIN}api/crm-listings/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const listingsSlice = createSlice({
  name: 'listings',
  initialState,
  reducers: {
    setPage(state, action) {  
      state.listingCurrentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListings.pending, (state) => {
        state.listingStatus = 'loading';
      })
      .addCase(getListings.fulfilled, (state, action) => {
        state.listingStatus = 'succeeded';
        state.listingData = action.payload;
        state.listingTotalPages = Math.ceil(action.payload.meta.pagination.total / action.payload.meta.pagination.pageSize);
      })
      .addCase(getUserListings.fulfilled, (state, action) => {
        state.listingUserStatus = 'succeeded';
        state.listingUserData = action.payload;
        state.listingUserTotalPages = Math.ceil(action.payload.meta.pagination.total / action.payload.meta.pagination.pageSize);
      })
      .addCase(getListings.rejected, (state, action) => {
        state.listingStatus = 'failed';
        state.listingError = action.error.message;
      });
  },
});
export const { setPage } = listingsSlice.actions;
export default listingsSlice.reducer;