import React, { useState } from "react";
import { IoLocationSharp } from "react-icons/io5";

export const InventoryProjects = ({ data, handleProjectsClick, count }) => {
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  console.log(data);

  console.log(count, "selected listings for project");

  const handleProjectSelection = (projectId) => {
    setSelectedProjectId(projectId);
    handleProjectsClick(projectId);
  };

  return (
    <div className="divide-y divide-gray-200 h-96 overflow-y-scroll">
      {data.map((project, index) => {
        const isSelected = project.id === selectedProjectId;

        return (
          <div
            key={index}
            onClick={() => handleProjectSelection(project.id)}
            className={`flex items-center justify-between py-4 px-2 cursor-pointer ${
              isSelected ? "bg-blue-100" : ""
            }`}
          >
            <div className="flex items-center">
              <div className="flex-shrink-0 h-16 w-16 rounded-lg overflow-hidden border border-gray-300">
                {project.attributes?.image?.data ? (
                  <img
                    src={
                      project.attributes?.image?.data[0]?.attributes?.url || null
                    }
                    alt={project.attributes?.project_name}
                    className="w-full h-full object-cover rounded-md"
                  />
                ) : (
                  <div className="w-full h-full bg-gray-200 flex items-center justify-center rounded-md">
                    No Image
                  </div>
                )}
              </div>
              <div className="ml-2 flex-grow flex flex-col w-full">
                <div className="font-semibold">
                  {project.attributes?.project_name}
                </div>
                <div className="text-gray-500 flex items-center w-full">
                  <div className="flex items-center w-full">
                    <IoLocationSharp className="text-gray-700 text-xl mr-2" />
                    <p className="w-40 truncate">
                      {project?.attributes?.address}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
