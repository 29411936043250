import React, { useState } from "react";
import {
  FaCaretDown,
  FaPhotoVideo,
  FaPaperclip,
  FaSmile,
} from "react-icons/fa";
import profilePicture from "../../services/images/profileLogo1.jpg";
import { PostModal } from "./PostModel/postModel.js";

export const UploadPost = ({ section }) => {
  const userProfileImageUrl = profilePicture;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="bg-white px-4 py-2 shadow-md rounded-lg mb-2.5">
      <h3 className="text-lg font-semibold mb-2">Add New Feed</h3>

      <div className="border-2 border-black-600 rounded-lg mb-2.5 w-full">
        <div className="flex items-start space-x-4 p-4">
          <img
            src={userProfileImageUrl}
            alt="Profile"
            className="w-10 h-10 rounded-full"
          />
          <div className="flex-1">
            <div
              className="flex items-center cursor-pointer"
              onClick={openModal}
            >
              <span className="text-black font-bold">Post</span>
              <span className="text-black-500">
                <FaCaretDown />
              </span>
            </div>
            <div
              onClick={openModal}
              className="w-full mt-2 text-sm text-gray-500"
            >
              What's on your mind?
            </div>
          </div>
        </div>
        <hr className="border-t-2 border-black-900" />
        <div className="flex items-center space-x-9 px-3 py-2">
          <button
            onClick={openModal}
            className="flex items-center text-black mr-5 hover:text-black-700"
          >
            <FaPhotoVideo className="mr-2 text-blue-500" />
            <span>Photo/Video</span>
            <input id="file-upload" type="file" className="hidden" />
          </button>
          <button
            onClick={openModal}
            className="flex items-center text-black mr-7 hover:text-black-700"
          >
            <FaPaperclip className="mr-2 text-green-500" />
            <span>Attach File</span>
          </button>
          <button
            onClick={openModal}
            className="flex items-center text-black mr-7 hover:text-black-700"
          >
            <FaSmile className="mr-2 text-orange-500" />
            <span>Emoji</span>
          </button>
        </div>
      </div>

      <PostModal section={section} isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};
