import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useLoadScript } from "@react-google-maps/api";
import Loader from "../../Loader";
import MultiColumnDropdown from "./multiColumnDropdown";
import {} from "@fortawesome/fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faWifi,
  faTv,
  faStoreAlt,
  faHeading,
  faStore,
  faHSquare,
} from "@fortawesome/fontawesome-free-solid";
import {} from "@fortawesome/fontawesome-free-regular";
import {} from "@fortawesome/fontawesome-svg-core";

export const AddEditPropertyModel = ({
  isOpen,
  onClose,
  onSubmit,
  formAction,
  setFormAction,
  property = {},
  projectData,
  selectedProjectId,
}) => {
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();

  const amenities = [
    {
      category: "FEATURES",
      dummyAmenities: [
        { id: "amenity_0_0", feature_name: "Electricity Backup", icon: faBolt },
        { id: "amenity_0_1", feature_name: "Air-Conditioned", icon: faHeading },
        { id: "amenity_0_2", feature_name: "Central Heating", icon: faHeading },
        { id: "amenity_0_4", feature_name: "Storage Areas", icon: faStore },
        { id: "amenity_0_5", feature_name: "Study Room", icon: faStoreAlt },
      ],
    },
    {
      category: "HEALTH AND FITNESS",
      dummyAmenities: [
        { id: "amenity_1_0", feature_name: "Medical Center", icon: faHSquare },
        { id: "amenity_1_1", feature_name: "Gym/Health Club", icon: faBolt },
        { id: "amenity_1_2", feature_name: "Swimming Pool", icon: faBolt },
        { id: "amenity_1_3", feature_name: "Jacuzzi", icon: faBolt },
        { id: "amenity_1_4", feature_name: "Sauna", icon: faBolt },
        { id: "amenity_1_5", feature_name: "Steam Room", icon: faBolt },
      ],
    },
    {
      category: "RECREATION AND FAMILY",
      dummyAmenities: [
        { id: "amenity_2_0", feature_name: "Kids Play Area", icon: faBolt },
        { id: "amenity_2_1", feature_name: "Lawn or Garden", icon: faBolt },
        { id: "amenity_2_2", feature_name: "Barbeque Area", icon: faBolt },
        { id: "amenity_2_3", feature_name: "Cafeteria", icon: faBolt },
      ],
    },
    {
      category: "CLEANING AND MAINTENANCE",
      dummyAmenities: [
        { id: "amenity_3_0", feature_name: "Waste Disposal", icon: faBolt },
        { id: "amenity_3_1", feature_name: "Maintenance Staff", icon: faBolt },
        { id: "amenity_3_2", feature_name: "Cleaning Services", icon: faBolt },
      ],
    },
    {
      category: "BUSINESS AND SECURITY",
      dummyAmenities: [
        { id: "amenity_4_0", feature_name: "Business Center", icon: faBolt },
        { id: "amenity_4_1", feature_name: "Conference Room", icon: faBolt },
        { id: "amenity_4_2", feature_name: "Security Staff", icon: faBolt },
        { id: "amenity_4_3", feature_name: "CCTV Security", icon: faBolt },
      ],
    },
    {
      category: "LAUNDRY AND KITCHEN",
      dummyAmenities: [
        { id: "amenity_5_0", feature_name: "Shared Kitchen", icon: faBolt },
        { id: "amenity_5_1", feature_name: "Laundry Room", icon: faBolt },
        { id: "amenity_5_2", feature_name: "Laundry Facility", icon: faBolt },
      ],
    },
    {
      category: "TECHNOLOGY",
      dummyAmenities: [
        { id: "amenity_6_0", feature_name: "Broadband", icon: faWifi },
        { id: "amenity_6_1", feature_name: "Satellite/Cable TV", icon: faTv },
        { id: "amenity_6_2", feature_name: "Intercom", icon: faBolt },
      ],
    },
    {
      category: "MISCELLANEOUS",
      dummyAmenities: [
        { id: "amenity_7_0", feature_name: "24/7 Concierge", icon: faBolt },
      ],
    },
  ];

  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [isImagesUploaded, setIsImagesUploaded] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadCount, setUploadCount] = useState(0);

  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCityId, setSelectedCityId] = useState(null);
  const [propertyState, setPropertyState] = useState("Property");
  const [aminityModel, setAminityModel] = useState(false);
  const [selectedAmenityId, setSelectedAmenityId] = useState([]);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await fetch(
          "https://admin.gulfin.ai/api/cities?pagination[page]=1&pagination[pageSize]=1000"
        );
        const data = await response.json();
        setCities(data.data);
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };

    fetchCities();
  }, []);

  const [formData, setFormData] = useState({
    property_name: "",
    property_address: "",
    listing_size_unit: "",
    property_size: "",
    location: "",
    city: "",
    state: "",
    currency: "USD",
    propertyType: "residential",
    description: "",
    number_of_beds: "",
    property_type: "",
    furniture: "",
    category: "",
    property_price: "",
    status: "Ready",
    project_id: "",
    floor_number: "",
    flat_number: null,
    number_of_baths: "",
    rent: null,
    coverage_area: null,
    residencial: false,
    commercial: false,
    rent_numerical: null,
    coverage_area_numerical: null,
    rating: null,
    latitude: null,
    longitude: null,
    image: null,
    // broker_section: {
    //   broker_email: null,
    //   broker_img: null,
    //   broker_name: '',
    //   broker_phone_number: null,
    //   broker_whatsapp_number: null,
    //   description: '',
    //   id: null,
    //   Reviews: ''
    // },
    nearby_locations: "",
    nearby_places: [
      {
        Distance: "",
        LocationImage: null,
        LocationName: "",
      },
    ],
    amenities: [
      {
        feature_name: "",
        feature_img: null,
      },
    ],
    other_images: [],
  });

  useEffect(() => {
    if (property && Object.keys(property).length > 0) {
      console.log(property);

      setFormAction("edit");
      setFormData({
        property_name: property.property_name || "",
        propertyType: "residential",
        property_type: property.property_type || "",
        city: property.city || "",
        state: property.state || "",
        country: property.country || "",
        listing_size_unit: property.listing_size_unit || "",
        property_size: property.property_size || "",
        currency: property.currency || "",
        category: property.residencial
          ? "residencial"
          : property.commercial
          ? "commercial"
          : "",
        furniture: property.furniture || "",
        project_id: property.project_id?.data?.id || "",
        number_of_baths: property.number_of_baths || "",
        number_of_beds: property.number_of_beds || "",
        floor_number: property.floor_number || "",
        flat_number: property.flat_number || "",
        status: property.status || "",
        rent: property.rent || "",
        property_price: property.property_price || "",
        property_address: property.property_address || "",
        description: property.description || "",
        image: property.thumbnail_image?.data?.id || null,
        residencial: property.residencial,
        commercial: property.commercial,
        rent_numerical: property.rent_numerical,
        coverage_area: property.coverage_area,
        coverage_area_numerical: property.coverage_area_numerical,
        rating: property.rating,
        nearby_locations: property.nearby_locations || "",
        // location: property.location,
        latitude: property.latitude,
        longitude: property.longitude,
        // broker_section: property.broker_section || {
        //   broker_email: loggedInUser.email,
        //   broker_img: null,
        //   broker_name: loggedInUser.email,
        //   broker_phone_number: null,
        //   broker_whatsapp_number: null,
        //   description: 'Broker',
        //   id: loggedInUser.id,
        //   Reviews: ''
        // },
        nearby_places: property.nearby_places || [
          {
            distance: "",
            LocationImage: null,
            LocationName: "",
          },
        ],
        amenities:
          property.amenities?.map((amenity) => ({
            id: amenity.id,
            feature_name: amenity.feature_name || "",
          })) || [],

        other_images:
          property.other_images?.data?.map((image) => image.id) || [],
      });
    } else {
      setFormAction("create");
      setFormData({
        property_name: "",
        property_address: "",
        city: "",
        state: "",
        currency: "USD",
        description: "",
        number_of_beds: "",
        propertyType: "",
        property_type: "",
        furniture: "",
        category: "",
        listing_size_unit: "meter",
        property_size: "",
        property_price: "",
        status: "Ready",
        project_id: projectData.data[0].id || "",
        floor_number: "",
        flat_number: null,
        number_of_baths: "",
        rent: null,
        coverage_area: null,
        residencial: false,
        commercial: false,
        rent_numerical: null,
        coverage_area_numerical: null,
        rating: null,
        latitude: null,
        longitude: null,
        image: null,
        // broker_section: property.broker_section || {
        //   broker_email: loggedInUser.email,
        //   broker_img: null,
        //   broker_name: loggedInUser.username,
        //   broker_phone_number: null,
        //   broker_whatsapp_number: null,
        //   description: '',
        //   id: loggedInUser.id,
        //   Reviews: ''
        // },
        nearby_locations: "",
        nearby_places: [
          {
            Distance: "",
            LocationImage: null,
            LocationName: "",
          },
        ],
        amenities: [
          {
            feature_name: "",
            feature_img: null,
          },
        ],
        other_images: [],
      });
    }
  }, [property, setFormAction]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      location: selectedCity?.attributes?.Name || "",
    }));
  }, [selectedCity, setFormData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
    libraries: ["places"],
  });

  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
  };

  const onDragEnd = (event) => {
    const position = event.latLng.toJSON();
    setFormData((prevData) => ({
      ...prevData,
      latitude: position.lat,
      longitude: position.lng,
    }));
  };

  const inputRef = useRef(null);

  useEffect(() => {
    const initAutocomplete = async () => {
      console.log("here");

      if (window.google) {
        console.log("here");

        const autocomplete = new window.google.maps.places.Autocomplete(
          inputRef.current
        );

        autocomplete.addListener("place_changed", async () => {
          const place = autocomplete.getPlace();
          if (place && place.formatted_address) {
            const { location } = place.geometry;
            console.log(place);

            const addressComponents = place.address_components;
            const addressComponentsMap = {};
            let city = "";
            for (let i = 0; i < addressComponents.length; i++) {
              if (addressComponents[i].types.includes("locality")) {
                city = addressComponents[i].long_name;
                break;
              }
            }

            addressComponents.forEach((component) => {
              const types = component.types;
              types.forEach((type) => {
                addressComponentsMap[type] = component.long_name;
              });
            });

            console.log(addressComponentsMap);

            setFormData((prevData) => ({
              ...prevData,
              property_address: place.formatted_address,
              city: city,
              latitude: location.lat(),
              longitude: location.lng(),
            }));

            const localityData = {
              data: {
                place_name: addressComponentsMap["sublocality"],
                state: addressComponentsMap["locality"],
                country: addressComponentsMap["country"],
              },
            };

            try {
              const response = await fetch(
                "https://admin.gulfin.ai/api/localities",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(localityData),
                }
              );

              if (response.ok) {
                const newLocality = await response.json();
                console.log("Locality created:", newLocality);
                const localityId = newLocality.data.id;

                setFormData((prevData) => ({
                  ...prevData,
                  localities: [
                    {
                      id: localityId,
                      position: { end: true },
                    },
                  ],
                }));
              } else {
                console.error("Failed to create locality");
              }
            } catch (error) {
              console.error("Error creating locality:", error);
            }
            const map = new window.google.maps.Map(
              document.getElementById("map"),
              {
                center: { lat: location.lat(), lng: location.lng() },
                zoom: 14,
                options: mapOptions,
              }
            );

            const marker = new window.google.maps.Marker({
              map,
              position: { lat: location.lat(), lng: location.lng() },
              draggable: true,
            });

            marker.addListener("dragend", onDragEnd);
          }
        });
      }
    };

    if (isOpen) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
      script.async = true;
      script.onload = initAutocomplete;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [isOpen]);

  // near by place function
  const handleNearbyPlaceChange = (index, e) => {
    const { name, value } = e.target;
    const updatednearby_places = formData.nearby_places.map((place, i) =>
      i === index ? { ...place, [name]: value } : place
    );
    setFormData({
      ...formData,
      nearby_places: updatednearby_places,
    });
  };

  const handleAddNearbyPlace = () => {
    setFormData({
      ...formData,
      nearby_places: [
        ...formData.nearby_places,
        {
          Distance: "",
          id: null,
          LocationImage: null,
          LocationName: "",
        },
      ],
    });
  };

  const handleMultipleImageUpload = async (e) => {
    const files = e.target.files;
    if (files.length === 0) return;
    const imageIds = [];
    const imageBlobs = [];

    setUploading(true);

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const typeOfImage = file.type.startsWith("image/");
      if (!typeOfImage) continue;

      const imageBlob = URL.createObjectURL(file);
      imageBlobs.push(imageBlob);

      const imageData = new FormData();
      imageData.append("files", file);

      try {
        const response = await axios.post(
          `https://admin.gulfin.ai/api/upload`,
          imageData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status !== 200) {
          throw new Error("Image upload failed");
        }

        const result = response.data;
        const imageId = result[0].id;
        imageIds.push(imageId);

        setUploadCount((prevCount) => prevCount + 1);
      } catch (error) {
        console.error(`Image upload error for file ${i + 1}:, error`);
      }
    }
    // setImageURLs(imageBlobs);
    updateFormDataWithImageIds(imageIds);
    setUploading(false);
    alert(`Images uploaded successfully`);
    setIsImagesUploaded(false);
  };

  const updateFormDataWithImageIds = (imageIds) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      other_images: imageIds,
      image: [imageIds[0]],
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const conversionRates = {
      usdToInr: 83.8,
      usdToAed: 3.67,
      inrToUsd: 0.012,
      inrToAed: 0.044,
      aedToUsd: 0.27,
      aedToInr: 22.83,
    };

    let rent_in_usd = 0;
    let rent_in_inr = 0;
    let rent_in_aed = 0;

    if (formData.currency === "USD") {
      rent_in_usd = formData.rent;
      rent_in_inr = formData.rent * conversionRates.usdToInr;
      rent_in_aed = formData.rent * conversionRates.usdToAed;
    } else if (formData.currency === "INR") {
      rent_in_inr = formData.rent;
      rent_in_usd = formData.rent * conversionRates.inrToUsd;
      rent_in_aed = formData.rent * conversionRates.inrToAed;
    } else if (formData.currency === "AED") {
      rent_in_aed = formData.rent;
      rent_in_usd = formData.rent * conversionRates.aedToUsd;
      rent_in_inr = formData.rent * conversionRates.aedToInr;
    }

    const payload = {
      property_name: formData.property_name,
      property_address: formData.property_address,
      listing_size_unit: formData.listing_size_unit,
      property_size: formData.property_size,
      city: formData.city,
      state: formData.state,
      currency: formData.currency,
      description: formData.description,
      number_of_beds: formData.number_of_beds,
      property_type: formData.property_type,
      thumbnail_image: formData.image,
      furniture: true,
      property_price: formData.property_price,
      status: formData.status,
      project_id: {
        disconnect: [],
        connect: [{ id: formData.project_id, position: { end: true } }],
      },
      property_user: {
        disconnect: [],
        connect: [
          {
            id: loggedInUser.id,
            position: {
              end: true,
            },
          },
        ],
      },
      location: {
        disconnect: [],
        connect: [{ id: selectedCityId, position: { end: true } }],
      },
      floor_number: formData.floor_number,
      flat_number: formData.flat_number,
      number_of_baths: formData.number_of_baths,
      rent: formData.rent,
      coverage_area: formData.coverage_area,
      rent_numerical: formData.rent_numerical,
      coverage_area_numerical: formData.property_size,
      rating: formData.rating,
      latitude: formData.latitude,
      longitude: formData.longitude,
      rent_in_usd: rent_in_usd,
      rent_in_inr: rent_in_inr,
      rent_in_aed: rent_in_aed,
      broker_section: {
        ...formData.broker_section,
      },
      nearby_locations: formData.nearby_locations,
      nearby_places: formData.nearby_places.map((place) => ({
        ...place,
      })),
      amenities: formData.amenities.map((amenitie) => ({
        ...amenitie,
      })),
      other_images: formData.other_images,
      localities: {
        disconnect: [],
        connect: formData.localities,
      },
    };

    onSubmit({ data: payload });
  };

  const options = {
    residential: [
      "Villa",
      "Townhouse",
      "Villa Compound",
      "Residential Plot",
      "Residential Floor",
      "Residential Building",
      "Pent House",
      "Hotel Apartment",
      "Apartment",
      "Other Residential",
    ],
    commercial: [
      "Office",
      "Shop",
      "Warehouse",
      "Labour Camp",
      "Commercial Villa",
      "Commercial Plot",
      "Commercial Floor",
      "Commercial Building",
      "Factory",
      "Industrial Land",
      "Mixed Used Land",
      "Showroom",
      "Other Commercial",
    ],
  };

  const handleDropdownChange = (selectedValue) => {
    setFormData((prevData) => ({
      ...prevData,
      property_type: selectedValue,
    }));
  };

  const handleKeyDown = (e) => {
    const { name, value } = e.target;
    if (formData[name] === "" && e.key === "0") {
      e.preventDefault();
    }
  };

  const handleAmenitySelect = (id, category) => {
    // console.log(category, 'category')
    const index = selectedAmenityId.indexOf(id);
    if (index !== -1) {
      setSelectedAmenityId(selectedAmenityId.filter((item) => item !== id));
      const updatedSelected = selectedAmenities.filter(
        (amenity) => amenity.id !== id
      );
      setSelectedAmenities(updatedSelected);

      setFormData({ ...formData, amenities: updatedSelected });
    } else {
      setSelectedAmenityId([...selectedAmenityId, id]);
      const newSelectedAmenities = [
        ...selectedAmenities,
        ...amenities
          .find((amenity) => amenity.category === category)
          ?.dummyAmenities.filter(
            (amenity) =>
              id === amenity.id &&
              !selectedAmenities.some((selected) => selected.id === amenity.id)
          ),
      ];

      setSelectedAmenities(newSelectedAmenities);

      setFormData({ ...formData, amenities: newSelectedAmenities });
    }
  };
  useEffect(() => {
    console.log(selectedAmenityId, "selected amenity id");
    console.log(selectedAmenities, "selected amenities");
  }, [selectedAmenityId]);

  if (!isOpen) return null;
  if (!isLoaded) return <div>Loading...</div>;

  const mapHeight = formData.latitude && formData.longitude ? "300px" : "0";

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl overflow-y-auto overscrollBehavior-contain h-[650px]">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">{`${
            location.pathname === "/projects"
              ? property && Object.keys(property).length > 0
                ? "Edit Your Listing"
                : "Add a New Listing"
              : "Add a New Property"
          }`}</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 text-3xl"
          >
            &times;
          </button>
        </div>
        <form onSubmit={handleFormSubmit}>
          {/* Form fields */}
          <div className="mb-4 flex space-x-4">
            <div className="w-1/2">
              <label className="block text-sm font-medium mb-1">
                Property Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="property_name"
                value={formData.property_name}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="w-1/2">
              <label className="block text-sm font-medium mb-1">
                Select Type <span className="text-red-500">*</span>
              </label>

              <MultiColumnDropdown
                options={options}
                value={formData.property_type}
                onChange={handleDropdownChange}
                propertyState={propertyState}
              />
            </div>
          </div>
          <div className="mb-4 flex space-x-4">
            <div className="w-1/3">
              <label className="block text-sm font-medium mb-1">
                Property currency <span className="text-red-500">*</span>
              </label>
              <select
                name="currency"
                value={formData.currency}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              >
                <option value="USD">USD</option>
                <option value="INR">INR</option>
                <option value="AED">AED</option>
              </select>
            </div>

            <div className="w-1/3">
              <label className="block text-sm font-medium mb-1">
                Property Price <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                name="rent"
                value={formData.rent}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                className="w-full p-2 border rounded"
                required
                min="1"
              />
            </div>
            <div className="w-1/3">
              <label className="block text-sm font-medium mb-1">
                Select Project <span className="text-red-500">*</span>
              </label>
              <select
                name="project_id"
                value={formData.project_id}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              >
                {projectData.data.map((i) => (
                  <option key={i.id} value={i.id}>
                    {i.attributes.project_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {formData.property_type &&
            formData.property_type !== "Residential Plot" && (
              <div className="mb-4 flex space-x-4">
                <div className="w-1/3">
                  <label className="block text-sm font-medium mb-1">
                    No of Baths <span className="text-red-500">*</span>
                  </label>

                  <input
                    type="number"
                    name="number_of_baths"
                    value={formData.number_of_baths}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    className="w-full p-2 border rounded"
                    required
                    min="1"
                  />
                </div>
                <div className="w-1/3">
                  <label className="block text-sm font-medium mb-1">
                    No of Beds <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    name="number_of_beds"
                    value={formData.number_of_beds}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    className="w-full p-2 border rounded"
                    required
                    min="1"
                  />
                </div>
              </div>
            )}
          {formData.property_type &&
            formData.property_type !== "Residential Plot" &&
            formData.property_type !== "Villa" &&
            formData.property_type !== "Pent House" &&
            formData.property_type !== "Townhouse" &&
            formData.property_type !== "Villa Compound" && (
              <div className="mb-4 flex space-x-4">
                <div className="w-1/3">
                  <label className="block text-sm font-medium mb-1">
                    Floor Number <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    name="floor_number"
                    value={formData.floor_number}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    className="w-full p-2 border rounded"
                    required
                    min="0"
                  />
                </div>
                <div className="w-1/3">
                  <label className="block text-sm font-medium mb-1">
                    Flat Number <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    name="flat_number"
                    value={formData.flat_number}
                    onKeyDown={handleKeyDown}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    required
                    min="1"
                  />
                </div>
                <div className="w-1/3">
                  <label className="block text-sm font-medium mb-1">
                    Select Status <span className="text-red-500">*</span>
                  </label>
                  <select
                    name="status"
                    value={formData.status}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    required
                  >
                    <option value="">Select Status</option>
                    <option value="Ready">Ready</option>
                    <option value="Offplan">Off Plan</option>
                  </select>
                </div>
              </div>
            )}
          <div className="mb-4 flex space-x-4">
            <div className="w-1/4">
              <label className="block text-sm font-medium mb-1">
                Area Unit <span className="text-red-500">*</span>
              </label>
              <select
                name="listing_size_unit"
                value={formData.listing_size_unit}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              >
                <option value="">select unit type</option>
                <option value="meter">Meter</option>
                <option value="feet">Sqrt Feet</option>
              </select>
            </div>
            <div className="w-full">
              <label className="block text-sm font-medium mb-1">
                Property Size <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                name="property_size"
                value={formData.property_size}
                onKeyDown={handleKeyDown}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
                min="0"
              />
            </div>
          </div>
          <div className="mb-4">
            {selectedAmenities && (
              <label className="block font-medium mb-1 text-lg">
                Selected Amenities
              </label>
            )}

            {selectedAmenities && (
              <div className="grid grid-cols-5 gap-x-5 mb-4">
                {selectedAmenities.map((amenity) => (
                  <div key={amenity.id} className="flex items-center mb-2">
                    <FontAwesomeIcon
                      icon={[amenity.icon.prefix, amenity.icon.iconName]}
                      className="mr-2"
                    />
                    <span className="truncate">{amenity.feature_name}</span>
                  </div>
                ))}
              </div>
            )}

            <div className="w-full flex">
              <button
                type="submit"
                className="w-1/10 p-2 bg-blue-500 text-white font-bold rounded"
                onClick={() => setAminityModel(true)}
              >
                Add Amenities <span className="text-red-500">*</span>
              </button>
            </div>
            {aminityModel && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div className="bg-white p-4 rounded-lg shadow-lg w-1/3 max-w-2xl overflow-auto h-4/6">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl">Amenities</h2>
                    <button
                      onClick={() => setAminityModel(false)}
                      className="text-3xl text-gray-500 hover:text-gray-700"
                    >
                      &times;
                    </button>
                  </div>
                  {amenities.map((amenitie) => (
                    <div>
                      <h3
                        key={amenitie.category}
                        className="text-gray-500 font-bold"
                      >
                        {amenitie.category}
                      </h3>
                      <div className="grid grid-cols-3 gap-4 p-4">
                        {amenitie.dummyAmenities.map((amenity) => (
                          <div>
                            <div
                              onClick={(e) =>
                                handleAmenitySelect(
                                  amenity.id,
                                  amenitie.category
                                )
                              }
                              key={amenity.id}
                              className={`bg-gray-100 h-24 border ${
                                selectedAmenityId.includes(amenity.id)
                                  ? "border-blue-500 font-bold"
                                  : ""
                              } rounded text-sm hover:font-bold hover:cursor-pointer text-center pt-4`}
                            >
                              <FontAwesomeIcon icon={amenity.icon} size="2x" />
                              <div>{amenity.feature_name}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                  <button
                    type="submit"
                    className="w-1/4 p-2 bg-blue-500 text-white font-bold rounded m-5"
                    onClick={() => setAminityModel(false)}
                  >
                    Apply
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Property Address <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="property_address"
              ref={inputRef}
              value={formData.property_address}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label
              className={`${
                formData.latitude && formData.longitude ? "block" : "hidden"
              }  text-sm font-medium mb-1`}
            >
              Property Location
            </label>
            <div
              id="map"
              style={{ height: mapHeight, transition: "height 0.3s ease" }}
            ></div>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Property Description <span className="text-red-500">*</span>
            </label>
            <textarea
              type="text"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              className="w-full border rounded h-24"
              required
            />
          </div>
          {/* <div className="mb-4">
            <h3 className="text-lg font-bold mb-2">
              Nearby Places <span className="text-red-500">*</span>
            </h3>
            {formData.nearby_places.map((place, index) => (
              <div key={index} className="mb-4 flex space-x-4 items-center">
                <div className="w-1/3">
                  <label className="block text-sm font-medium mb-1">
                    Location Name
                  </label>
                  <input
                    type="text"
                    name="LocationName"
                    value={place.LocationName}
                    onChange={(e) => handleNearbyPlaceChange(index, e)}
                    className="w-full p-1 border rounded"
                    required={formAction === "create"}
                  />
                </div>
                <div className="w-1/3">
                  <label className="block text-sm font-medium mb-1">
                    Distance
                  </label>
                  <input
                    type="text"
                    name="Distance"
                    value={place.Distance}
                    onChange={(e) => handleNearbyPlaceChange(index, e)}
                    className="w-full p-1 border rounded"
                    required={formAction === "create"}
                  />
                </div>
                <div className="w-1/3">
                  <label className="block text-sm font-medium mb-1">
                    Location Image
                  </label>
                  <input
                    type="file"
                    name="locationimage"
                    onChange={(e) => handleUploadLocationImage(index, e)}
                    className="w-full p-2"
                    required={formAction === "create"}
                  />
                </div>
                <div className="w-1/3 flex items-end">
                  <button
                    type="button"
                    onClick={() => handleRemoveNearbyPlace(index)}
                    className="bg-red-500 text-white p-1 px-3 rounded mt-4"
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddNearbyPlace}
              className="bg-blue-500 text-white p-2 rounded"
            >
              Add Nearby Place
            </button>
          </div> */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Images <span className="text-red-500">*</span>{" "}
              <span className="text-[10px] ml-2">{`(Add at least 5 images)`}</span>{" "}
            </label>

            {formAction === "edit" && (
              <div className="flex flex-wrap gap-2 mb-2">
                {property.other_images.data.map((imageId) => (
                  <div
                    key={imageId}
                    className="relative w-24 h-24 border rounded overflow-hidden"
                  >
                    <img
                      src={imageId?.attributes?.url}
                      alt={`${imageId}`}
                      className="object-cover w-full h-full"
                    />
                  </div>
                ))}
              </div>
            )}
            <input
              type="file"
              name="image"
              onChange={handleMultipleImageUpload}
              className="w-full p-2 border rounded"
              required={formAction === "create"}
              multiple
            />
            {uploading && (
              <div className="flex justify-center items-center mt-2">
                <Loader />
              </div>
            )}
            {!uploading && uploadCount > 0 && (
              <div className="mt-2 text-green-600">
                {uploadCount} images uploaded successfully.
              </div>
            )}
          </div>
          <div className="flex space-x-4">
            <button
              type="reset"
              onClick={onClose}
              className="w-1/2 p-2 bg-gray-300 text-gray-700 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`w-1/2 p-2 rounded ${
                !formData.property_address ||
                uploading ||
                !formData.image ||
                !formData.description
                  ? "bg-gray-300 cursor-not-allowed text-gray-700"
                  : "bg-blue-500 text-white"
              }`}
              disabled={
                !formData.property_address ||
                !formData.image ||
                !formData.description ||
                uploading
              }
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
