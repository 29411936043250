import React from 'react';
export const DeleteConfirmantionModel = ({ isOpen, onCancel, onDelete, property }) => {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white p-4 rounded shadow-lg w-96">
        <h2 className="text-xl font-semibold mb-4">Confirm Delete</h2>
        <p>Are you sure you want to delete</p>
        <div className="mt-4 flex justify-end">
          <button onClick={onCancel} className="bg-gray-200 py-2 px-4 rounded mr-2">
            Cancel 
          </button>
          <button onClick={onDelete} className="bg-red-500 text-white py-2 px-4 rounded">
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};
