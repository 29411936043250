import React, { useEffect, useState } from "react";
import { FaUserCircle, FaRegBell } from "react-icons/fa";
import styles from "./header.module.css";
import { FaSearch } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
const Header = ({ user }) => {
  // const loggedInUser = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
  const location = useLocation();
  const [isPersonal, setIsPersonal] = useState(false);
  useEffect(() => {
    const path = location.pathname;
    const isPersonalDashboard = path.includes("personal");
    setIsPersonal(isPersonalDashboard);
  }, [location.pathname]);

  const handleToggleChange = () => {
    const newPath = isPersonal ? "/dashboard" : "/personal-dashboard";
    navigate(newPath);
  };

  return (
    <header className="flex w-full items-center justify-between bg-white py-1 px-3 rounded-lg">
      <div className={styles.left}>
        {user?.profileImage ? (
          <div className={styles.profileImage}>
            <img
              class="w-10 h-10 rounded-full mr-5 object-cover"
              src={user.profileImage}
              alt="Profile"
            />
          </div>
        ) : (
          <FaUserCircle size={26} className={styles.icon} />
        )}
        <div className="flex gap-x-5">
          <span className={styles.username}>{user?.username}</span>
          <label class="inline-flex items-center cursor-pointer">
            <span class="me-3 text-sm font-medium text-gray-900">Business</span>
            <input
              checked={isPersonal}
              onChange={handleToggleChange}
              type="checkbox"
              value=""
              class="sr-only peer"
            />
            <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
            <span class="ms-3 text-sm font-medium text-gray-900">Personal</span>
          </label>
        </div>
      </div>
      <div className={styles.right}>
        <div className="relative">
          <input
            type="text"
            placeholder="Search..."
            className={`${styles.searchInput} pl-10 pr-4`} // Adjust padding as needed
            style={{ paddingLeft: "2rem" }} // Example of adding padding inline
          />
          {/* Add the search icon inside the border */}
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <FaSearch className="text-gray-400" />
          </div>
        </div>
        <FaRegBell className={styles.icon} />
      </div>
    </header>
  );
};

export default Header;