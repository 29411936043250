import { useState } from "react";
import React from 'react'

const MultiDeveloperDropdown = ({options, value, onChange}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
      setIsOpen(!isOpen);
    };
  
    const handleSelect = (option) => {
      onChange(option);
      setIsOpen(false);
    };
  
    return (
      <div className="relative">
        <button
          onClick={handleToggle}
          className="w-full p-2 border rounded text-left"
        >
          {value || `Select Developer`}
        </button>
        {isOpen && (
          <div className="absolute z-10 bg-white border rounded shadow-lg mt-1 w-full">
            <div className="flex">
              <div className="w-full p-2">
                {/* <h4 className="font-medium mb-2">Residential</h4> */}
                {options.map((option) => (
                  <div
                    key={option.id}
                    className="cursor-pointer p-1 truncate hover:bg-gray-200"
                    onClick={() => handleSelect(option.name)}
                  >
                    {option.name}
                  </div>
                ))}
              </div>
              {/* <div className="w-1/2 p-2">
                <h4 className="font-medium mb-2">Commercial</h4>
                {options.map((option) => (
                  <div
                    key={option.}
                    className="cursor-pointer p-1 truncate hover:bg-gray-200"
                    onClick={() => handleSelect(option)}
                  >
                    {option}
                  </div>
                ))}
              </div> */}
            </div>
          </div>
        )}
      </div>
    );
  };

export default MultiDeveloperDropdown