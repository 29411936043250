import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GetLoggedInUserData } from "../services/api";

const API = process.env.REACT_APP_BACKEND_DOMAIN;

export const getLoggedInUser = createAsyncThunk(
  "leads/GetLoggedInUserData",
  async () => {
    const userData = await GetLoggedInUserData();
    console.log(userData, "in auth slice");
    return userData;
  }
);

export const loginUser = createAsyncThunk(
  "auth/login",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API}api/auth/local`, {
        identifier: credentials.email,
        password: credentials.password,
      });

      const data = response.data;
      if (data.error) {
        throw data.error;
      }

      localStorage.setItem("token", data.jwt);
      localStorage.setItem("user", JSON.stringify(data.user));
      localStorage.setItem("userId", data.user.id);

      return data.user;
    } catch (error) {
      return rejectWithValue(error.message || "Login failed");
    }
  }
);

export const registerUser = createAsyncThunk(
  "auth/register",
  async (credentials, { rejectWithValue }) => {
    const mattermostpayload = {
      email: credentials.email,
      username: credentials.username,
      password: credentials.password,
      team_id: "ix1bdn6bwpbd9gak17r9eydurc"
    };
    
    const createUserIdmatterMost = await axios.post(
      `${API}api/create-user-mattermost
      `,
      mattermostpayload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log(createUserIdmatterMost.data)
    const mattermostUserId = createUserIdmatterMost.data.mattermostUserId
    const mattermostSessioToken = createUserIdmatterMost.data.mattermostSessionToken
    try {
      // First, set confirmUser to false and register the user
      const response = await axios.post(`${API}api/auth/local/register`, {
        username: credentials.username,
        email: credentials.email,
        password: credentials.password,
        confirmed: false,
      });

      const data = response.data;
      const id = data.user.id;    

      const updatedRole = await axios.put(`${API}api/users/${id}`, {
        user_role: credentials.role,
        confirmed: false,
        phone_number: credentials.phone,
        password: credentials.password,
        mattermost_user_id: mattermostUserId,
        mattermost_session_token_id: mattermostSessioToken,
      });

      if (updatedRole.status !== 200) {
        throw new Error("Failed to send email to admin");
      }

      if (data.error) {
        throw data.error;
      }

      const emailResponse = await axios.post(`${API}api/send-email `, {
        to: "admin@gulfin.ai",
        subject: "New User Registration Request",
        text: `A new user has registered with the following details:
               Username: ${credentials.username}
               Email: ${credentials.email}
               Phone: ${credentials.phone}
               Role: ${credentials.role}
               Please approve or reject this registration.`,
        html: `<p>A new user has registered with the following details:</p>
               <p>Username: ${credentials.username}</p>
               <p>Email: ${credentials.email}</p>
               <p>Phone: ${credentials.phone}</p>
               <p>Role: ${credentials.role}</p>
               <p>Please <a href="admin-approval-url">approve</a> or <a href="admin-rejection-url">reject</a> this registration.</p>`,
      });

      if (emailResponse.status !== 200) {
        throw new Error("Failed to send email to admin");
      }

      localStorage.setItem("token", data.jwt);
      localStorage.setItem("user", JSON.stringify(updatedRole.data));

      return {
        message: "Registration request sent. Please wait for admin approval.",
      };
    } catch (error) {
      return rejectWithValue(error.message || "Registration failed");
    }
  }
);
const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: !!localStorage.getItem("token"),
    isRemoteAuthenticationCheck: false,
    user: JSON.parse(localStorage.getItem("user")) || null,
    loading: false,
    userData: [],
    isUserLoading: true,
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("userId");
    },
    setIsUserLoading: (state) => {
      state.isUserLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(getLoggedInUser.fulfilled, (state, action) => {
        state.userData = action.payload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { logout, setIsUserLoading } = authSlice.actions;
export const selectAuth = (state) => state.auth;
export default authSlice.reducer;
