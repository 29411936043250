import React, { useEffect, useState } from "react";
import {
  FaCaretDown,
  FaPaperclip,
  FaPhotoVideo,
  FaSmile,
} from "react-icons/fa";
import profilePicture from "../../../services/images/profileLogo1.jpg";
import { ImageIcon, Paperclip, Smile } from "lucide-react";
import axios from "axios";

export const PostModal = ({ section, isOpen, onClose }) => {
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const id = loggedInUser.id;
  const [imageURL, setImageURL] = useState("");

  const [formData, setFormData] = useState({
    project: {
      connect: [],
    },

    user: {
      connect: [
        {
          id: id,
          position: {
            end: true,
          },
        },
      ],
    },
    post: "",
    location: "",
    number_of_likes: "0",
    number_of_comments: "0",
    category: "Buysell",
    date_posted: "",
    image: null,
  });

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];

    const typeOfImage =
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/svg+xml" ||
      file.type === "image/gif" ||
      file.type === "image/bmp" ||
      file.type === "image/webp";

    if (!typeOfImage) {
      return;
    }
    const imageUrl = URL.createObjectURL(file);
    setImageURL(imageUrl);
    const imageData = new FormData();
    imageData.append("files", file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}api/upload`,
        imageData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("Image upload failed");
      }

      const result = response.data;
      const imageId = result[0].id;
      updateFormDataWithImageId(imageId);
    } catch (error) {
      console.error("Image upload error:", error);
    }
  };

  const updateFormDataWithImageId = (imageId) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: imageId,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "project") {
      const projectId = parseInt(value, 10);
      const newPosition = {
        end: true,
      };

      setFormData((prevFormData) => ({
        ...prevFormData,
        project: {
          connect: [
            {
              id: projectId,
              position: newPosition,
            },
          ],
        },
      }));

      return;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  console.log(formData);

  useEffect(() => {
    const formatDate = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");
      const milliseconds = String(now.getMilliseconds()).padStart(3, "0");

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
    };

    setFormData((prevState) => ({
      ...prevState,
      date_posted: formatDate(),
    }));
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    const dataToSend = {
      data: formData,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_DOMAIN}api/feeds`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToSend),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const res = await response.json();
      console.log("Data submitted successfully");
      onClose();
      window.location.reload();
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 w-1/2 rounded-lg shadow-lg">
        <div className="flex justify-between mb-4">
          <div className="flex items-center space-x-4">
            <img
              src={profilePicture}
              alt="Profile"
              className="w-10 h-10 rounded-full"
            />
            <div className="flex-1">
              <div className="flex items-center cursor-pointer">
                <span className="text-black font-bold">Post</span>
                <span className="text-black-500">
                  <FaCaretDown />
                </span>
              </div>
              <p className="text-l font-light capitalize">
                Post to {section === "communication" ? `General` : section}
              </p>
            </div>
          </div>
          <button
            onClick={onClose}
            className="text-3xl text-gray-500 hover:text-black"
          >
            &times;
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col items-start space-x-4 p-2">
            <textarea
              type="text"
              name="post"
              id="post"
              placeholder="What's on your mind?"
              className="w-full py-2 outline-none focus:border-blue-500 mt-2"
              value={formData.post}
              onChange={handleChange}
            />
          </div>

          <div className="flex justify-center items-center w-auto pb-5">
            {imageURL ? (
              <div className="">
                <img
                  src={imageURL}
                  width={10000}
                  height={400}
                  alt="image"
                  className="w-full h-[250px] object-cover"
                />
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <hr className="border-t-2 border-black-900" />

          <div className="flex items-center justify-between space-x-9 p-3">
            <input
              type="file"
              id="image"
              name="image"
              accept="image/*"
              hidden
              onChange={(e) => handleImageUpload(e)}
            />
            <label
              htmlFor="image"
              className="cursor-pointer flex text-sm py-1 rounded-md justify-center items-center hover:scale-105 transition duration-150 ease-in-out"
            >
              <div className="flex items-center text-black mr-5 hover:text-black-700">
                <FaPhotoVideo className="mr-2 text-blue-500" />
                <span className="text-base">Photo/Video</span>
                <input id="file-upload" type="file" className="hidden" />
              </div>
            </label>
            <label
              htmlFor="image"
              className="cursor-pointer flex text-sm py-1 rounded-md justify-center items-center hover:scale-105 transition duration-150 ease-in-out"
            >
              <div className="flex items-center text-black mr-7 hover:text-black-700">
                <FaPaperclip className="mr-2 text-green-500" />
                <span className="text-base">Attach File</span>
              </div>
            </label>
            <div className="flex items-center text-black mr-7 hover:text-black-700">
              <FaSmile className="mr-2 text-orange-500" />
              <span>Emoji</span>
            </div>

            <button
              type="submit"
              className="bg-[#407BFF] text-white font-medium rounded py-1 px-10"
            >
              <span>POST</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
