import { X } from "lucide-react";
import React, { useState } from "react";

export const InvoiceTable = ({ data }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [helpFormOpen, setHelpFormOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [popupContent, setPopupContent] = useState("");

  const [filters, setFilters] = useState({
    project: "",
    status: "",
    sortBy: "",
  });

  const filteredData = data.filter((invoice) => {
    const typeMatches = filters.type === "" || invoice.type === filters.type;
    const statusMatches =
      filters.status === "" || invoice.status === filters.status;
    return typeMatches && statusMatches;
  });

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const handleTDClick = (content) => {
    // setPopupContent(content);
    setModalOpen(true);
  };

  const handleHelpButtonClick = () => {
    setHelpFormOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://admin.gulfin.ai/api/enquiries", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: formData,
        }),
      });
      if (response.ok) {
        alert("We have recieved your query! You will be reached out soon.");
        setHelpFormOpen(false);
        setFormData({ name: "", email: "", message: "" });
      } else {
        alert("Failed to send message. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <div className="w-full md:w-full">
      <div className="bg-white border border-gray-200 rounded-lg p-4">
        <div className="flex justify-between items-center mb-4">
          <div className="flex gap-x-20">
            <select
              name="type"
              value={filters.type}
              onChange={handleFilterChange}
              className="p-2 border rounded"
            >
              <option value="">Select Type</option>
              <option value="Property">Property</option>
              <option value="Services">Services</option>
            </select>
            <select
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
              className="p-2 border rounded"
            >
              <option value="">Select status</option>
              <option value="In Progress">In Progress</option>
              <option value="Pending">Pending</option>
              <option value="Completed">Completed</option>
              <option value="Cancelled">Cancelled</option>
            </select>
          </div>
          <select
            name="sortBy"
            value={filters.sortBy}
            onChange={handleFilterChange}
            className="p-2 border rounded"
          >
            <option value="">Sort By</option>
            <option value="date">Date</option>
            <option value="invoiceNumber">Invoice Number</option>
          </select>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full table-auto">
            <thead className="bg-blue-500 text-white">
              <tr>
                <th className="px-4 py-2 text-left">Lead ID</th>
                <th className="px-4 py-2 text-left">Type</th>
                <th className="px-4 py-2 text-left">Order Details</th>
                <th className="px-4 py-2 text-left">Status</th>
                <th className="px-4 py-2 text-left">Invoice</th>
                <th className="px-4 py-2"></th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((invoice, index) => (
                <tr
                  key={invoice.id}
                  className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
                >
                  <td className="px-4 py-2">{invoice.leadId}</td>
                  <td className="px-4 py-2">{invoice.type}</td>
                  <td
                    onClick={() => handleTDClick(invoice.orderDetails)}
                    className="px-4 py-2 text-blue-500 underline underline-offset-4 cursor-pointer"
                  >
                    {invoice.orderDetails}
                  </td>
                  <td className="px-4 py-2">{invoice.status}</td>
                  <td className="px-4 py-2 text-blue-500 cursor-pointer">
                    {invoice.invoice}
                  </td>
                  <td className="px-4 py-2">
                    <button
                      onClick={() => handleHelpButtonClick()}
                      className="px-4 py-1.5 bg-blue-200 text-black rounded"
                    >
                      Take Help
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
            {modalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                <div className="bg-white px-20 pb-20 rounded-lg">
                  <div className="flex justify-end">
                    <X
                      color="red"
                      onClick={() => setModalOpen(false)}
                      className=" my-5 hover:scale-105 cursor-pointer"
                    />
                  </div>
                  <div className="grid grid-cols-3 gap-y-4 gap-x-2 justify-center items-center">
                    <div>Listing Id</div>
                    <div>:</div>
                    <div>255576788</div>
                    <div>Lead Id</div>
                    <div>:</div>
                    <div>25557</div>
                    <div>Type</div>
                    <div>:</div>
                    <div>Property</div>
                    <div>Company Name</div>
                    <div>:</div>
                    <div>Kalinga</div>
                    <div>Company Contact</div>
                    <div>:</div>
                    <div>987987979</div>
                    <div>Status</div>
                    <div>:</div>
                    <div>Paid</div>
                    <div>Payment Id</div>
                    <div>:</div>
                    <div>87876877856568788</div>
                  </div>
                </div>
              </div>
            )}

            {helpFormOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                <div className="bg-white px-10 pb-10 rounded-lg w-[700px]">
                  <div className="flex justify-end">
                    <X
                      color="red"
                      onClick={() => setHelpFormOpen(false)}
                      className="my-5 hover:scale-105 cursor-pointer"
                    />
                  </div>
                  <h2 className="text-xl font-semibold mb-4 text-center">
                    Enquiry Form
                  </h2>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <label className="block text-sm font-medium mb-1">
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        className="p-2 border rounded w-full"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium mb-1">
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                        className="p-2 border rounded w-full"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-sm font-medium mb-1">
                        Message
                      </label>
                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        required
                        className="p-2 border rounded w-full"
                      />
                    </div>
                    <div className="flex justify-center">
                      <button
                        type="submit"
                        className="px-4 py-2 bg-blue-500 text-white rounded"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};
