import React, { useState, useEffect } from "react";
import axios from "axios";

export const AddNewServiceListing = ({
  isOpen,
  onClose,
  onSubmit,
  service = {},
  setFormAction,
  serviceSelectId,
  servicesData,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    price: 0,
    budget_friendly: true,
    services_id: "",
    category: "",
    service_type: "",
    delivery_fees: 0,
    currency: "",
    thumbnail_image: null,
  });
  const serviceCategoriesWithTypes = {
    Product: ["delivery fees"],
    Solution: ["On-Premises", "Our Store"],
    Service: ["per hour", "per day", "per week", "per month"],
  };
  const currencies = ["USD", "AED", "INR", "EUR", "GBP"];
  const [serviceCategory, setServiceCategory] = useState("Service");
  const [isServiceTypeEnabled, setIsServiceTypeEnabled] = useState(false);
  console.log(
    "servicesData : ",
    servicesData,
    "servicesSelectId : ",
    serviceSelectId,
    "service : ",
    service
  );

  const loggedInUser = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (service && Object.keys(service).length > 0) {
      setFormAction("edit");
      console.log("here", service);

      setFormData({
        name: service.name || "",
        description: service.description || "",
        price: service.price || "",
        budget_friendly: service.budget_friendly || false,
        services_id: service.services_id.data.id || "",
        category: service.category || "",
        service_type: service.service_type || "",
        delivery_fees: service.delivery_fees || "",
        currency: service.currency || "",
        thumbnail_image: service.thumbnail_image?.id || null,
      });
    } else {
      setFormAction("create");
      setFormData({
        name: "",
        description: "",
        price: "",
        budget_friendly: false,
        services_id: serviceSelectId || "",
        category: service.category || "",
        service_type: service.service_type || "",
        delivery_fees: service.delivery_fees || "",
        currency: service.currency || "",
        thumbnail_image: null,
      });
    }
  }, [service, serviceSelectId, setFormAction]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    const validImageTypes = [
      "image/png",
      "image/jpeg",
      "image/svg+xml",
      "image/gif",
      "image/bmp",
      "image/webp",
    ];

    if (!validImageTypes.includes(file.type)) {
      alert("Invalid image type");
      return;
    }

    const imageData = new FormData();
    imageData.append("files", file);

    try {
      const response = await axios.post(
        "https://admin.gulfin.ai/api/upload",
        imageData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("Image upload failed");
      }

      const result = response.data;
      const imageUrl = result[0].id;
      setFormData((prevFormData) => ({
        ...prevFormData,
        thumbnail_image: imageUrl,
      }));
    } catch (error) {
      console.error("Image upload error:", error);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const payload = {
      name: formData.name,
      description: formData.description,
      user: {
        disconnect: [],
        connect: [
          {
            id: loggedInUser.id,
            position: {
              end: true,
            },
          },
        ],
      },
      services_id: {
        disconnect: [],
        connect: [{ id: formData.services_id, position: { end: true } }],
      },
      price: formData.price,
      budget_friendly: formData.budget_friendly,
      thumbnail_image: formData.thumbnail_image,
      category: formData.category,
      service_type: formData.service_type || "Delivery Fees",
      delivery_fees: formData.delivery_fees.toString(),
      currency: formData.currency,
    };
    console.log({ data: payload });
    onSubmit({ data: payload });
  };

  const handleCategoryChange = (e) => {
    const { name, value } = e.target;
    setServiceCategory(value);
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    setIsServiceTypeEnabled(true);
  };
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-2xl">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Add Your Service Listing</h2>
          <button
            onClick={onClose}
            className="text-3xl text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
        </div>
        <form onSubmit={handleFormSubmit}>
          <div className="mb-4 flex space-x-4">
            <div className="w-1/2">
              <label className="block text-sm font-medium mb-1">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>

            {/* <div className="w-1/2">
              <label className="block text-sm font-medium mb-1">Price</label>
              <input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div> */}
            <div className="w-1/2">
              <label className="block text-sm font-medium mb-1">
                Select Service Category
              </label>
              <select
                name="category"
                value={formData.category}
                onChange={handleCategoryChange}
                className="w-full p-2 border rounded"
                required
              >
                <option value="" disabled>
                  Select Service Category
                </option>
                {Object.keys(serviceCategoriesWithTypes).map(
                  (serviceCategory, index) => (
                    <option key={index} value={serviceCategory}>
                      {serviceCategory}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>
          <div className="mb-4 flex space-x-4">
            {serviceCategory === "Product" ? (
              <div className="w-1/2">
                <label className="block text-sm font-medium mb-1">
                  Delivery Fees
                </label>
                <input
                  type="number"
                  name="delivery_fees"
                  value={formData.delivery_fees}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
            ) : (
              <div className="w-1/2">
                <label className="block text-sm font-medium mb-1">
                  Select Type
                </label>
                <select
                  name="service_type"
                  value={formData.service_type}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                  required
                  disabled={!isServiceTypeEnabled}
                >
                  <option value="" disabled>
                    Select Service Type
                  </option>
                  {serviceCategoriesWithTypes[serviceCategory].map(
                    (serviceType, index) => (
                      <option key={index} value={serviceType}>
                        {serviceType}
                      </option>
                    )
                  )}
                </select>
              </div>
            )}

            <div className="w-1/2">
              <label className="block text-sm font-medium mb-1">
                Budget Friendly
              </label>
              <select
                name="budget_friendly"
                value={formData.budget_friendly}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              >
                <option value="" disabled>
                  Select
                </option>
                {[false, true].map((value, index) => (
                  <option key={index} value={value}>
                    {value ? "No" : "Yes"}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mb-4 flex space-x-4">
            <div className="w-1/2">
              <label className="block text-sm font-medium mb-1">Currency</label>
              <select
                name="currency"
                value={formData.currency}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              >
                <option value="" disabled>
                  Select Currency
                </option>
                {currencies.map((currency, index) => (
                  <option key={index} value={currency}>
                    {currency}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-1/2">
              <label className="block text-sm font-medium mb-1">Price</label>
              <input
                type="number"
                value={formData.price}
                name="price"
                className="w-full p-2 border rounded"
                required
                onChange={handleInputChange}
              ></input>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Description
            </label>
            <textarea
              type="textarea"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Image</label>
            <input
              type="file"
              name="image"
              onChange={handleImageChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="mb-4 flex justify-end w-full">
            <div className="flex h-12 space-x-8 ">
              <button
                onClick={onClose}
                className="bg-gray-300 text-black-700 py-2 px-4 rounded"
              >
                Close
              </button>
              <button
                type="submit"
                className="bg-[#407BFF] text-white py-2 px-4 rounded"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
