import React, { useState, useEffect } from "react";
import { SubHeaderLeads } from "./subheaderLeads";
import { AllLeadsMain } from "./allLeads/AllLeads";
import { AddNewLead } from "../../../components/common/models/addNewLead";
import { createLeads } from "../../../redux/leadSlice";
import { useDispatch } from "react-redux";
import { TrackSystemForProject } from "./TrackSystemForProject";
import { MessagesPageInCommon } from "../../../components/Messages/messages";

export const ProjectLead = ({ leadsData, listings, leadsUserData }) => {
  const [projects, setProjects] = useState([]);
  const [leads, setLeads] = useState([]);
  const [properties, setProperties] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("[properties_name]");
  const [isOrderByDropdownOpen, setIsOrderByDropdownOpen] = useState(false);
  const [showActive, setShowActive] = useState(true);
  const [showInactive, setShowInactive] = useState(true);
  const [isLeadModalOpen, setIsLeadModalOpen] = useState(false);
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [messagesLeadByUser, setMessagesLeadByUser] = useState([]);
  const [activeSubHeader, setActiveSubHeader] = useState("All Leads");
  const [selectedListingMatterMostId, SetSelectedListingMatterMostId] =
    useState("");

  const dispatch = useDispatch();

  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const loggedInId = loggedInUser.id;

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectedList = (data) => {
    setSelectedPropertyId(data.id);
    SetSelectedListingMatterMostId(data.attributes.mattermost_id);
  };

  const handleOrderChange = (value) => {
    setOrderBy(value);
    setIsOrderByDropdownOpen(false);
  };

  const toggleOrderByDropdown = () => {
    setIsOrderByDropdownOpen(!isOrderByDropdownOpen);
  };

  const toggleActive = () => {
    setShowActive(!showActive);
  };

  const toggleInactive = () => {
    setShowInactive(!showInactive);
  };

  const openModal = () => {
    setIsLeadModalOpen(true);
  };

  const closeModal = () => {
    setIsLeadModalOpen(false);
  };

  const handleAddLeadFormSubmit = (formData) => {
    formData.data.crm_listings = {
      disconnect: [],
      connect: [{ id: selectedPropertyId, position: { end: true } }],
    };
    dispatch(
      createLeads({ leadsData: formData, selectedListingMatterMostId })
    ).then(() => {
      window.location.reload();
      closeModal();
    });
  };

  const handleSubHeaderClick = (title) => {
    setActiveSubHeader(title);
    setSelectedPropertyId(null);
  };

  return (
    <div>
      <SubHeaderLeads
        searchTerm={searchTerm}
        handleSearchChange={handleSearchChange}
        orderBy={orderBy}
        isOrderByDropdownOpen={isOrderByDropdownOpen}
        toggleOrderByDropdown={toggleOrderByDropdown}
        handleOrderChange={handleOrderChange}
        // showActive={showActive}
        // toggleActive={toggleActive}
        // showInactive={showInactive}
        // toggleInactive={toggleInactive}
        openModal={openModal}
        activeSubHeader={activeSubHeader}
        handleSubHeaderClick={handleSubHeaderClick}
        selectedPropertyId={selectedPropertyId}
      />
      {activeSubHeader === "All Leads" && (
        <AllLeadsMain
          projectsData={projects}
          properties={listings ? listings : []}
          leadsData={leadsData.data}
          handleSelectedListingId={handleSelectedList}
        />
      )}
      {activeSubHeader === "Lead Tracking System" && (
        <TrackSystemForProject
          projectsData={projects}
          properties={listings ? listings : []}
          leadsData={leadsData.data}
          handleSelectedListingId={handleSelectedList}
        />
      )}
      {activeSubHeader === "Messages" && (
        <MessagesPageInCommon leadsData={leadsUserData.data} />
      )}
      {isLeadModalOpen && (
        <AddNewLead
          isOpen={isLeadModalOpen}
          onClose={closeModal}
          onSubmit={handleAddLeadFormSubmit}
        />
      )}
    </div>
  );
};
