import React, { useState, useEffect } from "react";
import { DeleteConfirmantionModel } from "../../../components/common/models/deleteConfirmantionModel";
import {
  createPropertyListingFromProjects,
  deleteProperty,
  updateProperty,
} from "../../../redux/propertiesSlice";
import axios from "axios";
import { useDispatch } from "react-redux";
import { InventoryProjects } from "../../../components/Projects/InventoryProjects";
import { InventoryProperties } from "../../../components/Projects/InventoryProperties";
import { AddProjectModal } from "../../../components/AddProjectModal";
import {
  createProjects,
} from "../../../redux/proejctsSlice";
import { AddEditPropertyModel } from "../../../components/common/models/addNewProperty";
import { BiChevronDown } from "react-icons/bi";

export const ProjectsInventoryLatest = ({ projectsData, properties1 }) => {
  const [selectedPropjects, setSelectedProjects] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("property_name");
  const [isOrderByDropdownOpen, setIsOrderByDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isPropertyModelOpen, setIsPropertyModelOpen] = useState(false);
  const [propertyToDelete, setPropertyToDelete] = useState(null);
  const [formAction, setFormAction] = useState("create");
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [editPropertyId, setEditPropertyId] = useState(null);
  const [editPropertiesId, setEditPropertiesId] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedProjectListings, setSelectedProjectListings] = useState([]);
  const [isProjectSelected, setIsProjectSelected] = useState(false);
  const dispatch = useDispatch();

  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const id = loggedInUser.id;
  const [isActive, setIsActive] = useState('all');
  const [filteredProjectListings, setFilteredProjectListings] = useState([]);

  useEffect(() => {
    const filterListings = () => {
      const filtered = isActive === 'all' ? selectedProjectListings : selectedProjectListings.filter((listing) =>
        isActive === 'active' ? !listing.attributes.draft : listing.attributes.draft
      );
      setFilteredProjectListings(filtered);
    };

    filterListings();
  }, [selectedProjectListings, isActive]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://admin.gulfin.ai/api/crm-listings?populate=*&filters[property_user][$eq]=${id}&filters[project_id][$eq]=${selectedProjectId}&filters[rent_status][$null]=true}`
        );
        setSelectedProjectListings(response.data.data);
        setIsProjectSelected(true);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [selectedProjectId, isActive, id]);

  const handleProjectsClick = (projectId) => {
    setSelectedProjectId(projectId);
    setCurrentPage(1);
  };

  const handleOrderChange = (value) => {
    setOrderBy(value);
    setIsOrderByDropdownOpen(false);
  };

  const toggleOrderByDropdown = () => {
    setIsOrderByDropdownOpen(!isOrderByDropdownOpen);
  };

  const handleDelete = (id) => {
    setPropertyToDelete(id);
    setShowDeleteModal(true);
  };

  const handleEdit = (property) => {
    setSelectedProperty(property.attributes);
    setEditPropertyId(property.id);
    setIsPropertyModelOpen(true);
  };

  const handleDeleteConfirmation = () => {
    dispatch(deleteProperty(id)).then(() => {
      setIsModalOpen(false);
      window.location.reload();
    });
    setShowDeleteModal(false);
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
    setSelectedProjects({});
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProperty(null);
  };

  const propertyModelOpen = () => {
    setIsPropertyModelOpen(true);
    setSelectedProperty({});
  };

  const propertyModelClose = () => {
    setIsPropertyModelOpen(false);
    setSelectedProperty(null);
    setFormAction("create");
  };

  const handleAddEditProjectsFormSubmit = async (formData) => {
    await dispatch(createProjects(formData)).then(() => {
      setIsModalOpen(false);
      window.location.reload();
    });
    setIsModalOpen(false);
  };

  const handleAddEditFormSubmit = async (formData) => {
    let updatedName = formData.data.property_name.split(" ").join("");
    formData.data.name = updatedName.toLowerCase();
    formData.data.display_name = formData.data.property_name;
    // formData.data.type = "O";
    if (formAction === "edit") {
      await dispatch(
        updateProperty({ id: editPropertyId, propertyData: formData })
      ).then(() => {
        setIsModalOpen(false);
        window.location.reload();
      });
      propertyModelClose();
    } else {
      dispatch(createPropertyListingFromProjects(formData)).then(() => {
        setIsModalOpen(false);
        window.location.reload();
      });
      propertyModelClose();
    }
  };



  return (
    <div className="bg-white rounded h-screen">
      <div className="flex items-center justify-between w-full p-4">
        <div className="flex font-medium gap-x-5">
          <button
            className={`   
              flex items-center text-blue-500 border-blue-300 border-2 p-1.5 px-10 rounded hover:text-white hover:bg-blue-500 hover:border-blue-500 transition-colors ease-linear duration-200`}
            onClick={openModal}
          >
            Add new project +
          </button>
        </div>
        <div className="relative">
          <p className="font-semibold flex flex-col items-center">
            <p className="text-2xl">12/20</p>
            <p className="">Available Credits</p>
          </p>

          {isOrderByDropdownOpen && (
            <div className="absolute right-0 mt-1 bg-white border border-gray-300 rounded shadow-lg">
              <div
                onClick={() => handleOrderChange("property_name")}
                className={`px-4 py-2 cursor-pointer ${
                  orderBy === "property_name"
                    ? "bg-gray-100"
                    : "hover:bg-gray-100"
                }`}
              >
                Title
              </div>
              <div
                onClick={() => handleOrderChange("property_price")}
                className={`px-4 py-2 cursor-pointer ${
                  orderBy === "property_price"
                    ? "bg-gray-100"
                    : "hover:bg-gray-100"
                }`}
              >
                Price
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="w-full">
        <div className="flex w-full h-screen flex-grow pl-4 pr-4">
          <div className="w-full md:w-3/12">
            <div className="bg-[#407BFF] p-2 text-center text-white text-lg font-semibold">
              My Inventory
            </div>
            {projectsData.data.length !== 0 ? (
              <InventoryProjects
                data={projectsData.data}
                handleProjectsClick={handleProjectsClick}
                count={selectedProjectListings}
              />
            ) : (
              <div className="flex flex-col justify-center items-center mt-5">
                <div>No Available Projects.</div>
                <div>Add projects.</div>
              </div>
            )}
          </div>
          <div className="w-full md:w-3/7 mx-2 h-screen">
            <div className="w-full border-2 border-grey-200 ml-2">
              <div className="flex justify-between font-medium">
                <div className="flex items-center gap-4">
                  <button
                    className={`flex items-center text-blue-500 border-blue-300 border-2 p-1.5 px-10 m-2 ml-4 rounded hover:text-white hover:bg-blue-500 hover:border-blue-500 transition-colors ease-linear duration-200`}
                    onClick={() => {
                      if (projectsData.data.length !== 0) {
                        propertyModelOpen();
                      } else {
                        alert(
                          "You don't have any projects yet, try adding a project first."
                        );
                      }
                    }}
                  >
                    Create Listing +
                  </button>
                  <div className="flex w-32">
                  <button
                      className={`px-4 rounded-l-md border-r-0 w-32
    ${
      isActive === 'all'
        ? "bg-green-500 text-white"
        : "bg-gray-100 h-10 border-2 border-gray-300 text-gray-300"
    }`}
                      onClick={() => setIsActive('all')}
                    >
                      All
                    </button>
                    <button
                      className={`px-4 rounded-l--md border-l-0 h-10 w-32
    ${
      isActive === 'active'
        ? "bg-green-500 text-white"
        : "bg-gray-100 border-2 border-gray-300 text-gray-300"
    }`}
                      onClick={() => setIsActive('active') }
                    >
                      Active
                    </button>
                    <button
                      className={`px-4 rounded-r-md border-l-0 h-10 w-32 
    ${
      isActive === 'draft'
        ? "bg-gray-500 text-white"
        : "bg-gray-100 border-2 border-gray-300 text-gray-300"
    }`}
                      onClick={() => setIsActive('draft')}
                    >
                      Draft
                    </button>
                  </div>
                </div>
                <button
                  onClick={toggleOrderByDropdown}
                  className="border-2 border-black text-black p-2 mb-2 mt-2 mr-4 px-4 rounded flex items-center"
                >
                  Sort By{" "}
                  <span className="ml-2">
                    {" "}
                    <BiChevronDown className="text-xl" />
                  </span>
                </button>
              </div>
              <div className="h-96 overflow-y-auto">
                {isProjectSelected ? (
                  filteredProjectListings.length ? (
                    <InventoryProperties
                      properties={filteredProjectListings}
                      onDelete={handleDelete}
                      onEdit={handleEdit}
                      containerWidth={"3/7"}
                      borderOfContainer={"border-b lg"}
                    />
                  ) : (
                    <div className="text-2xl text-center p-4 ml-4">
                      No {isActive ? "active" : "draft"} listings for this
                      particular project.
                    </div>
                  )
                ) : (
                  <div className="text-2xl text-center p-4 ml-4">
                    Select a project to view associated listings.
                  </div>
                )}
              </div>
              {/* <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={setCurrentPage}
                /> */}
            </div>
          </div>
        </div>
      </div>
      {showDeleteModal && (
        <DeleteConfirmantionModel
          isOpen={showDeleteModal}
          onCancel={handleDeleteCancel}
          onDelete={handleDeleteConfirmation}
          property={propertyToDelete}
        />
      )}
      {isPropertyModelOpen && (
        <AddEditPropertyModel
          isOpen={isPropertyModelOpen}
          onClose={propertyModelClose}
          onSubmit={handleAddEditFormSubmit}
          formAction={formAction}
          setFormAction={setFormAction}
          property={selectedProperty}
          projectData={projectsData}
          selectedProjectId={selectedProjectId}
        />
      )}
      {isModalOpen && (
        <AddProjectModal
          isOpen={isModalOpen}
          onClose={closeModal}
          onSubmit={handleAddEditProjectsFormSubmit}
          setFormAction={setFormAction}
          project={selectedPropjects}
        />
      )}
    </div>
  );
};
