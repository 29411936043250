import React, { useState } from "react";
import { IoLocationSharp } from "react-icons/io5";
export const ServicesInventoryServices = ({
  data,
  handlePropertyClick,
  propertyCountMap,
}) => {
  const [selectedServiceId, setSelectedServiceId] = useState(null);

  const handlePropertySelection = (propertyId) => {
    setSelectedServiceId(propertyId);
    handlePropertyClick(propertyId);
  };
  console.log(data, "data in service inverntory");
  return (
    <div className="divide-y divide-gray-200 h-[60vh] overflow-y-auto">
      {data.map((serivce, index) => {
        const isSelected = serivce.id === selectedServiceId;
        return (
          <div
            key={index}
            onClick={() => handlePropertySelection(serivce.id)}
            className={`flex items-center justify-between py-4 px-2 cursor-pointer ${
              isSelected ? "bg-blue-100" : ""
            }`}
          >
            <div className="flex items-center">
              <div className="flex-shrink-0 h-16 w-16 rounded-lg overflow-hidden border border-gray-300">
                <img
                  className="h-full w-full object-cover"
                  src={serivce.attributes.image.data?.attributes.url}
                  alt="Project"
                />
              </div>
              <div className="ml-3 flex-grow flex flex-col">
                <div className="font-semibold">{serivce.attributes.name}</div>
                <div>
                  Price:{" "}
                  <span className="font-medium">
                    {" "}
                    {serivce.attributes.price}
                  </span>
                </div>
                <div className="text-gray-500 flex items-center">
                  <div className="flex items-center">
                    <IoLocationSharp className="text-gray-700 text-xl mr-1" />
                    <p className="w-40 truncate">
                      {serivce.attributes.location},{serivce.attributes.area}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
