import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

export const AddPropertyListingModal = ({
  isOpen,
  onClose,
  onSubmit,
  property,
  formAction,
  setFormAction,
  propertiesData,
}) => {
  const [isImagesUploaded, setIsImagesUploaded] = useState(false);
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();


  console.log(property);
  

  const dummyAmenities = [
    { id: 1, name: "Bed" },
    { id: 2, name: "Bath" },
    { id: 3, name: "Parking" },
    { id: 4, name: "Balcony" },
    { id: 5, name: "Gym" },
    { id: 6, name: "Swimming Pool" },
    { id: 7, name: "Steam Room" },
    { id: 8, name: "Wi-Fi" },
    { id: 9, name: "Lamp" },
    { id: 10, name: "Surveillance Camera" },
    { id: 11, name: "Laundry" },
  ];

  const [selectedAmenities, setSelectedAmenities] = useState([]);

  const [formData, setFormData] = useState({
    property_name: "",
    property_address: "",
    city: "",
    state: "",
    currency: "",
    description: "",
    number_of_beds: "",
    property_type: "",
    property_size: "",
    furniture: "",
    category: "",
    property_price: "",
    status: "",
    project_id: "",
    floor_number: "",
    rent_status: "",
    flat_number: null,
    number_of_baths: "",
    rent: null,
    coverage_area: null,
    mattermost_id: "",
    residencial: false,
    commercial: false,
    rent_numerical: null,
    coverage_area_numerical: null,
    rating: null,
    latitude: null,
    longitude: null,
    image: null,
    // broker_section: {
    //   broker_email: null,
    //   broker_img: null,
    //   broker_name: '',
    //   broker_phone_number: null,
    //   broker_whatsapp_number: null,
    //   description: '',
    //   id: null,
    //   Reviews: ''
    // },
    nearby_places: [
      {
        Distance: "",
        LocationImage: null,
        LocationName: "",
      },
    ],
    amenities: [
      {
        feature_name: "",
        feature_img: null,
      },
    ],
    other_images: [],
  });

  useEffect(() => {
    if (property && property.attributes) {
      const listing = property.attributes;
      console.log(listing);

      const extractedImageIds =
        listing.other_images?.data.map((image) => image.id) || [];

      setFormData({
        property_size: listing.property_size || "",
        property_type: listing.property_type || "",
        city: listing.city || "",
        state: listing.state || "",
        currency: listing.currency || "",
        category: listing.residencial
          ? "residencial"
          : listing.commercial
          ? "commercial"
          : "",
        furniture: listing.furniture || "",
        project_id: listing.project_id?.data?.id || "",
        number_of_baths: listing.number_of_baths || "",
        number_of_beds: listing.number_of_beds || "",
        floor_number: listing.floor_number || "",
        rent_status: "",
        flat_number: listing.flat_number || "",
        status: listing.status || "",
        rent: listing.rent || "",
        property_price: listing.property_price || "",
        property_address: listing.property_address || "",
        image: listing.thumbnail_image.data.id || null,
        residencial: listing.residencial,
        commercial: listing.commercial,
        rent_numerical: listing.rent_numerical,
        coverage_area: listing.coverage_area,
        coverage_area_numerical: listing.coverage_area_numerical,
        rating: listing.rating,
        latitude: listing.latitude,
        longitude: listing.longitude,
        mattermost_id: listing.mattermost_id,
        // broker_section: property.broker_section || {
        //   broker_email: loggedInUser.email,
        //   broker_img: null,
        //   broker_name: loggedInUser.email,
        //   broker_phone_number: null,
        //   broker_whatsapp_number: null,
        //   description: 'Broker',
        //   id: loggedInUser.id,
        //   Reviews: ''
        // },
        nearby_places: listing.nearby_places || [
          {
            distance: "",
            LocationImage: null,
            LocationName: "",
          },
        ],
        // amenities: listing.amenities || [
        //   {
        //     feature_name: "",
        //     feature_img: null,
        //   },
        // ],

        other_images: extractedImageIds,
      });
    }
  }, [property]);

  const handleSelectChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions);
    const selectedIds = selectedOptions.map((option) => parseInt(option.value));

    const newSelectedAmenities = [
      ...selectedAmenities,
      ...dummyAmenities.filter(
        (amenity) =>
          selectedIds.includes(amenity.id) &&
          !selectedAmenities.some((selected) => selected.id === amenity.id)
      ),
    ];
    setSelectedAmenities(newSelectedAmenities);
    setFormData({ ...formData, amenities: newSelectedAmenities });
  };

  const handleRemoveAmenity = (id) => {
    const updatedSelected = selectedAmenities.filter(
      (amenity) => amenity.id !== id
    );
    setSelectedAmenities(updatedSelected);
    setFormData({ ...formData, amenities: updatedSelected });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log(formData);
  };

  console.log(formData)



  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const conversionRates = {
      usdToInr: 83.8,
      usdToAed: 3.67,
      inrToUsd: 0.012,
      inrToAed: 0.044,
      aedToUsd: 0.27,
      aedToInr: 22.83,
    };

    let rent_in_usd = 0;
    let rent_in_inr = 0;
    let rent_in_aed = 0;

    if (formData.currency === "USD") {
      rent_in_usd = formData.rent;
      rent_in_inr = formData.rent * conversionRates.usdToInr;
      rent_in_aed = formData.rent * conversionRates.usdToAed;
    } else if (formData.currency === "INR") {
      rent_in_inr = formData.rent;
      rent_in_usd = formData.rent * conversionRates.inrToUsd;
      rent_in_aed = formData.rent * conversionRates.inrToAed;
    } else if (formData.currency === "AED") {
      rent_in_aed = formData.rent;
      rent_in_usd = formData.rent * conversionRates.aedToUsd;
      rent_in_inr = formData.rent * conversionRates.aedToInr;
    }

    const payload = {
      property_name: formData.property_name,
      property_address: formData.property_address,
      city: formData.city,
      state: formData.state,
      currency: formData.currency,
      description: formData.description,
      number_of_beds: formData.number_of_beds,
      property_type: formData.property_type,
      property_size: formData.property_size,
      thumbnail_image: formData.image,
      furniture: true,
      property_price: formData.property_price,
      status: formData.status,
      rent_status: formData.rent_status,
      mattermost_id: formData.mattermost_id,
      rent_in_usd: rent_in_usd,
      project_id: {
        disconnect: [],
        connect: [{ id: formData.project_id, position: { end: true } }],
      },
      property_user: {
        disconnect: [],
        connect: [
          {
            id: loggedInUser.id,
            position: {
              end: true,
            },
          },
        ],
      },
      floor_number: formData.floor_number,
      flat_number: formData.flat_number,
      number_of_baths: formData.number_of_baths,
      rent: formData.rent,
      coverage_area: formData.coverage_area,
      residencial: formData.category === "residencial",
      commercial: formData.category === "commercial",
      rent_numerical: formData.rent_numerical,
      coverage_area_numerical: formData.property_size,
      rating: formData.rating,
      latitude: formData.latitude,
      longitude: formData.longitude,
      broker_section: {
        ...formData.broker_section,
      },
      nearby_places: formData.nearby_places.map((place) => ({
        ...place,
      })),
      amenities: formData.amenities.map((amenitie) => ({
        ...amenitie,
      })),
      other_images: formData.other_images,
    };

    console.log(payload);
    onSubmit({ data: payload });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl overflow-y-auto overscrollBehavior-contain h-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Add a New Listing</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 text-3xl"
          >
            &times;
          </button>
        </div>
        <form onSubmit={handleFormSubmit}>
          <div className="flex gap-x-10">
            <div className="w-full">
              <label className="block text-sm font-medium mb-1">
                Listing Name
              </label>
              <input
                type="text"
                name="property_name"
                value={formData.property_name}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
              />
            </div>
          </div>
          <div className="my-4 flex space-x-4">
            <div className="w-full">
              <label className="block text-sm font-medium mb-1">
                Listing Description
              </label>
              <textarea
                type="text"
                rows={5}
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
              />
            </div>
          </div>

          <div className="flex gap-x-10">
            <div className="w-1/3">
              <label className="block text-sm font-medium mb-1">Status</label>
              <select
                name="rent_status"
                value={formData.rent_status}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
              >
                <option value="">Select Status</option>
                <option value="rent ">Rent</option>
                <option value="buy">Buy</option>
                <option value="shared">Shared</option>
              </select>
            </div>

            {formData.rent_status === "rent " && (
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">
                  Rent Type
                </label>
                <select
                  name="rent_type"
                  value={formData.rent_type}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                >
                  <option value="">Select Rent Type</option>
                  <option value="yearly">Yearly</option>
                  <option value="monthly">Monthly</option>
                </select>
              </div>
            )}
            <div className="mb-4 flex space-x-4">
              <div className="w-full">
                <label className="block text-sm font-medium mb-1">Price</label>
                <input
                  type="text"
                  name="rent"
                  value={formData.rent}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
            </div>
          </div>

          <div className="mb-4">
            <div className="block text-sm font-medium mb-1">
              Select Amenities
            </div>

            {/* Display selected amenities */}
            <div className="mb-4">
              {selectedAmenities.map((amenity) => (
                <span
                  key={amenity.id}
                  className="inline-flex items-center bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded"
                >
                  {amenity.name}
                  <button
                    type="button"
                    onClick={() => handleRemoveAmenity(amenity.id)}
                    className="ml-1 text-red-500"
                  >
                    &times;
                  </button>
                </span>
              ))}
            </div>
            <select
              onChange={handleSelectChange}
              className="w-full p-2 border rounded"
              multiple
            >
              {dummyAmenities.map((amenity) => (
                <option key={amenity.id} value={amenity.id}>
                  {amenity.name}
                </option>
              ))}
            </select>
          </div>

          <div className="flex space-x-4">
            <button
              type="reset"
              onClick={onClose}
              className="w-1/2 p-2 bg-gray-300 text-gray-700 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="w-1/2 p-2 bg-blue-500 text-white rounded"
              // {isImagesUploaded?disabled:disabled}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
