import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaBars, FaEnvelope, FaBuilding, FaCog } from "react-icons/fa";
import { MdDashboard, MdExplore } from "react-icons/md";
import { FaUserGear } from "react-icons/fa6";
import { GoProject } from "react-icons/go";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import styles from "./sidebar.module.css";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../redux/sidebarSlice";
import { logout } from "../../redux/authSlice";
import { useNavigate } from "react-router-dom";
import { RiLogoutCircleLine } from "react-icons/ri";
const Sidebar = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const role = loggedInUser.user_role;

  const [isOpen, setIsOpen] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isCollapsed = useSelector((state) => state.sidebar.isCollapsed);
  const handleToggleSidebar = () => {
    setIsOpen(!isOpen);
    dispatch(toggleSidebar());
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <nav
      className={`${styles.sidebar} ${
        isOpen ? styles.expanded : styles.collapsed
      } `}
    >
      <div className="flex justify-end items-center mb-6">
        <FaBars
          className={`${styles.hamburger} ${
            isOpen ? styles.expanded : styles.collapsed
          }`}
          onClick={handleToggleSidebar}
        />
      </div>
      <ul className="flex flex-col font-medium">
        <li className="mb-4">
          <NavLink
            to="/dashboard"
            className={({ isActive }) =>
              isActive
                ? `${styles["nav-link"]} ${styles.active}`
                : styles["nav-link"]
            }
          >
            <MdDashboard className={styles.icon} />
            <span className={isOpen ? "block" : "hidden"}>Dashboard</span>
          </NavLink>
        </li>
        <li className="mb-4">
          <NavLink
            to="/feed"
            className={({ isActive }) =>
              isActive
                ? `${styles["nav-link"]} ${styles.active}`
                : styles["nav-link"]
            }
          >
            <MdExplore className={styles.icon} />
            <span className={isOpen ? "block" : "hidden"}>Feed</span>
          </NavLink>
        </li>
{/* 
        <li className="mb-4">
          <NavLink
            to="/communication"
            className={({ isActive }) =>
              isActive
                ? `${styles["nav-link"]} ${styles.active}`
                : styles["nav-link"]
            }
          >
            <FaEnvelope className={styles.icon} />
            <span className={isOpen ? "block" : "hidden"}>Communication</span>
          </NavLink>
        </li> */}

        <li className="mb-4">
          <NavLink
            to="/projects"
            className={({ isActive }) =>
              isActive
                ? `${styles["nav-link"]} ${styles.active}`
                : styles["nav-link"]
            }
          >
            <GoProject className={styles.icon} />
            <span className={isOpen ? "block" : "hidden"}>Projects</span>
          </NavLink>
        </li>

        <li className="mb-4">
          <NavLink
            to="/properties"
            className={({ isActive }) =>
              isActive
                ? `${styles["nav-link"]} ${styles.active}`
                : styles["nav-link"]
            }
          >
            <FaBuilding className={styles.icon} />
            <span className={isOpen ? "block" : "hidden"}>Properties</span>
          </NavLink>
        </li>

        <li className="mb-4">
          <NavLink
            to="/services"
            className={({ isActive }) =>
              isActive
                ? `${styles["nav-link"]} ${styles.active}`
                : styles["nav-link"]
            }
          >
            <FaUserGear className={styles.icon} />
            <span className={isOpen ? "block" : "hidden"}>My Services</span>
          </NavLink>
        </li>

        <li className="mb-4">
          <NavLink
            to="/invoice"
            className={({ isActive }) =>
              isActive
                ? `${styles["nav-link"]} ${styles.active}`
                : styles["nav-link"]
            }
          >
            <LiaFileInvoiceSolid className={styles.icon} />
            <span className={isOpen ? "block" : "hidden"}>Invoice</span>
          </NavLink>
        </li>
        <li className="mb-4">
          <NavLink
            to="/settings"
            className={({ isActive }) =>
              isActive
                ? `${styles["nav-link"]} ${styles.active}`
                : styles["nav-link"]
            }
          >
            <FaCog className={styles.icon} />
            <span className={isOpen ? "block" : "hidden"}>Settings</span>
          </NavLink>
        </li>
        <li className="mb-4 flex">
          {!isOpen ? (
            <RiLogoutCircleLine
              onClick={handleLogout}
              className="ml-4 text-xl "
              style={{ cursor: "pointer" }}
            />
          ) : (
            <button
              onClick={handleLogout}
              className="ml-4 w-32 px-4 py-2 border border-black text-sm font-medium rounded-md text-black"
            >
              Logout
            </button>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;
