// import "./App.css";
// import React, { useEffect, useState } from "react";
// import { BrowserRouter as Router } from "react-router-dom";
// import { RoutesComponent } from "./pages/Routes/RouterPage";
// import { useSelector, useDispatch } from "react-redux";
// import { selectAuth, setIsAuthenticated } from "./redux/authSlice";
// import {
//   getLocalStorage,
//   setLocalStorage,
// } from "../src/handlers/local-storage";
// import { fetchUserDetailsFromId } from "./services/api";

// function App() {
//   const { isAuthenticated } = useSelector(selectAuth);
//   // const [userIdState, setUserIdState] = useState(null);
//   // const dispatch = useDispatch();

//   // useEffect(() => {
//   //   const checkToken = async () => {
//   //     if (isAuthenticated === false) {
//   //       const token = await getLocalStorage("token");
//   //       console.log("Token from useEffect:", token);
//   //       setLocalStorage("token", token);
//   //       if (token) dispatch(setIsAuthenticated());
//   //     }
//   //   };

//   //   checkToken();
//   // }, []);

//   // useEffect(() => {
//   //   const fetchUserId = async () => {
//   //     const userId = await getLocalStorage("userId");
//   //     console.log("UserId from useEffect:", userId);
//   //     setLocalStorage("userId", userId);
//   //     setUserIdState(userId);
//   //   };

//   //   fetchUserId();
//   // }, []);

//   // useEffect(() => {
//   //   const fetchUserDetails = async () => {
//   //     if (userIdState) {
//   //       const res = await fetchUserDetailsFromId(userIdState);
//   //       console.log("User data:", res, res.name);
//   //       setLocalStorage("user", JSON.stringify(res));
//   //     }
//   //   };

//   //   fetchUserDetails();
//   // }, [userIdState]);

//   // useEffect(() => {
//   //   getLocalStorage("token").then((token) => {
//   //     console.log("Token from useEffect:", token);
//   //     setLocalStorage("token", token);
//   //   });
//   //   getLocalStorage("userId").then((userId) => {
//   //     console.log("UserId from useEffect:", userId);
//   //     setLocalStorage("userId", userId);
//   //   });
//   // }, []);

//   return (
//     <Router>
//       <div className="min-h-screen bg-gray-100" style={{ height: "100vh" }}>
//         <RoutesComponent isAuthenticated={isAuthenticated} />
//       </div>
//     </Router>
//   );
// }

// export default App;

import "./App.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { RoutesComponent } from "./pages/Routes/RouterPage";
import { useSelector, useDispatch } from "react-redux";
import { selectAuth, setIsUserLoading } from "./redux/authSlice";
import { fetchUserDetailsFromId } from "./services/api";

function App() {
  const { isAuthenticated } = useSelector(selectAuth);
  const [userIdState, setUserIdState] = useState(null);
  const dispatch = useDispatch();

  console.log("app.js");
  useEffect(() => {
    const checkAndSetLocalStorage = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");
      const userId = urlParams.get("userId");

      var storedToken = localStorage.getItem("token");
      var storedUserId = localStorage.getItem("userId");
      console.log("Stored token:", storedToken);
      if (token && !storedToken) {
        localStorage.setItem("token", token);
        storedToken = token;
      }

      if (userId && !storedUserId) {
        localStorage.setItem("userId", userId);
        storedUserId = userId;
      }

      if (!storedToken || !storedUserId) {
        if (window.location.pathname === "/login") return;
        else {
          window.location.href = "/login";
          return;
        }
      }

      setUserIdState(storedUserId);
    };

    checkAndSetLocalStorage();
  }, []);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (!user && userIdState) {
      fetchUserDetailsFromId(userIdState).then((res) => {
        localStorage.setItem("user", JSON.stringify(res));
      });
    }
    setTimeout(() => {
      dispatch(setIsUserLoading());
    }, 1200);
  }, [userIdState]);

  return (
    <Router>
      <div className="min-h-screen bg-gray-100" style={{ height: "100vh" }}>
        <RoutesComponent isAuthenticated={isAuthenticated} />
      </div>
    </Router>
  );
}

export default App;
