import React, { useState, useEffect } from "react";
import { DeleteConfirmantionModel } from "../../../components/common/models/deleteConfirmantionModel";
import {
  createProperty,
  deleteProperty,
  updateProperty,
} from "../../../redux/propertiesSlice";
import { Pagination } from "../../../components/common/pagination/pagination";
import { useDispatch } from "react-redux";
import { AddEditPropertyModel } from "../../../components/common/models/addNewProperty";
import { MyPropertiesListing } from "../../../components/Properties/MyPropertiesListing";
import { MyListingPage } from "../../../components/Properties/myListingPage";
import { EditPropertyInventoryListingModel } from "../../../components/common/models/EditPropertyInventoryListing";
import {
  createListing,
  deleteListing,
  updateListing,
} from "../../../redux/listingSlice";
import { EditPropertyListingModal } from "../../../components/common/models/editPropertyListingModal";

export const PropertiesListings = ({ properties1, listings, pageCount }) => {
  const [listing, setListing] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("property_name");
  const [isOrderByDropdownOpen, setIsOrderByDropdownOpen] = useState(false);
  const [showActive, setShowActive] = useState(true);
  const [isPropertyModelOpen, setIsPropertyModelOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [propertyToDelete, setPropertyToDelete] = useState(null);
  const [formAction, setFormAction] = useState("create");
  const [currentPage, setCurrentPage] = useState(1);
  const [editListingId, setEditListingId] = useState(null);
  const [filteredListings, setFilteredListings] = useState([]);
  const [isActive, setIsActive] = useState("all");

  const propertiesPerPage = 10;
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(listings, "listings");
    const filterListings = () => {
      const filtered =
        isActive === "all"
          ? listings
          : listings.filter((listing) =>
              isActive === "active"
                ? !listing.attributes.draft
                : listing.attributes.draft
            );
      setFilteredListings(filtered);
    };

    filterListings();
  }, [listings, isActive]);

  const handleOrderChange = (value) => {
    setOrderBy(value);
    setIsOrderByDropdownOpen(false);
  };

  const toggleOrderByDropdown = () => {
    setIsOrderByDropdownOpen(!isOrderByDropdownOpen);
  };

  const toggleActive = () => {
    setShowActive(!showActive);
  };

  const handleDelete = (id) => {
    setPropertyToDelete(id);
    setShowDeleteModal(true);
  };

  const handleEdit = (listing) => {
    setSelectedProperty(listing);
    setEditListingId(listing.id);
    setIsEditModalOpen(true);
  };

  const handleDeleteConfirmation = () => {
    dispatch(deleteListing(propertyToDelete)).then(() => {
      window.location.reload();
      setShowDeleteModal(false);
    });
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
  };


  const closeEditModal = () => {
    setIsEditModalOpen(false);
    // setSelectedProperty(null);
  };

  const handleEditListingSubmit = (formData) => {
    dispatch(updateListing({ id: editListingId, listingData: formData })).then(() => {
      alert("Listing updated successfully!");
      closeEditModal();
      window.location.reload();
    });
  };


  return (
    <div className="bg-white h-[100vh]">
      <div className="flex items-center justify-between w-full p-4">
        <div className="flex font-medium">
          {/* <button className="flex items-center border-black border-2 p-1.5 px-10 rounded hover:text-white hover:bg-black transition-colors ease-linear duration-200">Filter</button> */}
          {/* <button
            className={`flex items-center text-blue-500 border-blue-300 border-2 p-1.5 px-10 rounded hover:text-white hover:bg-blue-500 hover:border-blue-500 transition-colors ease-linear duration-200`}
            onClick={propertyModelOpen}
          >
            Create a Listing +
          </button> */}
          <div className="flex w-full">
            <button
              className={`px-4 rounded-l-md border-r-0 w-32
                        ${
                          isActive === "all"
                            ? "bg-green-500 text-white h-10"
                            : "bg-gray-100 h-10 border-2 border-gray-300 text-gray-300"
                        }`}
              onClick={() => setIsActive("all")}
            >
              All
            </button>
            <button
              className={`px-4 rounded-l--md border-l-0 h-10 w-32
                        ${
                          isActive === "active"
                            ? "bg-green-500 text-white"
                            : "bg-gray-100 border-2 border-gray-300 text-gray-300"
                        }`}
              onClick={() => setIsActive("active")}
            >
              Active
            </button>
            <button
              className={`px-4 rounded-r-md border-l-0 h-10 w-32 
                        ${
                          isActive === "draft"
                            ? "bg-gray-500 text-white"
                            : "bg-gray-100 border-2 border-gray-300 text-gray-300"
                        }`}
              onClick={() => setIsActive("draft")}
            >
              Draft
            </button>
          </div>
        </div>
      </div>
      <div className="w-full h-[600px] overflow-y-auto">
        {listings.length ? (
          <>
            <div className="flex flex-wrap ">
              <MyListingPage
                listing={filteredListings}
                onDelete={handleDelete}
                onEdit={handleEdit}
                containerWidth={"1/2"}
                borderOfContainer={"p-4 border rounded-lg"}
              />
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={pageCount.pagination.pageCount}
              onPageChange={setCurrentPage}
            />
          </>
        ) : (
          <div className="text-2xl text-center p-4 ml-4">
            Listing not found for this property
          </div>
        )}
      </div>

      {showDeleteModal && (
        <DeleteConfirmantionModel
          isOpen={showDeleteModal}
          onCancel={handleDeleteCancel}
          onDelete={handleDeleteConfirmation}
          property={propertyToDelete}
        />
      )}

      {isEditModalOpen && (
        <EditPropertyListingModal
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          onSubmit={handleEditListingSubmit}
          property={selectedProperty}
        />
      )}
    </div>
  );
};
