import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dummyData from "../../services/dummy_data.json";
import { DashboardCharts } from "../../components/charts/DashboardChart";
import { FaRegBuilding } from "react-icons/fa";

export const PersonalDashboardPage = () => {
  const dispatch = useDispatch();
  const dashboardData = useSelector((state) => state.dashboard);

  //   useEffect(() => {
  //     dispatch(calculateDashboardData(dummyData.properties, dummyData.services));
  //   }, [dispatch]);

  if (!dashboardData) return <div>Loading...</div>;

  const combinedProfitByMonth = {
    totalSales: dummyData.properties.profitByMonth.map(
      (value, index) => value + dummyData.services.profitByMonth[index]
    ),
    totalConversions: dummyData.properties.profitByMonth.map(
      (value, index) => value + dummyData.services.profitByMonth[index]
    ),
  };

  const combinedIncomeBreakdown = {
    serviceIncome:
      dummyData.properties.incomeBreakdown.serviceIncome +
      dummyData.services.incomeBreakdown.serviceIncome,
    rentalIncome:
      dummyData.properties.incomeBreakdown.rentalIncome +
      dummyData.services.incomeBreakdown.rentalIncome,
    propertyIncome:
      dummyData.properties.incomeBreakdown.propertyIncome +
      dummyData.services.incomeBreakdown.propertyIncome,
  };

  return (
    <div className="dashboard-page">
      <div
        className="flex justify-start rounded-lg shadow-md mb-8"
        style={{ margin: "auto", backgroundColor: "white" }}
      >
        {/* Box 1 */}
        <div className="w-4/5 flex items-center p-4">
          <FaRegBuilding className="text-4xl text-blue-500 mr-4" />
          <div>
            <p className="text-2xl font-bold text-gray-900">
              {dashboardData.totalLeads}
            </p>
            <h3 className="text-lg font-semibold text-gray-800">
              Total Purchases
            </h3>
          </div>
        </div>
      </div>
      {/* <div className='rounded-lg shadow-md mb-8'> */}
      <DashboardCharts
        profitByMonth={combinedProfitByMonth}
        incomeBreakdown={combinedIncomeBreakdown}
      />
      {/* </div> */}
    </div>
  );
};
