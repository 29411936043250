import React from 'react';
import { MdOutlineArrowCircleLeft, MdOutlineArrowCircleRight } from "react-icons/md";
export const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    if (pageNumber !== currentPage) {
      onPageChange(pageNumber);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`px-2 py-1 mx-1 ${i === currentPage ? 'border border-1 border-black text-blue-700' : 'bg-gray-200'}`}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  return (
    <div className="flex items-center justify-center my-4">
      <MdOutlineArrowCircleLeft
      className={`mx-1 font-size-12 ${currentPage === 1 ? ' cursor-not-allowed text-black' : 'text-black'}`}
      onClick={handlePreviousPage}
      disabled={currentPage === 1}
      />
      {renderPageNumbers()}
        <MdOutlineArrowCircleRight
        className={`mx-1 ${currentPage === totalPages ? ' cursor-not-allowed' : 'text-black'}`}
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
        />
    </div>
  );
};
