import React, { useEffect, useState } from "react";
import { IoLocationSharp } from "react-icons/io5";

export const InventoryProperties = ({
  data,
  handlePropertyClick,
  propertyCountMap,
}) => {
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  const handlePropertySelection = (propertyId) => {
    setSelectedProjectId(propertyId);
    handlePropertyClick(propertyId);
  };

  return (
    <div className="divide-y divide-gray-200 h-96 overflow-y-auto">
      {data.map((properties, index) => {
        // Ensure properties and attributes are not null or undefined
        if (
          !properties ||
          !properties.attributes ||
          !properties.attributes.thumbnail_image ||
          !properties.attributes.thumbnail_image.data
        ) {
          return null;
        }
        const isSelected = properties.id === selectedProjectId;
        return (
          <div
            key={index}
            onClick={() => handlePropertySelection(properties.id)}
            className={`flex items-center justify-between py-4 px-2 cursor-pointer ${isSelected ? "bg-blue-100" : ""
              }`}
          >
            <div className="flex items-center">
              <div className="flex-shrink-0 h-16 w-16 rounded-lg overflow-hidden border border-gray-300">
                <img
                  className="h-full w-full object-cover"
                  src={
                    properties.attributes.thumbnail_image.data.attributes.url
                  }
                  alt="Project"
                />
              </div>
              <div className="ml-2 flex flex-col">
                <div className="font-semibold">
                  {properties.attributes.property_name}
                </div>
                <div className="text-gray-500 flex items-center">
                  <div className="flex items-center">
                    <IoLocationSharp className="text-gray-700 text-xl mr-2" />
                    <p className="w-40 truncate">{properties.attributes.property_address}</p>
                  </div>
                </div>
                {/* <div className="text-gray-500 flex gap-x-5">
                  <p>{propertyCountMap[properties.id] || 0} Listings</p>
                  <p>{properties.attributes.property_size}</p>
                </div> */}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
