import React from "react";
import { GlobalAnalyticsChart } from "../../../components/charts/reuseChart";
import { RecentTransactions } from "../../../components/common/RecentTransactions/recentTransactions";
// import {TopProjects} from './topProjects';
// import {RecentTransactions} from './recentTransactions';

export const AnalystServicesPage = ({ leadsData, servicesListing }) => {
  console.log(leadsData);
  console.log(servicesListing);
  
  const filterAndCountLeads = (data) => {
    const getDateParts = (dateString) => {
      const date = new Date(dateString);
      return { month: date.getMonth() + 1, year: date.getFullYear() };
    };

    const currentMonth = new Date().getMonth() + 1; 
    let countsArray = Array(currentMonth).fill(0); 

    data.data.forEach((lead) => {
      if (lead.attributes.crm_service_listings.data.length > 0) {
        const { month } = getDateParts(lead.attributes.createdAt);
        if (month <= currentMonth) {
          countsArray[month - 1]++; 
        }
      }
    });

    return countsArray;
  };

  const totalLeadsData = filterAndCountLeads(leadsData);

  const countPropertiesUpToCurrentMonth = (properties) => {
    // Helper function to get month from createdAt
    const getMonth = (dateString) => {
      const date = new Date(dateString);
      return date.getMonth(); // Returns month index starting from 0 for January
    };

    // Determine the current month index (0-based)
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();

    // Initialize an array with zeros for each month up to the current month
    let monthlyCounts = Array(currentMonthIndex + 1).fill(0); // +1 because we want to include the current month

    // Count properties by month, up to the current month
    properties.forEach((property) => {
      const propertyMonthIndex = getMonth(property.attributes.createdAt);
      if (propertyMonthIndex <= currentMonthIndex) {
        // Only count properties up to the current month
        monthlyCounts[propertyMonthIndex]++;
      }
    });

    return monthlyCounts;
  };

  const totalData = countPropertiesUpToCurrentMonth(servicesListing);

  const filterAndCountConversions = (data) => {
    // Helper function to get month and year from createdAt
    const getDateParts = (dateString) => {
      const date = new Date(dateString);
      return { month: date.getMonth() + 1, year: date.getFullYear() };
    };

    // Initialize an array with zeros up to the current month
    const currentMonth = new Date().getMonth() + 1; // Get the current month (1-12)
    let countsArray = Array(currentMonth).fill(0); // Fill with zeros

    // Filter leads that have crm_properties data and count them by month
    data.data.forEach((lead) => {
      if (lead.attributes.crm_service_listings.data.length > 0 && lead.attributes.status === "converted") {
        const { month } = getDateParts(lead.attributes.createdAt);
        if (month <= currentMonth) {
          // Ensure we're only counting up to the current month
          countsArray[month - 1]++; // Increment the count for this month
        }
      }
    });

    return countsArray;
  };

  const totalConversions = filterAndCountConversions(leadsData);
  const totalSales = [0, 0, 0, 0, 0, 0, 0, 0 ,0];

  return (
    <div className="min-h-screen">
      <div className="flex justify-between pr-2 flex-wrap h-[600px] overflow-y-auto overscroll-contain">
        <GlobalAnalyticsChart title="Total Leads" data={totalLeadsData} />
        <GlobalAnalyticsChart title="Total Listing" data={totalData} />
        <GlobalAnalyticsChart
          title="Total Conversions "
          data={totalConversions}
        />
        {/* <RecentTransactions transactions={transactions}  width="md:w-1/2" /> */}
        <GlobalAnalyticsChart title="Total Sales" data={totalSales} />
      </div>
    </div>
  );
};
