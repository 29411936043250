import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  totalLeads: 0,
  totalListing: 0,
  totalConversions: 0,
  totalSales: 0,
  profitByMonth: [],
  incomeBreakdown: {
    serviceIncome: 0,
    rentalIncome: 0,
    propertyIncome: 0,
  },
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardData(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setDashboardData } = dashboardSlice.actions;

export const calculateDashboardData = (properties, services) => (dispatch) => {
  const totalLeads = properties.totalLeads + services.totalLeads;
  const totalListing = properties.totalListing + services.totalListing;
  const totalConversions = properties.totalConversions + services.totalConversions;
  const totalSales = properties.totalSales + services.totalSales;

  const profitByMonth = properties.profitByMonth.map((profit, index) => profit + services.profitByMonth[index]);

  const incomeBreakdown = {
    serviceIncome: properties.incomeBreakdown.serviceIncome + services.incomeBreakdown.serviceIncome,
    rentalIncome: properties.incomeBreakdown.rentalIncome + services.incomeBreakdown.rentalIncome,
    propertyIncome: properties.incomeBreakdown.propertyIncome + services.incomeBreakdown.propertyIncome,
  };

  const dashboardData = {
    totalLeads,
    totalListing,
    totalConversions,
    totalSales,
    profitByMonth,
    incomeBreakdown,
  };

  dispatch(setDashboardData(dashboardData));
};

export default dashboardSlice.reducer;
