// Notes.js
import React, { useState } from 'react';

export const Notes = ({ notes, addNote, statusHistory }) => {
  const [noteText, setNoteText] = useState('');

  const handleAddNote = () => {
    if (noteText.trim()) {
      addNote(noteText);
      setNoteText('');
    }
  };

  return (
    <div className="w-full">
      <h3 className="text-lg font-semibold mb-4">Notes</h3>
      <div className="space-y-2">
        {notes.map((note, index) => (
          <div key={index} className="p-2 bg-gray-100 rounded-lg">
            <p className="text-gray-700">{note}</p>
          </div>
        ))}
      </div>
      <div className="flex items-center mt-4">
        <input
          type="text"
          className="border p-2 rounded-lg w-full"
          value={noteText}
          onChange={(e) => setNoteText(e.target.value)}
          placeholder="Add a note..."
        />
        <button onClick={handleAddNote} className="ml-2 bg-blue-500 text-white px-4 py-2 rounded-lg">
          Add
        </button>
      </div>
      <div className="mt-8">
        <h3 className="text-lg font-semibold mb-4">Status History</h3>
        <div className="space-y-2">
          {statusHistory.map((entry, index) => (
            <div key={index} className="p-2 bg-gray-100 rounded-lg">
              <p className="text-gray-700">{entry.status}</p>
              <p className="text-gray-500 text-sm">{new Date(entry.timestamp).toLocaleString()}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
