import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import { registerUser, selectAuth } from "../../redux/authSlice";
import { RoleToggleSection } from "./roleToggleSection";

export const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("broker");
  const [username, setUsername] = useState("");
  const [fullName, setFullName] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, message, isAuthenticated } = useSelector(selectAuth);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validatePassword = (password) => {
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numericRegex = /\d/;
    const minLength = 6;

    if (!specialCharRegex.test(password)) {
      return "Password must contain at least one special character.";
    }
    if (!numericRegex.test(password)) {
      return "Password must contain at least one numeric character.";
    }
    if (password.length < minLength) {
      return `Password must be at least ${minLength} characters long.`;
    }
    return "";
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\+?\d{1,3}?[- ]?\(?\d{1,3}\)?[- ]?\d{3,4}[- ]?\d{4}$/;
    if (!phoneRegex.test(phone)) {
      return "Invalid phone number format. Please enter a valid phone number.";
    }
    return "";
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    const error = validatePassword(newPassword);
    setPasswordError(error);
  };

  const handlePhoneChange = (e) => {
    const newPhone = e.target.value;
    setPhone(newPhone);
    const error = validatePhone(newPhone);
    setPhoneError(error);
  };

  const generateUsername = (name) => {
    const baseUsername = name.replace(/\s+/g, "").toLowerCase();
    const randomNum = Math.floor(10 + Math.random() * 90);
    return `${baseUsername}${randomNum}`;
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    if (passwordError || phoneError) {
      return;
    }

    const username = generateUsername(fullName);

    const resultAction = await dispatch(
      registerUser({ username, email, phone, password, role })
    );
    if (registerUser.fulfilled.match(resultAction)) {
      navigate("/dashboard");
    }
  };

  if (isAuthenticated) {
    console.log("message", message);
    // navigate('/dashboard'); // Uncomment if you want to navigate on successful authentication
  }

  const handleRole = (e) => {
    setRole(e);
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Register As
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <form onSubmit={handleRegister}>
          <div className="space-y-6 py-8 px-4 sm:rounded-lg sm:px-10">
            <div>
              <label
                htmlFor="fullName"
                className="block text-sm font-medium text-gray-700"
              >
                Full Name
              </label>
              <div className="mt-1">
                <input
                  id="fullName"
                  name="fullName"
                  type="text"
                  autoComplete="fullName"
                  required
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700"
              >
                Phone Number
              </label>
              <div className="mt-1">
                <input
                  id="phone"
                  name="phone"
                  type="tel"
                  autoComplete="tel"
                  required
                  value={phone}
                  onChange={handlePhoneChange}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              {phoneError && (
                <p className="mt-2 text-sm text-red-600">{phoneError}</p>
              )}
            </div>

            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700"
              >
                Select Role
              </label>
              <select
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none mt-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="broker">Builder</option>
                <option value="owner">Owner/Agent</option>
                <option value="company">Company</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1 relative">
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="new-password"
                  required
                  value={password}
                  onChange={handlePasswordChange}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center"
                >
                  {showPassword ? (
                    <span className="text-gray-500">
                      <IoIosEye />
                    </span>
                  ) : (
                    <span className="text-gray-500">
                      <IoIosEyeOff />
                    </span>
                  )}
                </button>
              </div>
              {passwordError && (
                <p className="mt-2 text-sm text-red-600">{passwordError}</p>
              )}
            </div>
            {error && <div className="text-red-500 text-sm">{error}</div>}
            {message && <div className="text-green-500 text-sm">{message}</div>}
            <div>
              <button
                type="submit"
                disabled={loading}
                style={{ backgroundColor: "#407BFF" }}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {loading ? "Signing up..." : "Sign up"}
              </button>
            </div>
          </div>
        </form>
        <div className="mt-6 bg-transparent">
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full "></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2">Already have an account?</span>
            </div>
          </div>
          <div className="mt-6">
            <Link
              to="/login"
              style={{ color: "#407BFF" }}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md text-sm font-medium hover:bg-gray-50"
            >
              Log in
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
