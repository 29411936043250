import React, { useEffect, useState } from "react";
import {
  FaHeart,
  FaRegCommentAlt,
  FaEllipsisH,
  FaBookmark,
  FaRegBookmark,
  FaRegHeart,
} from "react-icons/fa";
import { RiShareForwardLine } from "react-icons/ri";
import { fetchFeed } from "../../services/api";
import profileImage from "../../services/images/profileLogo.jpg";
export const PostList = ({ section }) => {
  const [postSaved, setPostSaved] = useState(false);
  const [feedData, setFeedData] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleSavePost = () => {
    setPostSaved(!postSaved);
  };

  useEffect(() => {
    async function fetchFeedData(page, pageSize) {
      const data = await fetchFeed(page, pageSize);
      setFeedData(data.data);
      setLoading(false);
    }
    fetchFeedData(1, 100);
  }, []);

  function formatDate(inputDateString) {
    const date = new Date(inputDateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }

  return (
    <div className="bg-gray-100">
      {loading ? (
        <div>Loading....</div>
      ) : (
        feedData &&
        feedData.map((post) => (
          <div
            key={post.id}
            className="bg-white p-4 shadow-md rounded-lg mb-6 w-full "
          >
            <div className="flex items-start justify-between mb-4">
              <div className="flex items-center space-x-4">
                <img
                  src={profileImage}
                  alt="Profile"
                  className="w-10 h-10 rounded-full"
                />
                <div>
                  <div className="text-black font-bold">
                    {post.attributes.user.data?.attributes.username}
                  </div>
                  <div className="flex items-center text-gray-500 text-sm">
                    {/* {section === "projects" && (
                      <span>
                        {post.attributes.project.data?.attributes.project_name}
                      </span>
                    )} */}

                    {/* {section === "properties" && (
                      <span>
                        {post.attributes.property.data.attributes.property_name}
                      </span>
                    )} */}
                    {/* <span className="mx-2">•</span> */}
                    <span>{formatDate(post.attributes.createdAt)}</span>
                  </div>
                </div>
              </div>
              <FaEllipsisH className="text-gray-500 cursor-pointer" />
            </div>
            <div className="mb-2">{post.attributes.post}</div>
            {post.attributes.image.data && (
              <img
                src={post.attributes.image.data.attributes.url}
                alt="Post"
                style={{ height: "500px" }}
                className="w-full h-auto rounded-lg mb-4 object-cover"
              />
            )}
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center space-x-4">
                <button className="flex items-center text-black hover:text-red-500">
                  <FaRegHeart className="mr-1" />
                  <span>{post.attributes.number_of_likes} Likes</span>
                </button>
                <button className="flex items-center text-black hover:text-blue-500">
                  <FaRegCommentAlt className="mr-1" />
                  <span>{post.attributes.number_of_comments} Comments</span>
                </button>
                <button className="flex items-center text-black hover:text-green-500">
                  <RiShareForwardLine className="mr-1" />
                  <span>Share</span>
                </button>
              </div>
              <button
                onClick={handleSavePost}
                className="text-blue-500 hover:text-yellow-500"
              >
                {postSaved ? <FaBookmark /> : <FaRegBookmark />}
              </button>
            </div>
          </div>
        ))
      )}
    </div>
  );
};
