import React, { useState } from 'react';
export const AllLeadSerivcesListings = ({ data, handleListingSelect, propertyCountMap }) => 
  {
    const [selectedServiceListingId,  setSelectedServiceListingId] = useState(null);

    const handlePropertySelection = (propertyId) => {
      setSelectedServiceListingId(propertyId);
      handleListingSelect(propertyId);
    };
  return (
    <div className="divide-y divide-gray-200">
      {data && data.map((listing, index) => {
        const isSelected = listing.id === selectedServiceListingId
        return(
        <div
          key={index}
          onClick={() => handlePropertySelection(listing.id)}
          className={`flex items-center justify-between py-4 px-2 cursor-pointer ${
              isSelected ? "bg-blue-100" : ""
            }`}
        >
          <div className="flex items-center">
            <div className="flex-shrink-0 h-16 w-16 rounded-lg overflow-hidden border border-gray-300">
              <img
                className="h-full w-full object-cover"
                src={listing?.attributes?.thumbnail_image?.data?.attributes
                  ?.url}
                alt="Project"
              />
            </div>
            <div className="ml-2 flex-grow">
              <div className="font-semibold ">{listing.attributes.name}</div>
              {/* <IoLocationSharp className="text-gray-700 text-3xl mr-2" />                     */}
              <div className="text-gray-500 text-sm flex items-center">
                {listing.attributes.price}
              </div>
              {/* <div className="text-blue-500">{inventory.attributes.property_price}</div> */}
            </div>
          </div>
          <div className="text-gray-500">
            {/* {propertyCountMap[properties.id] || 0} properties */}
          </div>
        </div>
      )})}
    </div>
  );
}