import React from 'react'
import { PostList } from './postList'
import { UploadPost } from './uploadPost'

export const Post = ({section}) => {
  return (
    <div className='overflow-y-scroll flex flex-col h-[600px] pr-3'>
        <UploadPost section = {section}/>
      <PostList section = {section}/>
    </div>
  )
}
