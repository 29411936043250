// import { X } from "lucide-react";
// import profileLogo from "../../services/images/profileLogo.jpg";
// import React, { useState } from "react";
// import { AiOutlineFile, AiOutlineGif, AiOutlinePicture } from "react-icons/ai";
// import { BsEmojiSmile } from "react-icons/bs";
// import { FiSend } from "react-icons/fi";

// export const EnquiryTable = ({ data }) => {
//   const [modalOpen, setModalOpen] = useState(false); 

//   const [filters, setFilters] = useState({
//     type: "",
//     status: "",
//     sortBy: "",
//   });

//   const handleFilterChange = (e) => {
//     setFilters({
//       ...filters,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const filteredData = data.filter((invoice) => {
//     const typeMatches = filters.type === "" || invoice.type === filters.type;
//     const statusMatches = filters.status === "" || invoice.status === filters.status;
//     return typeMatches && statusMatches;
//   });


//   const handleTDClick = (content) => {
//     // setPopupContent(content);
//     setModalOpen(true);
//   };

//   return (
//     <div className="w-full md:w-full">
//       <div className="bg-white border border-gray-200 rounded-lg p-4">
//         <div className="flex justify-between items-center mb-4">
//           <div className="flex gap-x-20">
//             <select
//               name="type"
//               value={filters.type}
//               onChange={handleFilterChange}
//               className="p-2 border rounded"
//             >
//               <option value="">Select Type</option>
//               <option value="Property">Property</option>
//               <option value="Services">Services</option>
//             </select>
//             <select
//               name="status"
//               value={filters.status}
//               onChange={handleFilterChange}
//               className="p-2 border rounded"
//             >
//               <option value="">Select Status</option>
//               <option value="Active">Active</option>
//               <option value="Pending">Pending</option>
//               <option value="Inactive">Inactive</option>
//             </select>
//           </div>
//           <select
//             name="sortBy"
//             value={filters.sortBy}
//             onChange={handleFilterChange}
//             className="p-2 border rounded"
//           >
//             <option value="">Sort By</option>
//             <option value="date">Date</option>
//             <option value="invoiceNumber">Invoice Number</option>
//           </select>
//         </div>
//         <div className="overflow-x-scroll flex-nowrap">
//           <table className="w-full">
//             <thead className="bg-blue-500 text-white">
//               <tr>
//                 <th className="px-4 py-2 text-left">Lead ID</th>
//                 <th className="px-4 py-2 text-left">Listing ID</th>
//                 <th className="px-4 py-2 text-left">Type</th>
//                 <th className="px-4 py-2 text-left">Company Name</th>
//                 <th className="px-4 py-2 text-left">Company Contact</th>
//                 <th className="px-4 py-2 text-left">Status</th>
//                 <th className="px-4 py-2 text-left">Payment Link</th>
//                 <th className="px-4 py-2 text-left">Messaging</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredData.map((invoice, index) => (
//                 <tr
//                   key={invoice.id}
//                   className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
//                 >
//                   <td className="px-4 py-2">{invoice.leadId}</td>
//                   <td className="px-4 py-2 text-blue-500 underline underline-offset-4 cursor-pointer">
//                     {invoice.listingId}
//                   </td>
//                   <td className="px-4 py-2">{invoice.type}</td>
//                   <td className="px-4 py-2">{invoice.companyName}</td>
//                   <td className="px-4 py-2">{invoice.companyContact}</td>
//                   <td className="px-4 py-2">{invoice.status}</td>
//                   <td className="px-4 py-2 text-blue-500 underline underline-offset-4 cursor-pointer">
//                     {invoice.paymentLink}
//                   </td>
//                   <td className="px-4 py-2">
//                     <button
//                       onClick={() => handleTDClick(invoice.orderDetails)}
//                       className="px-2 py-1.5 bg-blue-200 text-black rounded text-nowrap"
//                     >
//                       Click to view
//                     </button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>

//             {modalOpen && (
//               <div className="fixed bottom-0 right-0 mr-5 bg-white shadow-lg rounded-lg z-50 border">
//                 <div className="bg-white px-2 pb-2 rounded-lg w-80">
//                   <div className="flex justify-between items-center">
//                     <div className="font-medium text-xl">ABC Group1</div>
//                     <X
//                       color="red"
//                       onClick={() => setModalOpen(false)}
//                       className=" my-5 hover:scale-105 cursor-pointer"
//                     />
//                   </div>
//                   <div className="flex flex-col gap-y-5 h-96 px-2 overflow-y-scroll pr-5">
//                     <div className="flex flex-col gap-y-2">
//                       <div className="flex gap-x-2 items-center">
//                         <img
//                           src={profileLogo}
//                           alt=""
//                           className="w-8 h-8 rounded-full object-cover"
//                         />
//                         <div className="font-medium">User 1</div>
//                         <div className="text-gray-500 text-sm">4:57 PM</div>
//                       </div>
//                       <div className="pr-5 text-xs">
//                         Message Lamet consectetur. Accumsan phasellus risus dui
//                         justo facilisis blandit adipiscing
//                       </div>
//                     </div>

//                     <div className="flex flex-col gap-y-5">
//                       <div className="flex gap-x-2 items-center">
//                         <img
//                           src={profileLogo}
//                           alt=""
//                           className="w-8 h-8 rounded-full object-cover"
//                         />
//                         <div className="font-medium">User 1</div>
//                         <div className="text-gray-500 text-sm">4:57 PM</div>
//                       </div>
//                       <div className="pr-5 text-xs">
//                         Message Lamet consectetur. Accumsan phasellus risus dui
//                         justo facilisis blandit adipiscing
//                       </div>
//                     </div>

//                     <div className="flex flex-col gap-y-5">
//                       <div className="flex gap-x-2 items-center justify-end">
//                         <img
//                           src={profileLogo}
//                           alt=""
//                           className="w-8 h-8 rounded-full object-cover"
//                         />
//                         <div className="font-medium">You</div>
//                         <div className="text-gray-500 text-sm">4:57 PM</div>
//                       </div>
//                       <div className="pl-5 text-xs text-right">
//                         Message Lamet consectetur. Accumsan phasellus risus dui
//                         justo facilisis blandit adipiscing
//                       </div>
//                     </div>

//                     <div className="flex flex-col gap-y-5">
//                       <div className="flex gap-x-2 items-center justify-end">
//                         <img
//                           src={profileLogo}
//                           alt=""
//                           className="w-8 h-8 rounded-full object-cover"
//                         />
//                         <div className="font-medium">You</div>
//                         <div className="text-gray-500 text-sm">4:57 PM</div>
//                       </div>
//                       <div className="pl-5 text-xs text-right">
//                         Message Lamet consectetur. Accumsan phasellus risus dui
//                         justo facilisis blandit adipiscing
//                       </div>
//                     </div>
//                   </div>
//                   <div className="border rounded mt-4 pt-4">
//                       <input
//                         type="text"
//                         placeholder="Type a message..."
//                         className="w-full p-2 border-b"
//                       />
//                       <div className="flex items-center mt-2 border-t border-grey">
//                         <button className="p-2">
//                           <AiOutlinePicture size={24} />
//                         </button>
//                         <button className="p-2">
//                           <AiOutlineFile size={24} />
//                         </button>
//                         <button className="p-2">
//                           <AiOutlineGif size={24} />
//                         </button>
//                         <button className="p-2">
//                           <BsEmojiSmile size={24} />
//                         </button>
//                         <button className="ml-auto p-2 border-none bg-transparent">
//                           <FiSend size={24} />
//                         </button>
//                       </div>
//                     </div>
//                 </div>
//               </div>
//             )}
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// };


import { X } from "lucide-react";
import profileLogo from "../../services/images/profileLogo.jpg";
import React, { useEffect, useState } from "react";
import { AiOutlineFile, AiOutlineGif, AiOutlinePicture } from "react-icons/ai";
import { BsEmojiSmile } from "react-icons/bs";
import { FiSend } from "react-icons/fi";
import { format } from 'date-fns';
import { useDispatch, useSelector } from "react-redux";
import { SendMessageForLeads } from "../../redux/leadSlice";
import axios from "axios";

export const EnquiryTable = ({ data }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
   
    // console.log(data[0].attributes.user.data.attributes.username, 'dataa')
    const filteredData1 = data.filter((invoice) => {
      const typeMatches = filters.type === "" || invoice.type === filters.type;
      const statusMatches = filters.status === "" || invoice.status === filters.status;
      return typeMatches && statusMatches;
    });
    setFilteredData(filteredData1);
  }, [])
  const [filters, setFilters] = useState({
    type: "",
    status: "",
    sortBy: "",
  });

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };



  const handleTDClick = (content) => {
    // setPopupContent(content);
    setModalOpen(true);
  };

  //////////////////////////////////////////
  const isSidebarCollapsed = useSelector((state) => state.sidebar.isCollapsed);
  const [adminData, setAdminData] = useState([]);
  const messageSectionWidth = isSidebarCollapsed ? '' : '';
  const [selectedLead, setSelectedLead] = useState(null);
  const [adminMessage, setAdminMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [webSocket, setWebSocket] = useState(null)

  const dispatch = useDispatch();

  const loggedInUser = JSON.parse(localStorage.getItem('user'));
  const sessionToken = loggedInUser.mattermost_session_token_id;

  useEffect(() => {
    
    setSelectedLead(filteredData[0] && filteredData[0].attributes);
    setAdminData(data[0]?.attributes?.user?.data)
  }, [filteredData, loggedInUser,data]);

  useEffect(() => {
    const fetchMessages = async () => {
      const rootId = filteredData[0] && filteredData[0].attributes.mattermost_lead_id;
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_DOMAIN}api/get-mattermost-messages?postId=${rootId}`);
        const sortedMessages = Object.values(response.data.mattermostResponse.posts).sort((a, b) => a.create_at - b.create_at);

        // Fetch admin data
        console.log("sortedUser", sortedMessages);
        const responseAdminData = await fetchAdminData(sortedMessages[0].user_id);
        if (responseAdminData) {
          console.log(responseAdminData[0].username,'admin username')
          sortedMessages[0].admin_name = responseAdminData[0].username;
        }
        setMessages(sortedMessages);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();
  }, [filteredData]);

   useEffect(() => {
    console.log(messages, 'state message')
   })

 useEffect(() => {
  console.log(filteredData[0], 'filteredData')
  const rootId = filteredData[0] && filteredData[0].attributes.mattermost_lead_id;
    const ws = new WebSocket('wss://messaging.gulfin.ai/api/v4/websocket');

      ws.onopen = () => {
        console.log('WebSocket connected');
  
        // Send authentication challenge
        const authData = {
          "seq": 1,
          "action": "authentication_challenge",
          "data": {
            "token": sessionToken
          }
        };
        ws.send(JSON.stringify(authData));
      };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log(rootId, 'root_ID')
      // console.log(JSON.parse(event.data));
      if(data.event === 'posted' && JSON.parse(data.data.post).root_id === rootId) {
        console.log(JSON.parse(data.data.post), 'state message')
        setMessages((prevMessages) => {
          console.log(prevMessages[prevMessages.length], 'prev mess id')
          if(prevMessages[0].id !== JSON.parse(data.data.post).id) {
            return [...prevMessages, JSON.parse(data.data.post)]
          }
          return prevMessages;
        });    
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    setWebSocket(ws);
  }, [filteredData]);

  const fetchAdminData = async (mattermost_user_id) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_DOMAIN}api/users?filters[mattermost_user_id][$eq]=${mattermost_user_id}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log('admin data',data);
      return data;
    } catch (error) {
      console.error("Error fetching listing:", error);
      throw error;
    }
  };

  const sendMessage = async () => {
    if (!adminMessage) {
      console.error('Message cannot be empty');
      return;
    }

    const channelId = selectedLead.mattermost_channel_id;
    const rootId = selectedLead.mattermost_lead_id;

    const payload = {
      channel_id: channelId,
      message: adminMessage,
      root_id: rootId,
      sessionToken: sessionToken
    };

    try {
      await dispatch(SendMessageForLeads(payload));
      setAdminMessage('');
      // const getMessages = await axios.get(
      //   `${process.env.REACT_APP_BACKEND_DOMAIN}api/get-mattermost-messages?postId=${rootId}`
      // );
      // const mess = Object.values(getMessages.data.mattermostResponse.posts);

      // const sortedData = mess.sort((a, b) => a.create_at - b.create_at);
      
      // setMessages(sortedData);
    } catch (error) {
      console.error('Error dispatching message:', error);
    }
  };

  const formatTimestamp = (timestamp) => {
    return format(new Date(timestamp), 'hh:mm a');
  };

  return (
    <div className="w-full md:w-full">
      <div className="bg-white border border-gray-200 rounded-lg p-4">
        <div className="flex justify-between items-center mb-4">
          <div className="flex gap-x-20">
            <select
              name="type"
              value={filters.type}
              onChange={handleFilterChange}
              className="p-2 border rounded"
            >
              <option value="">Select Type</option>
              <option value="Property">Property</option>
              <option value="Services">Services</option>
            </select>
            <select
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
              className="p-2 border rounded"
            >
              <option value="">Select Status</option>
              <option value="Active">Active</option>
              <option value="Pending">Pending</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>
          <select
            name="sortBy"
            value={filters.sortBy}
            onChange={handleFilterChange}
            className="p-2 border rounded"
          >
            <option value="">Sort By</option>
            <option value="date">Date</option>
            <option value="invoiceNumber">Invoice Number</option>
          </select>
        </div>
        <div className="overflow-x-scroll flex-nowrap">
          <table className="w-full">
            <thead className="bg-blue-500 text-white">
              <tr>
                <th className="px-4 py-2 text-left">Lead ID</th>
                <th className="px-4 py-2 text-left">Listing ID</th>
                <th className="px-4 py-2 text-left">Type</th>
                <th className="px-4 py-2 text-left">Company Name</th>
                <th className="px-4 py-2 text-left">Company Contact</th>
                <th className="px-4 py-2 text-left">Status</th>
                <th className="px-4 py-2 text-left">Payment Link</th>
                <th className="px-4 py-2 text-left">Messaging</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((invoice, index) => (
                <tr
                  key={invoice.id}
                  className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
                >
                  <td className="px-4 py-2">{invoice.id}</td>
                  <td className="px-4 py-2 text-blue-500 underline underline-offset-4 cursor-pointer">
                    {invoice.listingId}
                  </td>
                  <td className="px-4 py-2">{invoice.type}</td>
                  <td className="px-4 py-2">{invoice.companyName}</td>
                  <td className="px-4 py-2">{invoice.companyContact}</td>
                  <td className="px-4 py-2">{invoice.status}</td>
                  <td className="px-4 py-2 text-blue-500 underline underline-offset-4 cursor-pointer">
                    {invoice.paymentLink}
                  </td>
                  <td className="px-4 py-2">
                    <button
                      onClick={() => handleTDClick(invoice.orderDetails)}
                      className="px-2 py-1.5 bg-blue-200 text-black rounded text-nowrap"
                    >
                      Click to view
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>

            {modalOpen && (
              <div className="fixed bottom-0 right-0 mr-5 bg-white shadow-lg rounded-lg z-50 border">
                <div className="bg-white px-2 pb-2 rounded-lg w-96">
                  <div className="flex justify-between items-center">
                    <div className="font-medium text-xl">{adminData.attributes.username}-{filteredData[0].id}</div>
                    <X
                      color="red"
                      onClick={() => setModalOpen(false)}
                      className=" my-5 hover:scale-105 cursor-pointer"
                    />
                  </div>
                <div
                  className={`${messageSectionWidth} h-[450px] p-2 border-r border-gray-300 flex flex-col`}
                >
                  <div className="flex-grow overflow-auto">
                    {filteredData && (
                      <div>
                        <div className="mt-3">
                          {messages.map((message, index) => (
                            <div
                              key={index}
                              className={`flex items-center mb-2 ${message.user_id === loggedInUser.mattermost_user_id
                                  ? 'justify-end'
                                  : 'justify-start'
                                }`}
                            >
                              <div
                                className={`p-2 rounded-lg text-black ${message.user_id === loggedInUser.mattermost_user_id
                                    ? 'bg-gray-100'
                                    : 'bg-blue-100'
                                  }`}
                              >
                                <strong>
                                  {message.user_id === loggedInUser.mattermost_user_id
                                    ? 'You'
                                    : adminData.attributes.username}
                                </strong>
                                <span className="text-gray-500 ml-2">
                                  {formatTimestamp(message.create_at)}
                                </span>
                                <p>{message.message}</p>
                              </div>
                              {/* {message.user_id !== loggedInUser.mattermost_user_id && (
                                <img
                                  src={loggedInUser.profilePicture}
                                  alt={loggedInUser.username}
                                  className="h-8 w-8 rounded-full ml-2"
                                />
                              )} */}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="flex items-center mt-4 border-t border-gray-300 pt-2">
                    <div className="flex items-center space-x-2">
                      <AiOutlinePicture className="h-5 w-5 text-gray-600" />
                      <AiOutlineFile className="h-5 w-5 text-gray-600" />
                      <AiOutlineGif className="h-5 w-5 text-gray-600" />
                      <BsEmojiSmile className="h-5 w-5 text-gray-600" />
                    </div>
                    <input
                      type="text"
                      value={adminMessage}
                      onChange={(e) => setAdminMessage(e.target.value)}
                      className="flex-grow p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                      placeholder="Write a message..."
                    />
                    <button
                      onClick={sendMessage}
                      className="ml-2 p-2  rounded-lg"
                    >
                      <FiSend className="h-5 w-5" />
                    </button>
                  </div>
                </div>
                </div>
              </div>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};
