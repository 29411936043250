import React, { useState, useEffect } from "react";
import { AllLeadsPropertiesUserDetails } from "../../../../components/Properties/Lead/AllLeadsPropertiesUserDetails.js";
import { useDispatch } from "react-redux";
import {
  FaChevronRight,
  FaSearch,
  FaAngleRight,
  FaAngleDown,
} from "react-icons/fa";
import { Pagination } from "../../../../components/common/pagination/pagination.js";
import {
  SelectProjectsLead,
  SelectPropertiesLead,
} from "../../../../components/Properties/Lead/SelectPropertiesLead.js";
import { AllLeadPropertiesListings } from "../../../../components/Properties/Lead/AllLeadsPropertiesListing.js";
import { SelectLeadListing } from "../../../../components/Properties/Lead/SelectLeadListing.js";
import { AllLeadProjectsListings } from "../../../../components/Projects/Lead/AllLeadsProjectsListing.js";
import { AllLeadsProjectsUserDetails } from "../../../../components/Projects/Lead/AllLeadsProjectsUserDetails.js";
import axios from "axios";
import Loader from "../../../../components/Loader.js";
export const AllLeadsMain = ({
  projectsData,
  properties,
  leadsData,
  handleSelectedListingId,
}) => {
  const [property, setProperty] = useState([]);
  const [userLeadsToShow, setUserLeadsToShow] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("name");
  const [filteredListing, setFilteredListing] = useState([]);
  const [isOrderByDropdownOpen, setIsOrderByDropdownOpen] = useState(false);
  const [showActive, setShowActive] = useState(true);
  const [currentPage, setCurrentPage] = useState(3);
  const [selectedLeads, setSelectedLeads] = useState();
  const [isSelecting, setIsSelecting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showListingSelectingList, setShowListingsSelectingList] =
    useState(false);
  const [selectedProjectsListings, setSelectedProjecListings] = useState([]);
  const [selectedProject, setSelectedProject] = useState([]);
  const listingsPerPage = 10;
  const dispatch = useDispatch();

  console.log(selectedProperty, "selected property");

  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const id = loggedInUser.id;

  useEffect(() => {
    const fetchLeads = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://admin.gulfin.ai/api/crm-leads?filters[user][$eq]=${id}&filters[crm_listings][$eq]=${selectedProperty}&populate=crm_listings`
        );

        setUserLeadsToShow(response.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchLeads();
  }, [selectedProperty, id]);

  useEffect(() => {
    setProperty(properties);
    // setSelectedProperty(properties[0]?.id || null);
    setFilteredListing(properties);
  }, [properties, property]);

  const handlePropertyClick = (listingId) => {
    setSelectedProperty(listingId);
    const filtered =
      property && property.filter((listings) => listings.id === listingId);
    setFilteredListing(filtered);
    handleSelectedListingId(filtered[0]);
    let getLead = leadsData.filter(
      (lead) =>
        lead.attributes.crm_listings &&
        lead.attributes.crm_listings.data.some(
          (listing) => listing.id === listingId
        )
    );
    setSelectedLeads(getLead);
    setCurrentPage(1);
  };

  const handleOrderChange = (value) => {
    setOrderBy(value);
    setIsOrderByDropdownOpen(false);
  };

  const toggleOrderByDropdown = () => {
    setIsOrderByDropdownOpen(!isOrderByDropdownOpen);
  };

  const toggleActive = () => {
    setShowActive(!showActive);
  };

  const handleSelectListingClick = () => {
    setIsSelecting(!isSelecting);
  };

  const handlePropertySelect = (projectId) => {
    let updatedselectedProject;
    if (selectedProject.includes(projectId)) {
      updatedselectedProject = selectedProject.filter((id) => id !== projectId);
    } else {
      updatedselectedProject = [...selectedProject, projectId];
    }
    setSelectedProject(updatedselectedProject);
    filterListings(searchTerm, updatedselectedProject);
  };

  const handleSelectAll = () => {
    const allPropertyIds = projectsData.map((peroperty) => peroperty.id);
    setSelectedProject(allPropertyIds);

    filterListings(searchTerm, allPropertyIds);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);

    filterListings(e.target.value, selectedProject);
  };

  const filterListings = (searchTerm, selectedProperties) => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    console.log(properties, "+++");
    const filtered = properties.filter((listing) => {
      const matchesSearchTerm = listing.attributes.property_name
        .toLowerCase()
        .includes(lowercasedSearchTerm);
      console.log(listing);
      const matchesProperty =
        selectedProperties.length === 0 ||
        selectedProperties.includes(listing.attributes.property_id.data.id);
      return matchesSearchTerm && matchesProperty;
    });
    setFilteredListing(filtered);
    setProperty(filtered);
  };

  const indexOfLastListing = currentPage * listingsPerPage;
  const indexOfFirstListings = indexOfLastListing - listingsPerPage;
  const currentListing = filteredListing.slice(
    indexOfFirstListings,
    indexOfLastListing
  );
  const totalPages = Math.ceil(filteredListing.length / listingsPerPage);

  const listingCountMap = property.reduce((acc, property) => {
    const propertuId = property.attributes.property_id?.data?.id;
    if (propertuId) {
      acc[propertuId] = (acc[propertuId] || 0) + 1;
    }
    return acc;
  }, {});

  const handleProjectClick = (projectId) => {
    const myProjects = projectsData.find(
      (project) => property.id === projectId
    );

    if (myProjects) {
      const projectListings = properties.filter(
        (listing) => listing.attributes.property_id.data.id === projectId
      );
      setSelectedProjecListings(projectListings);
      setSelectedProject(myProjects);
      setShowListingsSelectingList(true); // Show the properties selecting list
    } else {
      setSelectedProjecListings([]);
      setSelectedProject(null);
      setShowListingsSelectingList(false); // Hide the properties selecting list
    }
  };

  return (
    <div className="w-full">
      <div className="flex w-full flex-grow">
        <div className="w-1/4 bg-white border rounded">
          <div className="w-full p-2">
            {!isSelecting && (
              <>
                <div className="flex justify-between h-full mb-2">
                  <div className="relative w-full">
                    <FaSearch className="absolute left-3 top-3 text-gray-400" />
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                      className="w-full pl-10 border border-blue-500 rounded py-2 px-4 bg-gray-100"
                    />
                  </div>
                  {/* <button className="text-black-500 truncate font-semibold text-sm underline py-2 px-4 rounded flex items-center ml-2">
                    Sort By
                  </button> */}
                </div>
                <div className="h-[450px] overflow-y-auto">
                  <AllLeadProjectsListings
                    data={property}
                    handlePropertyClick={handlePropertyClick}
                    listingCountMap={listingCountMap}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="ml-2 w-3/4 border bg-white rounded-lg overflow-y-auto max-w-[95%] sm:max-w-[90%]">
          <div className="w-full absolute">
            <div className="fixed flex justify-start items-center">
              {showListingSelectingList && (
                <SelectLeadListing
                  selectedProperties={selectedProject}
                  selectedProjectsListings={selectedProjectsListings}
                  setShowListingsSelectingList={setShowListingsSelectingList}
                />
              )}
            </div>
          </div>
          <div className="w-full">
            {currentListing.length ? (
              <div className="border px-5 rounded-lg shadow-lg bg-white">
                <div className="flex justify-between items-center mb-4">
                  {/* <button className="text-black-700 flex items-center border-2 border-black px-4 py-2 rounded" onClick={toggleOrderByDropdown}>
                    Sort By <FaAngleRight className="ml-2" />
                  </button> */}
                </div>
                {isOrderByDropdownOpen && (
                  <ul className="bg-blue-300 text-white border rounded mt-2">
                    <li
                      onClick={() => handleOrderChange("property_name")}
                      className="py-2 px-4 cursor-pointer hover:bg-gray-200"
                    >
                      Property Name
                    </li>
                    <li
                      onClick={() => handleOrderChange("date_added")}
                      className="py-2 px-4 cursor-pointer hover:bg-gray-200"
                    >
                      Date Added
                    </li>
                    <li
                      onClick={() => handleOrderChange("price")}
                      className="py-2 px-4 cursor-pointer hover:bg-gray-200"
                    >
                      Price
                    </li>
                  </ul>
                )}
                <p className="text-2xl font-medium">
                  Leads for {currentListing[0].attributes.property_name}
                </p>
                <div className="bg-white overflow-y-scroll h-[500px]">
                  {loading ? (
                    <div className="flex justify-center items-center h-1/2">
                      <Loader />
                    </div>
                  ) : (
                    <AllLeadsProjectsUserDetails
                      leadsData={userLeadsToShow.data}
                      propertyData={filteredListing}
                    />
                  )}

                  {userLeadsToShow.data &&
                    userLeadsToShow.data.length !== 0 && (
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        setCurrentPage={setCurrentPage}
                      />
                    )}
                </div>
              </div>
            ) : (
              <div className="text-md text-center">Please select a listing</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
