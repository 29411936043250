import React, { useState, useEffect } from 'react';
import { AllLeadsPropertiesUserDetails } from '../../../../components/Properties/Lead/AllLeadsPropertiesUserDetails.js';
import { useDispatch } from 'react-redux';
import { FaChevronRight, FaSearch, FaAngleRight, FaAngleDown } from 'react-icons/fa';
import { Pagination } from '../../../../components/common/pagination/pagination';
import { SelectProjectsLead, SelectPropertiesLead } from '../../../../components/Properties/Lead/SelectPropertiesLead.js';
import { AllLeadPropertiesListings } from '../../../../components/Properties/Lead/AllLeadsPropertiesListing.js';
import { SelectLeadListing } from '../../../../components/Properties/Lead/SelectLeadListing.js';
import { AllLeadProjectsListings } from '../../../../components/Projects/Lead/AllLeadsProjectsListing.js';
import { AllLeadsProjectsUserDetails } from '../../../../components/Projects/Lead/AllLeadsProjectsUserDetails.js';
import { AllLeadSerivcesListings } from '../../../../components/services/Lead/AllLeadSerivcesListings.js';
import { AllLeadsServicesUserDetails } from '../../../../components/services/Lead/AllLeadsServicesUserDetails.js';
import axios from 'axios';

export const AllLeadsMain = ({ services, listings, leadsData, handleSelectedListingId }) => {

  const [projects, setProjects] = useState([]);
  const [listing, setListing] = useState([]);
  const [selectedProperties, setSelectedProperty] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [orderBy, setOrderBy] = useState('name');
  const [filteredListing, setFilteredListing] = useState([]);
  const [isOrderByDropdownOpen, setIsOrderByDropdownOpen] = useState(false);
  const [showActive, setShowActive] = useState(true);
  const [currentPage, setCurrentPage] = useState(3);
  const [selectedLeads, setSelectedLeads] = useState([])
  const [isSelecting, setIsSelecting] = useState(false);
  const [showListingSelectingList, setShowListingsSelectingList] = useState(false);
  const [selectedServiceListingsListings, setSelectedProjecListings] = useState([]);
  const [selectedServiceListing, setSelectedServiceListing] = useState([]);
  const [loading, setLoading] = useState(true)
  const listingsPerPage = 10;
  const dispatch = useDispatch();
  console.log( listing);

  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const id = loggedInUser.id;

  useEffect(() => {
    const fetchLeads = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://admin.gulfin.ai/api/crm-leads?filters[user][$eq]=${id}&filters[crm_service_listings][$eq]=${selectedServiceListing}&populate=crm_service_listings`
        );

        setSelectedLeads(response.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchLeads();
  }, [selectedServiceListing, id]);

  useEffect(() => {
    setListing(listings);
    // setSelectedProperty(listings[0]?.id || null);
    setFilteredListing(listing);
  }, [listings, listing, leadsData]);

  const handleListingSelect = (listingId) => {
    setSelectedServiceListing(listingId)
    console.log(listingId, 'service listing id')
    const filtered = listing.filter(listings => listings.id === listingId);
    setFilteredListing(filtered);
    console.log(filtered, leadsData, '+++')
    let getLead = leadsData.filter(lead =>
      lead.attributes.crm_service_listings && lead.attributes.crm_service_listings.data.some(listing => listing.id === listingId)
    );
    setSelectedLeads(getLead);
    console.log(getLead, 'getLead');
    handleSelectedListingId(filtered[0]);
    setCurrentPage(1); // Reset to the first page when a new project is selected
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    filterListings(e.target.value, selectedServiceListing);
  };

  const handleOrderChange = (value) => {
    setOrderBy(value);
    setIsOrderByDropdownOpen(false);
  };

  const toggleOrderByDropdown = () => {
    setIsOrderByDropdownOpen(!isOrderByDropdownOpen);
  };

  const toggleActive = () => {
    setShowActive(!showActive);
  };

  const handleSelectListingClick = () => {
    setIsSelecting(!isSelecting);
  };

  const handlePropertySelect = (projectId) => {
    let updatedselectedServiceListing;
    if (selectedServiceListing.includes(projectId)) {
      updatedselectedServiceListing = selectedServiceListing.filter((id) => id !== projectId);
    } else {
      updatedselectedServiceListing = [...selectedServiceListing, projectId];
    }
    setSelectedServiceListing(updatedselectedServiceListing);
    filterListings(searchTerm, updatedselectedServiceListing);
  };

  const handleSelectAll = () => {
    const allPropertyIds = services.map((peroperty) => peroperty.id);
    setSelectedServiceListing(allPropertyIds);

    filterListings(searchTerm, allPropertyIds);
  };

  const filterListings = (searchTerm, selectedProperties) => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const filtered = listing.filter((listing) => {
      const matchesSearchTerm = listing.attributes.name.toLowerCase().includes(lowercasedSearchTerm);
      const matchesProperty = selectedProperties.length === 0 || selectedProperties.includes(listing.attributes.property_id.data.id);
      return matchesSearchTerm && matchesProperty;
    });
    setFilteredListing(filtered);
    setListing(filtered);
  };

  const indexOfLastListing = currentPage * listingsPerPage;
  const indexOfFirstListings = indexOfLastListing - listingsPerPage;
  const currentListing = filteredListing.slice(indexOfFirstListings, indexOfLastListing);
  const totalPages = Math.ceil(filteredListing.length / listingsPerPage);


  const listingCountMap = listings.reduce((acc, listing) => {
    const propertuId = listing.attributes.services_id?.data?.id;
    if (propertuId) {
      acc[propertuId] = (acc[propertuId] || 0) + 1;
    }
    return acc;
  }, {});


  const handleServiceClick = (projectId) => {
    const myProjects = services.find((project) => listing.id === projectId);
    if (myProjects) {
      const projectListings = listings.filter((listing) => listing.attributes.property_id.data.id === projectId);
      setSelectedProjecListings(projectListings);
      setSelectedServiceListing(myProjects);
      setShowListingsSelectingList(true); // Show the properties selecting list
    } else {
      setSelectedProjecListings([]);
      setSelectedServiceListing(null);
      setShowListingsSelectingList(false); // Hide the properties selecting list
    }
  };

  return (
    <div className="w-full">
      <div className="flex w-full flex-grow ">
        <div className="w-1/4 bg-white border rounded">
          <div className="p-2">
            {isSelecting && (
              <SelectPropertiesLead
                properties={services}
                handlePropertySelect={handlePropertySelect}
                listings={listing}
                handleSelectAll={handleSelectAll}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                selectedProperties={selectedServiceListing}
                onProjectClick={handleServiceClick}
              />
            )}
            {!isSelecting && (
              <>
                <div className="flex justify-between h-full">
                  <div className="relative w-full">
                    <FaSearch className="absolute left-3 top-3 text-gray-400" />
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                      className="w-full pl-10 border border-blue-500 rounded py-2 px-4 bg-gray-100"
                    />
                  </div>
                  {/* <button className="text-black-500 font-semibold underline py-2 px-4 rounded flex items-center ml-2">
                    Filters
                  </button> */}
                </div>
                <div className='h-[540px] overflow-y-auto'>
                  <AllLeadSerivcesListings data={listing} handleListingSelect={handleListingSelect} listingCountMap={listingCountMap} />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="w-3/4">
          <div className="w-full absolute">
            <div className="fixed flex justify-start items-center">
              {showListingSelectingList && (
                <SelectLeadListing
                  selectedProperties={selectedServiceListing}
                  selectedServiceListingsListings={selectedServiceListingsListings}
                  setShowListingsSelectingList={setShowListingsSelectingList}
                />
              )}
            </div>
          </div>
          <div className=" pl-3 h-[550px]">
            {currentListing.length ?
              <div className="border h-full p-4 rounded-lg shadow-lg bg-white">
                <div className="flex justify-between items-center mb-4">
                  {/* <button className="text-black-700 flex items-center border-2 border-black px-4 py-2 rounded" onClick={toggleOrderByDropdown}>
                    Sort By <FaAngleRight className="ml-2" />
                  </button> */}
                </div>
                {isOrderByDropdownOpen && (
                  <ul className="bg-blue-300 text-white border rounded mt-2">
                    <li onClick={() => handleOrderChange('property_name')} className="py-2 px-4 cursor-pointer hover:bg-gray-200">Property Name</li>
                    <li onClick={() => handleOrderChange('date_added')} className="py-2 px-4 cursor-pointer hover:bg-gray-200">Date Added</li>
                    <li onClick={() => handleOrderChange('price')} className="py-2 px-4 cursor-pointer hover:bg-gray-200">Price</li>
                  </ul>
                )}
                <p className='text-2xl'>{currentListing[0].attributes.property_name}</p>
                <div className="flex justify-between items-center mb-4">
                  {/* <div className="space-x-4">
                    <a href="#" className="text-gray-600 hover:text-blue-500">Needs Review</a>
                    <a href="#" className="text-gray-600 hover:text-blue-500">Saved For Later</a>
                    <a href="#" className="text-gray-600 hover:text-blue-500">Rejected</a>
                  </div> */}
                </div>
                <div className="h-[450px] overflow-y-auto">
                  <AllLeadsServicesUserDetails leadsData={selectedLeads.data} />
                  <Pagination currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} />
                </div>
              </div> : <div className='text-md bg-white rounded-md h-full border text-center'>Please select a listing</div>}
          </div>
        </div>
      </div>
    </div>
  );
};