import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

export const AllLeadMessageSection = ({ loading, error, selectedUser, loggedInUser }) => {
  const isSidebarCollapsed = useSelector((state) => state.sidebar.isCollapsed);

  useEffect(() => {
    console.log(selectedUser, 'selected user');
  }, [selectedUser]);

  const formatTimestamp = (timestamp) => {
    return format(new Date(timestamp), 'hh:mm a');
  };

  const messageSectionWidth = isSidebarCollapsed ? 'w-2/3' : 'w-4/5';

  return (
    <div className="flex justify-between p-4 bg-white" style={{ width: "100%" }}>
      <div className={`${messageSectionWidth} p-4 border-r border-gray-300`}>
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {selectedUser && selectedUser.messages && selectedUser.messages.length > 0 ? (
          <div>
            <div className="mt-4">
              {selectedUser.messages.map((message, index) => (
                <div
                  key={index}
                  className={`flex items-center mb-2 ${
                    message.sender === loggedInUser.name ? 'justify-end' : ''
                  }`}
                >
                  {message.sender !== loggedInUser.name && (
                    <img
                      src={selectedUser.profilePicture}
                      alt={selectedUser.name}
                      className="w-10 h-10 rounded-full mr-2 object-cover"
                    />
                  )}
                  <div
                    className={`p-2 rounded-lg ${
                      message.sender === loggedInUser.name ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'
                    }`}
                  >
                    <strong>{message.sender}: </strong>
                    <span className="text-gray-500 ml-2">{formatTimestamp(message.timestamp)}</span>
                    <p>{message.content}</p>
                  </div>
                  {message.sender === loggedInUser.name && (
                    <img
                      src={loggedInUser.profilePicture}
                      alt={loggedInUser.name}
                      className="w-10 h-10 rounded-full ml-2 object-cover"
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <p>No messages available</p>
        )}
      </div>
    </div>
  );
};
