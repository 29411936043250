import React, { useState } from "react";

export const AllLeadProjectsListings = ({
  data,
  handlePropertyClick,
  propertyCountMap,
}) => {
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  const handlePropertySelection = (propertyId) => {    
    setSelectedProjectId(propertyId);
    handlePropertyClick(propertyId);
  };

  return (
    <div className="divide-y divide-gray-200">
      {data &&
        data.map((properties, index) => {
          const isSelected = properties.id === selectedProjectId;
          return (
            <div
              key={index}
              onClick={() => handlePropertySelection(properties.id)}
              className={`flex items-center justify-between py-4 px-2 cursor-pointer ${
                isSelected ? "bg-blue-100" : ""
              }`}
            >
              <div className="flex items-center">
                <div className="flex-shrink-0 h-16 w-16 rounded-lg overflow-hidden border border-gray-300">
                  <img
                    className="h-full w-full object-cover"
                    src={
                      properties?.attributes?.thumbnail_image?.data?.attributes
                        ?.url
                    }
                    alt="Project"
                  />
                </div>
                <div className="ml-2 flex-grow">
                  <div className="font-semibold ">
                    {properties?.attributes?.property_name}
                  </div>
                  <div className="text-gray-500 text-sm flex items-center">
                    {properties?.attributes?.property_address}
                  </div>
                  {/* <div className="text-blue-500">{inventory.attributes.property_price}</div> */}
                </div>
              </div>
              <div className="text-gray-500">
                {/* {propertyCountMap[properties.id] || 0} properties */}
              </div>
            </div>
          );
        })}
    </div>
  );
};
