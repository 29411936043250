import {
  FaParking,
  FaTree,
  FaLock,
  FaBed,
  FaWifi,
  FaChair,
  FaWater,
  FaEdit,
  FaTrash,
  FaSwimmingPool,
  FaDumbbell,
} from "react-icons/fa";
import React, { useState, useEffect } from "react";
import { IoLocationSharp } from "react-icons/io5";
import { BiCloset } from "react-icons/bi";
import { BsTextarea } from "react-icons/bs";
import { MdOutlineGasMeter } from "react-icons/md";
import { Star } from "lucide-react";

export const InventoryListings = ({
  listings,
  onDelete,
  onEdit,
  containerWidth,
  borderOfContainer,
}) => {
  const [clicks, setClicks] = useState({});
  const [expandedAmenities, setExpandedAmenities] = useState({});

  console.log(listings);

  useEffect(() => {
    setClicks({});
    setExpandedAmenities({});
  }, [listings]);

  const handleClick = (id) => {
    setClicks((prevClicks) => ({
      ...prevClicks,
      [id]: (prevClicks[id] || 0) + 1,
    }));
  };

  const handleExpandAmenities = (id) => {
    setExpandedAmenities((prevExpanded) => ({
      ...prevExpanded,
      [id]: !prevExpanded[id],
    }));
  };

  const handleDelete = (id) => {
    onDelete(id);
  };

  const handleEdit = (id) => {
    const listing = listings.find((prop) => prop.id === id);
    onEdit(listing);
  };

  const renderAmenityIcon = (amenityKey) => {
    switch (amenityKey) {
      case "bed":
        return <FaBed className="text-black-700 mr-1" />;
      case "garden":
        return <FaTree className="text-black-500 mr-1" />;
      case "security":
        return <FaLock className="text-black-500 mr-1" />;
      case "gas":
        return <MdOutlineGasMeter className="text-black-500 mr-1" />;
      case "Wifi":
        return <FaWifi className="text-black-500 mr-1" />;
      case "chair":
        return <FaChair className="text-black-500 mr-1" />;
      case "water":
        return <FaWater className="text-black-500 mr-1" />;
      case "wardrobe":
        return <BiCloset className="text-black-500 mr-1" />;
      case "parking":
        return <FaParking className="text-black-500 mr-1" />;
      default:
        return null;
    }
  };

  return (
    <>
      {/* <div className="flex flex-wrap"> */}
      {listings ? (
        listings.map((listing) => (
          <div
            key={listing.id}
            className={`w-full md:w-${containerWidth} px-4 mb-4`}
          >
            <div
              className={`${borderOfContainer} bg-white h-full flex flex-col`}
            >
              <div className="flex flex-col md:flex-row flex-grow">
                <div className="relative w-full md:w-48 h-48 mr-4 mb-4 md:mb-0">
                  <img
                    src={
                      listing?.attributes?.thumbnail_image?.data?.attributes
                        ?.url || null
                    }
                    alt={listing.attributes.name}
                    className="w-full h-full object-cover rounded-md"
                  />
                  <div className="absolute top-2 left-2 flex space-x-2">
                    <button
                      onClick={() => handleEdit(listing.id)}
                      className="bg-white p-1 rounded-full shadow"
                    >
                      <FaEdit className="text-blue-500" />
                    </button>
                    <button
                      onClick={() => handleDelete(listing.id)}
                      className="bg-white p-1 rounded-full shadow"
                    >
                      <FaTrash className="text-red-500" />
                    </button>
                  </div>
                </div>
                <div className="flex flex-col justify-between flex-grow">
                  <div>
                    <div className="flex justify-between items-center ">
                      <h2 className="text-2xl font-semibold">
                        {listing.attributes.property_name}
                      </h2>
                      <span className="text-blue-600">
                        <p>ID: {listing.id}</p>
                        <button
                          onClick={() => handleClick(listing.id)}
                          className="text-blue-600"
                        >
                          Clicks: {clicks[listing.id] || 0}{" "}
                        </button>{" "}
                      </span>
                    </div>
                    {/* <p className="text-lg text-gray-700 ">AED {listing.attributes.price}</p> */}
                    <div className="space-y-1">
                      <div className="flex items-center space-x-2">
                        <IoLocationSharp className="text-gray-700" />
                        <p>{listing.attributes.property_address}</p>
                      </div>
                      <div className="flex flex-wrap items-center">
                        {listing.attributes.property_size && (
                          <div className="flex items-center space-x-2  w-1/2">
                            <BsTextarea className="text-gray-700" />
                            <p>
                              {listing.attributes.property_size}{" "}
                              {listing.attributes.listing_size_unit || "meter"}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="grid grid-cols-2 items-center gap-x-20">
                        <div>
                          Listing Status :{" "}
                          <span className="capitalize">
                            {" "}
                            {listing.attributes.rent_status}{" "}
                          </span>
                        </div>
                        <div>
                          {listing.attributes.rent_status === "rent "
                            ? "Rent"
                            : "Price"}
                          : {listing.attributes.currency}{" "}
                          {listing.attributes.rent}
                        </div>
                        <div>
                          Property Type : {listing.attributes.property_type}
                        </div>
                        <div className="flex gap-x-1">
                          Rating : {listing.attributes.rating}{" "}
                          <Star color="yellow" fill="yellow" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-4 font-medium">
                {/* <h3 className="text-lg font-semibold ">Property Description:</h3> */}
                <p className="text-gray-700">
                  {listing.attributes?.description &&
                  listing.attributes.description.length > 300
                    ? `${listing.attributes.description.substring(0, 315)}...`
                    : listing.attributes?.description || "No Description"}
                </p>
              </div>
            </div>
            {/* <DetailsCard property={property}/> */}
          </div>
        ))
      ) : (
        <div>Loading...</div>
      )}
      {/* </div> */}
    </>
  );
};
