import React, { useState, useEffect } from "react";
import axios from "axios";
import { DeleteConfirmantionModel } from "../../../components/common/models/deleteConfirmantionModel";
import {
  createProperty,
  deleteProperty,
  deleteService,
  updateProperty,
} from "../../../redux/propertiesSlice";
import { Pagination } from "../../../components/common/pagination/pagination";
import { useDispatch } from "react-redux";
import { AddEditPropertyModel } from "../../../components/common/models/addNewProperty";
import { MyServicesListing } from "../../../components/services/myServicesListing";
import {
  createService,
  updateService,
  updateservice,
} from "../../../redux/servicesSlice";
import { AddNewService } from "../../../components/common/models/addNewService";
import { AddNewServiceListing } from "../../../components/common/models/addNewServiceListing";
import {
  createServicesListing,
  updateServicesListing,
} from "../../../redux/servicesListingSlice";
console.log("Add renamed in this ");

export const ServicesListing = ({ serviceListing, servicesData }) => {
  const [services, setServices] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("property_name");
  const [isOrderByDropdownOpen, setIsOrderByDropdownOpen] = useState(false);
  const [showActive, setShowActive] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [propertyToDelete, setPropertyToDelete] = useState(null);
  const [formAction, setFormAction] = useState("create");
  const [isServiceModelOpen, setIsServiceModelOpen] = useState(false);
  const [serviceSelectId, setServiceSelectId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [editListingId, setEditlistingId] = useState(null);
  const [isActive, setIsActive] = useState("all");

  const propertiesPerPage = 10;
  const dispatch = useDispatch();

  const [filteredServices, setFilteredServices] = useState([]);

  // useEffect(() => {
  //   const filtered = filterServices();
  //   setFilteredServices(filtered);
  // }, [services, showActive]);

  // const filterServices = () => {
  //   return services.filter(service =>
  //     showActive ? !service.attributes.draft : service.attributes.draft
  //   );
  // };

  // useEffect(() => {
  //   setServices(serviceListing);
  //   setSelectedProperty(serviceListing);
  //   // setServiceSelectId(servicesListingData?.data[0]?.id);
  // }, [serviceListing]);
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const id = loggedInUser.id;

  useEffect(() => {
    // console.log(serviceListing, 'services')
    const filterListings = () => {
      const filtered =
        isActive === "all"
          ? serviceListing
          : serviceListing.filter((listing) =>
              isActive === "active"
                ? !listing.attributes.draft
                : listing.attributes.draft
            );
      setFilteredServices(filtered);
    };

    filterListings();
  }, [serviceListing, isActive]);

  const handleOrderChange = (value) => {
    setOrderBy(value);
    setIsOrderByDropdownOpen(false);
  };

  const toggleOrderByDropdown = () => {
    setIsOrderByDropdownOpen(!isOrderByDropdownOpen);
  };

  const toggleActive = () => {
    setShowActive(!showActive);
    setCurrentPage(1);
  };

  const handleDelete = (id) => {
    setPropertyToDelete(id);
    setShowDeleteModal(true);
  };

  const handleEdit = (property) => {
    setSelectedProperty(property.attributes);
    setEditlistingId(property.id);
    setServiceSelectId(property.id);
    setIsServiceModelOpen(true);
  };
  const handleDeleteConfirmation = () => {
    dispatch(deleteService(propertyToDelete)).then(() => {
      window.location.reload();
      setShowDeleteModal(false);
    });
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
  };

  const openModal = () => {
    // setIsModalOpen(true);
    setSelectedProperty({});
  };

  const closeModal = () => {
    // setIsModalOpen(false);
    setSelectedProperty(null);
  };

  const serviceModelOpen = () => {
    setIsServiceModelOpen(true);
    setSelectedProperty({});
  };

  const servicesModelClose = () => {
    setIsServiceModelOpen(false);
    setSelectedProperty(null);
  };

  // const handleAddEditFormSubmit = (formData) => {
  //    console.log(formData,formAction);
  //   if (formAction === 'edit') {
  //     console.log("edit");
  //     dispatch(updateService({ id: editPropertyId, serviceData: formData }));
  //     window.location.reload();
  //     servicesModelClose();
  //   } else {
  //     console.log("create");
  //     dispatch(createService(formData));
  //     window.location.reload();
  //     servicesModelClose();
  //   }
  // };

  const handleAddServiceListingSubmit = (formData) => {
    formData.data.display_name = formData.data.name;
    formData.data.name = formData.data.name.split(" ").join("");
    if (formAction === "edit") {
      console.log("edit", formData);
      dispatch(
        updateServicesListing({ id: editListingId, listingData: formData })
      ).then(() => {
        window.location.reload();
        closeModal();
      });
    } else {
      console.log("create", formData);
      dispatch(createServicesListing(formData)).then(() => {
        window.location.reload();
        closeModal();
      });
    }
  };

  const handleFormAction = (action, property) => {
    setFormAction(action);
    setSelectedProperty(property);
    // setIsModalOpen(true);
  };

  const filteredProperties = filteredServices
    .filter(
      (property) =>
        property.attributes.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        property.attributes.location
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (orderBy === "name") {
        return a.attributes.name.localeCompare(b.attributes.name);
      } else if (orderBy === "price") {
        return (
          parseInt(a.attributes.name.replace(/[^0-9]/g, "")) -
          parseInt(b.attributes.price.replace(/[^0-9]/g, ""))
        );
      }
      return 0;
    });
  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
  const currentServices = filteredProperties.slice(
    indexOfFirstProperty,
    indexOfLastProperty
  );
  const totalPages = Math.ceil(filteredProperties.length / propertiesPerPage);

  return (
    <div className="bg-white h-[100vh]">
      <div className="flex items-center justify-between w-full p-4">
        <div className="flex font-medium">
          <button
            className={`flex items-center text-blue-500 border-blue-300 border-2 p-1.5 px-10 rounded hover:text-white hover:bg-blue-500 hover:border-blue-500 transition-colors ease-linear duration-200`}
            onClick={serviceModelOpen}
          >
            Create a Listing +
          </button>
          <div className="flex w-1/2 ml-10">
            <button
              className={`px-4 rounded-l-md border-r-0 w-32
    ${
      isActive === "all"
        ? "bg-green-500 text-white h-10"
        : "bg-gray-100 h-10 border-2 border-gray-300 text-gray-300"
    }`}
              onClick={() => setIsActive("all")}
            >
              All
            </button>
            <button
              className={`px-4 rounded-l--md border-l-0 h-10 w-32
    ${
      isActive === "active"
        ? "bg-green-500 text-white"
        : "bg-gray-100 border-2 border-gray-300 text-gray-300"
    }`}
              onClick={() => setIsActive("active")}
            >
              Active
            </button>
            <button
              className={`px-4 rounded-r-md border-l-0 h-10 w-32 
    ${
      isActive === "draft"
        ? "bg-gray-500 text-white"
        : "bg-gray-100 border-2 border-gray-300 text-gray-300"
    }`}
              onClick={() => setIsActive("draft")}
            >
              Draft
            </button>
          </div>
        </div>
      </div>
      <div className="w-full h-[450px] overflow-y-auto">
        {filteredServices.length > 0 ? (
          <>
            <div className="flex flex-wrap ">
              <MyServicesListing
                services={currentServices}
                onDelete={handleDelete}
                onEdit={handleEdit}
                containerWidth={"1/2"}
                borderOfContainer={"p-4 border rounded-lg"}
              />
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
            />
          </>
        ) : (
          <div className="text-2xl text-center p-4 ml-4">
            No {showActive ? "active" : "draft"} services available.
          </div>
        )}
      </div>
      {showDeleteModal && (
        <DeleteConfirmantionModel
          isOpen={showDeleteModal}
          onCancel={handleDeleteCancel}
          onDelete={handleDeleteConfirmation}
          property={propertyToDelete}
        />
      )}

      {isServiceModelOpen && (
        <AddNewServiceListing
          isOpen={isServiceModelOpen}
          onClose={servicesModelClose}
          onSubmit={handleAddServiceListingSubmit}
          service={selectedProperty}
          setFormAction={setFormAction}
          serviceSelectId={serviceSelectId}
          servicesData={servicesData}
        />
      )}
    </div>
  );
};
