import React from "react";
import { GlobalAnalyticsChart } from "../../../components/charts/reuseChart";
import { RecentTransactions } from "../../../components/common/RecentTransactions/recentTransactions";
// import {TopProjects} from './topProjects';
// import {RecentTransactions} from './recentTransactions';

export const AnalystPropertiesPage = ({
  leadsData,
  listingData,
}) => {

  const filterAndCountLeads = (data) => {
    const getDateParts = (dateString) => {
      const date = new Date(dateString);
      return { month: date.getMonth() + 1, year: date.getFullYear() };
    };

    const currentMonth = new Date().getMonth() + 1; 
    let countsArray = Array(currentMonth).fill(0); 

    data.data && data.data?.forEach((lead) => {
      if (lead.attributes.crm_listings.data.length > 0) {
        const { month } = getDateParts(lead.attributes.createdAt);
        if (month <= currentMonth) {
          countsArray[month - 1]++; 
        }
      }
    });

    return countsArray;
  };

  const totalLeadsData = filterAndCountLeads(leadsData);

  const countPropertiesUpToCurrentMonth = (properties) => {
    const getMonth = (dateString) => {
      const date = new Date(dateString);
      return date.getMonth();
    };

    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();

    let monthlyCounts = Array(currentMonthIndex + 1).fill(0);

    properties?.forEach((property) => {
      const propertyMonthIndex = getMonth(property.attributes.createdAt);
      if (propertyMonthIndex <= currentMonthIndex) {
        monthlyCounts[propertyMonthIndex]++;
      }
    });

    return monthlyCounts;
  };

  const totalData = countPropertiesUpToCurrentMonth(listingData);

  const filterAndCountConversions = (data) => {
    const getDateParts = (dateString) => {
      const date = new Date(dateString);
      return { month: date.getMonth() + 1, year: date.getFullYear() };
    };

    const currentMonth = new Date().getMonth() + 1;
    let countsArray = Array(currentMonth).fill(0);

    data.data?.forEach((lead) => {
      if (lead.attributes.status === "converted") {
        const { month } = getDateParts(lead.attributes.createdAt);
        if (month <= currentMonth) {
          countsArray[month - 1]++;
        }
      }
    });

    return countsArray;
  };

  const totalConversions = filterAndCountConversions(leadsData);

  const totalSales = [0, 0, 0, 0, 0, 0, 0, 0, 0];

  return (
    <div className="m-1 h-[100vh]">
      <div className="flex flex-wrap h-[600px] pr-2 overflow-y-auto">
        <GlobalAnalyticsChart title="Total Leads" data={totalLeadsData} />
        <GlobalAnalyticsChart title="Total Listing" data={totalData} />
        <GlobalAnalyticsChart
          title="Total Conversions "
          data={totalConversions}
        />
        {/* <RecentTransactions transactions={transactions} width="md:w-1/2" /> */}
        <GlobalAnalyticsChart title="Total Sales" data={totalSales} />
      </div>
      {/* <div className="flex flex-wrap  mt-4">
        <div className="w-full md:w-2/5 ">
          <TopProjects projects={dummyProjects} />
        </div>
        <div className="w-full md:w-3/5">
          <RecentTransactions />
        </div>
      </div> */}
    </div>
  );
};
