import React, { useEffect, useState } from "react";
import { FaCheckCircle, FaExpandAlt, FaSave } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { UserViewDetails } from "../../common/models/userViewDetails";
import { deleteLead, updateLeads } from "../../../redux/leadSlice";
import { ImCross } from "react-icons/im";

import axios from "axios";
import { useDispatch } from "react-redux";

export const AllLeadsServicesUserDetails = ({ leadsData }) => {
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [statusHistory, setStatusHistory] = useState([]);
  const dispatch = useDispatch();

  const handleViewDetails = (lead) => {
    setSelectedLead(lead);
    setShowUserModal(true);
  };
  console.log(leadsData, "lead data for service");

  useEffect(() => {
    console.log(leadsData, "leadsData__ in service listing leads profile");
  }, [leadsData]);


  const handleAcceptLead = async (userleaddata) => {
    const updatedLead = {
      ...userleaddata,
      attributes: {
        ...userleaddata.attributes,
        status: "accepted",
      },
    };

    let updatedLeadData = { data: updatedLead.attributes };
    try {
      await dispatch(updateLeads({ id: userleaddata.id, updatedLeadData }));

      const emailResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}api/send-email`,
        {
          to: userleaddata.attributes.email,
          subject: "Your Lead Has Been Accepted",
          text: `Your lead has been accepted. Here are the details: 
               Status: Accepted`,
          html: `<p>Your lead has been accepted.</p>
               <p>Status: Accepted</p>`,
        }
      );

      if (emailResponse.status !== 200) {
        throw new Error("Failed to send email to user");
      }

      const newStatusEntry = {
        status: "accepted",
        timestamp: new Date().toISOString(),
      };
      setStatusHistory([...statusHistory, newStatusEntry]);
      window.location.reload();
    } catch (error) {
      console.error("Error in accepting lead:", error);
    }
  };

  const handleDeleteLead = async (userleaddata) => {
    try {
      // Delete the lead
      await dispatch(deleteLead(userleaddata.id));

      const emailResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}api/send-email`,
        {
          to: userleaddata.attributes.email,
          subject: "Your Lead Has Been Rejected",
          text: `Your lead has been rejected. Here are the details: 
               Lead ID: ${userleaddata.id}`,
          html: `<p>Your lead has been rejected.</p>
               <p>Lead ID: ${userleaddata.id}</p>`,
        }
      );

      if (emailResponse.status !== 200) {
        throw new Error("Failed to send email to user");
      }

      window.location.reload();
    } catch (error) {
      console.error("Error in deleting lead:", error);
    }
  };


  if (!leadsData) {
    return <div>No leads data available.</div>;
  }

  return (
    <>
      <div className="flex flex-wrap">
        {leadsData.map((userleaddata, index) => {
          console.log(userleaddata);
          
          const serviceslistings =
            userleaddata.attributes.crm_service_listings?.data[0]?.attributes;
          // const users = userleaddata.attributes.leads_users?.data || [];
          // const leadMessages = userleaddata.attributes.lead_message || "";
          return (
            <div key={index} className="w-full md:w-1/2 p-2">
              <div className="mb-4 p-4 border-2 flex flex-col">
                <div className="flex items-center w-full">
                  <div className="w-full">
                    <div className="flex items-center justify-between">
                      <h3 className="text-lg font-semibold">
                        {userleaddata.attributes.client_name}
                      </h3>
                      <FaExpandAlt
                        className="text-2xl cursor-pointer"
                        onClick={() => handleViewDetails(userleaddata)}
                      />
                    </div>
                    <p className="text-gray-600">
                      {" "}
                      {userleaddata.attributes.email}
                    </p>
                    <p className="text-gray-600">
                      {" "}
                      {userleaddata.attributes.client_number}
                    </p>
                    <div className="flex justify-between items-center mb-4">
                      <h3 className="text-lg font-semibold">Message</h3>
                    </div>
                    <p>{userleaddata.attributes.lead_message}</p>
                  </div>
                </div>
                <div className="flex justify-center gap-x-10">
                    {userleaddata.attributes.status === null ? (
                      <>
                        <button
                          onClick={() => handleDeleteLead(userleaddata)}
                          className="text-red-900 bg-red-300 border px-4 py-2 rounded-lg flex items-center"
                        >
                          <ImCross className="mr-2" /> Reject
                        </button>
                        <button
                          onClick={() => handleAcceptLead(userleaddata)}
                          className="text-green-900 bg-green-300 border px-4 py-2 rounded-lg flex items-center"
                        >
                          <FaCheckCircle className="mr-2" /> Accept
                        </button>
                      </>
                    ) : (
                      <p className="text-green-500 mb-4">{`Status ${userleaddata.attributes.status}`}</p>
                    )}
                  </div>
              </div>
            </div>
          );
        })}
      </div>
      {showUserModal && selectedLead && (
        <UserViewDetails
          lead={selectedLead}
          onClose={() => setShowUserModal(false)}
        />
      )}
    </>
  );
};
