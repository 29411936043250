import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SubHeader } from "./subHeader";
import { ServicesListing } from "./Listing/ServiceListing";
import { AnalystServicesPage } from "./serviceAnalytics/serviceAnalytics";
import { Post } from "../../components/Post/post";
import { ServicesExplore } from "./ExplorePage/ExplorePage";
import { ServicesInventoryLatest } from "./inventory/serviceInventory";
import { ServicesLead } from "./Leads/lead";
import { getServices, getUserServices } from "../../redux/servicesSlice";
import { getServicesListings } from "../../redux/servicesListingSlice";
import { getLeads, getUserLeads } from "../../redux/leadSlice";

const LoadingComponent = () => (
  <div
    className="animate-spin inline-block size-8 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
    role="status"
    aria-label="loading"
  >
    <span class="sr-only">Loading...</span>
  </div>
);

export const ServicesPage = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const id = loggedInUser.id;
  const dispatch = useDispatch();

  const [activeItem, setActiveItem] = useState(() => {
    const savedItem = sessionStorage.getItem("activeServicesItem");
    return savedItem || "ServicesAnalytics";
  });

  const updateActiveItem = (item) => {
    setActiveItem(item);
    sessionStorage.setItem("activeServicesItem", item);
  };

  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);
  const [listings, setListings] = useState([]);
  const [allListings, setAllListings] = useState([]);
  const [listingsMeta, setListingsMeta] = useState(null);
  const [allListingsMeta, setAllListingsMeta] = useState(null);

  const { serviceData, serviceStatus, serviceError, serviceCurrentPage } =
    useSelector((state) => state.services);

  const {
    userServiceData,
    userServiceStatus,
    userServiceError,
    userServiceCurrentPage,
  } = useSelector((state) => state.services);
  const {
    leadsUserData,
    leadsUserStatus,
    leadsUserError,
    leadsUserCurrentPage,
    leadsUserTotalPages,
  } = useSelector((state) => state.leads);

  const {
    servicesListingData,
    servicesListingStatus,
    servicesListingError,
    servicesListingCurrentPage,
  } = useSelector((state) => state.servicesListings);

  const { leadsData, leadsStatus, leadsError, leadsCurrentPage } = useSelector(
    (state) => state.leads
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://admin.gulfin.ai/api/crm-service-listings/?populate=*&filters[user][$eq]=${id}`
        );

        const response2 = await fetch(
          `https://admin.gulfin.ai/api/crm-service-listings/?populate=*`
        );
        if (!response.ok) {
          throw new Error("Network response for listings was not ok");
        }

        if (!response2.ok) {
          throw new Error("Network response for listings was not ok");
        }

        const listingsResult = await response.json();
        const allListings = await response2.json();
        
        console.log(listingsResult,')))))-----((((', allListings);
        setListings(listingsResult.data);
        setAllListings(allListings.data);
        setListingsMeta(listingsResult.meta);
        setAllListingsMeta(allListings.meta);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        dispatch(getLeads({ page: leadsCurrentPage, pageSize: 50 })),
        dispatch(getServices({ page: serviceCurrentPage, pageSize: 50 })),
        dispatch(
          getUserServices({
            page: userServiceCurrentPage,
            pageSize: 10,
            userId: id,
          })
        ),
        dispatch(
          getServicesListings({
            page: servicesListingCurrentPage,
            pageSize: 50,
          })
        ),
      ]);
      setIsInitialLoadComplete(true);
    };

    fetchData();
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(
      getUserLeads({ page: leadsUserCurrentPage, pageSize: 100, userId: id, pageName: "services" })
    );
  }, [dispatch, leadsUserCurrentPage, id]);
  const isConfirm = loggedInUser.confirmed;
  const role = loggedInUser.user_role;

  console.log(listings);

  const renderComponent = () => {
    if (!isInitialLoadComplete) {
      return (
        <div className="flex justify-center items-center h-[500px]">
          <LoadingComponent />;
        </div>
      );
    }

    switch (activeItem) {
      // case "Post":
      //   return <Post section="services" />;
      case "Explore":
        return (
          <ServicesExplore
            servicesListings={allListings}
            servicesData={serviceData}
          />
        );
      case "Inventory":
        return role === "owner" || isConfirm ? (
          <ServicesInventoryLatest
            servicesData={userServiceData}
            servicesListingData={servicesListingData}
          />
        ) : (
          <p>
            Your profile is not verified yet. Please wait till an Admin approves
            your profile.
          </p>
        );
      case "Listing":
        return role === "owner" || isConfirm ? (
          <ServicesListing
            serviceListing={listings}
            servicesData={listings}
            pageCount={listingsMeta}
          />
        ) : (
          <p>
            Your profile is not verified yet. Please wait till an Admin approves
            your profile.
          </p>
        );
      case "ServicesAnalytics":
        return role === "owner" || isConfirm ? (
          <AnalystServicesPage
            leadsData={leadsData}
            servicesListing={servicesListingData.data}
            serviceData={serviceData}
          />
        ) : (
          <p>Your analytics will appear here once your profile is approved</p>
        );
      case "Lead":
        return role === "owner" || isConfirm ? (
          <ServicesLead
            leadsData={leadsData}
            servicesListing={servicesListingData.data}
            serviceData={serviceData}
            leadsUserData={leadsUserData}
          />
        ) : (
          <p>
            Your service leads will appear here once your profile is approved
          </p>
        );
      default:
        return null;
    }
  };

  return (
    <div className="Projects-page bg-gray-100 min-h-screen p-1">
      <SubHeader activeItem={activeItem} onNavigate={updateActiveItem} />
      {renderComponent()}
    </div>
  );
};
