import React, { useState } from "react";
import axios from "axios";

export const AddNewLead = ({ isOpen, onClose, onSubmit }) => {
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const [formData, setFormData] = useState({
    client_name: "",
    client_number: "",
    email: "",
    message: "",
    property_type: "",
    property_area: "",
    status: null,
    min_budget: "",
    max_budget: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      client_name: formData.client_name,
      client_number: formData.client_number,
      email: formData.email,
      location: formData.location,
      status: null,
      property_type: formData.property_type,
      property_area: formData.property_area,
      min_budget: formData.min_budget,
      max_budget: formData.max_budget,
      // crm_listings
      leads_user: {
        disconnect: [],
        connect: [
          {
            id: loggedInUser.id,
            position: {
              end: true,
            },
          },
        ],
      },
    };
    onSubmit({ data: payload });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div
        className="bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl overflow-y-auto"
        style={{ height: "60%" }}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Add New Lead</h2>
          <button
            onClick={onClose}
            className="text-2xl text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
        </div>
        <form onSubmit={handleFormSubmit}>
          <div className="mb-4 flex space-x-4">
            <div className="w-1/3">
              <label className="block text-sm font-medium mb-1">
                Client Name
              </label>
              <input
                type="text"
                name="client_name"
                value={formData.client_name}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="w-1/3">
              <label className="block text-sm font-medium mb-1">
                Contact Number
              </label>
              <input
                type="text"
                name="client_number"
                value={formData.client_number}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="w-1/3">
              <label className="block text-sm font-medium mb-1">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
          </div>
          <div className="mb-4 flex space-x-4">
            <div className="w-full">
              <label className="block text-sm font-medium mb-1">Message</label>
              <textarea
                type="text"
                name="message"
                rows={5}
                value={formData.message}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
          </div>

          <div className="flex justify-center mt-10">
            <button
              type="button"
              onClick={onClose}
              className="w-40  bg-gray-300 text-gray-700 px-4 py-2 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="w-40 bg-blue-500 ml-4 text-white px-4 py-2 rounded"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
